import { Injectable } from '@angular/core'

@Injectable()
export class AssertService {
  assertTrue(condition: boolean, message: string) {
    if (!condition) {
      this.throwException(message)
    }
  }

  throwException(message: string) {
    location.href = `/errors/${btoa(encodeURIComponent(message))}`
  }
}
