import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from 'core'
import { environment } from '../environments/environment'
import { InvitationComponent } from './invitation/invitation.component'

const routes: Routes = [
  {
    path: '',
    component: InvitationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('projects/core/src/lib/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'dev',
    loadChildren: () => import('projects/core/src/lib/dev/dev.module').then(m => m.DevModule),
  },
]

if (!environment.production) {
  // TODO: 고치기 (외않돼지??)
  routes.push({
    path: 'dev',
    loadChildren: () => import('projects/core/src/lib/dev/dev.module').then(m => m.DevModule),
  })
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
