import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthGuard } from 'core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../environments/environment'
import { taxi } from 'core/lib/proto/proto'
import UserGetInvitationCouponCodeResult = taxi.server.UserGetInvitationCouponCodeResult

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private auth: AuthGuard,
    private httpClient: HttpClient,
  ) {
  }

  private getOptions(): object {
    return {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.auth.accessToken}`,
      }),
    }
  }

  getInvitationCouponCode(): Observable<UserGetInvitationCouponCodeResult> {
    return this.httpClient.post(
      `${environment.apiUrl}/u/GetInvitationCouponCode`,
      {},
      this.getOptions(),
    ).pipe(map(result => UserGetInvitationCouponCodeResult.fromObject(result)))
  }
}
