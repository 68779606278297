import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Xliff } from '@angular/compiler'

const enContent = getEnContent()
const koContent = getKoContent()

function getEnContent() {
  // @ts-ignore
  return require('raw-loader!../../locale/messages.en.xlf').default
}

function getKoContent() {
  // @ts-ignore
  return require('raw-loader!../../locale/messages.ko.xlf').default
}

@Injectable()
export class I18nService {
  private readonly xliff: any

  constructor(
    @Inject(LOCALE_ID) private locale: string,
  ) {
    if (locale == 'en') {
      this.xliff = new Xliff().load(enContent, '')
    } else {
      this.xliff = new Xliff().load(koContent, '')
    }
  }

  get(key: string, ...replacements: string[]): string {
    let str = this.xliff.i18nNodesByMsgId[key][0].value
    replacements.forEach((r, i) => str = str.replace(`[${i}]`, r))
    return str
  }
}
