<div class="overlay" *ngIf="display"></div>

<div class="dialogContainer" *ngIf="display">
  <div class="dialog">
    <div class="title" *ngIf="dialogParams?.title != null">
      {{ dialogParams?.title }}
    </div>
    <div class="body">
      {{ dialogParams?.body }}
    </div>
    <div [ngClass]="{
      'alert': dialogParams?.dialogType === 'ALERT',
      'confirm': dialogParams?.dialogType === 'CONFIRM'
    }">
      <button
        *ngFor="let button of dialogParams?.buttons"
        (click)="close(button.value)"
        [ngStyle]="{
          'color': button.fontColor,
          'background-color': button.backgroundColor,
          'font-weight': button.fontWeight
        }">
        {{ button.name }}
      </button>
    </div>
  </div>
</div>
