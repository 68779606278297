import { EventEmitter, Injectable, NgZone } from '@angular/core'
import { taxi } from 'core/lib/proto/proto'
import IOpenShareDialogParams = taxi.server.trip.IOpenShareDialogParams
import IOpenShareDialogResult = taxi.server.trip.IOpenShareDialogResult
import OpenShareDialogResult = taxi.server.trip.OpenShareDialogResult

function _window(): any {
  // return the global native browser window object
  return window
}

@Injectable()
export class NativeService {
  isIOS: boolean

  openShareSheet: NativeCall<IOpenShareDialogParams>

  openShareSheetCallback: NativeCallback<IOpenShareDialogResult>

  constructor(private zone: NgZone) {
    this.isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

    this.openShareSheet = new NativeCall('openShareDialog', this.isIOS)

    this.openShareSheetCallback = new NativeCallback('OpenShareDialog', zone, (obj: any) => {
      return OpenShareDialogResult.fromObject(obj) as IOpenShareDialogResult
    })

    _window().tadaNativeCallback = (event: string, json: string) => {
      if (this.openShareSheetCallback.tryHandle(event, json)) {
        return
      }
    }
  }
}

export class NativeCall<Params> {
  constructor(
    public functionName: string,
    private isIOS: boolean,
  ) {
  }

  isAvailable() {
    let nativeFunction: any
    if (this.isIOS) {
      nativeFunction = _window().webkit ? _window().webkit.messageHandlers[this.functionName] : null
    } else {
      nativeFunction = _window().tada ? _window().tada[this.functionName] : null
    }
    return !!nativeFunction
  }

  call(params: Params) {
    if (this.isIOS) {
      _window().webkit.messageHandlers[this.functionName].postMessage(JSON.stringify(params))
    } else {
      _window().tada[this.functionName](JSON.stringify(params))
    }
  }
}

export class NativeCallback<Result> {
  result$: EventEmitter<Result>

  constructor(
    public callbackName: string,
    private zone: NgZone,
    public objectConversion: (obj: any) => Result,
  ) {
    this.result$ = new EventEmitter()
  }

  tryHandle(callbackName: string, json: string): boolean {
    if (callbackName === this.callbackName) {
      const result = this.objectConversion(JSON.parse(json))
      this.zone.run(() => {
        this.result$.emit(result)
      })
      return true
    }
    return false
  }
}
