import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { AuthGuard } from 'core'
import { GaService } from 'core/src/lib/ga.service'
import { ApiService } from './api.service'
import { AppRoutingModule } from './app-routing.module'

import { AppComponent } from './app.component'
import { I18nService } from './i18n.service'
import { InvitationComponent } from './invitation/invitation.component'
import { NativeService } from 'core/src/lib/native.service'

@NgModule({
  declarations: [
    AppComponent,
    InvitationComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    RouterModule,
  ],
  providers: [
    I18nService,
    ApiService,
    AuthGuard,
    NativeService,
    GaService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
