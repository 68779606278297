import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DialogComponent } from './dialog.component'

@NgModule({
  declarations: [DialogComponent],
  imports: [
    CommonModule,
  ],
  exports: [
    DialogComponent,
  ],
  providers: [
    DialogComponent,
  ],
})
export class DialogModule {
}
