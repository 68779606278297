import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable()
export class AuthGuard implements CanActivate {
  accessToken?: string

  constructor(
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const initAccessToken = location.hash.substr(1)
    if (initAccessToken) {
      this.accessToken = initAccessToken
    }
    const accessTokenFromStorage = localStorage.getItem('accessToken')
    if (accessTokenFromStorage != null) {
      this.accessToken = accessTokenFromStorage
    }
    if (!this.accessToken) {
      this.router.navigate(['/dev/auth'])
      return false
    }
    return true
  }
}
