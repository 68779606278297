import { Pipe, PipeTransform } from '@angular/core'
import { taxi } from 'core/src/lib/proto/proto'
import IPaymentMethodDTO = taxi.IPaymentMethodDTO
import CardType = taxi.PaymentMethodDTO.CardType

@Pipe({
  name: 'paymentMethod',
})
export class PaymentMethodPipe implements PipeTransform {

  transform(p: IPaymentMethodDTO): string {
    if (p.card == null) {
      return ''
    }
    let cardType = ''
    if (p.card.type == CardType.PERSONAL) {
      cardType = '개인'
    } else if (p.card.type == CardType.CORPORATION) {
      cardType = '법인'
    }
    return `${p.card.publisher} ****${p.card.partialNumber} ${cardType}`
  }
}
