<section class="content-01">
  <h1>
    <img [src]="i18nService.get('url/content-1-evnet-title')" alt="">
    <p i18n="@@invitation/content-1">타다를 알려주고 싶은 친구가 있으신가요?</p>
  </h1>
</section>

<section class="content-02">
  <div class="coupon-img"></div>
  <img [src]="i18nService.get('url/content-2')" alt="">
</section>

<ul class="tab sticky">
  <li [ngClass]="currentSection==='invitation-code-section' && 'on'">
    <a href="#invitation-code" (click)="smoothControl('#invitation-code')">
      <strong>INVITE</strong>
      <span i18n="@@invitation/tab-1-1">내 초대코드</span>
      <span i18n="@@invitation/tab-1-2">공유하기</span>
    </a>
  </li>
  <li [ngClass]="currentSection==='invitation-tip-section' && 'on'">
    <a href="#invitation-tip" (click)="smoothControl('#invitation-tip')">
      <strong>TIP</strong>
      <span i18n="@@invitation/tab-2-1">이런 친구를</span>
      <span i18n="@@invitation/tab-2-2">초대해보세요</span>
    </a>
  </li>
  <li [ngClass]="currentSection==='invitation-how-to-section' && 'on'">
    <a href="#invitation-how-to" (click)="smoothControl('#invitation-how-to')">
      <strong>HOW TO</strong>
      <span i18n="@@invitation/tab-3-1">초대코드를</span>
      <span i18n="@@invitation/tab-3-2">받으셨나요?</span>
    </a>
  </li>
</ul>

<section #invitationCodeSection id="invitation-code-section" class="invitation-code">
  <a class="anchor" id="invitation-code"></a>

  <h2 class="title">
    <img src="/assets/images/invite_title.png" alt="invite">
    <p i18n="@@invitation/code-section-title">나만의 초대코드를 친구와 공유하세요</p>
  </h2>

  <div>
    <div class="coupon-code" (click)="onClickCouponCode()">{{couponCode}}</div>
    <button (click)="shareInvitation()">
      <span i18n="@@invitation/share">내 초대코드 공유하기</span>
    </button>
  </div>
</section>

<section #invitationTipSection id="invitation-tip-section" class="invitation-tip">
  <a class="anchor" id="invitation-tip"></a>

  <h2 class="title">
    <img src="/assets/images/tip_title.png" alt="tip">
    <p i18n="@@invitation/tip-section-title">이런 친구를 초대해보세요</p>
  </h2>

  <section #slide class="slide" (scroll)="getScrollPos($event)">
    <div class="panel commuter">
      <div class="bg"></div>
        <h3 i18n="@@invitation/slide-commuter-title">프로 통근러</h3>
        <p>
          <span i18n="@@invitation/slide-commuter-detail-1">출퇴근 시간만큼은 휴식이 필요한</span>
          <span i18n="@@invitation/slide-commuter-detail-2">세상 제일 바쁜 친구</span>
        </p>
    </div>
    <div class="panel pet-butler">
      <div class="bg"></div>
        <h3 i18n="@@invitation/slide-pet-butler-title">반려동물 집사</h3>
        <p>
          <span i18n="@@invitation/slide-pet-butler-detail-1">반려동물과 외출할 때마다</span>
          <span i18n="@@invitation/slide-pet-butler-detail-2">이동 수단 걱정으로 진땀 빼는 친구</span>
        </p>
    </div>
    <div class="panel tada-lovers">
      <div class="bg"></div>
      <h3 i18n="@@invitation/slide-tada-lovers-title">전 타다 매니아</h3>
      <p>
        <span i18n="@@invitation/slide-tada-lovers-detail-1">자칭 타다 홍보팀이었으나</span>
        <span i18n="@@invitation/slide-tada-lovers-detail-2">한동안 타다를 잊고 지냈던 친구</span>
      </p>
    </div>
    <div class="panel busy-bees">
      <div class="bg"></div>
      <h3 i18n="@@invitation/slide-busy-bees-title">슈퍼맘 &#183; 슈퍼대디</h3>
      <p>
        <span i18n="@@invitation/slide-busy-bees-detail-1">아이와 함께 안전하고 편안한</span>
        <span i18n="@@invitation/slide-busy-bees-detail-2">이동 시간을 선물해주고 싶은 친구</span>
      </p>
    </div>
  </section>
  
  <div class="pagination" (click)="getPaginationIndex($event)">
    <button class="pagination-item" *ngFor="let pagination of paginationList" [class.selected] = "pagination.status">
      <span class="a11y-hidden">{{pagination.index}}</span>
    </button>
  </div>

  <div #rewardCoupons class="reward-coupons description-block" [ngClass]="hasBeenHere && 'on'">
    <div class="coupon-wrap">
      <h3>
        <span i18n="@@invitation/coupon-description/1">내가 받은</span>
        <br>
        <span i18n="@@invitation/coupon-description/2">친구초대 감사쿠폰</span>
      </h3>
      <div class="coupon-count">
        {{formatCouponsCount()}}
      </div>
      <a [href]="sanitizer.bypassSecurityTrustUrl(toCouponsLink())" i18n="@@invitation/check-reward">
        쿠폰 확인하기 
      </a>
    </div>
  </div>

  <div class="readme description-block">
    <h3>
      <span class="line"></span>
      <span class="title" i18n="@@invitation/readme">꼭 읽어주세요</span>
    </h3>
    <div i18n="@@invitation/readme/1">초대할 수 있는 친구의 수는 제한이 없습니다.</div>
    <div i18n="@@invitation/readme/2">타다 가입 유무와 관계없이, 2020년 10월 28일 이후 타다 이용 이력이 없는 친구를 초대할 수 있습니다.</div>
    <div i18n="@@invitation/readme/3">타다 앱 [메뉴>쿠폰]에서 나의 초대코드를 등록한 친구가 30일 이내에 타다를 이용하면, 나에게 감사 쿠폰이 자동 발급됩니다. (예약 서비스 이용 이력 제외)</div>
    <div i18n="@@invitation/readme/4">마케팅 수신에 동의하신 경우, 최초 1회에 한해 친구초대 감사쿠폰 발급 여부를 앱 푸시 메시지로 알려드립니다. 발급된 쿠폰은 [메뉴>쿠폰]에서 확인하실 수 있습니다.</div>
    <div i18n="@@invitation/readme/5">중복 가입 등 부정한 방법으로 친구초대를 유도할 경우 감사쿠폰 지급이 제한됩니다.</div>
    <div i18n="@@invitation/readme/6">친구초대 이벤트는 상황에 따라 사전 공지 없이 변경되거나 종료될 수 있습니다.</div>
  </div>
</section>

<section #invitationHowToSection id="invitation-how-to-section" class="footer">
  <a class="anchor" id="invitation-how-to"></a>

  <h2>
    <span i18n="@@invitation/how-to-section-title-1">친구에게</span>
    <span i18n="@@invitation/how-to-section-title-2">초대코드를 받으셨나요?</span>
  </h2>

  <div class="footer-block">
    <a [href]="sanitizer.bypassSecurityTrustUrl(toCouponsLink())" i18n="@@invitation/if-invited-button">
      지금 초대코드 등록하기
    </a>
    <div i18n="@@invitation/if-invited">
      초대코드를 등록하고 새로워진 타다를 이용해보세요
    </div>
  </div>
</section>
