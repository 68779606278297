import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { DomSanitizer, Title } from '@angular/platform-browser'
import { taxi } from 'core/lib/proto/proto'
import { GaService } from 'core/src/lib/ga.service'
import { NativeService } from 'core/src/lib/native.service'
import { environment } from '../../environments/environment'
import { ApiService } from '../api.service'
import { I18nService } from '../i18n.service'
import BrazeEvent = taxi.BrazeEvent
import OpenShareDialogParams = taxi.server.trip.OpenShareDialogParams
import KakaoTalkShareMessage = taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
import StringValue = taxi.StringValue

const panelWidth = 292

@Component({
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss'],
})
export class InvitationComponent implements OnInit {
  couponCode?: string | null = '-'
  userName: string = ''
  rewardCount: number = 0
  list = null
  presentIndex: number = 0
  panelNum: number = 4
  paginationList:Array<{id: string, status: boolean, index: number}> = []
  hasBeenHere: boolean = false

  currentSection: string = 'invitation-code-section'
  @ViewChild('invitationCodeSection') invitationCodeSectionElement?: ElementRef
  @ViewChild('invitationTipSection') invitationTipSectionElement?: ElementRef
  @ViewChild('invitationHowToSection') invitationHowToSectionElement?: ElementRef
  @ViewChild('slide') slideElement?: ElementRef
  @ViewChild('rewardCoupons') rewardCouponsElement?: ElementRef

  //이벤트용
  brazeEventName = 'invitation_share_202011'

  constructor(
    public sanitizer: DomSanitizer,
    private apiService: ApiService,
    private nativeService: NativeService,
    public i18nService: I18nService,
    private titleService: Title,
    private gaService: GaService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.i18nService.get('invitation/title'))

    this.apiService.getInvitationCouponCode()
      .subscribe(result => {
        if (result.rewardCount != null) {
          this.rewardCount = result.rewardCount
        }
        if (result.couponCode != null) {
          this.couponCode = result.couponCode.value
        }
        this.userName = result.userNameForSharing
      })

    this.setPagination()
  }

  private get appsflyerShareLink() {
    return `https://tada.onelink.me/QC69?pid=${encodeURIComponent('inapp')}&af_web_dp=${encodeURIComponent('https://tadatada.com')}&c=${encodeURIComponent('inapp_invitation_accept_211123')}&deep_link_value=${encodeURIComponent(`tada-rider://invitation/accept?code=${this.couponCode}`)}&af_dp=${encodeURIComponent('tada-rider://')}&af_channel=${encodeURIComponent('shared link')}&af_adset=${encodeURIComponent('invitation_accep')}`
  }

  shareInvitation() {
    const i18nStringValue = (key: string, ...rep: string[]) => StringValue.create({ value: this.i18nService.get(key, ...rep) })
    this.gaService.emit('invitation_share', 'invitation', 'click')

    const params = OpenShareDialogParams.create()
    params.dialogTitle = i18nStringValue('share/dialog/title')
    params.dialogDescription = i18nStringValue('share/dialog/description')
    params.textToShare = i18nStringValue('share/text-to-share', this.userName, this.couponCode!, this.appsflyerShareLink)
    params.kakaoTalkShareMessage = KakaoTalkShareMessage.create({
      title: i18nStringValue('share/kakao-talk/title', this.userName),
      description: i18nStringValue('share/kakao-talk/content', this.couponCode!),
      imageUrl: i18nStringValue('share/kakao-talk/img-url'),
      link: i18nStringValue('share/kakao-talk/link', this.appsflyerShareLink),
      buttons: [{
        title: this.i18nService.get('share/kakao-talk/button/title'),
        url: this.appsflyerShareLink,
      }],
    })

    // 이벤트용
    params.brazeEventsOnShare = [BrazeEvent.create({
      eventName: this.brazeEventName,
      properties: [{
        key: 'sharing_code',
        value: this.couponCode,
      }],
    })]

    if (this.nativeService.openShareSheet.isAvailable()) {
      this.nativeService.openShareSheet.call(params)
    }
  }

  formatCouponsCount() {
    // TODO 이거 대신 angular i18n의 복수형 표시 기능을 사용하자 (https://angular.io/guide/i18n#pluralization)
    return this.i18nService.get(
      this.rewardCount < 2 ? 'invitation/coupons-count' : 'invitation/coupons-count/plural',
      this.rewardCount.toString(),
    )
  }

  toCouponsLink() {
    return 'tada-rider://coupons'
  }

  onClickCouponCode() {
    const tempElem = document.createElement('textarea')
    tempElem.value = this.couponCode!
    document.body.appendChild(tempElem)

    tempElem.select()
    document.execCommand('copy')
    document.body.removeChild(tempElem)

    location.href = `tada-rider://show-toast?text=${encodeURIComponent(this.i18nService.get('share/copy-coupon'))}`
  }

  setPagination() {
    for (let index = 0; index < this.panelNum; index++) {
      this.paginationList = [...this.paginationList, { id: `pagination${index}`, status: !index, index }]
    }
  }

  moveSlide() {
    const slide = this.slideElement && this.slideElement.nativeElement
    slide.scrollLeft = panelWidth * this.presentIndex
  }

  togglePagination(posX: number) {
    for (let index = 0; index < this.panelNum; index++) {
      if (posX <= panelWidth * index) {
        this.presentIndex = index
        break
      }
    }

    this.paginationList.forEach((item: { status: boolean; }, index: number) => {
      item.status = index === this.presentIndex ? true : false
    })
  }

  getScrollPos(e:any) {
    const posX = e.target.scrollLeft
    this.togglePagination(posX)
  }

  getPaginationIndex(e:any) {
    if(!e.target.matches('.pagination-item')) return

    this.presentIndex = Number(e.target.children[0].innerHTML)
    this.moveSlide()
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const sectionElements = [this.invitationCodeSectionElement, this.invitationTipSectionElement, this.invitationHowToSectionElement]
    sectionElements.map(element => {
      if (element!!.nativeElement.offsetTop - window.scrollY < 300) {
        this.currentSection = element!!.nativeElement.id
      }
    })

    this.showRewardCoupons()
  }

  smoothControl(href: string) {
    const target: HTMLElement | null = document.querySelector(href)
    const to = target!!.offsetTop
    this.scrollTo(to, 800)
    return false
  }

  scrollTo(to: any, duration: number) {
    let start = window.scrollY
    let change = to - start
    let currentTime = 0
    let increment = 20

    const animateScroll = () => {
      currentTime += increment
      document.documentElement.scrollTop = this.easeInOutQuad(currentTime, start, change, duration)
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll)
      }
    }

    animateScroll()
  }

  easeInOutQuad(currentTime: number, startValue: number, changeInValue: number, duration: number) {
    currentTime /= duration / 2
    if (currentTime < 1) {
      return changeInValue / 2 * currentTime * currentTime + startValue
    }
    currentTime--
    return -changeInValue / 2 * (currentTime * (currentTime - 2) - 1) + startValue
  }

  showRewardCoupons() {
    const rewardCouponSection = this.rewardCouponsElement?.nativeElement.offsetTop - window.innerHeight

    if(rewardCouponSection - window.scrollY < -100) {
      this.hasBeenHere = true
    }
  }
}
