import { EventEmitter } from '@angular/core'

export class SnapScroll {
  element: HTMLDivElement
  widthSize: number
  maxScrollSize: number
  currentScrollIndex = 0

  constructor(element: HTMLDivElement, onChangeIndex?: EventEmitter<number>) {
    this.element = element
    this.widthSize = element.offsetWidth
    this.maxScrollSize = this.widthSize * element.childElementCount

    if (onChangeIndex) {
      element.onscroll = () => {
        this.currentScrollIndex = Math.round(element.scrollLeft / element.offsetWidth)
        onChangeIndex.emit(this.currentScrollIndex)
      }
    }
  }

  next() {
    this.setMaxScrollSize()
    if ((this.getCurrentScrollSize() + this.widthSize) >= this.maxScrollSize) {
      return
    }
    this.currentScrollIndex += 1
    this.element.scroll({
      left: this.getCurrentScrollSize(),
      behavior: 'smooth',
    })
  }

  prev() {
    this.setMaxScrollSize()
    if (this.getCurrentScrollSize() <= 0) {
      return
    }
    this.currentScrollIndex -= 1
    this.element.scroll({
      left: this.getCurrentScrollSize(),
      behavior: 'smooth',
    })
  }

  moveTo(index: number) {
    this.setMaxScrollSize()
    const target = this.widthSize * index
    if (target >= this.maxScrollSize) {
      this.element.scroll({
        left: this.maxScrollSize,
        behavior: 'smooth',
      })
    } else {
      this.element.scroll({
        left: target,
        behavior: 'smooth',
      })
    }
  }

  getCurrentScrollSize(): number {
    return this.currentScrollIndex * this.widthSize
  }

  setMaxScrollSize() {
    this.maxScrollSize = this.widthSize * this.element.childElementCount
  }
}
