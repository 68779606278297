import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'krw',
})
export class KrwPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: number | null | undefined, hasUnit = true): string {
    if (value == null) {
      if (hasUnit) {
        if (this.isKorean()) {
          return '- 원'
        }
        return '￦ -'
      }
      return '-'
    }
    const absValue = Math.abs(value)
    const operation = this.getOperation(value)
    const krw = absValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (hasUnit) {
      if (this.isKorean()) {
        return `${operation} ${krw}원`.trim()
      }
      return `${operation} ￦${krw}`.trim()
    }
    return krw
  }

  isKorean(): boolean {
    // @ts-ignore
    return this.locale.toLowerCase().startsWith('ko')
  }

  getOperation(value: number): string {
    if (value < 0) {
      return '-'
    }
    return ''
  }
}
