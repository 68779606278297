import { Injectable } from '@angular/core'
import { EventAction, EventCategory } from 'core/src/lib/ga.model'

declare const gtag: (
  event: 'event',
  eventName: string,
  properties: {
    eventCategory: EventCategory,
    eventLabel?: string,
    eventAction: EventAction,
    eventValue?: string | number | boolean
  },
) => {}

@Injectable()
export class GaService {

  constructor() {
  }

  public emit(
    eventName: string,
    eventCategory: EventCategory,
    eventAction: EventAction = 'click',
    eventLabel: string | undefined = undefined,
    eventValue: number | boolean | undefined = undefined,
  ) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    })
  }
}
