import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { DialogParams } from 'core/src/lib/dialog/dialog.model'

@Component({
  selector: 'lib-dialog[onOpenStream]',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, OnDestroy {
  dialogParams?: DialogParams
  @Input() onOpenStream!: EventEmitter<DialogParams>
  @Output() onClose$: EventEmitter<string | null>
  display = false

  constructor() {
    this.onClose$ = new EventEmitter<string | null>()
  }

  ngOnInit() {
    this.onOpenStream.asObservable().subscribe(dialogParams => {
      this.display = true
      this.dialogParams = dialogParams
    })
  }

  ngOnDestroy() {
    this.display = false
    this.onClose$.complete()
  }

  close(result: string | null) {
    this.display = false
    this.onClose$.emit(result)
  }
}
