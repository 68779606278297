/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const taxi = $root.taxi = (() => {

    /**
     * Namespace taxi.
     * @exports taxi
     * @namespace
     */
    const taxi = {};

    taxi.server = (function() {

        /**
         * Namespace server.
         * @memberof taxi
         * @namespace
         */
        const server = {};

        server.TripCancelTripParams = (function() {

            /**
             * Properties of a TripCancelTripParams.
             * @memberof taxi.server
             * @interface ITripCancelTripParams
             * @property {string|null} [tripId] TripCancelTripParams tripId
             */

            /**
             * Constructs a new TripCancelTripParams.
             * @memberof taxi.server
             * @classdesc Represents a TripCancelTripParams.
             * @implements ITripCancelTripParams
             * @constructor
             * @param {taxi.server.ITripCancelTripParams=} [p] Properties to set
             */
            function TripCancelTripParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripCancelTripParams tripId.
             * @member {string} tripId
             * @memberof taxi.server.TripCancelTripParams
             * @instance
             */
            TripCancelTripParams.prototype.tripId = "";

            /**
             * Creates a new TripCancelTripParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripCancelTripParams
             * @static
             * @param {taxi.server.ITripCancelTripParams=} [properties] Properties to set
             * @returns {taxi.server.TripCancelTripParams} TripCancelTripParams instance
             */
            TripCancelTripParams.create = function create(properties) {
                return new TripCancelTripParams(properties);
            };

            /**
             * Creates a TripCancelTripParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripCancelTripParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripCancelTripParams} TripCancelTripParams
             */
            TripCancelTripParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripCancelTripParams)
                    return d;
                var m = new $root.taxi.server.TripCancelTripParams();
                if (d.tripId != null) {
                    m.tripId = String(d.tripId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripCancelTripParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripCancelTripParams
             * @static
             * @param {taxi.server.TripCancelTripParams} m TripCancelTripParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripCancelTripParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripId = "";
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = m.tripId;
                }
                return d;
            };

            /**
             * Converts this TripCancelTripParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripCancelTripParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripCancelTripParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripCancelTripParams;
        })();

        server.TripCancelTripResult = (function() {

            /**
             * Properties of a TripCancelTripResult.
             * @memberof taxi.server
             * @interface ITripCancelTripResult
             * @property {taxi.ITripReservation|null} [trip] TripCancelTripResult trip
             */

            /**
             * Constructs a new TripCancelTripResult.
             * @memberof taxi.server
             * @classdesc Represents a TripCancelTripResult.
             * @implements ITripCancelTripResult
             * @constructor
             * @param {taxi.server.ITripCancelTripResult=} [p] Properties to set
             */
            function TripCancelTripResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripCancelTripResult trip.
             * @member {taxi.ITripReservation|null|undefined} trip
             * @memberof taxi.server.TripCancelTripResult
             * @instance
             */
            TripCancelTripResult.prototype.trip = null;

            /**
             * Creates a new TripCancelTripResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripCancelTripResult
             * @static
             * @param {taxi.server.ITripCancelTripResult=} [properties] Properties to set
             * @returns {taxi.server.TripCancelTripResult} TripCancelTripResult instance
             */
            TripCancelTripResult.create = function create(properties) {
                return new TripCancelTripResult(properties);
            };

            /**
             * Creates a TripCancelTripResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripCancelTripResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripCancelTripResult} TripCancelTripResult
             */
            TripCancelTripResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripCancelTripResult)
                    return d;
                var m = new $root.taxi.server.TripCancelTripResult();
                if (d.trip != null) {
                    if (typeof d.trip !== "object")
                        throw TypeError(".taxi.server.TripCancelTripResult.trip: object expected");
                    m.trip = $root.taxi.TripReservation.fromObject(d.trip);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripCancelTripResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripCancelTripResult
             * @static
             * @param {taxi.server.TripCancelTripResult} m TripCancelTripResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripCancelTripResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.trip = null;
                }
                if (m.trip != null && m.hasOwnProperty("trip")) {
                    d.trip = $root.taxi.TripReservation.toObject(m.trip, o);
                }
                return d;
            };

            /**
             * Converts this TripCancelTripResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripCancelTripResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripCancelTripResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripCancelTripResult;
        })();

        server.TripCountScheduledTripParams = (function() {

            /**
             * Properties of a TripCountScheduledTripParams.
             * @memberof taxi.server
             * @interface ITripCountScheduledTripParams
             */

            /**
             * Constructs a new TripCountScheduledTripParams.
             * @memberof taxi.server
             * @classdesc Represents a TripCountScheduledTripParams.
             * @implements ITripCountScheduledTripParams
             * @constructor
             * @param {taxi.server.ITripCountScheduledTripParams=} [p] Properties to set
             */
            function TripCountScheduledTripParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripCountScheduledTripParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripCountScheduledTripParams
             * @static
             * @param {taxi.server.ITripCountScheduledTripParams=} [properties] Properties to set
             * @returns {taxi.server.TripCountScheduledTripParams} TripCountScheduledTripParams instance
             */
            TripCountScheduledTripParams.create = function create(properties) {
                return new TripCountScheduledTripParams(properties);
            };

            /**
             * Creates a TripCountScheduledTripParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripCountScheduledTripParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripCountScheduledTripParams} TripCountScheduledTripParams
             */
            TripCountScheduledTripParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripCountScheduledTripParams)
                    return d;
                return new $root.taxi.server.TripCountScheduledTripParams();
            };

            /**
             * Creates a plain object from a TripCountScheduledTripParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripCountScheduledTripParams
             * @static
             * @param {taxi.server.TripCountScheduledTripParams} m TripCountScheduledTripParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripCountScheduledTripParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripCountScheduledTripParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripCountScheduledTripParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripCountScheduledTripParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripCountScheduledTripParams;
        })();

        server.TripCountScheduledTripResult = (function() {

            /**
             * Properties of a TripCountScheduledTripResult.
             * @memberof taxi.server
             * @interface ITripCountScheduledTripResult
             * @property {number|null} [count] TripCountScheduledTripResult count
             */

            /**
             * Constructs a new TripCountScheduledTripResult.
             * @memberof taxi.server
             * @classdesc Represents a TripCountScheduledTripResult.
             * @implements ITripCountScheduledTripResult
             * @constructor
             * @param {taxi.server.ITripCountScheduledTripResult=} [p] Properties to set
             */
            function TripCountScheduledTripResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripCountScheduledTripResult count.
             * @member {number} count
             * @memberof taxi.server.TripCountScheduledTripResult
             * @instance
             */
            TripCountScheduledTripResult.prototype.count = 0;

            /**
             * Creates a new TripCountScheduledTripResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripCountScheduledTripResult
             * @static
             * @param {taxi.server.ITripCountScheduledTripResult=} [properties] Properties to set
             * @returns {taxi.server.TripCountScheduledTripResult} TripCountScheduledTripResult instance
             */
            TripCountScheduledTripResult.create = function create(properties) {
                return new TripCountScheduledTripResult(properties);
            };

            /**
             * Creates a TripCountScheduledTripResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripCountScheduledTripResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripCountScheduledTripResult} TripCountScheduledTripResult
             */
            TripCountScheduledTripResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripCountScheduledTripResult)
                    return d;
                var m = new $root.taxi.server.TripCountScheduledTripResult();
                if (d.count != null) {
                    m.count = d.count | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a TripCountScheduledTripResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripCountScheduledTripResult
             * @static
             * @param {taxi.server.TripCountScheduledTripResult} m TripCountScheduledTripResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripCountScheduledTripResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.count = 0;
                }
                if (m.count != null && m.hasOwnProperty("count")) {
                    d.count = m.count;
                }
                return d;
            };

            /**
             * Converts this TripCountScheduledTripResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripCountScheduledTripResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripCountScheduledTripResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripCountScheduledTripResult;
        })();

        server.TripCreateAirParams = (function() {

            /**
             * Properties of a TripCreateAirParams.
             * @memberof taxi.server
             * @interface ITripCreateAirParams
             * @property {taxi.ITripRider|null} [rider] TripCreateAirParams rider
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripCreateAirParams vehicleType
             * @property {taxi.ITripAddressedLocation|null} [location] TripCreateAirParams location
             * @property {Array.<taxi.ITripAddressedLocation>|null} [waypoints] TripCreateAirParams waypoints
             * @property {taxi.ITimestamp|null} [pickUpAt] TripCreateAirParams pickUpAt
             * @property {taxi.IStringValue|null} [comment] TripCreateAirParams comment
             * @property {number|null} [passenger] TripCreateAirParams passenger
             * @property {taxi.ITripLuggage|null} [luggage] TripCreateAirParams luggage
             * @property {number|null} [basicKm] TripCreateAirParams basicKm
             * @property {number|null} [feePerKm] TripCreateAirParams feePerKm
             * @property {string|null} [flightCode] TripCreateAirParams flightCode
             * @property {taxi.AirportCode|null} [airportCode] TripCreateAirParams airportCode
             * @property {taxi.TripAirType|null} [airType] TripCreateAirParams airType
             * @property {string|null} [paymentMethodId] TripCreateAirParams paymentMethodId
             * @property {taxi.IStringValue|null} [paymentProfileId] TripCreateAirParams paymentProfileId
             * @property {taxi.IStringValue|null} [couponCode] TripCreateAirParams couponCode
             * @property {taxi.IStringValue|null} [couponId] TripCreateAirParams couponId
             * @property {taxi.IStringValue|null} [estimationToken] TripCreateAirParams estimationToken
             * @property {taxi.IStringValue|null} [bizReason] TripCreateAirParams bizReason
             * @property {taxi.IStringValue|null} [designatedDriverComment] TripCreateAirParams designatedDriverComment
             * @property {taxi.IStringValue|null} [airDealId] TripCreateAirParams airDealId
             */

            /**
             * Constructs a new TripCreateAirParams.
             * @memberof taxi.server
             * @classdesc Represents a TripCreateAirParams.
             * @implements ITripCreateAirParams
             * @constructor
             * @param {taxi.server.ITripCreateAirParams=} [p] Properties to set
             */
            function TripCreateAirParams(p) {
                this.waypoints = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripCreateAirParams rider.
             * @member {taxi.ITripRider|null|undefined} rider
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.rider = null;

            /**
             * TripCreateAirParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.vehicleType = 0;

            /**
             * TripCreateAirParams location.
             * @member {taxi.ITripAddressedLocation|null|undefined} location
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.location = null;

            /**
             * TripCreateAirParams waypoints.
             * @member {Array.<taxi.ITripAddressedLocation>} waypoints
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.waypoints = $util.emptyArray;

            /**
             * TripCreateAirParams pickUpAt.
             * @member {taxi.ITimestamp|null|undefined} pickUpAt
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.pickUpAt = null;

            /**
             * TripCreateAirParams comment.
             * @member {taxi.IStringValue|null|undefined} comment
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.comment = null;

            /**
             * TripCreateAirParams passenger.
             * @member {number} passenger
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.passenger = 0;

            /**
             * TripCreateAirParams luggage.
             * @member {taxi.ITripLuggage|null|undefined} luggage
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.luggage = null;

            /**
             * TripCreateAirParams basicKm.
             * @member {number} basicKm
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.basicKm = 0;

            /**
             * TripCreateAirParams feePerKm.
             * @member {number} feePerKm
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.feePerKm = 0;

            /**
             * TripCreateAirParams flightCode.
             * @member {string} flightCode
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.flightCode = "";

            /**
             * TripCreateAirParams airportCode.
             * @member {taxi.AirportCode} airportCode
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.airportCode = 0;

            /**
             * TripCreateAirParams airType.
             * @member {taxi.TripAirType} airType
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.airType = 0;

            /**
             * TripCreateAirParams paymentMethodId.
             * @member {string} paymentMethodId
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.paymentMethodId = "";

            /**
             * TripCreateAirParams paymentProfileId.
             * @member {taxi.IStringValue|null|undefined} paymentProfileId
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.paymentProfileId = null;

            /**
             * TripCreateAirParams couponCode.
             * @member {taxi.IStringValue|null|undefined} couponCode
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.couponCode = null;

            /**
             * TripCreateAirParams couponId.
             * @member {taxi.IStringValue|null|undefined} couponId
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.couponId = null;

            /**
             * TripCreateAirParams estimationToken.
             * @member {taxi.IStringValue|null|undefined} estimationToken
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.estimationToken = null;

            /**
             * TripCreateAirParams bizReason.
             * @member {taxi.IStringValue|null|undefined} bizReason
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.bizReason = null;

            /**
             * TripCreateAirParams designatedDriverComment.
             * @member {taxi.IStringValue|null|undefined} designatedDriverComment
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.designatedDriverComment = null;

            /**
             * TripCreateAirParams airDealId.
             * @member {taxi.IStringValue|null|undefined} airDealId
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             */
            TripCreateAirParams.prototype.airDealId = null;

            /**
             * Creates a new TripCreateAirParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripCreateAirParams
             * @static
             * @param {taxi.server.ITripCreateAirParams=} [properties] Properties to set
             * @returns {taxi.server.TripCreateAirParams} TripCreateAirParams instance
             */
            TripCreateAirParams.create = function create(properties) {
                return new TripCreateAirParams(properties);
            };

            /**
             * Creates a TripCreateAirParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripCreateAirParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripCreateAirParams} TripCreateAirParams
             */
            TripCreateAirParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripCreateAirParams)
                    return d;
                var m = new $root.taxi.server.TripCreateAirParams();
                if (d.rider != null) {
                    if (typeof d.rider !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.rider: object expected");
                    m.rider = $root.taxi.TripRider.fromObject(d.rider);
                }
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                if (d.location != null) {
                    if (typeof d.location !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.location: object expected");
                    m.location = $root.taxi.TripAddressedLocation.fromObject(d.location);
                }
                if (d.waypoints) {
                    if (!Array.isArray(d.waypoints))
                        throw TypeError(".taxi.server.TripCreateAirParams.waypoints: array expected");
                    m.waypoints = [];
                    for (var i = 0; i < d.waypoints.length; ++i) {
                        if (typeof d.waypoints[i] !== "object")
                            throw TypeError(".taxi.server.TripCreateAirParams.waypoints: object expected");
                        m.waypoints[i] = $root.taxi.TripAddressedLocation.fromObject(d.waypoints[i]);
                    }
                }
                if (d.pickUpAt != null) {
                    if (typeof d.pickUpAt !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.pickUpAt: object expected");
                    m.pickUpAt = $root.taxi.Timestamp.fromObject(d.pickUpAt);
                }
                if (d.comment != null) {
                    if (typeof d.comment !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.comment: object expected");
                    m.comment = $root.taxi.StringValue.fromObject(d.comment);
                }
                if (d.passenger != null) {
                    m.passenger = d.passenger | 0;
                }
                if (d.luggage != null) {
                    if (typeof d.luggage !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.luggage: object expected");
                    m.luggage = $root.taxi.TripLuggage.fromObject(d.luggage);
                }
                if (d.basicKm != null) {
                    m.basicKm = d.basicKm | 0;
                }
                if (d.feePerKm != null) {
                    m.feePerKm = d.feePerKm | 0;
                }
                if (d.flightCode != null) {
                    m.flightCode = String(d.flightCode);
                }
                switch (d.airportCode) {
                case "ICN_1":
                case 0:
                    m.airportCode = 0;
                    break;
                case "ICN_2":
                case 1:
                    m.airportCode = 1;
                    break;
                case "GMP_INTERNATIONAL":
                case 3:
                    m.airportCode = 3;
                    break;
                case "GMP_DOMESTIC":
                case 4:
                    m.airportCode = 4;
                    break;
                case "CJU_INTERNATIONAL":
                case 5:
                    m.airportCode = 5;
                    break;
                case "CJU_DOMESTIC":
                case 6:
                    m.airportCode = 6;
                    break;
                case "PUS_INTERNATIONAL":
                case 7:
                    m.airportCode = 7;
                    break;
                case "PUS_DOMESTIC":
                case 8:
                    m.airportCode = 8;
                    break;
                }
                switch (d.airType) {
                case "SENDING":
                case 0:
                    m.airType = 0;
                    break;
                case "PICK_UP":
                case 1:
                    m.airType = 1;
                    break;
                }
                if (d.paymentMethodId != null) {
                    m.paymentMethodId = String(d.paymentMethodId);
                }
                if (d.paymentProfileId != null) {
                    if (typeof d.paymentProfileId !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.paymentProfileId: object expected");
                    m.paymentProfileId = $root.taxi.StringValue.fromObject(d.paymentProfileId);
                }
                if (d.couponCode != null) {
                    if (typeof d.couponCode !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.couponCode: object expected");
                    m.couponCode = $root.taxi.StringValue.fromObject(d.couponCode);
                }
                if (d.couponId != null) {
                    if (typeof d.couponId !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.couponId: object expected");
                    m.couponId = $root.taxi.StringValue.fromObject(d.couponId);
                }
                if (d.estimationToken != null) {
                    if (typeof d.estimationToken !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.estimationToken: object expected");
                    m.estimationToken = $root.taxi.StringValue.fromObject(d.estimationToken);
                }
                if (d.bizReason != null) {
                    if (typeof d.bizReason !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.bizReason: object expected");
                    m.bizReason = $root.taxi.StringValue.fromObject(d.bizReason);
                }
                if (d.designatedDriverComment != null) {
                    if (typeof d.designatedDriverComment !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.designatedDriverComment: object expected");
                    m.designatedDriverComment = $root.taxi.StringValue.fromObject(d.designatedDriverComment);
                }
                if (d.airDealId != null) {
                    if (typeof d.airDealId !== "object")
                        throw TypeError(".taxi.server.TripCreateAirParams.airDealId: object expected");
                    m.airDealId = $root.taxi.StringValue.fromObject(d.airDealId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripCreateAirParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripCreateAirParams
             * @static
             * @param {taxi.server.TripCreateAirParams} m TripCreateAirParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripCreateAirParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.waypoints = [];
                }
                if (o.defaults) {
                    d.rider = null;
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                    d.location = null;
                    d.pickUpAt = null;
                    d.comment = null;
                    d.passenger = 0;
                    d.luggage = null;
                    d.basicKm = 0;
                    d.feePerKm = 0;
                    d.flightCode = "";
                    d.airportCode = o.enums === String ? "ICN_1" : 0;
                    d.airType = o.enums === String ? "SENDING" : 0;
                    d.paymentMethodId = "";
                    d.couponCode = null;
                    d.couponId = null;
                    d.paymentProfileId = null;
                    d.bizReason = null;
                    d.estimationToken = null;
                    d.designatedDriverComment = null;
                    d.airDealId = null;
                }
                if (m.rider != null && m.hasOwnProperty("rider")) {
                    d.rider = $root.taxi.TripRider.toObject(m.rider, o);
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                if (m.location != null && m.hasOwnProperty("location")) {
                    d.location = $root.taxi.TripAddressedLocation.toObject(m.location, o);
                }
                if (m.waypoints && m.waypoints.length) {
                    d.waypoints = [];
                    for (var j = 0; j < m.waypoints.length; ++j) {
                        d.waypoints[j] = $root.taxi.TripAddressedLocation.toObject(m.waypoints[j], o);
                    }
                }
                if (m.pickUpAt != null && m.hasOwnProperty("pickUpAt")) {
                    d.pickUpAt = $root.taxi.Timestamp.toObject(m.pickUpAt, o);
                }
                if (m.comment != null && m.hasOwnProperty("comment")) {
                    d.comment = $root.taxi.StringValue.toObject(m.comment, o);
                }
                if (m.passenger != null && m.hasOwnProperty("passenger")) {
                    d.passenger = m.passenger;
                }
                if (m.luggage != null && m.hasOwnProperty("luggage")) {
                    d.luggage = $root.taxi.TripLuggage.toObject(m.luggage, o);
                }
                if (m.basicKm != null && m.hasOwnProperty("basicKm")) {
                    d.basicKm = m.basicKm;
                }
                if (m.feePerKm != null && m.hasOwnProperty("feePerKm")) {
                    d.feePerKm = m.feePerKm;
                }
                if (m.flightCode != null && m.hasOwnProperty("flightCode")) {
                    d.flightCode = m.flightCode;
                }
                if (m.airportCode != null && m.hasOwnProperty("airportCode")) {
                    d.airportCode = o.enums === String ? $root.taxi.AirportCode[m.airportCode] : m.airportCode;
                }
                if (m.airType != null && m.hasOwnProperty("airType")) {
                    d.airType = o.enums === String ? $root.taxi.TripAirType[m.airType] : m.airType;
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = m.paymentMethodId;
                }
                if (m.couponCode != null && m.hasOwnProperty("couponCode")) {
                    d.couponCode = $root.taxi.StringValue.toObject(m.couponCode, o);
                }
                if (m.couponId != null && m.hasOwnProperty("couponId")) {
                    d.couponId = $root.taxi.StringValue.toObject(m.couponId, o);
                }
                if (m.paymentProfileId != null && m.hasOwnProperty("paymentProfileId")) {
                    d.paymentProfileId = $root.taxi.StringValue.toObject(m.paymentProfileId, o);
                }
                if (m.bizReason != null && m.hasOwnProperty("bizReason")) {
                    d.bizReason = $root.taxi.StringValue.toObject(m.bizReason, o);
                }
                if (m.estimationToken != null && m.hasOwnProperty("estimationToken")) {
                    d.estimationToken = $root.taxi.StringValue.toObject(m.estimationToken, o);
                }
                if (m.designatedDriverComment != null && m.hasOwnProperty("designatedDriverComment")) {
                    d.designatedDriverComment = $root.taxi.StringValue.toObject(m.designatedDriverComment, o);
                }
                if (m.airDealId != null && m.hasOwnProperty("airDealId")) {
                    d.airDealId = $root.taxi.StringValue.toObject(m.airDealId, o);
                }
                return d;
            };

            /**
             * Converts this TripCreateAirParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripCreateAirParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripCreateAirParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripCreateAirParams;
        })();

        server.TripCreateAirResult = (function() {

            /**
             * Properties of a TripCreateAirResult.
             * @memberof taxi.server
             * @interface ITripCreateAirResult
             * @property {string|null} [tripId] TripCreateAirResult tripId
             * @property {string|null} [notice] TripCreateAirResult notice
             */

            /**
             * Constructs a new TripCreateAirResult.
             * @memberof taxi.server
             * @classdesc Represents a TripCreateAirResult.
             * @implements ITripCreateAirResult
             * @constructor
             * @param {taxi.server.ITripCreateAirResult=} [p] Properties to set
             */
            function TripCreateAirResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripCreateAirResult tripId.
             * @member {string} tripId
             * @memberof taxi.server.TripCreateAirResult
             * @instance
             */
            TripCreateAirResult.prototype.tripId = "";

            /**
             * TripCreateAirResult notice.
             * @member {string} notice
             * @memberof taxi.server.TripCreateAirResult
             * @instance
             */
            TripCreateAirResult.prototype.notice = "";

            /**
             * Creates a new TripCreateAirResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripCreateAirResult
             * @static
             * @param {taxi.server.ITripCreateAirResult=} [properties] Properties to set
             * @returns {taxi.server.TripCreateAirResult} TripCreateAirResult instance
             */
            TripCreateAirResult.create = function create(properties) {
                return new TripCreateAirResult(properties);
            };

            /**
             * Creates a TripCreateAirResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripCreateAirResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripCreateAirResult} TripCreateAirResult
             */
            TripCreateAirResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripCreateAirResult)
                    return d;
                var m = new $root.taxi.server.TripCreateAirResult();
                if (d.tripId != null) {
                    m.tripId = String(d.tripId);
                }
                if (d.notice != null) {
                    m.notice = String(d.notice);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripCreateAirResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripCreateAirResult
             * @static
             * @param {taxi.server.TripCreateAirResult} m TripCreateAirResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripCreateAirResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripId = "";
                    d.notice = "";
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = m.tripId;
                }
                if (m.notice != null && m.hasOwnProperty("notice")) {
                    d.notice = m.notice;
                }
                return d;
            };

            /**
             * Converts this TripCreateAirResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripCreateAirResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripCreateAirResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripCreateAirResult;
        })();

        server.TripCreateCharterParams = (function() {

            /**
             * Properties of a TripCreateCharterParams.
             * @memberof taxi.server
             * @interface ITripCreateCharterParams
             * @property {taxi.ITripRider|null} [rider] TripCreateCharterParams rider
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripCreateCharterParams vehicleType
             * @property {taxi.ITripAddressedLocation|null} [origin] TripCreateCharterParams origin
             * @property {Array.<taxi.ITripAddressedLocation>|null} [waypoints] TripCreateCharterParams waypoints
             * @property {taxi.ITripAddressedLocation|null} [destination] TripCreateCharterParams destination
             * @property {taxi.ITripAddressedLocation|null} [golfClub] TripCreateCharterParams golfClub
             * @property {taxi.ITimestamp|null} [pickUpAt] TripCreateCharterParams pickUpAt
             * @property {taxi.IStringValue|null} [comment] TripCreateCharterParams comment
             * @property {number|null} [passenger] TripCreateCharterParams passenger
             * @property {taxi.ITripLuggage|null} [luggage] TripCreateCharterParams luggage
             * @property {number|null} [basicKm] TripCreateCharterParams basicKm
             * @property {number|null} [feePerKm] TripCreateCharterParams feePerKm
             * @property {number|null} [basicMinute] TripCreateCharterParams basicMinute
             * @property {number|null} [feePerHour] TripCreateCharterParams feePerHour
             * @property {number|null} [vehicleCount] TripCreateCharterParams vehicleCount
             * @property {string|null} [paymentMethodId] TripCreateCharterParams paymentMethodId
             * @property {taxi.IStringValue|null} [paymentProfileId] TripCreateCharterParams paymentProfileId
             * @property {taxi.IStringValue|null} [couponCode] TripCreateCharterParams couponCode
             * @property {taxi.IStringValue|null} [couponId] TripCreateCharterParams couponId
             * @property {taxi.IStringValue|null} [estimationToken] TripCreateCharterParams estimationToken
             * @property {taxi.IStringValue|null} [bizReason] TripCreateCharterParams bizReason
             * @property {taxi.IStringValue|null} [designatedDriverComment] TripCreateCharterParams designatedDriverComment
             */

            /**
             * Constructs a new TripCreateCharterParams.
             * @memberof taxi.server
             * @classdesc Represents a TripCreateCharterParams.
             * @implements ITripCreateCharterParams
             * @constructor
             * @param {taxi.server.ITripCreateCharterParams=} [p] Properties to set
             */
            function TripCreateCharterParams(p) {
                this.waypoints = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripCreateCharterParams rider.
             * @member {taxi.ITripRider|null|undefined} rider
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.rider = null;

            /**
             * TripCreateCharterParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.vehicleType = 0;

            /**
             * TripCreateCharterParams origin.
             * @member {taxi.ITripAddressedLocation|null|undefined} origin
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.origin = null;

            /**
             * TripCreateCharterParams waypoints.
             * @member {Array.<taxi.ITripAddressedLocation>} waypoints
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.waypoints = $util.emptyArray;

            /**
             * TripCreateCharterParams destination.
             * @member {taxi.ITripAddressedLocation|null|undefined} destination
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.destination = null;

            /**
             * TripCreateCharterParams golfClub.
             * @member {taxi.ITripAddressedLocation|null|undefined} golfClub
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.golfClub = null;

            /**
             * TripCreateCharterParams pickUpAt.
             * @member {taxi.ITimestamp|null|undefined} pickUpAt
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.pickUpAt = null;

            /**
             * TripCreateCharterParams comment.
             * @member {taxi.IStringValue|null|undefined} comment
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.comment = null;

            /**
             * TripCreateCharterParams passenger.
             * @member {number} passenger
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.passenger = 0;

            /**
             * TripCreateCharterParams luggage.
             * @member {taxi.ITripLuggage|null|undefined} luggage
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.luggage = null;

            /**
             * TripCreateCharterParams basicKm.
             * @member {number} basicKm
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.basicKm = 0;

            /**
             * TripCreateCharterParams feePerKm.
             * @member {number} feePerKm
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.feePerKm = 0;

            /**
             * TripCreateCharterParams basicMinute.
             * @member {number} basicMinute
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.basicMinute = 0;

            /**
             * TripCreateCharterParams feePerHour.
             * @member {number} feePerHour
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.feePerHour = 0;

            /**
             * TripCreateCharterParams vehicleCount.
             * @member {number} vehicleCount
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.vehicleCount = 0;

            /**
             * TripCreateCharterParams paymentMethodId.
             * @member {string} paymentMethodId
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.paymentMethodId = "";

            /**
             * TripCreateCharterParams paymentProfileId.
             * @member {taxi.IStringValue|null|undefined} paymentProfileId
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.paymentProfileId = null;

            /**
             * TripCreateCharterParams couponCode.
             * @member {taxi.IStringValue|null|undefined} couponCode
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.couponCode = null;

            /**
             * TripCreateCharterParams couponId.
             * @member {taxi.IStringValue|null|undefined} couponId
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.couponId = null;

            /**
             * TripCreateCharterParams estimationToken.
             * @member {taxi.IStringValue|null|undefined} estimationToken
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.estimationToken = null;

            /**
             * TripCreateCharterParams bizReason.
             * @member {taxi.IStringValue|null|undefined} bizReason
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.bizReason = null;

            /**
             * TripCreateCharterParams designatedDriverComment.
             * @member {taxi.IStringValue|null|undefined} designatedDriverComment
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             */
            TripCreateCharterParams.prototype.designatedDriverComment = null;

            /**
             * Creates a new TripCreateCharterParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripCreateCharterParams
             * @static
             * @param {taxi.server.ITripCreateCharterParams=} [properties] Properties to set
             * @returns {taxi.server.TripCreateCharterParams} TripCreateCharterParams instance
             */
            TripCreateCharterParams.create = function create(properties) {
                return new TripCreateCharterParams(properties);
            };

            /**
             * Creates a TripCreateCharterParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripCreateCharterParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripCreateCharterParams} TripCreateCharterParams
             */
            TripCreateCharterParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripCreateCharterParams)
                    return d;
                var m = new $root.taxi.server.TripCreateCharterParams();
                if (d.rider != null) {
                    if (typeof d.rider !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.rider: object expected");
                    m.rider = $root.taxi.TripRider.fromObject(d.rider);
                }
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                if (d.origin != null) {
                    if (typeof d.origin !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.origin: object expected");
                    m.origin = $root.taxi.TripAddressedLocation.fromObject(d.origin);
                }
                if (d.waypoints) {
                    if (!Array.isArray(d.waypoints))
                        throw TypeError(".taxi.server.TripCreateCharterParams.waypoints: array expected");
                    m.waypoints = [];
                    for (var i = 0; i < d.waypoints.length; ++i) {
                        if (typeof d.waypoints[i] !== "object")
                            throw TypeError(".taxi.server.TripCreateCharterParams.waypoints: object expected");
                        m.waypoints[i] = $root.taxi.TripAddressedLocation.fromObject(d.waypoints[i]);
                    }
                }
                if (d.destination != null) {
                    if (typeof d.destination !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.destination: object expected");
                    m.destination = $root.taxi.TripAddressedLocation.fromObject(d.destination);
                }
                if (d.golfClub != null) {
                    if (typeof d.golfClub !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.golfClub: object expected");
                    m.golfClub = $root.taxi.TripAddressedLocation.fromObject(d.golfClub);
                }
                if (d.pickUpAt != null) {
                    if (typeof d.pickUpAt !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.pickUpAt: object expected");
                    m.pickUpAt = $root.taxi.Timestamp.fromObject(d.pickUpAt);
                }
                if (d.comment != null) {
                    if (typeof d.comment !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.comment: object expected");
                    m.comment = $root.taxi.StringValue.fromObject(d.comment);
                }
                if (d.passenger != null) {
                    m.passenger = d.passenger | 0;
                }
                if (d.luggage != null) {
                    if (typeof d.luggage !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.luggage: object expected");
                    m.luggage = $root.taxi.TripLuggage.fromObject(d.luggage);
                }
                if (d.basicKm != null) {
                    m.basicKm = d.basicKm | 0;
                }
                if (d.feePerKm != null) {
                    m.feePerKm = d.feePerKm | 0;
                }
                if (d.basicMinute != null) {
                    m.basicMinute = d.basicMinute | 0;
                }
                if (d.feePerHour != null) {
                    m.feePerHour = d.feePerHour | 0;
                }
                if (d.vehicleCount != null) {
                    m.vehicleCount = d.vehicleCount | 0;
                }
                if (d.paymentMethodId != null) {
                    m.paymentMethodId = String(d.paymentMethodId);
                }
                if (d.paymentProfileId != null) {
                    if (typeof d.paymentProfileId !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.paymentProfileId: object expected");
                    m.paymentProfileId = $root.taxi.StringValue.fromObject(d.paymentProfileId);
                }
                if (d.couponCode != null) {
                    if (typeof d.couponCode !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.couponCode: object expected");
                    m.couponCode = $root.taxi.StringValue.fromObject(d.couponCode);
                }
                if (d.couponId != null) {
                    if (typeof d.couponId !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.couponId: object expected");
                    m.couponId = $root.taxi.StringValue.fromObject(d.couponId);
                }
                if (d.estimationToken != null) {
                    if (typeof d.estimationToken !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.estimationToken: object expected");
                    m.estimationToken = $root.taxi.StringValue.fromObject(d.estimationToken);
                }
                if (d.bizReason != null) {
                    if (typeof d.bizReason !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.bizReason: object expected");
                    m.bizReason = $root.taxi.StringValue.fromObject(d.bizReason);
                }
                if (d.designatedDriverComment != null) {
                    if (typeof d.designatedDriverComment !== "object")
                        throw TypeError(".taxi.server.TripCreateCharterParams.designatedDriverComment: object expected");
                    m.designatedDriverComment = $root.taxi.StringValue.fromObject(d.designatedDriverComment);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripCreateCharterParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripCreateCharterParams
             * @static
             * @param {taxi.server.TripCreateCharterParams} m TripCreateCharterParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripCreateCharterParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.waypoints = [];
                }
                if (o.defaults) {
                    d.rider = null;
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                    d.origin = null;
                    d.destination = null;
                    d.pickUpAt = null;
                    d.comment = null;
                    d.passenger = 0;
                    d.luggage = null;
                    d.golfClub = null;
                    d.basicKm = 0;
                    d.feePerKm = 0;
                    d.basicMinute = 0;
                    d.feePerHour = 0;
                    d.vehicleCount = 0;
                    d.paymentMethodId = "";
                    d.couponCode = null;
                    d.couponId = null;
                    d.paymentProfileId = null;
                    d.bizReason = null;
                    d.estimationToken = null;
                    d.designatedDriverComment = null;
                }
                if (m.rider != null && m.hasOwnProperty("rider")) {
                    d.rider = $root.taxi.TripRider.toObject(m.rider, o);
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                if (m.origin != null && m.hasOwnProperty("origin")) {
                    d.origin = $root.taxi.TripAddressedLocation.toObject(m.origin, o);
                }
                if (m.waypoints && m.waypoints.length) {
                    d.waypoints = [];
                    for (var j = 0; j < m.waypoints.length; ++j) {
                        d.waypoints[j] = $root.taxi.TripAddressedLocation.toObject(m.waypoints[j], o);
                    }
                }
                if (m.destination != null && m.hasOwnProperty("destination")) {
                    d.destination = $root.taxi.TripAddressedLocation.toObject(m.destination, o);
                }
                if (m.pickUpAt != null && m.hasOwnProperty("pickUpAt")) {
                    d.pickUpAt = $root.taxi.Timestamp.toObject(m.pickUpAt, o);
                }
                if (m.comment != null && m.hasOwnProperty("comment")) {
                    d.comment = $root.taxi.StringValue.toObject(m.comment, o);
                }
                if (m.passenger != null && m.hasOwnProperty("passenger")) {
                    d.passenger = m.passenger;
                }
                if (m.luggage != null && m.hasOwnProperty("luggage")) {
                    d.luggage = $root.taxi.TripLuggage.toObject(m.luggage, o);
                }
                if (m.golfClub != null && m.hasOwnProperty("golfClub")) {
                    d.golfClub = $root.taxi.TripAddressedLocation.toObject(m.golfClub, o);
                }
                if (m.basicKm != null && m.hasOwnProperty("basicKm")) {
                    d.basicKm = m.basicKm;
                }
                if (m.feePerKm != null && m.hasOwnProperty("feePerKm")) {
                    d.feePerKm = m.feePerKm;
                }
                if (m.basicMinute != null && m.hasOwnProperty("basicMinute")) {
                    d.basicMinute = m.basicMinute;
                }
                if (m.feePerHour != null && m.hasOwnProperty("feePerHour")) {
                    d.feePerHour = m.feePerHour;
                }
                if (m.vehicleCount != null && m.hasOwnProperty("vehicleCount")) {
                    d.vehicleCount = m.vehicleCount;
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = m.paymentMethodId;
                }
                if (m.couponCode != null && m.hasOwnProperty("couponCode")) {
                    d.couponCode = $root.taxi.StringValue.toObject(m.couponCode, o);
                }
                if (m.couponId != null && m.hasOwnProperty("couponId")) {
                    d.couponId = $root.taxi.StringValue.toObject(m.couponId, o);
                }
                if (m.paymentProfileId != null && m.hasOwnProperty("paymentProfileId")) {
                    d.paymentProfileId = $root.taxi.StringValue.toObject(m.paymentProfileId, o);
                }
                if (m.bizReason != null && m.hasOwnProperty("bizReason")) {
                    d.bizReason = $root.taxi.StringValue.toObject(m.bizReason, o);
                }
                if (m.estimationToken != null && m.hasOwnProperty("estimationToken")) {
                    d.estimationToken = $root.taxi.StringValue.toObject(m.estimationToken, o);
                }
                if (m.designatedDriverComment != null && m.hasOwnProperty("designatedDriverComment")) {
                    d.designatedDriverComment = $root.taxi.StringValue.toObject(m.designatedDriverComment, o);
                }
                return d;
            };

            /**
             * Converts this TripCreateCharterParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripCreateCharterParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripCreateCharterParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripCreateCharterParams;
        })();

        server.TripCreateCharterResult = (function() {

            /**
             * Properties of a TripCreateCharterResult.
             * @memberof taxi.server
             * @interface ITripCreateCharterResult
             * @property {string|null} [tripId] TripCreateCharterResult tripId
             * @property {string|null} [notice] TripCreateCharterResult notice
             */

            /**
             * Constructs a new TripCreateCharterResult.
             * @memberof taxi.server
             * @classdesc Represents a TripCreateCharterResult.
             * @implements ITripCreateCharterResult
             * @constructor
             * @param {taxi.server.ITripCreateCharterResult=} [p] Properties to set
             */
            function TripCreateCharterResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripCreateCharterResult tripId.
             * @member {string} tripId
             * @memberof taxi.server.TripCreateCharterResult
             * @instance
             */
            TripCreateCharterResult.prototype.tripId = "";

            /**
             * TripCreateCharterResult notice.
             * @member {string} notice
             * @memberof taxi.server.TripCreateCharterResult
             * @instance
             */
            TripCreateCharterResult.prototype.notice = "";

            /**
             * Creates a new TripCreateCharterResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripCreateCharterResult
             * @static
             * @param {taxi.server.ITripCreateCharterResult=} [properties] Properties to set
             * @returns {taxi.server.TripCreateCharterResult} TripCreateCharterResult instance
             */
            TripCreateCharterResult.create = function create(properties) {
                return new TripCreateCharterResult(properties);
            };

            /**
             * Creates a TripCreateCharterResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripCreateCharterResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripCreateCharterResult} TripCreateCharterResult
             */
            TripCreateCharterResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripCreateCharterResult)
                    return d;
                var m = new $root.taxi.server.TripCreateCharterResult();
                if (d.tripId != null) {
                    m.tripId = String(d.tripId);
                }
                if (d.notice != null) {
                    m.notice = String(d.notice);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripCreateCharterResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripCreateCharterResult
             * @static
             * @param {taxi.server.TripCreateCharterResult} m TripCreateCharterResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripCreateCharterResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripId = "";
                    d.notice = "";
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = m.tripId;
                }
                if (m.notice != null && m.hasOwnProperty("notice")) {
                    d.notice = m.notice;
                }
                return d;
            };

            /**
             * Converts this TripCreateCharterResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripCreateCharterResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripCreateCharterResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripCreateCharterResult;
        })();

        server.TripEditTripParams = (function() {

            /**
             * Properties of a TripEditTripParams.
             * @memberof taxi.server
             * @interface ITripEditTripParams
             * @property {string|null} [tripId] TripEditTripParams tripId
             * @property {taxi.server.ITripCreateAirParams|null} [air] TripEditTripParams air
             * @property {taxi.server.ITripCreateCharterParams|null} [charter] TripEditTripParams charter
             */

            /**
             * Constructs a new TripEditTripParams.
             * @memberof taxi.server
             * @classdesc Represents a TripEditTripParams.
             * @implements ITripEditTripParams
             * @constructor
             * @param {taxi.server.ITripEditTripParams=} [p] Properties to set
             */
            function TripEditTripParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripEditTripParams tripId.
             * @member {string} tripId
             * @memberof taxi.server.TripEditTripParams
             * @instance
             */
            TripEditTripParams.prototype.tripId = "";

            /**
             * TripEditTripParams air.
             * @member {taxi.server.ITripCreateAirParams|null|undefined} air
             * @memberof taxi.server.TripEditTripParams
             * @instance
             */
            TripEditTripParams.prototype.air = null;

            /**
             * TripEditTripParams charter.
             * @member {taxi.server.ITripCreateCharterParams|null|undefined} charter
             * @memberof taxi.server.TripEditTripParams
             * @instance
             */
            TripEditTripParams.prototype.charter = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * TripEditTripParams params.
             * @member {"air"|"charter"|undefined} params
             * @memberof taxi.server.TripEditTripParams
             * @instance
             */
            Object.defineProperty(TripEditTripParams.prototype, "params", {
                get: $util.oneOfGetter($oneOfFields = ["air", "charter"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new TripEditTripParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripEditTripParams
             * @static
             * @param {taxi.server.ITripEditTripParams=} [properties] Properties to set
             * @returns {taxi.server.TripEditTripParams} TripEditTripParams instance
             */
            TripEditTripParams.create = function create(properties) {
                return new TripEditTripParams(properties);
            };

            /**
             * Creates a TripEditTripParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripEditTripParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripEditTripParams} TripEditTripParams
             */
            TripEditTripParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripEditTripParams)
                    return d;
                var m = new $root.taxi.server.TripEditTripParams();
                if (d.tripId != null) {
                    m.tripId = String(d.tripId);
                }
                if (d.air != null) {
                    if (typeof d.air !== "object")
                        throw TypeError(".taxi.server.TripEditTripParams.air: object expected");
                    m.air = $root.taxi.server.TripCreateAirParams.fromObject(d.air);
                }
                if (d.charter != null) {
                    if (typeof d.charter !== "object")
                        throw TypeError(".taxi.server.TripEditTripParams.charter: object expected");
                    m.charter = $root.taxi.server.TripCreateCharterParams.fromObject(d.charter);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripEditTripParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripEditTripParams
             * @static
             * @param {taxi.server.TripEditTripParams} m TripEditTripParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripEditTripParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripId = "";
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = m.tripId;
                }
                if (m.air != null && m.hasOwnProperty("air")) {
                    d.air = $root.taxi.server.TripCreateAirParams.toObject(m.air, o);
                    if (o.oneofs)
                        d.params = "air";
                }
                if (m.charter != null && m.hasOwnProperty("charter")) {
                    d.charter = $root.taxi.server.TripCreateCharterParams.toObject(m.charter, o);
                    if (o.oneofs)
                        d.params = "charter";
                }
                return d;
            };

            /**
             * Converts this TripEditTripParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripEditTripParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripEditTripParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripEditTripParams;
        })();

        server.TripEditTripResult = (function() {

            /**
             * Properties of a TripEditTripResult.
             * @memberof taxi.server
             * @interface ITripEditTripResult
             */

            /**
             * Constructs a new TripEditTripResult.
             * @memberof taxi.server
             * @classdesc Represents a TripEditTripResult.
             * @implements ITripEditTripResult
             * @constructor
             * @param {taxi.server.ITripEditTripResult=} [p] Properties to set
             */
            function TripEditTripResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripEditTripResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripEditTripResult
             * @static
             * @param {taxi.server.ITripEditTripResult=} [properties] Properties to set
             * @returns {taxi.server.TripEditTripResult} TripEditTripResult instance
             */
            TripEditTripResult.create = function create(properties) {
                return new TripEditTripResult(properties);
            };

            /**
             * Creates a TripEditTripResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripEditTripResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripEditTripResult} TripEditTripResult
             */
            TripEditTripResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripEditTripResult)
                    return d;
                return new $root.taxi.server.TripEditTripResult();
            };

            /**
             * Creates a plain object from a TripEditTripResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripEditTripResult
             * @static
             * @param {taxi.server.TripEditTripResult} m TripEditTripResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripEditTripResult.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripEditTripResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripEditTripResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripEditTripResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripEditTripResult;
        })();

        server.TripEstimateAirParams = (function() {

            /**
             * Properties of a TripEstimateAirParams.
             * @memberof taxi.server
             * @interface ITripEstimateAirParams
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripEstimateAirParams vehicleType
             * @property {taxi.ITripAddressedLocation|null} [location] TripEstimateAirParams location
             * @property {Array.<taxi.ITripAddressedLocation>|null} [waypoints] TripEstimateAirParams waypoints
             * @property {taxi.AirportCode|null} [airportCode] TripEstimateAirParams airportCode
             * @property {taxi.TripAirType|null} [airType] TripEstimateAirParams airType
             * @property {taxi.ITimestamp|null} [pickUpAt] TripEstimateAirParams pickUpAt
             * @property {taxi.IStringValue|null} [paymentMethodId] TripEstimateAirParams paymentMethodId
             * @property {taxi.IStringValue|null} [paymentProfileId] TripEstimateAirParams paymentProfileId
             * @property {taxi.IStringValue|null} [couponCode] TripEstimateAirParams couponCode
             * @property {taxi.IStringValue|null} [couponId] TripEstimateAirParams couponId
             * @property {taxi.IStringValue|null} [airDealId] TripEstimateAirParams airDealId
             * @property {taxi.IStringValue|null} [activeTripId] TripEstimateAirParams activeTripId
             * @property {taxi.IStringValue|null} [estimationToken] TripEstimateAirParams estimationToken
             */

            /**
             * Constructs a new TripEstimateAirParams.
             * @memberof taxi.server
             * @classdesc Represents a TripEstimateAirParams.
             * @implements ITripEstimateAirParams
             * @constructor
             * @param {taxi.server.ITripEstimateAirParams=} [p] Properties to set
             */
            function TripEstimateAirParams(p) {
                this.waypoints = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripEstimateAirParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.vehicleType = 0;

            /**
             * TripEstimateAirParams location.
             * @member {taxi.ITripAddressedLocation|null|undefined} location
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.location = null;

            /**
             * TripEstimateAirParams waypoints.
             * @member {Array.<taxi.ITripAddressedLocation>} waypoints
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.waypoints = $util.emptyArray;

            /**
             * TripEstimateAirParams airportCode.
             * @member {taxi.AirportCode} airportCode
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.airportCode = 0;

            /**
             * TripEstimateAirParams airType.
             * @member {taxi.TripAirType} airType
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.airType = 0;

            /**
             * TripEstimateAirParams pickUpAt.
             * @member {taxi.ITimestamp|null|undefined} pickUpAt
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.pickUpAt = null;

            /**
             * TripEstimateAirParams paymentMethodId.
             * @member {taxi.IStringValue|null|undefined} paymentMethodId
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.paymentMethodId = null;

            /**
             * TripEstimateAirParams paymentProfileId.
             * @member {taxi.IStringValue|null|undefined} paymentProfileId
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.paymentProfileId = null;

            /**
             * TripEstimateAirParams couponCode.
             * @member {taxi.IStringValue|null|undefined} couponCode
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.couponCode = null;

            /**
             * TripEstimateAirParams couponId.
             * @member {taxi.IStringValue|null|undefined} couponId
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.couponId = null;

            /**
             * TripEstimateAirParams airDealId.
             * @member {taxi.IStringValue|null|undefined} airDealId
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.airDealId = null;

            /**
             * TripEstimateAirParams activeTripId.
             * @member {taxi.IStringValue|null|undefined} activeTripId
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.activeTripId = null;

            /**
             * TripEstimateAirParams estimationToken.
             * @member {taxi.IStringValue|null|undefined} estimationToken
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             */
            TripEstimateAirParams.prototype.estimationToken = null;

            /**
             * Creates a new TripEstimateAirParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripEstimateAirParams
             * @static
             * @param {taxi.server.ITripEstimateAirParams=} [properties] Properties to set
             * @returns {taxi.server.TripEstimateAirParams} TripEstimateAirParams instance
             */
            TripEstimateAirParams.create = function create(properties) {
                return new TripEstimateAirParams(properties);
            };

            /**
             * Creates a TripEstimateAirParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripEstimateAirParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripEstimateAirParams} TripEstimateAirParams
             */
            TripEstimateAirParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripEstimateAirParams)
                    return d;
                var m = new $root.taxi.server.TripEstimateAirParams();
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                if (d.location != null) {
                    if (typeof d.location !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.location: object expected");
                    m.location = $root.taxi.TripAddressedLocation.fromObject(d.location);
                }
                if (d.waypoints) {
                    if (!Array.isArray(d.waypoints))
                        throw TypeError(".taxi.server.TripEstimateAirParams.waypoints: array expected");
                    m.waypoints = [];
                    for (var i = 0; i < d.waypoints.length; ++i) {
                        if (typeof d.waypoints[i] !== "object")
                            throw TypeError(".taxi.server.TripEstimateAirParams.waypoints: object expected");
                        m.waypoints[i] = $root.taxi.TripAddressedLocation.fromObject(d.waypoints[i]);
                    }
                }
                switch (d.airportCode) {
                case "ICN_1":
                case 0:
                    m.airportCode = 0;
                    break;
                case "ICN_2":
                case 1:
                    m.airportCode = 1;
                    break;
                case "GMP_INTERNATIONAL":
                case 3:
                    m.airportCode = 3;
                    break;
                case "GMP_DOMESTIC":
                case 4:
                    m.airportCode = 4;
                    break;
                case "CJU_INTERNATIONAL":
                case 5:
                    m.airportCode = 5;
                    break;
                case "CJU_DOMESTIC":
                case 6:
                    m.airportCode = 6;
                    break;
                case "PUS_INTERNATIONAL":
                case 7:
                    m.airportCode = 7;
                    break;
                case "PUS_DOMESTIC":
                case 8:
                    m.airportCode = 8;
                    break;
                }
                switch (d.airType) {
                case "SENDING":
                case 0:
                    m.airType = 0;
                    break;
                case "PICK_UP":
                case 1:
                    m.airType = 1;
                    break;
                }
                if (d.pickUpAt != null) {
                    if (typeof d.pickUpAt !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.pickUpAt: object expected");
                    m.pickUpAt = $root.taxi.Timestamp.fromObject(d.pickUpAt);
                }
                if (d.paymentMethodId != null) {
                    if (typeof d.paymentMethodId !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.paymentMethodId: object expected");
                    m.paymentMethodId = $root.taxi.StringValue.fromObject(d.paymentMethodId);
                }
                if (d.paymentProfileId != null) {
                    if (typeof d.paymentProfileId !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.paymentProfileId: object expected");
                    m.paymentProfileId = $root.taxi.StringValue.fromObject(d.paymentProfileId);
                }
                if (d.couponCode != null) {
                    if (typeof d.couponCode !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.couponCode: object expected");
                    m.couponCode = $root.taxi.StringValue.fromObject(d.couponCode);
                }
                if (d.couponId != null) {
                    if (typeof d.couponId !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.couponId: object expected");
                    m.couponId = $root.taxi.StringValue.fromObject(d.couponId);
                }
                if (d.airDealId != null) {
                    if (typeof d.airDealId !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.airDealId: object expected");
                    m.airDealId = $root.taxi.StringValue.fromObject(d.airDealId);
                }
                if (d.activeTripId != null) {
                    if (typeof d.activeTripId !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.activeTripId: object expected");
                    m.activeTripId = $root.taxi.StringValue.fromObject(d.activeTripId);
                }
                if (d.estimationToken != null) {
                    if (typeof d.estimationToken !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirParams.estimationToken: object expected");
                    m.estimationToken = $root.taxi.StringValue.fromObject(d.estimationToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripEstimateAirParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripEstimateAirParams
             * @static
             * @param {taxi.server.TripEstimateAirParams} m TripEstimateAirParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripEstimateAirParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.waypoints = [];
                }
                if (o.defaults) {
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                    d.location = null;
                    d.airportCode = o.enums === String ? "ICN_1" : 0;
                    d.airType = o.enums === String ? "SENDING" : 0;
                    d.pickUpAt = null;
                    d.paymentMethodId = null;
                    d.couponCode = null;
                    d.couponId = null;
                    d.paymentProfileId = null;
                    d.airDealId = null;
                    d.activeTripId = null;
                    d.estimationToken = null;
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                if (m.location != null && m.hasOwnProperty("location")) {
                    d.location = $root.taxi.TripAddressedLocation.toObject(m.location, o);
                }
                if (m.waypoints && m.waypoints.length) {
                    d.waypoints = [];
                    for (var j = 0; j < m.waypoints.length; ++j) {
                        d.waypoints[j] = $root.taxi.TripAddressedLocation.toObject(m.waypoints[j], o);
                    }
                }
                if (m.airportCode != null && m.hasOwnProperty("airportCode")) {
                    d.airportCode = o.enums === String ? $root.taxi.AirportCode[m.airportCode] : m.airportCode;
                }
                if (m.airType != null && m.hasOwnProperty("airType")) {
                    d.airType = o.enums === String ? $root.taxi.TripAirType[m.airType] : m.airType;
                }
                if (m.pickUpAt != null && m.hasOwnProperty("pickUpAt")) {
                    d.pickUpAt = $root.taxi.Timestamp.toObject(m.pickUpAt, o);
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = $root.taxi.StringValue.toObject(m.paymentMethodId, o);
                }
                if (m.couponCode != null && m.hasOwnProperty("couponCode")) {
                    d.couponCode = $root.taxi.StringValue.toObject(m.couponCode, o);
                }
                if (m.couponId != null && m.hasOwnProperty("couponId")) {
                    d.couponId = $root.taxi.StringValue.toObject(m.couponId, o);
                }
                if (m.paymentProfileId != null && m.hasOwnProperty("paymentProfileId")) {
                    d.paymentProfileId = $root.taxi.StringValue.toObject(m.paymentProfileId, o);
                }
                if (m.airDealId != null && m.hasOwnProperty("airDealId")) {
                    d.airDealId = $root.taxi.StringValue.toObject(m.airDealId, o);
                }
                if (m.activeTripId != null && m.hasOwnProperty("activeTripId")) {
                    d.activeTripId = $root.taxi.StringValue.toObject(m.activeTripId, o);
                }
                if (m.estimationToken != null && m.hasOwnProperty("estimationToken")) {
                    d.estimationToken = $root.taxi.StringValue.toObject(m.estimationToken, o);
                }
                return d;
            };

            /**
             * Converts this TripEstimateAirParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripEstimateAirParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripEstimateAirParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripEstimateAirParams;
        })();

        server.TripEstimateAirResult = (function() {

            /**
             * Properties of a TripEstimateAirResult.
             * @memberof taxi.server
             * @interface ITripEstimateAirResult
             * @property {taxi.ITripEstimation|null} [estimation] TripEstimateAirResult estimation
             * @property {number|null} [basicKm] TripEstimateAirResult basicKm
             * @property {number|null} [feePerKm] TripEstimateAirResult feePerKm
             * @property {string|null} [additionalFeeDescription] TripEstimateAirResult additionalFeeDescription
             * @property {string|null} [estimationToken] TripEstimateAirResult estimationToken
             */

            /**
             * Constructs a new TripEstimateAirResult.
             * @memberof taxi.server
             * @classdesc Represents a TripEstimateAirResult.
             * @implements ITripEstimateAirResult
             * @constructor
             * @param {taxi.server.ITripEstimateAirResult=} [p] Properties to set
             */
            function TripEstimateAirResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripEstimateAirResult estimation.
             * @member {taxi.ITripEstimation|null|undefined} estimation
             * @memberof taxi.server.TripEstimateAirResult
             * @instance
             */
            TripEstimateAirResult.prototype.estimation = null;

            /**
             * TripEstimateAirResult basicKm.
             * @member {number} basicKm
             * @memberof taxi.server.TripEstimateAirResult
             * @instance
             */
            TripEstimateAirResult.prototype.basicKm = 0;

            /**
             * TripEstimateAirResult feePerKm.
             * @member {number} feePerKm
             * @memberof taxi.server.TripEstimateAirResult
             * @instance
             */
            TripEstimateAirResult.prototype.feePerKm = 0;

            /**
             * TripEstimateAirResult additionalFeeDescription.
             * @member {string} additionalFeeDescription
             * @memberof taxi.server.TripEstimateAirResult
             * @instance
             */
            TripEstimateAirResult.prototype.additionalFeeDescription = "";

            /**
             * TripEstimateAirResult estimationToken.
             * @member {string} estimationToken
             * @memberof taxi.server.TripEstimateAirResult
             * @instance
             */
            TripEstimateAirResult.prototype.estimationToken = "";

            /**
             * Creates a new TripEstimateAirResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripEstimateAirResult
             * @static
             * @param {taxi.server.ITripEstimateAirResult=} [properties] Properties to set
             * @returns {taxi.server.TripEstimateAirResult} TripEstimateAirResult instance
             */
            TripEstimateAirResult.create = function create(properties) {
                return new TripEstimateAirResult(properties);
            };

            /**
             * Creates a TripEstimateAirResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripEstimateAirResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripEstimateAirResult} TripEstimateAirResult
             */
            TripEstimateAirResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripEstimateAirResult)
                    return d;
                var m = new $root.taxi.server.TripEstimateAirResult();
                if (d.estimation != null) {
                    if (typeof d.estimation !== "object")
                        throw TypeError(".taxi.server.TripEstimateAirResult.estimation: object expected");
                    m.estimation = $root.taxi.TripEstimation.fromObject(d.estimation);
                }
                if (d.basicKm != null) {
                    m.basicKm = d.basicKm | 0;
                }
                if (d.feePerKm != null) {
                    m.feePerKm = d.feePerKm | 0;
                }
                if (d.additionalFeeDescription != null) {
                    m.additionalFeeDescription = String(d.additionalFeeDescription);
                }
                if (d.estimationToken != null) {
                    m.estimationToken = String(d.estimationToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripEstimateAirResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripEstimateAirResult
             * @static
             * @param {taxi.server.TripEstimateAirResult} m TripEstimateAirResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripEstimateAirResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.estimation = null;
                    d.basicKm = 0;
                    d.feePerKm = 0;
                    d.additionalFeeDescription = "";
                    d.estimationToken = "";
                }
                if (m.estimation != null && m.hasOwnProperty("estimation")) {
                    d.estimation = $root.taxi.TripEstimation.toObject(m.estimation, o);
                }
                if (m.basicKm != null && m.hasOwnProperty("basicKm")) {
                    d.basicKm = m.basicKm;
                }
                if (m.feePerKm != null && m.hasOwnProperty("feePerKm")) {
                    d.feePerKm = m.feePerKm;
                }
                if (m.additionalFeeDescription != null && m.hasOwnProperty("additionalFeeDescription")) {
                    d.additionalFeeDescription = m.additionalFeeDescription;
                }
                if (m.estimationToken != null && m.hasOwnProperty("estimationToken")) {
                    d.estimationToken = m.estimationToken;
                }
                return d;
            };

            /**
             * Converts this TripEstimateAirResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripEstimateAirResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripEstimateAirResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripEstimateAirResult;
        })();

        server.TripEstimateCharterParams = (function() {

            /**
             * Properties of a TripEstimateCharterParams.
             * @memberof taxi.server
             * @interface ITripEstimateCharterParams
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripEstimateCharterParams vehicleType
             * @property {taxi.ITripAddressedLocation|null} [origin] TripEstimateCharterParams origin
             * @property {Array.<taxi.ITripAddressedLocation>|null} [waypoints] TripEstimateCharterParams waypoints
             * @property {taxi.ITripAddressedLocation|null} [destination] TripEstimateCharterParams destination
             * @property {taxi.ITimestamp|null} [pickUpAt] TripEstimateCharterParams pickUpAt
             * @property {taxi.ITripAddressedLocation|null} [golfClub] TripEstimateCharterParams golfClub
             * @property {number|null} [minutes] TripEstimateCharterParams minutes
             * @property {taxi.IStringValue|null} [paymentMethodId] TripEstimateCharterParams paymentMethodId
             * @property {taxi.IStringValue|null} [paymentProfileId] TripEstimateCharterParams paymentProfileId
             * @property {taxi.IStringValue|null} [couponCode] TripEstimateCharterParams couponCode
             * @property {taxi.IStringValue|null} [couponId] TripEstimateCharterParams couponId
             * @property {taxi.IStringValue|null} [activeTripId] TripEstimateCharterParams activeTripId
             * @property {taxi.IStringValue|null} [estimationToken] TripEstimateCharterParams estimationToken
             */

            /**
             * Constructs a new TripEstimateCharterParams.
             * @memberof taxi.server
             * @classdesc Represents a TripEstimateCharterParams.
             * @implements ITripEstimateCharterParams
             * @constructor
             * @param {taxi.server.ITripEstimateCharterParams=} [p] Properties to set
             */
            function TripEstimateCharterParams(p) {
                this.waypoints = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripEstimateCharterParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.vehicleType = 0;

            /**
             * TripEstimateCharterParams origin.
             * @member {taxi.ITripAddressedLocation|null|undefined} origin
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.origin = null;

            /**
             * TripEstimateCharterParams waypoints.
             * @member {Array.<taxi.ITripAddressedLocation>} waypoints
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.waypoints = $util.emptyArray;

            /**
             * TripEstimateCharterParams destination.
             * @member {taxi.ITripAddressedLocation|null|undefined} destination
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.destination = null;

            /**
             * TripEstimateCharterParams pickUpAt.
             * @member {taxi.ITimestamp|null|undefined} pickUpAt
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.pickUpAt = null;

            /**
             * TripEstimateCharterParams golfClub.
             * @member {taxi.ITripAddressedLocation|null|undefined} golfClub
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.golfClub = null;

            /**
             * TripEstimateCharterParams minutes.
             * @member {number} minutes
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.minutes = 0;

            /**
             * TripEstimateCharterParams paymentMethodId.
             * @member {taxi.IStringValue|null|undefined} paymentMethodId
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.paymentMethodId = null;

            /**
             * TripEstimateCharterParams paymentProfileId.
             * @member {taxi.IStringValue|null|undefined} paymentProfileId
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.paymentProfileId = null;

            /**
             * TripEstimateCharterParams couponCode.
             * @member {taxi.IStringValue|null|undefined} couponCode
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.couponCode = null;

            /**
             * TripEstimateCharterParams couponId.
             * @member {taxi.IStringValue|null|undefined} couponId
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.couponId = null;

            /**
             * TripEstimateCharterParams activeTripId.
             * @member {taxi.IStringValue|null|undefined} activeTripId
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.activeTripId = null;

            /**
             * TripEstimateCharterParams estimationToken.
             * @member {taxi.IStringValue|null|undefined} estimationToken
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             */
            TripEstimateCharterParams.prototype.estimationToken = null;

            /**
             * Creates a new TripEstimateCharterParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripEstimateCharterParams
             * @static
             * @param {taxi.server.ITripEstimateCharterParams=} [properties] Properties to set
             * @returns {taxi.server.TripEstimateCharterParams} TripEstimateCharterParams instance
             */
            TripEstimateCharterParams.create = function create(properties) {
                return new TripEstimateCharterParams(properties);
            };

            /**
             * Creates a TripEstimateCharterParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripEstimateCharterParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripEstimateCharterParams} TripEstimateCharterParams
             */
            TripEstimateCharterParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripEstimateCharterParams)
                    return d;
                var m = new $root.taxi.server.TripEstimateCharterParams();
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                if (d.origin != null) {
                    if (typeof d.origin !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.origin: object expected");
                    m.origin = $root.taxi.TripAddressedLocation.fromObject(d.origin);
                }
                if (d.waypoints) {
                    if (!Array.isArray(d.waypoints))
                        throw TypeError(".taxi.server.TripEstimateCharterParams.waypoints: array expected");
                    m.waypoints = [];
                    for (var i = 0; i < d.waypoints.length; ++i) {
                        if (typeof d.waypoints[i] !== "object")
                            throw TypeError(".taxi.server.TripEstimateCharterParams.waypoints: object expected");
                        m.waypoints[i] = $root.taxi.TripAddressedLocation.fromObject(d.waypoints[i]);
                    }
                }
                if (d.destination != null) {
                    if (typeof d.destination !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.destination: object expected");
                    m.destination = $root.taxi.TripAddressedLocation.fromObject(d.destination);
                }
                if (d.pickUpAt != null) {
                    if (typeof d.pickUpAt !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.pickUpAt: object expected");
                    m.pickUpAt = $root.taxi.Timestamp.fromObject(d.pickUpAt);
                }
                if (d.golfClub != null) {
                    if (typeof d.golfClub !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.golfClub: object expected");
                    m.golfClub = $root.taxi.TripAddressedLocation.fromObject(d.golfClub);
                }
                if (d.minutes != null) {
                    m.minutes = d.minutes | 0;
                }
                if (d.paymentMethodId != null) {
                    if (typeof d.paymentMethodId !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.paymentMethodId: object expected");
                    m.paymentMethodId = $root.taxi.StringValue.fromObject(d.paymentMethodId);
                }
                if (d.paymentProfileId != null) {
                    if (typeof d.paymentProfileId !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.paymentProfileId: object expected");
                    m.paymentProfileId = $root.taxi.StringValue.fromObject(d.paymentProfileId);
                }
                if (d.couponCode != null) {
                    if (typeof d.couponCode !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.couponCode: object expected");
                    m.couponCode = $root.taxi.StringValue.fromObject(d.couponCode);
                }
                if (d.couponId != null) {
                    if (typeof d.couponId !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.couponId: object expected");
                    m.couponId = $root.taxi.StringValue.fromObject(d.couponId);
                }
                if (d.activeTripId != null) {
                    if (typeof d.activeTripId !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.activeTripId: object expected");
                    m.activeTripId = $root.taxi.StringValue.fromObject(d.activeTripId);
                }
                if (d.estimationToken != null) {
                    if (typeof d.estimationToken !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterParams.estimationToken: object expected");
                    m.estimationToken = $root.taxi.StringValue.fromObject(d.estimationToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripEstimateCharterParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripEstimateCharterParams
             * @static
             * @param {taxi.server.TripEstimateCharterParams} m TripEstimateCharterParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripEstimateCharterParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.waypoints = [];
                }
                if (o.defaults) {
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                    d.origin = null;
                    d.destination = null;
                    d.pickUpAt = null;
                    d.minutes = 0;
                    d.golfClub = null;
                    d.paymentMethodId = null;
                    d.couponCode = null;
                    d.couponId = null;
                    d.paymentProfileId = null;
                    d.activeTripId = null;
                    d.estimationToken = null;
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                if (m.origin != null && m.hasOwnProperty("origin")) {
                    d.origin = $root.taxi.TripAddressedLocation.toObject(m.origin, o);
                }
                if (m.waypoints && m.waypoints.length) {
                    d.waypoints = [];
                    for (var j = 0; j < m.waypoints.length; ++j) {
                        d.waypoints[j] = $root.taxi.TripAddressedLocation.toObject(m.waypoints[j], o);
                    }
                }
                if (m.destination != null && m.hasOwnProperty("destination")) {
                    d.destination = $root.taxi.TripAddressedLocation.toObject(m.destination, o);
                }
                if (m.pickUpAt != null && m.hasOwnProperty("pickUpAt")) {
                    d.pickUpAt = $root.taxi.Timestamp.toObject(m.pickUpAt, o);
                }
                if (m.minutes != null && m.hasOwnProperty("minutes")) {
                    d.minutes = m.minutes;
                }
                if (m.golfClub != null && m.hasOwnProperty("golfClub")) {
                    d.golfClub = $root.taxi.TripAddressedLocation.toObject(m.golfClub, o);
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = $root.taxi.StringValue.toObject(m.paymentMethodId, o);
                }
                if (m.couponCode != null && m.hasOwnProperty("couponCode")) {
                    d.couponCode = $root.taxi.StringValue.toObject(m.couponCode, o);
                }
                if (m.couponId != null && m.hasOwnProperty("couponId")) {
                    d.couponId = $root.taxi.StringValue.toObject(m.couponId, o);
                }
                if (m.paymentProfileId != null && m.hasOwnProperty("paymentProfileId")) {
                    d.paymentProfileId = $root.taxi.StringValue.toObject(m.paymentProfileId, o);
                }
                if (m.activeTripId != null && m.hasOwnProperty("activeTripId")) {
                    d.activeTripId = $root.taxi.StringValue.toObject(m.activeTripId, o);
                }
                if (m.estimationToken != null && m.hasOwnProperty("estimationToken")) {
                    d.estimationToken = $root.taxi.StringValue.toObject(m.estimationToken, o);
                }
                return d;
            };

            /**
             * Converts this TripEstimateCharterParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripEstimateCharterParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripEstimateCharterParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripEstimateCharterParams;
        })();

        server.TripEstimateCharterResult = (function() {

            /**
             * Properties of a TripEstimateCharterResult.
             * @memberof taxi.server
             * @interface ITripEstimateCharterResult
             * @property {taxi.ITripEstimation|null} [estimation] TripEstimateCharterResult estimation
             * @property {number|null} [basicKm] TripEstimateCharterResult basicKm
             * @property {number|null} [feePerKm] TripEstimateCharterResult feePerKm
             * @property {number|null} [basicMinute] TripEstimateCharterResult basicMinute
             * @property {number|null} [feePerHour] TripEstimateCharterResult feePerHour
             * @property {string|null} [additionalFeeDescription] TripEstimateCharterResult additionalFeeDescription
             * @property {string|null} [estimationToken] TripEstimateCharterResult estimationToken
             */

            /**
             * Constructs a new TripEstimateCharterResult.
             * @memberof taxi.server
             * @classdesc Represents a TripEstimateCharterResult.
             * @implements ITripEstimateCharterResult
             * @constructor
             * @param {taxi.server.ITripEstimateCharterResult=} [p] Properties to set
             */
            function TripEstimateCharterResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripEstimateCharterResult estimation.
             * @member {taxi.ITripEstimation|null|undefined} estimation
             * @memberof taxi.server.TripEstimateCharterResult
             * @instance
             */
            TripEstimateCharterResult.prototype.estimation = null;

            /**
             * TripEstimateCharterResult basicKm.
             * @member {number} basicKm
             * @memberof taxi.server.TripEstimateCharterResult
             * @instance
             */
            TripEstimateCharterResult.prototype.basicKm = 0;

            /**
             * TripEstimateCharterResult feePerKm.
             * @member {number} feePerKm
             * @memberof taxi.server.TripEstimateCharterResult
             * @instance
             */
            TripEstimateCharterResult.prototype.feePerKm = 0;

            /**
             * TripEstimateCharterResult basicMinute.
             * @member {number} basicMinute
             * @memberof taxi.server.TripEstimateCharterResult
             * @instance
             */
            TripEstimateCharterResult.prototype.basicMinute = 0;

            /**
             * TripEstimateCharterResult feePerHour.
             * @member {number} feePerHour
             * @memberof taxi.server.TripEstimateCharterResult
             * @instance
             */
            TripEstimateCharterResult.prototype.feePerHour = 0;

            /**
             * TripEstimateCharterResult additionalFeeDescription.
             * @member {string} additionalFeeDescription
             * @memberof taxi.server.TripEstimateCharterResult
             * @instance
             */
            TripEstimateCharterResult.prototype.additionalFeeDescription = "";

            /**
             * TripEstimateCharterResult estimationToken.
             * @member {string} estimationToken
             * @memberof taxi.server.TripEstimateCharterResult
             * @instance
             */
            TripEstimateCharterResult.prototype.estimationToken = "";

            /**
             * Creates a new TripEstimateCharterResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripEstimateCharterResult
             * @static
             * @param {taxi.server.ITripEstimateCharterResult=} [properties] Properties to set
             * @returns {taxi.server.TripEstimateCharterResult} TripEstimateCharterResult instance
             */
            TripEstimateCharterResult.create = function create(properties) {
                return new TripEstimateCharterResult(properties);
            };

            /**
             * Creates a TripEstimateCharterResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripEstimateCharterResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripEstimateCharterResult} TripEstimateCharterResult
             */
            TripEstimateCharterResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripEstimateCharterResult)
                    return d;
                var m = new $root.taxi.server.TripEstimateCharterResult();
                if (d.estimation != null) {
                    if (typeof d.estimation !== "object")
                        throw TypeError(".taxi.server.TripEstimateCharterResult.estimation: object expected");
                    m.estimation = $root.taxi.TripEstimation.fromObject(d.estimation);
                }
                if (d.basicKm != null) {
                    m.basicKm = d.basicKm | 0;
                }
                if (d.feePerKm != null) {
                    m.feePerKm = d.feePerKm | 0;
                }
                if (d.basicMinute != null) {
                    m.basicMinute = d.basicMinute | 0;
                }
                if (d.feePerHour != null) {
                    m.feePerHour = d.feePerHour | 0;
                }
                if (d.additionalFeeDescription != null) {
                    m.additionalFeeDescription = String(d.additionalFeeDescription);
                }
                if (d.estimationToken != null) {
                    m.estimationToken = String(d.estimationToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripEstimateCharterResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripEstimateCharterResult
             * @static
             * @param {taxi.server.TripEstimateCharterResult} m TripEstimateCharterResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripEstimateCharterResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.estimation = null;
                    d.basicKm = 0;
                    d.feePerKm = 0;
                    d.basicMinute = 0;
                    d.feePerHour = 0;
                    d.additionalFeeDescription = "";
                    d.estimationToken = "";
                }
                if (m.estimation != null && m.hasOwnProperty("estimation")) {
                    d.estimation = $root.taxi.TripEstimation.toObject(m.estimation, o);
                }
                if (m.basicKm != null && m.hasOwnProperty("basicKm")) {
                    d.basicKm = m.basicKm;
                }
                if (m.feePerKm != null && m.hasOwnProperty("feePerKm")) {
                    d.feePerKm = m.feePerKm;
                }
                if (m.basicMinute != null && m.hasOwnProperty("basicMinute")) {
                    d.basicMinute = m.basicMinute;
                }
                if (m.feePerHour != null && m.hasOwnProperty("feePerHour")) {
                    d.feePerHour = m.feePerHour;
                }
                if (m.additionalFeeDescription != null && m.hasOwnProperty("additionalFeeDescription")) {
                    d.additionalFeeDescription = m.additionalFeeDescription;
                }
                if (m.estimationToken != null && m.hasOwnProperty("estimationToken")) {
                    d.estimationToken = m.estimationToken;
                }
                return d;
            };

            /**
             * Converts this TripEstimateCharterResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripEstimateCharterResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripEstimateCharterResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripEstimateCharterResult;
        })();

        server.TripGetAccessTokenParams = (function() {

            /**
             * Properties of a TripGetAccessTokenParams.
             * @memberof taxi.server
             * @interface ITripGetAccessTokenParams
             * @property {string|null} [email] TripGetAccessTokenParams email
             * @property {string|null} [password] TripGetAccessTokenParams password
             */

            /**
             * Constructs a new TripGetAccessTokenParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetAccessTokenParams.
             * @implements ITripGetAccessTokenParams
             * @constructor
             * @param {taxi.server.ITripGetAccessTokenParams=} [p] Properties to set
             */
            function TripGetAccessTokenParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetAccessTokenParams email.
             * @member {string} email
             * @memberof taxi.server.TripGetAccessTokenParams
             * @instance
             */
            TripGetAccessTokenParams.prototype.email = "";

            /**
             * TripGetAccessTokenParams password.
             * @member {string} password
             * @memberof taxi.server.TripGetAccessTokenParams
             * @instance
             */
            TripGetAccessTokenParams.prototype.password = "";

            /**
             * Creates a new TripGetAccessTokenParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetAccessTokenParams
             * @static
             * @param {taxi.server.ITripGetAccessTokenParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetAccessTokenParams} TripGetAccessTokenParams instance
             */
            TripGetAccessTokenParams.create = function create(properties) {
                return new TripGetAccessTokenParams(properties);
            };

            /**
             * Creates a TripGetAccessTokenParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetAccessTokenParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetAccessTokenParams} TripGetAccessTokenParams
             */
            TripGetAccessTokenParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetAccessTokenParams)
                    return d;
                var m = new $root.taxi.server.TripGetAccessTokenParams();
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.password != null) {
                    m.password = String(d.password);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetAccessTokenParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetAccessTokenParams
             * @static
             * @param {taxi.server.TripGetAccessTokenParams} m TripGetAccessTokenParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetAccessTokenParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.email = "";
                    d.password = "";
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.password != null && m.hasOwnProperty("password")) {
                    d.password = m.password;
                }
                return d;
            };

            /**
             * Converts this TripGetAccessTokenParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetAccessTokenParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetAccessTokenParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetAccessTokenParams;
        })();

        server.TripGetAccessTokenResult = (function() {

            /**
             * Properties of a TripGetAccessTokenResult.
             * @memberof taxi.server
             * @interface ITripGetAccessTokenResult
             */

            /**
             * Constructs a new TripGetAccessTokenResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetAccessTokenResult.
             * @implements ITripGetAccessTokenResult
             * @constructor
             * @param {taxi.server.ITripGetAccessTokenResult=} [p] Properties to set
             */
            function TripGetAccessTokenResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripGetAccessTokenResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetAccessTokenResult
             * @static
             * @param {taxi.server.ITripGetAccessTokenResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetAccessTokenResult} TripGetAccessTokenResult instance
             */
            TripGetAccessTokenResult.create = function create(properties) {
                return new TripGetAccessTokenResult(properties);
            };

            /**
             * Creates a TripGetAccessTokenResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetAccessTokenResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetAccessTokenResult} TripGetAccessTokenResult
             */
            TripGetAccessTokenResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetAccessTokenResult)
                    return d;
                return new $root.taxi.server.TripGetAccessTokenResult();
            };

            /**
             * Creates a plain object from a TripGetAccessTokenResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetAccessTokenResult
             * @static
             * @param {taxi.server.TripGetAccessTokenResult} m TripGetAccessTokenResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetAccessTokenResult.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripGetAccessTokenResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetAccessTokenResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetAccessTokenResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetAccessTokenResult;
        })();

        server.TripGetAirDealParams = (function() {

            /**
             * Properties of a TripGetAirDealParams.
             * @memberof taxi.server
             * @interface ITripGetAirDealParams
             * @property {string|null} [airDealId] TripGetAirDealParams airDealId
             */

            /**
             * Constructs a new TripGetAirDealParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetAirDealParams.
             * @implements ITripGetAirDealParams
             * @constructor
             * @param {taxi.server.ITripGetAirDealParams=} [p] Properties to set
             */
            function TripGetAirDealParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetAirDealParams airDealId.
             * @member {string} airDealId
             * @memberof taxi.server.TripGetAirDealParams
             * @instance
             */
            TripGetAirDealParams.prototype.airDealId = "";

            /**
             * Creates a new TripGetAirDealParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetAirDealParams
             * @static
             * @param {taxi.server.ITripGetAirDealParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetAirDealParams} TripGetAirDealParams instance
             */
            TripGetAirDealParams.create = function create(properties) {
                return new TripGetAirDealParams(properties);
            };

            /**
             * Creates a TripGetAirDealParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetAirDealParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetAirDealParams} TripGetAirDealParams
             */
            TripGetAirDealParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetAirDealParams)
                    return d;
                var m = new $root.taxi.server.TripGetAirDealParams();
                if (d.airDealId != null) {
                    m.airDealId = String(d.airDealId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetAirDealParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetAirDealParams
             * @static
             * @param {taxi.server.TripGetAirDealParams} m TripGetAirDealParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetAirDealParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.airDealId = "";
                }
                if (m.airDealId != null && m.hasOwnProperty("airDealId")) {
                    d.airDealId = m.airDealId;
                }
                return d;
            };

            /**
             * Converts this TripGetAirDealParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetAirDealParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetAirDealParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetAirDealParams;
        })();

        server.TripGetAirDealResult = (function() {

            /**
             * Properties of a TripGetAirDealResult.
             * @memberof taxi.server
             * @interface ITripGetAirDealResult
             * @property {string|null} [id] TripGetAirDealResult id
             * @property {taxi.AirportCode|null} [airportCode] TripGetAirDealResult airportCode
             * @property {taxi.TripAirType|null} [airType] TripGetAirDealResult airType
             * @property {taxi.ITripVehicle|null} [vehicle] TripGetAirDealResult vehicle
             * @property {number|null} [count] TripGetAirDealResult count
             * @property {number|null} [discountPercentage] TripGetAirDealResult discountPercentage
             * @property {taxi.ITimestamp|null} [pickUpAt] TripGetAirDealResult pickUpAt
             * @property {Array.<taxi.server.TripGetAirDealResult.ILineItem>|null} [info] TripGetAirDealResult info
             * @property {Array.<string>|null} [notices] TripGetAirDealResult notices
             */

            /**
             * Constructs a new TripGetAirDealResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetAirDealResult.
             * @implements ITripGetAirDealResult
             * @constructor
             * @param {taxi.server.ITripGetAirDealResult=} [p] Properties to set
             */
            function TripGetAirDealResult(p) {
                this.info = [];
                this.notices = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetAirDealResult id.
             * @member {string} id
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.id = "";

            /**
             * TripGetAirDealResult airportCode.
             * @member {taxi.AirportCode} airportCode
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.airportCode = 0;

            /**
             * TripGetAirDealResult airType.
             * @member {taxi.TripAirType} airType
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.airType = 0;

            /**
             * TripGetAirDealResult vehicle.
             * @member {taxi.ITripVehicle|null|undefined} vehicle
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.vehicle = null;

            /**
             * TripGetAirDealResult count.
             * @member {number} count
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.count = 0;

            /**
             * TripGetAirDealResult discountPercentage.
             * @member {number} discountPercentage
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.discountPercentage = 0;

            /**
             * TripGetAirDealResult pickUpAt.
             * @member {taxi.ITimestamp|null|undefined} pickUpAt
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.pickUpAt = null;

            /**
             * TripGetAirDealResult info.
             * @member {Array.<taxi.server.TripGetAirDealResult.ILineItem>} info
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.info = $util.emptyArray;

            /**
             * TripGetAirDealResult notices.
             * @member {Array.<string>} notices
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             */
            TripGetAirDealResult.prototype.notices = $util.emptyArray;

            /**
             * Creates a new TripGetAirDealResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetAirDealResult
             * @static
             * @param {taxi.server.ITripGetAirDealResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetAirDealResult} TripGetAirDealResult instance
             */
            TripGetAirDealResult.create = function create(properties) {
                return new TripGetAirDealResult(properties);
            };

            /**
             * Creates a TripGetAirDealResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetAirDealResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetAirDealResult} TripGetAirDealResult
             */
            TripGetAirDealResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetAirDealResult)
                    return d;
                var m = new $root.taxi.server.TripGetAirDealResult();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                switch (d.airportCode) {
                case "ICN_1":
                case 0:
                    m.airportCode = 0;
                    break;
                case "ICN_2":
                case 1:
                    m.airportCode = 1;
                    break;
                case "GMP_INTERNATIONAL":
                case 3:
                    m.airportCode = 3;
                    break;
                case "GMP_DOMESTIC":
                case 4:
                    m.airportCode = 4;
                    break;
                case "CJU_INTERNATIONAL":
                case 5:
                    m.airportCode = 5;
                    break;
                case "CJU_DOMESTIC":
                case 6:
                    m.airportCode = 6;
                    break;
                case "PUS_INTERNATIONAL":
                case 7:
                    m.airportCode = 7;
                    break;
                case "PUS_DOMESTIC":
                case 8:
                    m.airportCode = 8;
                    break;
                }
                switch (d.airType) {
                case "SENDING":
                case 0:
                    m.airType = 0;
                    break;
                case "PICK_UP":
                case 1:
                    m.airType = 1;
                    break;
                }
                if (d.vehicle != null) {
                    if (typeof d.vehicle !== "object")
                        throw TypeError(".taxi.server.TripGetAirDealResult.vehicle: object expected");
                    m.vehicle = $root.taxi.TripVehicle.fromObject(d.vehicle);
                }
                if (d.count != null) {
                    m.count = d.count | 0;
                }
                if (d.discountPercentage != null) {
                    m.discountPercentage = d.discountPercentage | 0;
                }
                if (d.pickUpAt != null) {
                    if (typeof d.pickUpAt !== "object")
                        throw TypeError(".taxi.server.TripGetAirDealResult.pickUpAt: object expected");
                    m.pickUpAt = $root.taxi.Timestamp.fromObject(d.pickUpAt);
                }
                if (d.info) {
                    if (!Array.isArray(d.info))
                        throw TypeError(".taxi.server.TripGetAirDealResult.info: array expected");
                    m.info = [];
                    for (var i = 0; i < d.info.length; ++i) {
                        if (typeof d.info[i] !== "object")
                            throw TypeError(".taxi.server.TripGetAirDealResult.info: object expected");
                        m.info[i] = $root.taxi.server.TripGetAirDealResult.LineItem.fromObject(d.info[i]);
                    }
                }
                if (d.notices) {
                    if (!Array.isArray(d.notices))
                        throw TypeError(".taxi.server.TripGetAirDealResult.notices: array expected");
                    m.notices = [];
                    for (var i = 0; i < d.notices.length; ++i) {
                        m.notices[i] = String(d.notices[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetAirDealResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetAirDealResult
             * @static
             * @param {taxi.server.TripGetAirDealResult} m TripGetAirDealResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetAirDealResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.info = [];
                    d.notices = [];
                }
                if (o.defaults) {
                    d.id = "";
                    d.airportCode = o.enums === String ? "ICN_1" : 0;
                    d.airType = o.enums === String ? "SENDING" : 0;
                    d.vehicle = null;
                    d.count = 0;
                    d.discountPercentage = 0;
                    d.pickUpAt = null;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.airportCode != null && m.hasOwnProperty("airportCode")) {
                    d.airportCode = o.enums === String ? $root.taxi.AirportCode[m.airportCode] : m.airportCode;
                }
                if (m.airType != null && m.hasOwnProperty("airType")) {
                    d.airType = o.enums === String ? $root.taxi.TripAirType[m.airType] : m.airType;
                }
                if (m.vehicle != null && m.hasOwnProperty("vehicle")) {
                    d.vehicle = $root.taxi.TripVehicle.toObject(m.vehicle, o);
                }
                if (m.count != null && m.hasOwnProperty("count")) {
                    d.count = m.count;
                }
                if (m.discountPercentage != null && m.hasOwnProperty("discountPercentage")) {
                    d.discountPercentage = m.discountPercentage;
                }
                if (m.pickUpAt != null && m.hasOwnProperty("pickUpAt")) {
                    d.pickUpAt = $root.taxi.Timestamp.toObject(m.pickUpAt, o);
                }
                if (m.info && m.info.length) {
                    d.info = [];
                    for (var j = 0; j < m.info.length; ++j) {
                        d.info[j] = $root.taxi.server.TripGetAirDealResult.LineItem.toObject(m.info[j], o);
                    }
                }
                if (m.notices && m.notices.length) {
                    d.notices = [];
                    for (var j = 0; j < m.notices.length; ++j) {
                        d.notices[j] = m.notices[j];
                    }
                }
                return d;
            };

            /**
             * Converts this TripGetAirDealResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetAirDealResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetAirDealResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            TripGetAirDealResult.LineItem = (function() {

                /**
                 * Properties of a LineItem.
                 * @memberof taxi.server.TripGetAirDealResult
                 * @interface ILineItem
                 * @property {string|null} [key] LineItem key
                 * @property {string|null} [value] LineItem value
                 */

                /**
                 * Constructs a new LineItem.
                 * @memberof taxi.server.TripGetAirDealResult
                 * @classdesc Represents a LineItem.
                 * @implements ILineItem
                 * @constructor
                 * @param {taxi.server.TripGetAirDealResult.ILineItem=} [p] Properties to set
                 */
                function LineItem(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * LineItem key.
                 * @member {string} key
                 * @memberof taxi.server.TripGetAirDealResult.LineItem
                 * @instance
                 */
                LineItem.prototype.key = "";

                /**
                 * LineItem value.
                 * @member {string} value
                 * @memberof taxi.server.TripGetAirDealResult.LineItem
                 * @instance
                 */
                LineItem.prototype.value = "";

                /**
                 * Creates a new LineItem instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.TripGetAirDealResult.LineItem
                 * @static
                 * @param {taxi.server.TripGetAirDealResult.ILineItem=} [properties] Properties to set
                 * @returns {taxi.server.TripGetAirDealResult.LineItem} LineItem instance
                 */
                LineItem.create = function create(properties) {
                    return new LineItem(properties);
                };

                /**
                 * Creates a LineItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.TripGetAirDealResult.LineItem
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.TripGetAirDealResult.LineItem} LineItem
                 */
                LineItem.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.TripGetAirDealResult.LineItem)
                        return d;
                    var m = new $root.taxi.server.TripGetAirDealResult.LineItem();
                    if (d.key != null) {
                        m.key = String(d.key);
                    }
                    if (d.value != null) {
                        m.value = String(d.value);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a LineItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.TripGetAirDealResult.LineItem
                 * @static
                 * @param {taxi.server.TripGetAirDealResult.LineItem} m LineItem
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LineItem.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.key = "";
                        d.value = "";
                    }
                    if (m.key != null && m.hasOwnProperty("key")) {
                        d.key = m.key;
                    }
                    if (m.value != null && m.hasOwnProperty("value")) {
                        d.value = m.value;
                    }
                    return d;
                };

                /**
                 * Converts this LineItem to JSON.
                 * @function toJSON
                 * @memberof taxi.server.TripGetAirDealResult.LineItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LineItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LineItem;
            })();

            return TripGetAirDealResult;
        })();

        server.TripGetCharterCatalogueParams = (function() {

            /**
             * Properties of a TripGetCharterCatalogueParams.
             * @memberof taxi.server
             * @interface ITripGetCharterCatalogueParams
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripGetCharterCatalogueParams vehicleType
             */

            /**
             * Constructs a new TripGetCharterCatalogueParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetCharterCatalogueParams.
             * @implements ITripGetCharterCatalogueParams
             * @constructor
             * @param {taxi.server.ITripGetCharterCatalogueParams=} [p] Properties to set
             */
            function TripGetCharterCatalogueParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetCharterCatalogueParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripGetCharterCatalogueParams
             * @instance
             */
            TripGetCharterCatalogueParams.prototype.vehicleType = 0;

            /**
             * Creates a new TripGetCharterCatalogueParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetCharterCatalogueParams
             * @static
             * @param {taxi.server.ITripGetCharterCatalogueParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetCharterCatalogueParams} TripGetCharterCatalogueParams instance
             */
            TripGetCharterCatalogueParams.create = function create(properties) {
                return new TripGetCharterCatalogueParams(properties);
            };

            /**
             * Creates a TripGetCharterCatalogueParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetCharterCatalogueParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetCharterCatalogueParams} TripGetCharterCatalogueParams
             */
            TripGetCharterCatalogueParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetCharterCatalogueParams)
                    return d;
                var m = new $root.taxi.server.TripGetCharterCatalogueParams();
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetCharterCatalogueParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetCharterCatalogueParams
             * @static
             * @param {taxi.server.TripGetCharterCatalogueParams} m TripGetCharterCatalogueParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetCharterCatalogueParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                return d;
            };

            /**
             * Converts this TripGetCharterCatalogueParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetCharterCatalogueParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetCharterCatalogueParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetCharterCatalogueParams;
        })();

        server.TripGetCharterCatalogueResult = (function() {

            /**
             * Properties of a TripGetCharterCatalogueResult.
             * @memberof taxi.server
             * @interface ITripGetCharterCatalogueResult
             * @property {Array.<taxi.server.TripGetCharterCatalogueResult.IProduct>|null} [products] TripGetCharterCatalogueResult products
             */

            /**
             * Constructs a new TripGetCharterCatalogueResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetCharterCatalogueResult.
             * @implements ITripGetCharterCatalogueResult
             * @constructor
             * @param {taxi.server.ITripGetCharterCatalogueResult=} [p] Properties to set
             */
            function TripGetCharterCatalogueResult(p) {
                this.products = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetCharterCatalogueResult products.
             * @member {Array.<taxi.server.TripGetCharterCatalogueResult.IProduct>} products
             * @memberof taxi.server.TripGetCharterCatalogueResult
             * @instance
             */
            TripGetCharterCatalogueResult.prototype.products = $util.emptyArray;

            /**
             * Creates a new TripGetCharterCatalogueResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetCharterCatalogueResult
             * @static
             * @param {taxi.server.ITripGetCharterCatalogueResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetCharterCatalogueResult} TripGetCharterCatalogueResult instance
             */
            TripGetCharterCatalogueResult.create = function create(properties) {
                return new TripGetCharterCatalogueResult(properties);
            };

            /**
             * Creates a TripGetCharterCatalogueResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetCharterCatalogueResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetCharterCatalogueResult} TripGetCharterCatalogueResult
             */
            TripGetCharterCatalogueResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetCharterCatalogueResult)
                    return d;
                var m = new $root.taxi.server.TripGetCharterCatalogueResult();
                if (d.products) {
                    if (!Array.isArray(d.products))
                        throw TypeError(".taxi.server.TripGetCharterCatalogueResult.products: array expected");
                    m.products = [];
                    for (var i = 0; i < d.products.length; ++i) {
                        if (typeof d.products[i] !== "object")
                            throw TypeError(".taxi.server.TripGetCharterCatalogueResult.products: object expected");
                        m.products[i] = $root.taxi.server.TripGetCharterCatalogueResult.Product.fromObject(d.products[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetCharterCatalogueResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetCharterCatalogueResult
             * @static
             * @param {taxi.server.TripGetCharterCatalogueResult} m TripGetCharterCatalogueResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetCharterCatalogueResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.products = [];
                }
                if (m.products && m.products.length) {
                    d.products = [];
                    for (var j = 0; j < m.products.length; ++j) {
                        d.products[j] = $root.taxi.server.TripGetCharterCatalogueResult.Product.toObject(m.products[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this TripGetCharterCatalogueResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetCharterCatalogueResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetCharterCatalogueResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            TripGetCharterCatalogueResult.Product = (function() {

                /**
                 * Properties of a Product.
                 * @memberof taxi.server.TripGetCharterCatalogueResult
                 * @interface IProduct
                 * @property {number|null} [durationMinutes] Product durationMinutes
                 * @property {number|null} [distanceKm] Product distanceKm
                 * @property {number|null} [originCost] Product originCost
                 * @property {number|null} [minCost] Product minCost
                 */

                /**
                 * Constructs a new Product.
                 * @memberof taxi.server.TripGetCharterCatalogueResult
                 * @classdesc Represents a Product.
                 * @implements IProduct
                 * @constructor
                 * @param {taxi.server.TripGetCharterCatalogueResult.IProduct=} [p] Properties to set
                 */
                function Product(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Product durationMinutes.
                 * @member {number} durationMinutes
                 * @memberof taxi.server.TripGetCharterCatalogueResult.Product
                 * @instance
                 */
                Product.prototype.durationMinutes = 0;

                /**
                 * Product distanceKm.
                 * @member {number} distanceKm
                 * @memberof taxi.server.TripGetCharterCatalogueResult.Product
                 * @instance
                 */
                Product.prototype.distanceKm = 0;

                /**
                 * Product originCost.
                 * @member {number} originCost
                 * @memberof taxi.server.TripGetCharterCatalogueResult.Product
                 * @instance
                 */
                Product.prototype.originCost = 0;

                /**
                 * Product minCost.
                 * @member {number} minCost
                 * @memberof taxi.server.TripGetCharterCatalogueResult.Product
                 * @instance
                 */
                Product.prototype.minCost = 0;

                /**
                 * Creates a new Product instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.TripGetCharterCatalogueResult.Product
                 * @static
                 * @param {taxi.server.TripGetCharterCatalogueResult.IProduct=} [properties] Properties to set
                 * @returns {taxi.server.TripGetCharterCatalogueResult.Product} Product instance
                 */
                Product.create = function create(properties) {
                    return new Product(properties);
                };

                /**
                 * Creates a Product message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.TripGetCharterCatalogueResult.Product
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.TripGetCharterCatalogueResult.Product} Product
                 */
                Product.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.TripGetCharterCatalogueResult.Product)
                        return d;
                    var m = new $root.taxi.server.TripGetCharterCatalogueResult.Product();
                    if (d.durationMinutes != null) {
                        m.durationMinutes = d.durationMinutes | 0;
                    }
                    if (d.distanceKm != null) {
                        m.distanceKm = d.distanceKm | 0;
                    }
                    if (d.originCost != null) {
                        m.originCost = d.originCost | 0;
                    }
                    if (d.minCost != null) {
                        m.minCost = d.minCost | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Product message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.TripGetCharterCatalogueResult.Product
                 * @static
                 * @param {taxi.server.TripGetCharterCatalogueResult.Product} m Product
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Product.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.durationMinutes = 0;
                        d.distanceKm = 0;
                        d.originCost = 0;
                        d.minCost = 0;
                    }
                    if (m.durationMinutes != null && m.hasOwnProperty("durationMinutes")) {
                        d.durationMinutes = m.durationMinutes;
                    }
                    if (m.distanceKm != null && m.hasOwnProperty("distanceKm")) {
                        d.distanceKm = m.distanceKm;
                    }
                    if (m.originCost != null && m.hasOwnProperty("originCost")) {
                        d.originCost = m.originCost;
                    }
                    if (m.minCost != null && m.hasOwnProperty("minCost")) {
                        d.minCost = m.minCost;
                    }
                    return d;
                };

                /**
                 * Converts this Product to JSON.
                 * @function toJSON
                 * @memberof taxi.server.TripGetCharterCatalogueResult.Product
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Product.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Product;
            })();

            return TripGetCharterCatalogueResult;
        })();

        server.TripGetCouponParams = (function() {

            /**
             * Properties of a TripGetCouponParams.
             * @memberof taxi.server
             * @interface ITripGetCouponParams
             * @property {string|null} [couponId] TripGetCouponParams couponId
             * @property {taxi.TripTypeDescription.TripType|null} [type] TripGetCouponParams type
             * @property {taxi.ITimestamp|null} [pickUpAt] TripGetCouponParams pickUpAt
             * @property {taxi.IStringValue|null} [paymentMethodId] TripGetCouponParams paymentMethodId
             * @property {taxi.IStringValue|null} [paymentProfileId] TripGetCouponParams paymentProfileId
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripGetCouponParams vehicleType
             * @property {taxi.TripAirType|null} [airType] TripGetCouponParams airType
             * @property {taxi.AirportCode|null} [airportCode] TripGetCouponParams airportCode
             * @property {taxi.IInt32Value|null} [basicMinute] TripGetCouponParams basicMinute
             * @property {taxi.IStringValue|null} [activeTripId] TripGetCouponParams activeTripId
             */

            /**
             * Constructs a new TripGetCouponParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetCouponParams.
             * @implements ITripGetCouponParams
             * @constructor
             * @param {taxi.server.ITripGetCouponParams=} [p] Properties to set
             */
            function TripGetCouponParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetCouponParams couponId.
             * @member {string} couponId
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.couponId = "";

            /**
             * TripGetCouponParams type.
             * @member {taxi.TripTypeDescription.TripType} type
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.type = 0;

            /**
             * TripGetCouponParams pickUpAt.
             * @member {taxi.ITimestamp|null|undefined} pickUpAt
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.pickUpAt = null;

            /**
             * TripGetCouponParams paymentMethodId.
             * @member {taxi.IStringValue|null|undefined} paymentMethodId
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.paymentMethodId = null;

            /**
             * TripGetCouponParams paymentProfileId.
             * @member {taxi.IStringValue|null|undefined} paymentProfileId
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.paymentProfileId = null;

            /**
             * TripGetCouponParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.vehicleType = 0;

            /**
             * TripGetCouponParams airType.
             * @member {taxi.TripAirType} airType
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.airType = 0;

            /**
             * TripGetCouponParams airportCode.
             * @member {taxi.AirportCode} airportCode
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.airportCode = 0;

            /**
             * TripGetCouponParams basicMinute.
             * @member {taxi.IInt32Value|null|undefined} basicMinute
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.basicMinute = null;

            /**
             * TripGetCouponParams activeTripId.
             * @member {taxi.IStringValue|null|undefined} activeTripId
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             */
            TripGetCouponParams.prototype.activeTripId = null;

            /**
             * Creates a new TripGetCouponParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetCouponParams
             * @static
             * @param {taxi.server.ITripGetCouponParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetCouponParams} TripGetCouponParams instance
             */
            TripGetCouponParams.create = function create(properties) {
                return new TripGetCouponParams(properties);
            };

            /**
             * Creates a TripGetCouponParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetCouponParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetCouponParams} TripGetCouponParams
             */
            TripGetCouponParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetCouponParams)
                    return d;
                var m = new $root.taxi.server.TripGetCouponParams();
                if (d.couponId != null) {
                    m.couponId = String(d.couponId);
                }
                switch (d.type) {
                case "UNSPECIFIED":
                case 0:
                    m.type = 0;
                    break;
                case "AIR":
                case 1:
                    m.type = 1;
                    break;
                case "CHARTER":
                case 2:
                    m.type = 2;
                    break;
                case "VAN":
                case 3:
                    m.type = 3;
                    break;
                }
                if (d.pickUpAt != null) {
                    if (typeof d.pickUpAt !== "object")
                        throw TypeError(".taxi.server.TripGetCouponParams.pickUpAt: object expected");
                    m.pickUpAt = $root.taxi.Timestamp.fromObject(d.pickUpAt);
                }
                if (d.paymentMethodId != null) {
                    if (typeof d.paymentMethodId !== "object")
                        throw TypeError(".taxi.server.TripGetCouponParams.paymentMethodId: object expected");
                    m.paymentMethodId = $root.taxi.StringValue.fromObject(d.paymentMethodId);
                }
                if (d.paymentProfileId != null) {
                    if (typeof d.paymentProfileId !== "object")
                        throw TypeError(".taxi.server.TripGetCouponParams.paymentProfileId: object expected");
                    m.paymentProfileId = $root.taxi.StringValue.fromObject(d.paymentProfileId);
                }
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                switch (d.airType) {
                case "SENDING":
                case 0:
                    m.airType = 0;
                    break;
                case "PICK_UP":
                case 1:
                    m.airType = 1;
                    break;
                }
                switch (d.airportCode) {
                case "ICN_1":
                case 0:
                    m.airportCode = 0;
                    break;
                case "ICN_2":
                case 1:
                    m.airportCode = 1;
                    break;
                case "GMP_INTERNATIONAL":
                case 3:
                    m.airportCode = 3;
                    break;
                case "GMP_DOMESTIC":
                case 4:
                    m.airportCode = 4;
                    break;
                case "CJU_INTERNATIONAL":
                case 5:
                    m.airportCode = 5;
                    break;
                case "CJU_DOMESTIC":
                case 6:
                    m.airportCode = 6;
                    break;
                case "PUS_INTERNATIONAL":
                case 7:
                    m.airportCode = 7;
                    break;
                case "PUS_DOMESTIC":
                case 8:
                    m.airportCode = 8;
                    break;
                }
                if (d.basicMinute != null) {
                    if (typeof d.basicMinute !== "object")
                        throw TypeError(".taxi.server.TripGetCouponParams.basicMinute: object expected");
                    m.basicMinute = $root.taxi.Int32Value.fromObject(d.basicMinute);
                }
                if (d.activeTripId != null) {
                    if (typeof d.activeTripId !== "object")
                        throw TypeError(".taxi.server.TripGetCouponParams.activeTripId: object expected");
                    m.activeTripId = $root.taxi.StringValue.fromObject(d.activeTripId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetCouponParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetCouponParams
             * @static
             * @param {taxi.server.TripGetCouponParams} m TripGetCouponParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetCouponParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.couponId = "";
                    d.type = o.enums === String ? "UNSPECIFIED" : 0;
                    d.pickUpAt = null;
                    d.paymentMethodId = null;
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                    d.airType = o.enums === String ? "SENDING" : 0;
                    d.airportCode = o.enums === String ? "ICN_1" : 0;
                    d.basicMinute = null;
                    d.paymentProfileId = null;
                    d.activeTripId = null;
                }
                if (m.couponId != null && m.hasOwnProperty("couponId")) {
                    d.couponId = m.couponId;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.taxi.TripTypeDescription.TripType[m.type] : m.type;
                }
                if (m.pickUpAt != null && m.hasOwnProperty("pickUpAt")) {
                    d.pickUpAt = $root.taxi.Timestamp.toObject(m.pickUpAt, o);
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = $root.taxi.StringValue.toObject(m.paymentMethodId, o);
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                if (m.airType != null && m.hasOwnProperty("airType")) {
                    d.airType = o.enums === String ? $root.taxi.TripAirType[m.airType] : m.airType;
                }
                if (m.airportCode != null && m.hasOwnProperty("airportCode")) {
                    d.airportCode = o.enums === String ? $root.taxi.AirportCode[m.airportCode] : m.airportCode;
                }
                if (m.basicMinute != null && m.hasOwnProperty("basicMinute")) {
                    d.basicMinute = $root.taxi.Int32Value.toObject(m.basicMinute, o);
                }
                if (m.paymentProfileId != null && m.hasOwnProperty("paymentProfileId")) {
                    d.paymentProfileId = $root.taxi.StringValue.toObject(m.paymentProfileId, o);
                }
                if (m.activeTripId != null && m.hasOwnProperty("activeTripId")) {
                    d.activeTripId = $root.taxi.StringValue.toObject(m.activeTripId, o);
                }
                return d;
            };

            /**
             * Converts this TripGetCouponParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetCouponParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetCouponParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetCouponParams;
        })();

        server.TripGetCouponResult = (function() {

            /**
             * Properties of a TripGetCouponResult.
             * @memberof taxi.server
             * @interface ITripGetCouponResult
             * @property {taxi.ICouponDTO|null} [coupon] TripGetCouponResult coupon
             */

            /**
             * Constructs a new TripGetCouponResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetCouponResult.
             * @implements ITripGetCouponResult
             * @constructor
             * @param {taxi.server.ITripGetCouponResult=} [p] Properties to set
             */
            function TripGetCouponResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetCouponResult coupon.
             * @member {taxi.ICouponDTO|null|undefined} coupon
             * @memberof taxi.server.TripGetCouponResult
             * @instance
             */
            TripGetCouponResult.prototype.coupon = null;

            /**
             * Creates a new TripGetCouponResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetCouponResult
             * @static
             * @param {taxi.server.ITripGetCouponResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetCouponResult} TripGetCouponResult instance
             */
            TripGetCouponResult.create = function create(properties) {
                return new TripGetCouponResult(properties);
            };

            /**
             * Creates a TripGetCouponResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetCouponResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetCouponResult} TripGetCouponResult
             */
            TripGetCouponResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetCouponResult)
                    return d;
                var m = new $root.taxi.server.TripGetCouponResult();
                if (d.coupon != null) {
                    if (typeof d.coupon !== "object")
                        throw TypeError(".taxi.server.TripGetCouponResult.coupon: object expected");
                    m.coupon = $root.taxi.CouponDTO.fromObject(d.coupon);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetCouponResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetCouponResult
             * @static
             * @param {taxi.server.TripGetCouponResult} m TripGetCouponResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetCouponResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.coupon = null;
                }
                if (m.coupon != null && m.hasOwnProperty("coupon")) {
                    d.coupon = $root.taxi.CouponDTO.toObject(m.coupon, o);
                }
                return d;
            };

            /**
             * Converts this TripGetCouponResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetCouponResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetCouponResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetCouponResult;
        })();

        server.TripGetGolfCatalogueParams = (function() {

            /**
             * Properties of a TripGetGolfCatalogueParams.
             * @memberof taxi.server
             * @interface ITripGetGolfCatalogueParams
             */

            /**
             * Constructs a new TripGetGolfCatalogueParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetGolfCatalogueParams.
             * @implements ITripGetGolfCatalogueParams
             * @constructor
             * @param {taxi.server.ITripGetGolfCatalogueParams=} [p] Properties to set
             */
            function TripGetGolfCatalogueParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripGetGolfCatalogueParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetGolfCatalogueParams
             * @static
             * @param {taxi.server.ITripGetGolfCatalogueParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetGolfCatalogueParams} TripGetGolfCatalogueParams instance
             */
            TripGetGolfCatalogueParams.create = function create(properties) {
                return new TripGetGolfCatalogueParams(properties);
            };

            /**
             * Creates a TripGetGolfCatalogueParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetGolfCatalogueParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetGolfCatalogueParams} TripGetGolfCatalogueParams
             */
            TripGetGolfCatalogueParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetGolfCatalogueParams)
                    return d;
                return new $root.taxi.server.TripGetGolfCatalogueParams();
            };

            /**
             * Creates a plain object from a TripGetGolfCatalogueParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetGolfCatalogueParams
             * @static
             * @param {taxi.server.TripGetGolfCatalogueParams} m TripGetGolfCatalogueParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetGolfCatalogueParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripGetGolfCatalogueParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetGolfCatalogueParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetGolfCatalogueParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetGolfCatalogueParams;
        })();

        server.TripGetGolfCatalogueResult = (function() {

            /**
             * Properties of a TripGetGolfCatalogueResult.
             * @memberof taxi.server
             * @interface ITripGetGolfCatalogueResult
             * @property {Array.<taxi.server.TripGetGolfCatalogueResult.IPriceLineItem>|null} [priceLineItems] TripGetGolfCatalogueResult priceLineItems
             */

            /**
             * Constructs a new TripGetGolfCatalogueResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetGolfCatalogueResult.
             * @implements ITripGetGolfCatalogueResult
             * @constructor
             * @param {taxi.server.ITripGetGolfCatalogueResult=} [p] Properties to set
             */
            function TripGetGolfCatalogueResult(p) {
                this.priceLineItems = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetGolfCatalogueResult priceLineItems.
             * @member {Array.<taxi.server.TripGetGolfCatalogueResult.IPriceLineItem>} priceLineItems
             * @memberof taxi.server.TripGetGolfCatalogueResult
             * @instance
             */
            TripGetGolfCatalogueResult.prototype.priceLineItems = $util.emptyArray;

            /**
             * Creates a new TripGetGolfCatalogueResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetGolfCatalogueResult
             * @static
             * @param {taxi.server.ITripGetGolfCatalogueResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetGolfCatalogueResult} TripGetGolfCatalogueResult instance
             */
            TripGetGolfCatalogueResult.create = function create(properties) {
                return new TripGetGolfCatalogueResult(properties);
            };

            /**
             * Creates a TripGetGolfCatalogueResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetGolfCatalogueResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetGolfCatalogueResult} TripGetGolfCatalogueResult
             */
            TripGetGolfCatalogueResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetGolfCatalogueResult)
                    return d;
                var m = new $root.taxi.server.TripGetGolfCatalogueResult();
                if (d.priceLineItems) {
                    if (!Array.isArray(d.priceLineItems))
                        throw TypeError(".taxi.server.TripGetGolfCatalogueResult.priceLineItems: array expected");
                    m.priceLineItems = [];
                    for (var i = 0; i < d.priceLineItems.length; ++i) {
                        if (typeof d.priceLineItems[i] !== "object")
                            throw TypeError(".taxi.server.TripGetGolfCatalogueResult.priceLineItems: object expected");
                        m.priceLineItems[i] = $root.taxi.server.TripGetGolfCatalogueResult.PriceLineItem.fromObject(d.priceLineItems[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetGolfCatalogueResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetGolfCatalogueResult
             * @static
             * @param {taxi.server.TripGetGolfCatalogueResult} m TripGetGolfCatalogueResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetGolfCatalogueResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.priceLineItems = [];
                }
                if (m.priceLineItems && m.priceLineItems.length) {
                    d.priceLineItems = [];
                    for (var j = 0; j < m.priceLineItems.length; ++j) {
                        d.priceLineItems[j] = $root.taxi.server.TripGetGolfCatalogueResult.PriceLineItem.toObject(m.priceLineItems[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this TripGetGolfCatalogueResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetGolfCatalogueResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetGolfCatalogueResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            TripGetGolfCatalogueResult.Price = (function() {

                /**
                 * Properties of a Price.
                 * @memberof taxi.server.TripGetGolfCatalogueResult
                 * @interface IPrice
                 * @property {number|null} [originCost] Price originCost
                 * @property {number|null} [minCost] Price minCost
                 */

                /**
                 * Constructs a new Price.
                 * @memberof taxi.server.TripGetGolfCatalogueResult
                 * @classdesc Represents a Price.
                 * @implements IPrice
                 * @constructor
                 * @param {taxi.server.TripGetGolfCatalogueResult.IPrice=} [p] Properties to set
                 */
                function Price(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Price originCost.
                 * @member {number} originCost
                 * @memberof taxi.server.TripGetGolfCatalogueResult.Price
                 * @instance
                 */
                Price.prototype.originCost = 0;

                /**
                 * Price minCost.
                 * @member {number} minCost
                 * @memberof taxi.server.TripGetGolfCatalogueResult.Price
                 * @instance
                 */
                Price.prototype.minCost = 0;

                /**
                 * Creates a new Price instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.TripGetGolfCatalogueResult.Price
                 * @static
                 * @param {taxi.server.TripGetGolfCatalogueResult.IPrice=} [properties] Properties to set
                 * @returns {taxi.server.TripGetGolfCatalogueResult.Price} Price instance
                 */
                Price.create = function create(properties) {
                    return new Price(properties);
                };

                /**
                 * Creates a Price message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.TripGetGolfCatalogueResult.Price
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.TripGetGolfCatalogueResult.Price} Price
                 */
                Price.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.TripGetGolfCatalogueResult.Price)
                        return d;
                    var m = new $root.taxi.server.TripGetGolfCatalogueResult.Price();
                    if (d.originCost != null) {
                        m.originCost = d.originCost | 0;
                    }
                    if (d.minCost != null) {
                        m.minCost = d.minCost | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Price message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.TripGetGolfCatalogueResult.Price
                 * @static
                 * @param {taxi.server.TripGetGolfCatalogueResult.Price} m Price
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Price.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.originCost = 0;
                        d.minCost = 0;
                    }
                    if (m.originCost != null && m.hasOwnProperty("originCost")) {
                        d.originCost = m.originCost;
                    }
                    if (m.minCost != null && m.hasOwnProperty("minCost")) {
                        d.minCost = m.minCost;
                    }
                    return d;
                };

                /**
                 * Converts this Price to JSON.
                 * @function toJSON
                 * @memberof taxi.server.TripGetGolfCatalogueResult.Price
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Price.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Price;
            })();

            TripGetGolfCatalogueResult.PriceLineItem = (function() {

                /**
                 * Properties of a PriceLineItem.
                 * @memberof taxi.server.TripGetGolfCatalogueResult
                 * @interface IPriceLineItem
                 * @property {string|null} [region] PriceLineItem region
                 * @property {number|null} [distanceKm] PriceLineItem distanceKm
                 * @property {taxi.server.TripGetGolfCatalogueResult.IPrice|null} [rv] PriceLineItem rv
                 * @property {taxi.server.TripGetGolfCatalogueResult.IPrice|null} [van] PriceLineItem van
                 */

                /**
                 * Constructs a new PriceLineItem.
                 * @memberof taxi.server.TripGetGolfCatalogueResult
                 * @classdesc Represents a PriceLineItem.
                 * @implements IPriceLineItem
                 * @constructor
                 * @param {taxi.server.TripGetGolfCatalogueResult.IPriceLineItem=} [p] Properties to set
                 */
                function PriceLineItem(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * PriceLineItem region.
                 * @member {string} region
                 * @memberof taxi.server.TripGetGolfCatalogueResult.PriceLineItem
                 * @instance
                 */
                PriceLineItem.prototype.region = "";

                /**
                 * PriceLineItem distanceKm.
                 * @member {number} distanceKm
                 * @memberof taxi.server.TripGetGolfCatalogueResult.PriceLineItem
                 * @instance
                 */
                PriceLineItem.prototype.distanceKm = 0;

                /**
                 * PriceLineItem rv.
                 * @member {taxi.server.TripGetGolfCatalogueResult.IPrice|null|undefined} rv
                 * @memberof taxi.server.TripGetGolfCatalogueResult.PriceLineItem
                 * @instance
                 */
                PriceLineItem.prototype.rv = null;

                /**
                 * PriceLineItem van.
                 * @member {taxi.server.TripGetGolfCatalogueResult.IPrice|null|undefined} van
                 * @memberof taxi.server.TripGetGolfCatalogueResult.PriceLineItem
                 * @instance
                 */
                PriceLineItem.prototype.van = null;

                /**
                 * Creates a new PriceLineItem instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.TripGetGolfCatalogueResult.PriceLineItem
                 * @static
                 * @param {taxi.server.TripGetGolfCatalogueResult.IPriceLineItem=} [properties] Properties to set
                 * @returns {taxi.server.TripGetGolfCatalogueResult.PriceLineItem} PriceLineItem instance
                 */
                PriceLineItem.create = function create(properties) {
                    return new PriceLineItem(properties);
                };

                /**
                 * Creates a PriceLineItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.TripGetGolfCatalogueResult.PriceLineItem
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.TripGetGolfCatalogueResult.PriceLineItem} PriceLineItem
                 */
                PriceLineItem.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.TripGetGolfCatalogueResult.PriceLineItem)
                        return d;
                    var m = new $root.taxi.server.TripGetGolfCatalogueResult.PriceLineItem();
                    if (d.region != null) {
                        m.region = String(d.region);
                    }
                    if (d.distanceKm != null) {
                        m.distanceKm = d.distanceKm | 0;
                    }
                    if (d.rv != null) {
                        if (typeof d.rv !== "object")
                            throw TypeError(".taxi.server.TripGetGolfCatalogueResult.PriceLineItem.rv: object expected");
                        m.rv = $root.taxi.server.TripGetGolfCatalogueResult.Price.fromObject(d.rv);
                    }
                    if (d.van != null) {
                        if (typeof d.van !== "object")
                            throw TypeError(".taxi.server.TripGetGolfCatalogueResult.PriceLineItem.van: object expected");
                        m.van = $root.taxi.server.TripGetGolfCatalogueResult.Price.fromObject(d.van);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a PriceLineItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.TripGetGolfCatalogueResult.PriceLineItem
                 * @static
                 * @param {taxi.server.TripGetGolfCatalogueResult.PriceLineItem} m PriceLineItem
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriceLineItem.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.region = "";
                        d.distanceKm = 0;
                        d.rv = null;
                        d.van = null;
                    }
                    if (m.region != null && m.hasOwnProperty("region")) {
                        d.region = m.region;
                    }
                    if (m.distanceKm != null && m.hasOwnProperty("distanceKm")) {
                        d.distanceKm = m.distanceKm;
                    }
                    if (m.rv != null && m.hasOwnProperty("rv")) {
                        d.rv = $root.taxi.server.TripGetGolfCatalogueResult.Price.toObject(m.rv, o);
                    }
                    if (m.van != null && m.hasOwnProperty("van")) {
                        d.van = $root.taxi.server.TripGetGolfCatalogueResult.Price.toObject(m.van, o);
                    }
                    return d;
                };

                /**
                 * Converts this PriceLineItem to JSON.
                 * @function toJSON
                 * @memberof taxi.server.TripGetGolfCatalogueResult.PriceLineItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriceLineItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PriceLineItem;
            })();

            return TripGetGolfCatalogueResult;
        })();

        server.TripGetProductParams = (function() {

            /**
             * Properties of a TripGetProductParams.
             * @memberof taxi.server
             * @interface ITripGetProductParams
             * @property {taxi.TripTypeDescription.TripType|null} [type] TripGetProductParams type
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripGetProductParams vehicleType
             */

            /**
             * Constructs a new TripGetProductParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetProductParams.
             * @implements ITripGetProductParams
             * @constructor
             * @param {taxi.server.ITripGetProductParams=} [p] Properties to set
             */
            function TripGetProductParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetProductParams type.
             * @member {taxi.TripTypeDescription.TripType} type
             * @memberof taxi.server.TripGetProductParams
             * @instance
             */
            TripGetProductParams.prototype.type = 0;

            /**
             * TripGetProductParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripGetProductParams
             * @instance
             */
            TripGetProductParams.prototype.vehicleType = 0;

            /**
             * Creates a new TripGetProductParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetProductParams
             * @static
             * @param {taxi.server.ITripGetProductParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetProductParams} TripGetProductParams instance
             */
            TripGetProductParams.create = function create(properties) {
                return new TripGetProductParams(properties);
            };

            /**
             * Creates a TripGetProductParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetProductParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetProductParams} TripGetProductParams
             */
            TripGetProductParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetProductParams)
                    return d;
                var m = new $root.taxi.server.TripGetProductParams();
                switch (d.type) {
                case "UNSPECIFIED":
                case 0:
                    m.type = 0;
                    break;
                case "AIR":
                case 1:
                    m.type = 1;
                    break;
                case "CHARTER":
                case 2:
                    m.type = 2;
                    break;
                case "VAN":
                case 3:
                    m.type = 3;
                    break;
                }
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetProductParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetProductParams
             * @static
             * @param {taxi.server.TripGetProductParams} m TripGetProductParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetProductParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.type = o.enums === String ? "UNSPECIFIED" : 0;
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.taxi.TripTypeDescription.TripType[m.type] : m.type;
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                return d;
            };

            /**
             * Converts this TripGetProductParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetProductParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetProductParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetProductParams;
        })();

        server.TripGetProductResult = (function() {

            /**
             * Properties of a TripGetProductResult.
             * @memberof taxi.server
             * @interface ITripGetProductResult
             * @property {taxi.ITripProduct|null} [product] TripGetProductResult product
             */

            /**
             * Constructs a new TripGetProductResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetProductResult.
             * @implements ITripGetProductResult
             * @constructor
             * @param {taxi.server.ITripGetProductResult=} [p] Properties to set
             */
            function TripGetProductResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetProductResult product.
             * @member {taxi.ITripProduct|null|undefined} product
             * @memberof taxi.server.TripGetProductResult
             * @instance
             */
            TripGetProductResult.prototype.product = null;

            /**
             * Creates a new TripGetProductResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetProductResult
             * @static
             * @param {taxi.server.ITripGetProductResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetProductResult} TripGetProductResult instance
             */
            TripGetProductResult.create = function create(properties) {
                return new TripGetProductResult(properties);
            };

            /**
             * Creates a TripGetProductResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetProductResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetProductResult} TripGetProductResult
             */
            TripGetProductResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetProductResult)
                    return d;
                var m = new $root.taxi.server.TripGetProductResult();
                if (d.product != null) {
                    if (typeof d.product !== "object")
                        throw TypeError(".taxi.server.TripGetProductResult.product: object expected");
                    m.product = $root.taxi.TripProduct.fromObject(d.product);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetProductResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetProductResult
             * @static
             * @param {taxi.server.TripGetProductResult} m TripGetProductResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetProductResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.product = null;
                }
                if (m.product != null && m.hasOwnProperty("product")) {
                    d.product = $root.taxi.TripProduct.toObject(m.product, o);
                }
                return d;
            };

            /**
             * Converts this TripGetProductResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetProductResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetProductResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetProductResult;
        })();

        server.TripGetReservationParams = (function() {

            /**
             * Properties of a TripGetReservationParams.
             * @memberof taxi.server
             * @interface ITripGetReservationParams
             * @property {string|null} [tripId] TripGetReservationParams tripId
             */

            /**
             * Constructs a new TripGetReservationParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetReservationParams.
             * @implements ITripGetReservationParams
             * @constructor
             * @param {taxi.server.ITripGetReservationParams=} [p] Properties to set
             */
            function TripGetReservationParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetReservationParams tripId.
             * @member {string} tripId
             * @memberof taxi.server.TripGetReservationParams
             * @instance
             */
            TripGetReservationParams.prototype.tripId = "";

            /**
             * Creates a new TripGetReservationParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetReservationParams
             * @static
             * @param {taxi.server.ITripGetReservationParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetReservationParams} TripGetReservationParams instance
             */
            TripGetReservationParams.create = function create(properties) {
                return new TripGetReservationParams(properties);
            };

            /**
             * Creates a TripGetReservationParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetReservationParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetReservationParams} TripGetReservationParams
             */
            TripGetReservationParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetReservationParams)
                    return d;
                var m = new $root.taxi.server.TripGetReservationParams();
                if (d.tripId != null) {
                    m.tripId = String(d.tripId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetReservationParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetReservationParams
             * @static
             * @param {taxi.server.TripGetReservationParams} m TripGetReservationParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetReservationParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripId = "";
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = m.tripId;
                }
                return d;
            };

            /**
             * Converts this TripGetReservationParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetReservationParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetReservationParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetReservationParams;
        })();

        server.TripGetReservationResult = (function() {

            /**
             * Properties of a TripGetReservationResult.
             * @memberof taxi.server
             * @interface ITripGetReservationResult
             * @property {taxi.ITripReservation|null} [reservation] TripGetReservationResult reservation
             */

            /**
             * Constructs a new TripGetReservationResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetReservationResult.
             * @implements ITripGetReservationResult
             * @constructor
             * @param {taxi.server.ITripGetReservationResult=} [p] Properties to set
             */
            function TripGetReservationResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetReservationResult reservation.
             * @member {taxi.ITripReservation|null|undefined} reservation
             * @memberof taxi.server.TripGetReservationResult
             * @instance
             */
            TripGetReservationResult.prototype.reservation = null;

            /**
             * Creates a new TripGetReservationResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetReservationResult
             * @static
             * @param {taxi.server.ITripGetReservationResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetReservationResult} TripGetReservationResult instance
             */
            TripGetReservationResult.create = function create(properties) {
                return new TripGetReservationResult(properties);
            };

            /**
             * Creates a TripGetReservationResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetReservationResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetReservationResult} TripGetReservationResult
             */
            TripGetReservationResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetReservationResult)
                    return d;
                var m = new $root.taxi.server.TripGetReservationResult();
                if (d.reservation != null) {
                    if (typeof d.reservation !== "object")
                        throw TypeError(".taxi.server.TripGetReservationResult.reservation: object expected");
                    m.reservation = $root.taxi.TripReservation.fromObject(d.reservation);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetReservationResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetReservationResult
             * @static
             * @param {taxi.server.TripGetReservationResult} m TripGetReservationResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetReservationResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.reservation = null;
                }
                if (m.reservation != null && m.hasOwnProperty("reservation")) {
                    d.reservation = $root.taxi.TripReservation.toObject(m.reservation, o);
                }
                return d;
            };

            /**
             * Converts this TripGetReservationResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetReservationResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetReservationResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetReservationResult;
        })();

        server.TripGetTripBannerParams = (function() {

            /**
             * Properties of a TripGetTripBannerParams.
             * @memberof taxi.server
             * @interface ITripGetTripBannerParams
             */

            /**
             * Constructs a new TripGetTripBannerParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetTripBannerParams.
             * @implements ITripGetTripBannerParams
             * @constructor
             * @param {taxi.server.ITripGetTripBannerParams=} [p] Properties to set
             */
            function TripGetTripBannerParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripGetTripBannerParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetTripBannerParams
             * @static
             * @param {taxi.server.ITripGetTripBannerParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetTripBannerParams} TripGetTripBannerParams instance
             */
            TripGetTripBannerParams.create = function create(properties) {
                return new TripGetTripBannerParams(properties);
            };

            /**
             * Creates a TripGetTripBannerParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetTripBannerParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetTripBannerParams} TripGetTripBannerParams
             */
            TripGetTripBannerParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetTripBannerParams)
                    return d;
                return new $root.taxi.server.TripGetTripBannerParams();
            };

            /**
             * Creates a plain object from a TripGetTripBannerParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetTripBannerParams
             * @static
             * @param {taxi.server.TripGetTripBannerParams} m TripGetTripBannerParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetTripBannerParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripGetTripBannerParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetTripBannerParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetTripBannerParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetTripBannerParams;
        })();

        server.TripGetTripBannerResult = (function() {

            /**
             * Properties of a TripGetTripBannerResult.
             * @memberof taxi.server
             * @interface ITripGetTripBannerResult
             * @property {taxi.server.UserGetActiveTripCountResult.IBanner|null} [banner] TripGetTripBannerResult banner
             */

            /**
             * Constructs a new TripGetTripBannerResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetTripBannerResult.
             * @implements ITripGetTripBannerResult
             * @constructor
             * @param {taxi.server.ITripGetTripBannerResult=} [p] Properties to set
             */
            function TripGetTripBannerResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetTripBannerResult banner.
             * @member {taxi.server.UserGetActiveTripCountResult.IBanner|null|undefined} banner
             * @memberof taxi.server.TripGetTripBannerResult
             * @instance
             */
            TripGetTripBannerResult.prototype.banner = null;

            /**
             * Creates a new TripGetTripBannerResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetTripBannerResult
             * @static
             * @param {taxi.server.ITripGetTripBannerResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetTripBannerResult} TripGetTripBannerResult instance
             */
            TripGetTripBannerResult.create = function create(properties) {
                return new TripGetTripBannerResult(properties);
            };

            /**
             * Creates a TripGetTripBannerResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetTripBannerResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetTripBannerResult} TripGetTripBannerResult
             */
            TripGetTripBannerResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetTripBannerResult)
                    return d;
                var m = new $root.taxi.server.TripGetTripBannerResult();
                if (d.banner != null) {
                    if (typeof d.banner !== "object")
                        throw TypeError(".taxi.server.TripGetTripBannerResult.banner: object expected");
                    m.banner = $root.taxi.server.UserGetActiveTripCountResult.Banner.fromObject(d.banner);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetTripBannerResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetTripBannerResult
             * @static
             * @param {taxi.server.TripGetTripBannerResult} m TripGetTripBannerResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetTripBannerResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.banner = null;
                }
                if (m.banner != null && m.hasOwnProperty("banner")) {
                    d.banner = $root.taxi.server.UserGetActiveTripCountResult.Banner.toObject(m.banner, o);
                }
                return d;
            };

            /**
             * Converts this TripGetTripBannerResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetTripBannerResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetTripBannerResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetTripBannerResult;
        })();

        server.UserGetActiveTripCountParams = (function() {

            /**
             * Properties of a UserGetActiveTripCountParams.
             * @memberof taxi.server
             * @interface IUserGetActiveTripCountParams
             */

            /**
             * Constructs a new UserGetActiveTripCountParams.
             * @memberof taxi.server
             * @classdesc Represents a UserGetActiveTripCountParams.
             * @implements IUserGetActiveTripCountParams
             * @constructor
             * @param {taxi.server.IUserGetActiveTripCountParams=} [p] Properties to set
             */
            function UserGetActiveTripCountParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserGetActiveTripCountParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetActiveTripCountParams
             * @static
             * @param {taxi.server.IUserGetActiveTripCountParams=} [properties] Properties to set
             * @returns {taxi.server.UserGetActiveTripCountParams} UserGetActiveTripCountParams instance
             */
            UserGetActiveTripCountParams.create = function create(properties) {
                return new UserGetActiveTripCountParams(properties);
            };

            /**
             * Creates a UserGetActiveTripCountParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetActiveTripCountParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetActiveTripCountParams} UserGetActiveTripCountParams
             */
            UserGetActiveTripCountParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetActiveTripCountParams)
                    return d;
                return new $root.taxi.server.UserGetActiveTripCountParams();
            };

            /**
             * Creates a plain object from a UserGetActiveTripCountParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetActiveTripCountParams
             * @static
             * @param {taxi.server.UserGetActiveTripCountParams} m UserGetActiveTripCountParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetActiveTripCountParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserGetActiveTripCountParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetActiveTripCountParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetActiveTripCountParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetActiveTripCountParams;
        })();

        server.UserGetActiveTripCountResult = (function() {

            /**
             * Properties of a UserGetActiveTripCountResult.
             * @memberof taxi.server
             * @interface IUserGetActiveTripCountResult
             * @property {number|null} [activeTripCount] UserGetActiveTripCountResult activeTripCount
             * @property {taxi.IStringValue|null} [label] UserGetActiveTripCountResult label
             * @property {taxi.server.UserGetActiveTripCountResult.IBanner|null} [banner] UserGetActiveTripCountResult banner
             */

            /**
             * Constructs a new UserGetActiveTripCountResult.
             * @memberof taxi.server
             * @classdesc Represents a UserGetActiveTripCountResult.
             * @implements IUserGetActiveTripCountResult
             * @constructor
             * @param {taxi.server.IUserGetActiveTripCountResult=} [p] Properties to set
             */
            function UserGetActiveTripCountResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserGetActiveTripCountResult activeTripCount.
             * @member {number} activeTripCount
             * @memberof taxi.server.UserGetActiveTripCountResult
             * @instance
             */
            UserGetActiveTripCountResult.prototype.activeTripCount = 0;

            /**
             * UserGetActiveTripCountResult label.
             * @member {taxi.IStringValue|null|undefined} label
             * @memberof taxi.server.UserGetActiveTripCountResult
             * @instance
             */
            UserGetActiveTripCountResult.prototype.label = null;

            /**
             * UserGetActiveTripCountResult banner.
             * @member {taxi.server.UserGetActiveTripCountResult.IBanner|null|undefined} banner
             * @memberof taxi.server.UserGetActiveTripCountResult
             * @instance
             */
            UserGetActiveTripCountResult.prototype.banner = null;

            /**
             * Creates a new UserGetActiveTripCountResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetActiveTripCountResult
             * @static
             * @param {taxi.server.IUserGetActiveTripCountResult=} [properties] Properties to set
             * @returns {taxi.server.UserGetActiveTripCountResult} UserGetActiveTripCountResult instance
             */
            UserGetActiveTripCountResult.create = function create(properties) {
                return new UserGetActiveTripCountResult(properties);
            };

            /**
             * Creates a UserGetActiveTripCountResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetActiveTripCountResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetActiveTripCountResult} UserGetActiveTripCountResult
             */
            UserGetActiveTripCountResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetActiveTripCountResult)
                    return d;
                var m = new $root.taxi.server.UserGetActiveTripCountResult();
                if (d.activeTripCount != null) {
                    m.activeTripCount = d.activeTripCount | 0;
                }
                if (d.label != null) {
                    if (typeof d.label !== "object")
                        throw TypeError(".taxi.server.UserGetActiveTripCountResult.label: object expected");
                    m.label = $root.taxi.StringValue.fromObject(d.label);
                }
                if (d.banner != null) {
                    if (typeof d.banner !== "object")
                        throw TypeError(".taxi.server.UserGetActiveTripCountResult.banner: object expected");
                    m.banner = $root.taxi.server.UserGetActiveTripCountResult.Banner.fromObject(d.banner);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserGetActiveTripCountResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetActiveTripCountResult
             * @static
             * @param {taxi.server.UserGetActiveTripCountResult} m UserGetActiveTripCountResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetActiveTripCountResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.activeTripCount = 0;
                    d.label = null;
                    d.banner = null;
                }
                if (m.activeTripCount != null && m.hasOwnProperty("activeTripCount")) {
                    d.activeTripCount = m.activeTripCount;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = $root.taxi.StringValue.toObject(m.label, o);
                }
                if (m.banner != null && m.hasOwnProperty("banner")) {
                    d.banner = $root.taxi.server.UserGetActiveTripCountResult.Banner.toObject(m.banner, o);
                }
                return d;
            };

            /**
             * Converts this UserGetActiveTripCountResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetActiveTripCountResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetActiveTripCountResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UserGetActiveTripCountResult.Banner = (function() {

                /**
                 * Properties of a Banner.
                 * @memberof taxi.server.UserGetActiveTripCountResult
                 * @interface IBanner
                 * @property {taxi.IImage|null} [icon] Banner icon
                 * @property {string|null} [title] Banner title
                 * @property {string|null} [description] Banner description
                 */

                /**
                 * Constructs a new Banner.
                 * @memberof taxi.server.UserGetActiveTripCountResult
                 * @classdesc Represents a Banner.
                 * @implements IBanner
                 * @constructor
                 * @param {taxi.server.UserGetActiveTripCountResult.IBanner=} [p] Properties to set
                 */
                function Banner(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Banner icon.
                 * @member {taxi.IImage|null|undefined} icon
                 * @memberof taxi.server.UserGetActiveTripCountResult.Banner
                 * @instance
                 */
                Banner.prototype.icon = null;

                /**
                 * Banner title.
                 * @member {string} title
                 * @memberof taxi.server.UserGetActiveTripCountResult.Banner
                 * @instance
                 */
                Banner.prototype.title = "";

                /**
                 * Banner description.
                 * @member {string} description
                 * @memberof taxi.server.UserGetActiveTripCountResult.Banner
                 * @instance
                 */
                Banner.prototype.description = "";

                /**
                 * Creates a new Banner instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.UserGetActiveTripCountResult.Banner
                 * @static
                 * @param {taxi.server.UserGetActiveTripCountResult.IBanner=} [properties] Properties to set
                 * @returns {taxi.server.UserGetActiveTripCountResult.Banner} Banner instance
                 */
                Banner.create = function create(properties) {
                    return new Banner(properties);
                };

                /**
                 * Creates a Banner message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.UserGetActiveTripCountResult.Banner
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.UserGetActiveTripCountResult.Banner} Banner
                 */
                Banner.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.UserGetActiveTripCountResult.Banner)
                        return d;
                    var m = new $root.taxi.server.UserGetActiveTripCountResult.Banner();
                    if (d.icon != null) {
                        if (typeof d.icon !== "object")
                            throw TypeError(".taxi.server.UserGetActiveTripCountResult.Banner.icon: object expected");
                        m.icon = $root.taxi.Image.fromObject(d.icon);
                    }
                    if (d.title != null) {
                        m.title = String(d.title);
                    }
                    if (d.description != null) {
                        m.description = String(d.description);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Banner message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.UserGetActiveTripCountResult.Banner
                 * @static
                 * @param {taxi.server.UserGetActiveTripCountResult.Banner} m Banner
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Banner.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.icon = null;
                        d.title = "";
                        d.description = "";
                    }
                    if (m.icon != null && m.hasOwnProperty("icon")) {
                        d.icon = $root.taxi.Image.toObject(m.icon, o);
                    }
                    if (m.title != null && m.hasOwnProperty("title")) {
                        d.title = m.title;
                    }
                    if (m.description != null && m.hasOwnProperty("description")) {
                        d.description = m.description;
                    }
                    return d;
                };

                /**
                 * Converts this Banner to JSON.
                 * @function toJSON
                 * @memberof taxi.server.UserGetActiveTripCountResult.Banner
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Banner.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Banner;
            })();

            return UserGetActiveTripCountResult;
        })();

        server.TripGetTripRefundTypeParams = (function() {

            /**
             * Properties of a TripGetTripRefundTypeParams.
             * @memberof taxi.server
             * @interface ITripGetTripRefundTypeParams
             * @property {string|null} [tripId] TripGetTripRefundTypeParams tripId
             */

            /**
             * Constructs a new TripGetTripRefundTypeParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetTripRefundTypeParams.
             * @implements ITripGetTripRefundTypeParams
             * @constructor
             * @param {taxi.server.ITripGetTripRefundTypeParams=} [p] Properties to set
             */
            function TripGetTripRefundTypeParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetTripRefundTypeParams tripId.
             * @member {string} tripId
             * @memberof taxi.server.TripGetTripRefundTypeParams
             * @instance
             */
            TripGetTripRefundTypeParams.prototype.tripId = "";

            /**
             * Creates a new TripGetTripRefundTypeParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetTripRefundTypeParams
             * @static
             * @param {taxi.server.ITripGetTripRefundTypeParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetTripRefundTypeParams} TripGetTripRefundTypeParams instance
             */
            TripGetTripRefundTypeParams.create = function create(properties) {
                return new TripGetTripRefundTypeParams(properties);
            };

            /**
             * Creates a TripGetTripRefundTypeParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetTripRefundTypeParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetTripRefundTypeParams} TripGetTripRefundTypeParams
             */
            TripGetTripRefundTypeParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetTripRefundTypeParams)
                    return d;
                var m = new $root.taxi.server.TripGetTripRefundTypeParams();
                if (d.tripId != null) {
                    m.tripId = String(d.tripId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetTripRefundTypeParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetTripRefundTypeParams
             * @static
             * @param {taxi.server.TripGetTripRefundTypeParams} m TripGetTripRefundTypeParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetTripRefundTypeParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripId = "";
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = m.tripId;
                }
                return d;
            };

            /**
             * Converts this TripGetTripRefundTypeParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetTripRefundTypeParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetTripRefundTypeParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetTripRefundTypeParams;
        })();

        server.TripGetTripRefundTypeResult = (function() {

            /**
             * Properties of a TripGetTripRefundTypeResult.
             * @memberof taxi.server
             * @interface ITripGetTripRefundTypeResult
             * @property {taxi.server.TripGetTripRefundTypeResult.TripRefundType|null} [refundType] TripGetTripRefundTypeResult refundType
             */

            /**
             * Constructs a new TripGetTripRefundTypeResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetTripRefundTypeResult.
             * @implements ITripGetTripRefundTypeResult
             * @constructor
             * @param {taxi.server.ITripGetTripRefundTypeResult=} [p] Properties to set
             */
            function TripGetTripRefundTypeResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetTripRefundTypeResult refundType.
             * @member {taxi.server.TripGetTripRefundTypeResult.TripRefundType} refundType
             * @memberof taxi.server.TripGetTripRefundTypeResult
             * @instance
             */
            TripGetTripRefundTypeResult.prototype.refundType = 0;

            /**
             * Creates a new TripGetTripRefundTypeResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetTripRefundTypeResult
             * @static
             * @param {taxi.server.ITripGetTripRefundTypeResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetTripRefundTypeResult} TripGetTripRefundTypeResult instance
             */
            TripGetTripRefundTypeResult.create = function create(properties) {
                return new TripGetTripRefundTypeResult(properties);
            };

            /**
             * Creates a TripGetTripRefundTypeResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetTripRefundTypeResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetTripRefundTypeResult} TripGetTripRefundTypeResult
             */
            TripGetTripRefundTypeResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetTripRefundTypeResult)
                    return d;
                var m = new $root.taxi.server.TripGetTripRefundTypeResult();
                switch (d.refundType) {
                case "WITH_REFUND":
                case 0:
                    m.refundType = 0;
                    break;
                case "WITH_FEE":
                case 2:
                    m.refundType = 2;
                    break;
                case "WITH_NO_REFUND":
                case 3:
                    m.refundType = 3;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetTripRefundTypeResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetTripRefundTypeResult
             * @static
             * @param {taxi.server.TripGetTripRefundTypeResult} m TripGetTripRefundTypeResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetTripRefundTypeResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.refundType = o.enums === String ? "WITH_REFUND" : 0;
                }
                if (m.refundType != null && m.hasOwnProperty("refundType")) {
                    d.refundType = o.enums === String ? $root.taxi.server.TripGetTripRefundTypeResult.TripRefundType[m.refundType] : m.refundType;
                }
                return d;
            };

            /**
             * Converts this TripGetTripRefundTypeResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetTripRefundTypeResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetTripRefundTypeResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * TripRefundType enum.
             * @name taxi.server.TripGetTripRefundTypeResult.TripRefundType
             * @enum {string}
             * @property {number} WITH_REFUND=0 WITH_REFUND value
             * @property {number} WITH_FEE=2 WITH_FEE value
             * @property {number} WITH_NO_REFUND=3 WITH_NO_REFUND value
             */
            TripGetTripRefundTypeResult.TripRefundType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "WITH_REFUND"] = 0;
                values[valuesById[2] = "WITH_FEE"] = 2;
                values[valuesById[3] = "WITH_NO_REFUND"] = 3;
                return values;
            })();

            return TripGetTripRefundTypeResult;
        })();

        server.TripGetUserContactParams = (function() {

            /**
             * Properties of a TripGetUserContactParams.
             * @memberof taxi.server
             * @interface ITripGetUserContactParams
             */

            /**
             * Constructs a new TripGetUserContactParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetUserContactParams.
             * @implements ITripGetUserContactParams
             * @constructor
             * @param {taxi.server.ITripGetUserContactParams=} [p] Properties to set
             */
            function TripGetUserContactParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripGetUserContactParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetUserContactParams
             * @static
             * @param {taxi.server.ITripGetUserContactParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetUserContactParams} TripGetUserContactParams instance
             */
            TripGetUserContactParams.create = function create(properties) {
                return new TripGetUserContactParams(properties);
            };

            /**
             * Creates a TripGetUserContactParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetUserContactParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetUserContactParams} TripGetUserContactParams
             */
            TripGetUserContactParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetUserContactParams)
                    return d;
                return new $root.taxi.server.TripGetUserContactParams();
            };

            /**
             * Creates a plain object from a TripGetUserContactParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetUserContactParams
             * @static
             * @param {taxi.server.TripGetUserContactParams} m TripGetUserContactParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetUserContactParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripGetUserContactParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetUserContactParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetUserContactParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetUserContactParams;
        })();

        server.TripGetUserContactResult = (function() {

            /**
             * Properties of a TripGetUserContactResult.
             * @memberof taxi.server
             * @interface ITripGetUserContactResult
             * @property {string|null} [name] TripGetUserContactResult name
             * @property {string|null} [phoneNumber] TripGetUserContactResult phoneNumber
             */

            /**
             * Constructs a new TripGetUserContactResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetUserContactResult.
             * @implements ITripGetUserContactResult
             * @constructor
             * @param {taxi.server.ITripGetUserContactResult=} [p] Properties to set
             */
            function TripGetUserContactResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetUserContactResult name.
             * @member {string} name
             * @memberof taxi.server.TripGetUserContactResult
             * @instance
             */
            TripGetUserContactResult.prototype.name = "";

            /**
             * TripGetUserContactResult phoneNumber.
             * @member {string} phoneNumber
             * @memberof taxi.server.TripGetUserContactResult
             * @instance
             */
            TripGetUserContactResult.prototype.phoneNumber = "";

            /**
             * Creates a new TripGetUserContactResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetUserContactResult
             * @static
             * @param {taxi.server.ITripGetUserContactResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetUserContactResult} TripGetUserContactResult instance
             */
            TripGetUserContactResult.create = function create(properties) {
                return new TripGetUserContactResult(properties);
            };

            /**
             * Creates a TripGetUserContactResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetUserContactResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetUserContactResult} TripGetUserContactResult
             */
            TripGetUserContactResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetUserContactResult)
                    return d;
                var m = new $root.taxi.server.TripGetUserContactResult();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.phoneNumber != null) {
                    m.phoneNumber = String(d.phoneNumber);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetUserContactResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetUserContactResult
             * @static
             * @param {taxi.server.TripGetUserContactResult} m TripGetUserContactResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetUserContactResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.phoneNumber = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                    d.phoneNumber = m.phoneNumber;
                }
                return d;
            };

            /**
             * Converts this TripGetUserContactResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetUserContactResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetUserContactResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetUserContactResult;
        })();

        server.TripGetUserStatusParams = (function() {

            /**
             * Properties of a TripGetUserStatusParams.
             * @memberof taxi.server
             * @interface ITripGetUserStatusParams
             */

            /**
             * Constructs a new TripGetUserStatusParams.
             * @memberof taxi.server
             * @classdesc Represents a TripGetUserStatusParams.
             * @implements ITripGetUserStatusParams
             * @constructor
             * @param {taxi.server.ITripGetUserStatusParams=} [p] Properties to set
             */
            function TripGetUserStatusParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripGetUserStatusParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetUserStatusParams
             * @static
             * @param {taxi.server.ITripGetUserStatusParams=} [properties] Properties to set
             * @returns {taxi.server.TripGetUserStatusParams} TripGetUserStatusParams instance
             */
            TripGetUserStatusParams.create = function create(properties) {
                return new TripGetUserStatusParams(properties);
            };

            /**
             * Creates a TripGetUserStatusParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetUserStatusParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetUserStatusParams} TripGetUserStatusParams
             */
            TripGetUserStatusParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetUserStatusParams)
                    return d;
                return new $root.taxi.server.TripGetUserStatusParams();
            };

            /**
             * Creates a plain object from a TripGetUserStatusParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetUserStatusParams
             * @static
             * @param {taxi.server.TripGetUserStatusParams} m TripGetUserStatusParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetUserStatusParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripGetUserStatusParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetUserStatusParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetUserStatusParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetUserStatusParams;
        })();

        server.TripGetUserStatusResult = (function() {

            /**
             * Properties of a TripGetUserStatusResult.
             * @memberof taxi.server
             * @interface ITripGetUserStatusResult
             * @property {taxi.IStringValue|null} [needReviewTripId] TripGetUserStatusResult needReviewTripId
             */

            /**
             * Constructs a new TripGetUserStatusResult.
             * @memberof taxi.server
             * @classdesc Represents a TripGetUserStatusResult.
             * @implements ITripGetUserStatusResult
             * @constructor
             * @param {taxi.server.ITripGetUserStatusResult=} [p] Properties to set
             */
            function TripGetUserStatusResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripGetUserStatusResult needReviewTripId.
             * @member {taxi.IStringValue|null|undefined} needReviewTripId
             * @memberof taxi.server.TripGetUserStatusResult
             * @instance
             */
            TripGetUserStatusResult.prototype.needReviewTripId = null;

            /**
             * Creates a new TripGetUserStatusResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripGetUserStatusResult
             * @static
             * @param {taxi.server.ITripGetUserStatusResult=} [properties] Properties to set
             * @returns {taxi.server.TripGetUserStatusResult} TripGetUserStatusResult instance
             */
            TripGetUserStatusResult.create = function create(properties) {
                return new TripGetUserStatusResult(properties);
            };

            /**
             * Creates a TripGetUserStatusResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripGetUserStatusResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripGetUserStatusResult} TripGetUserStatusResult
             */
            TripGetUserStatusResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripGetUserStatusResult)
                    return d;
                var m = new $root.taxi.server.TripGetUserStatusResult();
                if (d.needReviewTripId != null) {
                    if (typeof d.needReviewTripId !== "object")
                        throw TypeError(".taxi.server.TripGetUserStatusResult.needReviewTripId: object expected");
                    m.needReviewTripId = $root.taxi.StringValue.fromObject(d.needReviewTripId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripGetUserStatusResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripGetUserStatusResult
             * @static
             * @param {taxi.server.TripGetUserStatusResult} m TripGetUserStatusResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripGetUserStatusResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.needReviewTripId = null;
                }
                if (m.needReviewTripId != null && m.hasOwnProperty("needReviewTripId")) {
                    d.needReviewTripId = $root.taxi.StringValue.toObject(m.needReviewTripId, o);
                }
                return d;
            };

            /**
             * Converts this TripGetUserStatusResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripGetUserStatusResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripGetUserStatusResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripGetUserStatusResult;
        })();

        server.TripListAirAvailableDatetimeParams = (function() {

            /**
             * Properties of a TripListAirAvailableDatetimeParams.
             * @memberof taxi.server
             * @interface ITripListAirAvailableDatetimeParams
             * @property {taxi.ITimestamp|null} [datetime] TripListAirAvailableDatetimeParams datetime
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripListAirAvailableDatetimeParams vehicleType
             * @property {taxi.TripAvailableDatetimeType|null} [availableDatetimeType] TripListAirAvailableDatetimeParams availableDatetimeType
             * @property {taxi.AirportCode|null} [airportCode] TripListAirAvailableDatetimeParams airportCode
             * @property {taxi.TripAirType|null} [airType] TripListAirAvailableDatetimeParams airType
             * @property {taxi.IStringValue|null} [estimationToken] TripListAirAvailableDatetimeParams estimationToken
             * @property {taxi.IStringValue|null} [tripId] TripListAirAvailableDatetimeParams tripId
             */

            /**
             * Constructs a new TripListAirAvailableDatetimeParams.
             * @memberof taxi.server
             * @classdesc Represents a TripListAirAvailableDatetimeParams.
             * @implements ITripListAirAvailableDatetimeParams
             * @constructor
             * @param {taxi.server.ITripListAirAvailableDatetimeParams=} [p] Properties to set
             */
            function TripListAirAvailableDatetimeParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListAirAvailableDatetimeParams datetime.
             * @member {taxi.ITimestamp|null|undefined} datetime
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @instance
             */
            TripListAirAvailableDatetimeParams.prototype.datetime = null;

            /**
             * TripListAirAvailableDatetimeParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @instance
             */
            TripListAirAvailableDatetimeParams.prototype.vehicleType = 0;

            /**
             * TripListAirAvailableDatetimeParams availableDatetimeType.
             * @member {taxi.TripAvailableDatetimeType} availableDatetimeType
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @instance
             */
            TripListAirAvailableDatetimeParams.prototype.availableDatetimeType = 0;

            /**
             * TripListAirAvailableDatetimeParams airportCode.
             * @member {taxi.AirportCode} airportCode
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @instance
             */
            TripListAirAvailableDatetimeParams.prototype.airportCode = 0;

            /**
             * TripListAirAvailableDatetimeParams airType.
             * @member {taxi.TripAirType} airType
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @instance
             */
            TripListAirAvailableDatetimeParams.prototype.airType = 0;

            /**
             * TripListAirAvailableDatetimeParams estimationToken.
             * @member {taxi.IStringValue|null|undefined} estimationToken
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @instance
             */
            TripListAirAvailableDatetimeParams.prototype.estimationToken = null;

            /**
             * TripListAirAvailableDatetimeParams tripId.
             * @member {taxi.IStringValue|null|undefined} tripId
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @instance
             */
            TripListAirAvailableDatetimeParams.prototype.tripId = null;

            /**
             * Creates a new TripListAirAvailableDatetimeParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @static
             * @param {taxi.server.ITripListAirAvailableDatetimeParams=} [properties] Properties to set
             * @returns {taxi.server.TripListAirAvailableDatetimeParams} TripListAirAvailableDatetimeParams instance
             */
            TripListAirAvailableDatetimeParams.create = function create(properties) {
                return new TripListAirAvailableDatetimeParams(properties);
            };

            /**
             * Creates a TripListAirAvailableDatetimeParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListAirAvailableDatetimeParams} TripListAirAvailableDatetimeParams
             */
            TripListAirAvailableDatetimeParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListAirAvailableDatetimeParams)
                    return d;
                var m = new $root.taxi.server.TripListAirAvailableDatetimeParams();
                if (d.datetime != null) {
                    if (typeof d.datetime !== "object")
                        throw TypeError(".taxi.server.TripListAirAvailableDatetimeParams.datetime: object expected");
                    m.datetime = $root.taxi.Timestamp.fromObject(d.datetime);
                }
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                switch (d.availableDatetimeType) {
                case "DAYS_IN_MONTH":
                case 0:
                    m.availableDatetimeType = 0;
                    break;
                case "HOURS_IN_DAY":
                case 1:
                    m.availableDatetimeType = 1;
                    break;
                case "TEN_MINUTES_IN_HOUR":
                case 2:
                    m.availableDatetimeType = 2;
                    break;
                case "ALL_DAYS":
                case 3:
                    m.availableDatetimeType = 3;
                    break;
                }
                switch (d.airportCode) {
                case "ICN_1":
                case 0:
                    m.airportCode = 0;
                    break;
                case "ICN_2":
                case 1:
                    m.airportCode = 1;
                    break;
                case "GMP_INTERNATIONAL":
                case 3:
                    m.airportCode = 3;
                    break;
                case "GMP_DOMESTIC":
                case 4:
                    m.airportCode = 4;
                    break;
                case "CJU_INTERNATIONAL":
                case 5:
                    m.airportCode = 5;
                    break;
                case "CJU_DOMESTIC":
                case 6:
                    m.airportCode = 6;
                    break;
                case "PUS_INTERNATIONAL":
                case 7:
                    m.airportCode = 7;
                    break;
                case "PUS_DOMESTIC":
                case 8:
                    m.airportCode = 8;
                    break;
                }
                switch (d.airType) {
                case "SENDING":
                case 0:
                    m.airType = 0;
                    break;
                case "PICK_UP":
                case 1:
                    m.airType = 1;
                    break;
                }
                if (d.estimationToken != null) {
                    if (typeof d.estimationToken !== "object")
                        throw TypeError(".taxi.server.TripListAirAvailableDatetimeParams.estimationToken: object expected");
                    m.estimationToken = $root.taxi.StringValue.fromObject(d.estimationToken);
                }
                if (d.tripId != null) {
                    if (typeof d.tripId !== "object")
                        throw TypeError(".taxi.server.TripListAirAvailableDatetimeParams.tripId: object expected");
                    m.tripId = $root.taxi.StringValue.fromObject(d.tripId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListAirAvailableDatetimeParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @static
             * @param {taxi.server.TripListAirAvailableDatetimeParams} m TripListAirAvailableDatetimeParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListAirAvailableDatetimeParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.datetime = null;
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                    d.availableDatetimeType = o.enums === String ? "DAYS_IN_MONTH" : 0;
                    d.airportCode = o.enums === String ? "ICN_1" : 0;
                    d.airType = o.enums === String ? "SENDING" : 0;
                    d.estimationToken = null;
                    d.tripId = null;
                }
                if (m.datetime != null && m.hasOwnProperty("datetime")) {
                    d.datetime = $root.taxi.Timestamp.toObject(m.datetime, o);
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                if (m.availableDatetimeType != null && m.hasOwnProperty("availableDatetimeType")) {
                    d.availableDatetimeType = o.enums === String ? $root.taxi.TripAvailableDatetimeType[m.availableDatetimeType] : m.availableDatetimeType;
                }
                if (m.airportCode != null && m.hasOwnProperty("airportCode")) {
                    d.airportCode = o.enums === String ? $root.taxi.AirportCode[m.airportCode] : m.airportCode;
                }
                if (m.airType != null && m.hasOwnProperty("airType")) {
                    d.airType = o.enums === String ? $root.taxi.TripAirType[m.airType] : m.airType;
                }
                if (m.estimationToken != null && m.hasOwnProperty("estimationToken")) {
                    d.estimationToken = $root.taxi.StringValue.toObject(m.estimationToken, o);
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = $root.taxi.StringValue.toObject(m.tripId, o);
                }
                return d;
            };

            /**
             * Converts this TripListAirAvailableDatetimeParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListAirAvailableDatetimeParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListAirAvailableDatetimeParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListAirAvailableDatetimeParams;
        })();

        server.TripListAirAvailableDatetimeResult = (function() {

            /**
             * Properties of a TripListAirAvailableDatetimeResult.
             * @memberof taxi.server
             * @interface ITripListAirAvailableDatetimeResult
             * @property {Array.<taxi.ITripAvailableDatetime>|null} [availableDatetimes] TripListAirAvailableDatetimeResult availableDatetimes
             * @property {string|null} [estimationToken] TripListAirAvailableDatetimeResult estimationToken
             */

            /**
             * Constructs a new TripListAirAvailableDatetimeResult.
             * @memberof taxi.server
             * @classdesc Represents a TripListAirAvailableDatetimeResult.
             * @implements ITripListAirAvailableDatetimeResult
             * @constructor
             * @param {taxi.server.ITripListAirAvailableDatetimeResult=} [p] Properties to set
             */
            function TripListAirAvailableDatetimeResult(p) {
                this.availableDatetimes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListAirAvailableDatetimeResult availableDatetimes.
             * @member {Array.<taxi.ITripAvailableDatetime>} availableDatetimes
             * @memberof taxi.server.TripListAirAvailableDatetimeResult
             * @instance
             */
            TripListAirAvailableDatetimeResult.prototype.availableDatetimes = $util.emptyArray;

            /**
             * TripListAirAvailableDatetimeResult estimationToken.
             * @member {string} estimationToken
             * @memberof taxi.server.TripListAirAvailableDatetimeResult
             * @instance
             */
            TripListAirAvailableDatetimeResult.prototype.estimationToken = "";

            /**
             * Creates a new TripListAirAvailableDatetimeResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListAirAvailableDatetimeResult
             * @static
             * @param {taxi.server.ITripListAirAvailableDatetimeResult=} [properties] Properties to set
             * @returns {taxi.server.TripListAirAvailableDatetimeResult} TripListAirAvailableDatetimeResult instance
             */
            TripListAirAvailableDatetimeResult.create = function create(properties) {
                return new TripListAirAvailableDatetimeResult(properties);
            };

            /**
             * Creates a TripListAirAvailableDatetimeResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListAirAvailableDatetimeResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListAirAvailableDatetimeResult} TripListAirAvailableDatetimeResult
             */
            TripListAirAvailableDatetimeResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListAirAvailableDatetimeResult)
                    return d;
                var m = new $root.taxi.server.TripListAirAvailableDatetimeResult();
                if (d.availableDatetimes) {
                    if (!Array.isArray(d.availableDatetimes))
                        throw TypeError(".taxi.server.TripListAirAvailableDatetimeResult.availableDatetimes: array expected");
                    m.availableDatetimes = [];
                    for (var i = 0; i < d.availableDatetimes.length; ++i) {
                        if (typeof d.availableDatetimes[i] !== "object")
                            throw TypeError(".taxi.server.TripListAirAvailableDatetimeResult.availableDatetimes: object expected");
                        m.availableDatetimes[i] = $root.taxi.TripAvailableDatetime.fromObject(d.availableDatetimes[i]);
                    }
                }
                if (d.estimationToken != null) {
                    m.estimationToken = String(d.estimationToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListAirAvailableDatetimeResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListAirAvailableDatetimeResult
             * @static
             * @param {taxi.server.TripListAirAvailableDatetimeResult} m TripListAirAvailableDatetimeResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListAirAvailableDatetimeResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.availableDatetimes = [];
                }
                if (o.defaults) {
                    d.estimationToken = "";
                }
                if (m.availableDatetimes && m.availableDatetimes.length) {
                    d.availableDatetimes = [];
                    for (var j = 0; j < m.availableDatetimes.length; ++j) {
                        d.availableDatetimes[j] = $root.taxi.TripAvailableDatetime.toObject(m.availableDatetimes[j], o);
                    }
                }
                if (m.estimationToken != null && m.hasOwnProperty("estimationToken")) {
                    d.estimationToken = m.estimationToken;
                }
                return d;
            };

            /**
             * Converts this TripListAirAvailableDatetimeResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListAirAvailableDatetimeResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListAirAvailableDatetimeResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListAirAvailableDatetimeResult;
        })();

        server.TripListAirDealsParams = (function() {

            /**
             * Properties of a TripListAirDealsParams.
             * @memberof taxi.server
             * @interface ITripListAirDealsParams
             * @property {taxi.TripAirType|null} [airType] TripListAirDealsParams airType
             */

            /**
             * Constructs a new TripListAirDealsParams.
             * @memberof taxi.server
             * @classdesc Represents a TripListAirDealsParams.
             * @implements ITripListAirDealsParams
             * @constructor
             * @param {taxi.server.ITripListAirDealsParams=} [p] Properties to set
             */
            function TripListAirDealsParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListAirDealsParams airType.
             * @member {taxi.TripAirType} airType
             * @memberof taxi.server.TripListAirDealsParams
             * @instance
             */
            TripListAirDealsParams.prototype.airType = 0;

            /**
             * Creates a new TripListAirDealsParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListAirDealsParams
             * @static
             * @param {taxi.server.ITripListAirDealsParams=} [properties] Properties to set
             * @returns {taxi.server.TripListAirDealsParams} TripListAirDealsParams instance
             */
            TripListAirDealsParams.create = function create(properties) {
                return new TripListAirDealsParams(properties);
            };

            /**
             * Creates a TripListAirDealsParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListAirDealsParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListAirDealsParams} TripListAirDealsParams
             */
            TripListAirDealsParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListAirDealsParams)
                    return d;
                var m = new $root.taxi.server.TripListAirDealsParams();
                switch (d.airType) {
                case "SENDING":
                case 0:
                    m.airType = 0;
                    break;
                case "PICK_UP":
                case 1:
                    m.airType = 1;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListAirDealsParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListAirDealsParams
             * @static
             * @param {taxi.server.TripListAirDealsParams} m TripListAirDealsParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListAirDealsParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.airType = o.enums === String ? "SENDING" : 0;
                }
                if (m.airType != null && m.hasOwnProperty("airType")) {
                    d.airType = o.enums === String ? $root.taxi.TripAirType[m.airType] : m.airType;
                }
                return d;
            };

            /**
             * Converts this TripListAirDealsParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListAirDealsParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListAirDealsParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListAirDealsParams;
        })();

        server.TripListAirDealsResult = (function() {

            /**
             * Properties of a TripListAirDealsResult.
             * @memberof taxi.server
             * @interface ITripListAirDealsResult
             * @property {Array.<taxi.server.TripListAirDealsResult.IAirDealLineItems>|null} [airDealLineItems] TripListAirDealsResult airDealLineItems
             */

            /**
             * Constructs a new TripListAirDealsResult.
             * @memberof taxi.server
             * @classdesc Represents a TripListAirDealsResult.
             * @implements ITripListAirDealsResult
             * @constructor
             * @param {taxi.server.ITripListAirDealsResult=} [p] Properties to set
             */
            function TripListAirDealsResult(p) {
                this.airDealLineItems = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListAirDealsResult airDealLineItems.
             * @member {Array.<taxi.server.TripListAirDealsResult.IAirDealLineItems>} airDealLineItems
             * @memberof taxi.server.TripListAirDealsResult
             * @instance
             */
            TripListAirDealsResult.prototype.airDealLineItems = $util.emptyArray;

            /**
             * Creates a new TripListAirDealsResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListAirDealsResult
             * @static
             * @param {taxi.server.ITripListAirDealsResult=} [properties] Properties to set
             * @returns {taxi.server.TripListAirDealsResult} TripListAirDealsResult instance
             */
            TripListAirDealsResult.create = function create(properties) {
                return new TripListAirDealsResult(properties);
            };

            /**
             * Creates a TripListAirDealsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListAirDealsResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListAirDealsResult} TripListAirDealsResult
             */
            TripListAirDealsResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListAirDealsResult)
                    return d;
                var m = new $root.taxi.server.TripListAirDealsResult();
                if (d.airDealLineItems) {
                    if (!Array.isArray(d.airDealLineItems))
                        throw TypeError(".taxi.server.TripListAirDealsResult.airDealLineItems: array expected");
                    m.airDealLineItems = [];
                    for (var i = 0; i < d.airDealLineItems.length; ++i) {
                        if (typeof d.airDealLineItems[i] !== "object")
                            throw TypeError(".taxi.server.TripListAirDealsResult.airDealLineItems: object expected");
                        m.airDealLineItems[i] = $root.taxi.server.TripListAirDealsResult.AirDealLineItems.fromObject(d.airDealLineItems[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListAirDealsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListAirDealsResult
             * @static
             * @param {taxi.server.TripListAirDealsResult} m TripListAirDealsResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListAirDealsResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.airDealLineItems = [];
                }
                if (m.airDealLineItems && m.airDealLineItems.length) {
                    d.airDealLineItems = [];
                    for (var j = 0; j < m.airDealLineItems.length; ++j) {
                        d.airDealLineItems[j] = $root.taxi.server.TripListAirDealsResult.AirDealLineItems.toObject(m.airDealLineItems[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this TripListAirDealsResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListAirDealsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListAirDealsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            TripListAirDealsResult.AirDealLineItems = (function() {

                /**
                 * Properties of an AirDealLineItems.
                 * @memberof taxi.server.TripListAirDealsResult
                 * @interface IAirDealLineItems
                 * @property {taxi.ITimestamp|null} [pickUpAt] AirDealLineItems pickUpAt
                 * @property {number|null} [maxDiscountPercentage] AirDealLineItems maxDiscountPercentage
                 * @property {number|null} [count] AirDealLineItems count
                 * @property {Array.<taxi.server.TripListAirDealsResult.AirDealLineItems.IAirDeal>|null} [airDeals] AirDealLineItems airDeals
                 */

                /**
                 * Constructs a new AirDealLineItems.
                 * @memberof taxi.server.TripListAirDealsResult
                 * @classdesc Represents an AirDealLineItems.
                 * @implements IAirDealLineItems
                 * @constructor
                 * @param {taxi.server.TripListAirDealsResult.IAirDealLineItems=} [p] Properties to set
                 */
                function AirDealLineItems(p) {
                    this.airDeals = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * AirDealLineItems pickUpAt.
                 * @member {taxi.ITimestamp|null|undefined} pickUpAt
                 * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                 * @instance
                 */
                AirDealLineItems.prototype.pickUpAt = null;

                /**
                 * AirDealLineItems maxDiscountPercentage.
                 * @member {number} maxDiscountPercentage
                 * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                 * @instance
                 */
                AirDealLineItems.prototype.maxDiscountPercentage = 0;

                /**
                 * AirDealLineItems count.
                 * @member {number} count
                 * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                 * @instance
                 */
                AirDealLineItems.prototype.count = 0;

                /**
                 * AirDealLineItems airDeals.
                 * @member {Array.<taxi.server.TripListAirDealsResult.AirDealLineItems.IAirDeal>} airDeals
                 * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                 * @instance
                 */
                AirDealLineItems.prototype.airDeals = $util.emptyArray;

                /**
                 * Creates a new AirDealLineItems instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                 * @static
                 * @param {taxi.server.TripListAirDealsResult.IAirDealLineItems=} [properties] Properties to set
                 * @returns {taxi.server.TripListAirDealsResult.AirDealLineItems} AirDealLineItems instance
                 */
                AirDealLineItems.create = function create(properties) {
                    return new AirDealLineItems(properties);
                };

                /**
                 * Creates an AirDealLineItems message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.TripListAirDealsResult.AirDealLineItems} AirDealLineItems
                 */
                AirDealLineItems.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.TripListAirDealsResult.AirDealLineItems)
                        return d;
                    var m = new $root.taxi.server.TripListAirDealsResult.AirDealLineItems();
                    if (d.pickUpAt != null) {
                        if (typeof d.pickUpAt !== "object")
                            throw TypeError(".taxi.server.TripListAirDealsResult.AirDealLineItems.pickUpAt: object expected");
                        m.pickUpAt = $root.taxi.Timestamp.fromObject(d.pickUpAt);
                    }
                    if (d.maxDiscountPercentage != null) {
                        m.maxDiscountPercentage = d.maxDiscountPercentage | 0;
                    }
                    if (d.count != null) {
                        m.count = d.count | 0;
                    }
                    if (d.airDeals) {
                        if (!Array.isArray(d.airDeals))
                            throw TypeError(".taxi.server.TripListAirDealsResult.AirDealLineItems.airDeals: array expected");
                        m.airDeals = [];
                        for (var i = 0; i < d.airDeals.length; ++i) {
                            if (typeof d.airDeals[i] !== "object")
                                throw TypeError(".taxi.server.TripListAirDealsResult.AirDealLineItems.airDeals: object expected");
                            m.airDeals[i] = $root.taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal.fromObject(d.airDeals[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an AirDealLineItems message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                 * @static
                 * @param {taxi.server.TripListAirDealsResult.AirDealLineItems} m AirDealLineItems
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AirDealLineItems.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.airDeals = [];
                    }
                    if (o.defaults) {
                        d.pickUpAt = null;
                        d.maxDiscountPercentage = 0;
                        d.count = 0;
                    }
                    if (m.pickUpAt != null && m.hasOwnProperty("pickUpAt")) {
                        d.pickUpAt = $root.taxi.Timestamp.toObject(m.pickUpAt, o);
                    }
                    if (m.maxDiscountPercentage != null && m.hasOwnProperty("maxDiscountPercentage")) {
                        d.maxDiscountPercentage = m.maxDiscountPercentage;
                    }
                    if (m.count != null && m.hasOwnProperty("count")) {
                        d.count = m.count;
                    }
                    if (m.airDeals && m.airDeals.length) {
                        d.airDeals = [];
                        for (var j = 0; j < m.airDeals.length; ++j) {
                            d.airDeals[j] = $root.taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal.toObject(m.airDeals[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this AirDealLineItems to JSON.
                 * @function toJSON
                 * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AirDealLineItems.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                AirDealLineItems.AirDeal = (function() {

                    /**
                     * Properties of an AirDeal.
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                     * @interface IAirDeal
                     * @property {taxi.IStringValue|null} [id] AirDeal id
                     * @property {taxi.AirportCode|null} [airportCode] AirDeal airportCode
                     * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] AirDeal vehicleType
                     * @property {number|null} [count] AirDeal count
                     * @property {number|null} [discountPercentage] AirDeal discountPercentage
                     */

                    /**
                     * Constructs a new AirDeal.
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems
                     * @classdesc Represents an AirDeal.
                     * @implements IAirDeal
                     * @constructor
                     * @param {taxi.server.TripListAirDealsResult.AirDealLineItems.IAirDeal=} [p] Properties to set
                     */
                    function AirDeal(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * AirDeal id.
                     * @member {taxi.IStringValue|null|undefined} id
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @instance
                     */
                    AirDeal.prototype.id = null;

                    /**
                     * AirDeal airportCode.
                     * @member {taxi.AirportCode} airportCode
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @instance
                     */
                    AirDeal.prototype.airportCode = 0;

                    /**
                     * AirDeal vehicleType.
                     * @member {taxi.TripVehicle.TripVehicleType} vehicleType
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @instance
                     */
                    AirDeal.prototype.vehicleType = 0;

                    /**
                     * AirDeal count.
                     * @member {number} count
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @instance
                     */
                    AirDeal.prototype.count = 0;

                    /**
                     * AirDeal discountPercentage.
                     * @member {number} discountPercentage
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @instance
                     */
                    AirDeal.prototype.discountPercentage = 0;

                    /**
                     * Creates a new AirDeal instance using the specified properties.
                     * @function create
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @static
                     * @param {taxi.server.TripListAirDealsResult.AirDealLineItems.IAirDeal=} [properties] Properties to set
                     * @returns {taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal} AirDeal instance
                     */
                    AirDeal.create = function create(properties) {
                        return new AirDeal(properties);
                    };

                    /**
                     * Creates an AirDeal message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal} AirDeal
                     */
                    AirDeal.fromObject = function fromObject(d) {
                        if (d instanceof $root.taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal)
                            return d;
                        var m = new $root.taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal();
                        if (d.id != null) {
                            if (typeof d.id !== "object")
                                throw TypeError(".taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal.id: object expected");
                            m.id = $root.taxi.StringValue.fromObject(d.id);
                        }
                        switch (d.airportCode) {
                        case "ICN_1":
                        case 0:
                            m.airportCode = 0;
                            break;
                        case "ICN_2":
                        case 1:
                            m.airportCode = 1;
                            break;
                        case "GMP_INTERNATIONAL":
                        case 3:
                            m.airportCode = 3;
                            break;
                        case "GMP_DOMESTIC":
                        case 4:
                            m.airportCode = 4;
                            break;
                        case "CJU_INTERNATIONAL":
                        case 5:
                            m.airportCode = 5;
                            break;
                        case "CJU_DOMESTIC":
                        case 6:
                            m.airportCode = 6;
                            break;
                        case "PUS_INTERNATIONAL":
                        case 7:
                            m.airportCode = 7;
                            break;
                        case "PUS_DOMESTIC":
                        case 8:
                            m.airportCode = 8;
                            break;
                        }
                        switch (d.vehicleType) {
                        case "CARNIVAL":
                        case 0:
                            m.vehicleType = 0;
                            break;
                        case "SPRINTER":
                        case 1:
                            m.vehicleType = 1;
                            break;
                        case "SOLATI":
                        case 2:
                            m.vehicleType = 2;
                            break;
                        case "SEDAN":
                        case 3:
                            m.vehicleType = 3;
                            break;
                        }
                        if (d.count != null) {
                            m.count = d.count | 0;
                        }
                        if (d.discountPercentage != null) {
                            m.discountPercentage = d.discountPercentage | 0;
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from an AirDeal message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @static
                     * @param {taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal} m AirDeal
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AirDeal.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.id = null;
                            d.airportCode = o.enums === String ? "ICN_1" : 0;
                            d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                            d.count = 0;
                            d.discountPercentage = 0;
                        }
                        if (m.id != null && m.hasOwnProperty("id")) {
                            d.id = $root.taxi.StringValue.toObject(m.id, o);
                        }
                        if (m.airportCode != null && m.hasOwnProperty("airportCode")) {
                            d.airportCode = o.enums === String ? $root.taxi.AirportCode[m.airportCode] : m.airportCode;
                        }
                        if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                            d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                        }
                        if (m.count != null && m.hasOwnProperty("count")) {
                            d.count = m.count;
                        }
                        if (m.discountPercentage != null && m.hasOwnProperty("discountPercentage")) {
                            d.discountPercentage = m.discountPercentage;
                        }
                        return d;
                    };

                    /**
                     * Converts this AirDeal to JSON.
                     * @function toJSON
                     * @memberof taxi.server.TripListAirDealsResult.AirDealLineItems.AirDeal
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AirDeal.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AirDeal;
                })();

                return AirDealLineItems;
            })();

            return TripListAirDealsResult;
        })();

        server.TripListCharterAvailableDatetimeParams = (function() {

            /**
             * Properties of a TripListCharterAvailableDatetimeParams.
             * @memberof taxi.server
             * @interface ITripListCharterAvailableDatetimeParams
             * @property {taxi.ITimestamp|null} [datetime] TripListCharterAvailableDatetimeParams datetime
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripListCharterAvailableDatetimeParams vehicleType
             * @property {taxi.TripAvailableDatetimeType|null} [availableDatetimeType] TripListCharterAvailableDatetimeParams availableDatetimeType
             * @property {taxi.IInt32Value|null} [minutes] TripListCharterAvailableDatetimeParams minutes
             * @property {taxi.ITripAddressedLocation|null} [origin] TripListCharterAvailableDatetimeParams origin
             */

            /**
             * Constructs a new TripListCharterAvailableDatetimeParams.
             * @memberof taxi.server
             * @classdesc Represents a TripListCharterAvailableDatetimeParams.
             * @implements ITripListCharterAvailableDatetimeParams
             * @constructor
             * @param {taxi.server.ITripListCharterAvailableDatetimeParams=} [p] Properties to set
             */
            function TripListCharterAvailableDatetimeParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListCharterAvailableDatetimeParams datetime.
             * @member {taxi.ITimestamp|null|undefined} datetime
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @instance
             */
            TripListCharterAvailableDatetimeParams.prototype.datetime = null;

            /**
             * TripListCharterAvailableDatetimeParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @instance
             */
            TripListCharterAvailableDatetimeParams.prototype.vehicleType = 0;

            /**
             * TripListCharterAvailableDatetimeParams availableDatetimeType.
             * @member {taxi.TripAvailableDatetimeType} availableDatetimeType
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @instance
             */
            TripListCharterAvailableDatetimeParams.prototype.availableDatetimeType = 0;

            /**
             * TripListCharterAvailableDatetimeParams minutes.
             * @member {taxi.IInt32Value|null|undefined} minutes
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @instance
             */
            TripListCharterAvailableDatetimeParams.prototype.minutes = null;

            /**
             * TripListCharterAvailableDatetimeParams origin.
             * @member {taxi.ITripAddressedLocation|null|undefined} origin
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @instance
             */
            TripListCharterAvailableDatetimeParams.prototype.origin = null;

            /**
             * Creates a new TripListCharterAvailableDatetimeParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @static
             * @param {taxi.server.ITripListCharterAvailableDatetimeParams=} [properties] Properties to set
             * @returns {taxi.server.TripListCharterAvailableDatetimeParams} TripListCharterAvailableDatetimeParams instance
             */
            TripListCharterAvailableDatetimeParams.create = function create(properties) {
                return new TripListCharterAvailableDatetimeParams(properties);
            };

            /**
             * Creates a TripListCharterAvailableDatetimeParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListCharterAvailableDatetimeParams} TripListCharterAvailableDatetimeParams
             */
            TripListCharterAvailableDatetimeParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListCharterAvailableDatetimeParams)
                    return d;
                var m = new $root.taxi.server.TripListCharterAvailableDatetimeParams();
                if (d.datetime != null) {
                    if (typeof d.datetime !== "object")
                        throw TypeError(".taxi.server.TripListCharterAvailableDatetimeParams.datetime: object expected");
                    m.datetime = $root.taxi.Timestamp.fromObject(d.datetime);
                }
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                switch (d.availableDatetimeType) {
                case "DAYS_IN_MONTH":
                case 0:
                    m.availableDatetimeType = 0;
                    break;
                case "HOURS_IN_DAY":
                case 1:
                    m.availableDatetimeType = 1;
                    break;
                case "TEN_MINUTES_IN_HOUR":
                case 2:
                    m.availableDatetimeType = 2;
                    break;
                case "ALL_DAYS":
                case 3:
                    m.availableDatetimeType = 3;
                    break;
                }
                if (d.minutes != null) {
                    if (typeof d.minutes !== "object")
                        throw TypeError(".taxi.server.TripListCharterAvailableDatetimeParams.minutes: object expected");
                    m.minutes = $root.taxi.Int32Value.fromObject(d.minutes);
                }
                if (d.origin != null) {
                    if (typeof d.origin !== "object")
                        throw TypeError(".taxi.server.TripListCharterAvailableDatetimeParams.origin: object expected");
                    m.origin = $root.taxi.TripAddressedLocation.fromObject(d.origin);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListCharterAvailableDatetimeParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @static
             * @param {taxi.server.TripListCharterAvailableDatetimeParams} m TripListCharterAvailableDatetimeParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListCharterAvailableDatetimeParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.datetime = null;
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                    d.availableDatetimeType = o.enums === String ? "DAYS_IN_MONTH" : 0;
                    d.minutes = null;
                    d.origin = null;
                }
                if (m.datetime != null && m.hasOwnProperty("datetime")) {
                    d.datetime = $root.taxi.Timestamp.toObject(m.datetime, o);
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                if (m.availableDatetimeType != null && m.hasOwnProperty("availableDatetimeType")) {
                    d.availableDatetimeType = o.enums === String ? $root.taxi.TripAvailableDatetimeType[m.availableDatetimeType] : m.availableDatetimeType;
                }
                if (m.minutes != null && m.hasOwnProperty("minutes")) {
                    d.minutes = $root.taxi.Int32Value.toObject(m.minutes, o);
                }
                if (m.origin != null && m.hasOwnProperty("origin")) {
                    d.origin = $root.taxi.TripAddressedLocation.toObject(m.origin, o);
                }
                return d;
            };

            /**
             * Converts this TripListCharterAvailableDatetimeParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListCharterAvailableDatetimeParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListCharterAvailableDatetimeParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListCharterAvailableDatetimeParams;
        })();

        server.TripListCharterAvailableDatetimeResult = (function() {

            /**
             * Properties of a TripListCharterAvailableDatetimeResult.
             * @memberof taxi.server
             * @interface ITripListCharterAvailableDatetimeResult
             * @property {Array.<taxi.ITripAvailableDatetime>|null} [availableDatetimes] TripListCharterAvailableDatetimeResult availableDatetimes
             */

            /**
             * Constructs a new TripListCharterAvailableDatetimeResult.
             * @memberof taxi.server
             * @classdesc Represents a TripListCharterAvailableDatetimeResult.
             * @implements ITripListCharterAvailableDatetimeResult
             * @constructor
             * @param {taxi.server.ITripListCharterAvailableDatetimeResult=} [p] Properties to set
             */
            function TripListCharterAvailableDatetimeResult(p) {
                this.availableDatetimes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListCharterAvailableDatetimeResult availableDatetimes.
             * @member {Array.<taxi.ITripAvailableDatetime>} availableDatetimes
             * @memberof taxi.server.TripListCharterAvailableDatetimeResult
             * @instance
             */
            TripListCharterAvailableDatetimeResult.prototype.availableDatetimes = $util.emptyArray;

            /**
             * Creates a new TripListCharterAvailableDatetimeResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListCharterAvailableDatetimeResult
             * @static
             * @param {taxi.server.ITripListCharterAvailableDatetimeResult=} [properties] Properties to set
             * @returns {taxi.server.TripListCharterAvailableDatetimeResult} TripListCharterAvailableDatetimeResult instance
             */
            TripListCharterAvailableDatetimeResult.create = function create(properties) {
                return new TripListCharterAvailableDatetimeResult(properties);
            };

            /**
             * Creates a TripListCharterAvailableDatetimeResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListCharterAvailableDatetimeResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListCharterAvailableDatetimeResult} TripListCharterAvailableDatetimeResult
             */
            TripListCharterAvailableDatetimeResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListCharterAvailableDatetimeResult)
                    return d;
                var m = new $root.taxi.server.TripListCharterAvailableDatetimeResult();
                if (d.availableDatetimes) {
                    if (!Array.isArray(d.availableDatetimes))
                        throw TypeError(".taxi.server.TripListCharterAvailableDatetimeResult.availableDatetimes: array expected");
                    m.availableDatetimes = [];
                    for (var i = 0; i < d.availableDatetimes.length; ++i) {
                        if (typeof d.availableDatetimes[i] !== "object")
                            throw TypeError(".taxi.server.TripListCharterAvailableDatetimeResult.availableDatetimes: object expected");
                        m.availableDatetimes[i] = $root.taxi.TripAvailableDatetime.fromObject(d.availableDatetimes[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListCharterAvailableDatetimeResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListCharterAvailableDatetimeResult
             * @static
             * @param {taxi.server.TripListCharterAvailableDatetimeResult} m TripListCharterAvailableDatetimeResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListCharterAvailableDatetimeResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.availableDatetimes = [];
                }
                if (m.availableDatetimes && m.availableDatetimes.length) {
                    d.availableDatetimes = [];
                    for (var j = 0; j < m.availableDatetimes.length; ++j) {
                        d.availableDatetimes[j] = $root.taxi.TripAvailableDatetime.toObject(m.availableDatetimes[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this TripListCharterAvailableDatetimeResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListCharterAvailableDatetimeResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListCharterAvailableDatetimeResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListCharterAvailableDatetimeResult;
        })();

        server.TripListCharterMinutesParams = (function() {

            /**
             * Properties of a TripListCharterMinutesParams.
             * @memberof taxi.server
             * @interface ITripListCharterMinutesParams
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripListCharterMinutesParams vehicleType
             */

            /**
             * Constructs a new TripListCharterMinutesParams.
             * @memberof taxi.server
             * @classdesc Represents a TripListCharterMinutesParams.
             * @implements ITripListCharterMinutesParams
             * @constructor
             * @param {taxi.server.ITripListCharterMinutesParams=} [p] Properties to set
             */
            function TripListCharterMinutesParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListCharterMinutesParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripListCharterMinutesParams
             * @instance
             */
            TripListCharterMinutesParams.prototype.vehicleType = 0;

            /**
             * Creates a new TripListCharterMinutesParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListCharterMinutesParams
             * @static
             * @param {taxi.server.ITripListCharterMinutesParams=} [properties] Properties to set
             * @returns {taxi.server.TripListCharterMinutesParams} TripListCharterMinutesParams instance
             */
            TripListCharterMinutesParams.create = function create(properties) {
                return new TripListCharterMinutesParams(properties);
            };

            /**
             * Creates a TripListCharterMinutesParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListCharterMinutesParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListCharterMinutesParams} TripListCharterMinutesParams
             */
            TripListCharterMinutesParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListCharterMinutesParams)
                    return d;
                var m = new $root.taxi.server.TripListCharterMinutesParams();
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListCharterMinutesParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListCharterMinutesParams
             * @static
             * @param {taxi.server.TripListCharterMinutesParams} m TripListCharterMinutesParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListCharterMinutesParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                return d;
            };

            /**
             * Converts this TripListCharterMinutesParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListCharterMinutesParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListCharterMinutesParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListCharterMinutesParams;
        })();

        server.TripListCharterMinutesResult = (function() {

            /**
             * Properties of a TripListCharterMinutesResult.
             * @memberof taxi.server
             * @interface ITripListCharterMinutesResult
             * @property {Array.<number>|null} [minutes] TripListCharterMinutesResult minutes
             */

            /**
             * Constructs a new TripListCharterMinutesResult.
             * @memberof taxi.server
             * @classdesc Represents a TripListCharterMinutesResult.
             * @implements ITripListCharterMinutesResult
             * @constructor
             * @param {taxi.server.ITripListCharterMinutesResult=} [p] Properties to set
             */
            function TripListCharterMinutesResult(p) {
                this.minutes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListCharterMinutesResult minutes.
             * @member {Array.<number>} minutes
             * @memberof taxi.server.TripListCharterMinutesResult
             * @instance
             */
            TripListCharterMinutesResult.prototype.minutes = $util.emptyArray;

            /**
             * Creates a new TripListCharterMinutesResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListCharterMinutesResult
             * @static
             * @param {taxi.server.ITripListCharterMinutesResult=} [properties] Properties to set
             * @returns {taxi.server.TripListCharterMinutesResult} TripListCharterMinutesResult instance
             */
            TripListCharterMinutesResult.create = function create(properties) {
                return new TripListCharterMinutesResult(properties);
            };

            /**
             * Creates a TripListCharterMinutesResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListCharterMinutesResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListCharterMinutesResult} TripListCharterMinutesResult
             */
            TripListCharterMinutesResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListCharterMinutesResult)
                    return d;
                var m = new $root.taxi.server.TripListCharterMinutesResult();
                if (d.minutes) {
                    if (!Array.isArray(d.minutes))
                        throw TypeError(".taxi.server.TripListCharterMinutesResult.minutes: array expected");
                    m.minutes = [];
                    for (var i = 0; i < d.minutes.length; ++i) {
                        m.minutes[i] = d.minutes[i] | 0;
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListCharterMinutesResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListCharterMinutesResult
             * @static
             * @param {taxi.server.TripListCharterMinutesResult} m TripListCharterMinutesResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListCharterMinutesResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.minutes = [];
                }
                if (m.minutes && m.minutes.length) {
                    d.minutes = [];
                    for (var j = 0; j < m.minutes.length; ++j) {
                        d.minutes[j] = m.minutes[j];
                    }
                }
                return d;
            };

            /**
             * Converts this TripListCharterMinutesResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListCharterMinutesResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListCharterMinutesResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListCharterMinutesResult;
        })();

        server.TripListProductsParams = (function() {

            /**
             * Properties of a TripListProductsParams.
             * @memberof taxi.server
             * @interface ITripListProductsParams
             */

            /**
             * Constructs a new TripListProductsParams.
             * @memberof taxi.server
             * @classdesc Represents a TripListProductsParams.
             * @implements ITripListProductsParams
             * @constructor
             * @param {taxi.server.ITripListProductsParams=} [p] Properties to set
             */
            function TripListProductsParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripListProductsParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListProductsParams
             * @static
             * @param {taxi.server.ITripListProductsParams=} [properties] Properties to set
             * @returns {taxi.server.TripListProductsParams} TripListProductsParams instance
             */
            TripListProductsParams.create = function create(properties) {
                return new TripListProductsParams(properties);
            };

            /**
             * Creates a TripListProductsParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListProductsParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListProductsParams} TripListProductsParams
             */
            TripListProductsParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListProductsParams)
                    return d;
                return new $root.taxi.server.TripListProductsParams();
            };

            /**
             * Creates a plain object from a TripListProductsParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListProductsParams
             * @static
             * @param {taxi.server.TripListProductsParams} m TripListProductsParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListProductsParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripListProductsParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListProductsParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListProductsParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListProductsParams;
        })();

        server.TripListProductsResult = (function() {

            /**
             * Properties of a TripListProductsResult.
             * @memberof taxi.server
             * @interface ITripListProductsResult
             * @property {Array.<taxi.ITripProduct>|null} [air] TripListProductsResult air
             * @property {Array.<taxi.ITripProduct>|null} [charter] TripListProductsResult charter
             */

            /**
             * Constructs a new TripListProductsResult.
             * @memberof taxi.server
             * @classdesc Represents a TripListProductsResult.
             * @implements ITripListProductsResult
             * @constructor
             * @param {taxi.server.ITripListProductsResult=} [p] Properties to set
             */
            function TripListProductsResult(p) {
                this.air = [];
                this.charter = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListProductsResult air.
             * @member {Array.<taxi.ITripProduct>} air
             * @memberof taxi.server.TripListProductsResult
             * @instance
             */
            TripListProductsResult.prototype.air = $util.emptyArray;

            /**
             * TripListProductsResult charter.
             * @member {Array.<taxi.ITripProduct>} charter
             * @memberof taxi.server.TripListProductsResult
             * @instance
             */
            TripListProductsResult.prototype.charter = $util.emptyArray;

            /**
             * Creates a new TripListProductsResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListProductsResult
             * @static
             * @param {taxi.server.ITripListProductsResult=} [properties] Properties to set
             * @returns {taxi.server.TripListProductsResult} TripListProductsResult instance
             */
            TripListProductsResult.create = function create(properties) {
                return new TripListProductsResult(properties);
            };

            /**
             * Creates a TripListProductsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListProductsResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListProductsResult} TripListProductsResult
             */
            TripListProductsResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListProductsResult)
                    return d;
                var m = new $root.taxi.server.TripListProductsResult();
                if (d.air) {
                    if (!Array.isArray(d.air))
                        throw TypeError(".taxi.server.TripListProductsResult.air: array expected");
                    m.air = [];
                    for (var i = 0; i < d.air.length; ++i) {
                        if (typeof d.air[i] !== "object")
                            throw TypeError(".taxi.server.TripListProductsResult.air: object expected");
                        m.air[i] = $root.taxi.TripProduct.fromObject(d.air[i]);
                    }
                }
                if (d.charter) {
                    if (!Array.isArray(d.charter))
                        throw TypeError(".taxi.server.TripListProductsResult.charter: array expected");
                    m.charter = [];
                    for (var i = 0; i < d.charter.length; ++i) {
                        if (typeof d.charter[i] !== "object")
                            throw TypeError(".taxi.server.TripListProductsResult.charter: object expected");
                        m.charter[i] = $root.taxi.TripProduct.fromObject(d.charter[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListProductsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListProductsResult
             * @static
             * @param {taxi.server.TripListProductsResult} m TripListProductsResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListProductsResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.air = [];
                    d.charter = [];
                }
                if (m.air && m.air.length) {
                    d.air = [];
                    for (var j = 0; j < m.air.length; ++j) {
                        d.air[j] = $root.taxi.TripProduct.toObject(m.air[j], o);
                    }
                }
                if (m.charter && m.charter.length) {
                    d.charter = [];
                    for (var j = 0; j < m.charter.length; ++j) {
                        d.charter[j] = $root.taxi.TripProduct.toObject(m.charter[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this TripListProductsResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListProductsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListProductsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListProductsResult;
        })();

        server.TripListReservationsParams = (function() {

            /**
             * Properties of a TripListReservationsParams.
             * @memberof taxi.server
             * @interface ITripListReservationsParams
             * @property {taxi.IStringValue|null} [nextToken] TripListReservationsParams nextToken
             * @property {taxi.IInt32Value|null} [limit] TripListReservationsParams limit
             */

            /**
             * Constructs a new TripListReservationsParams.
             * @memberof taxi.server
             * @classdesc Represents a TripListReservationsParams.
             * @implements ITripListReservationsParams
             * @constructor
             * @param {taxi.server.ITripListReservationsParams=} [p] Properties to set
             */
            function TripListReservationsParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListReservationsParams nextToken.
             * @member {taxi.IStringValue|null|undefined} nextToken
             * @memberof taxi.server.TripListReservationsParams
             * @instance
             */
            TripListReservationsParams.prototype.nextToken = null;

            /**
             * TripListReservationsParams limit.
             * @member {taxi.IInt32Value|null|undefined} limit
             * @memberof taxi.server.TripListReservationsParams
             * @instance
             */
            TripListReservationsParams.prototype.limit = null;

            /**
             * Creates a new TripListReservationsParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListReservationsParams
             * @static
             * @param {taxi.server.ITripListReservationsParams=} [properties] Properties to set
             * @returns {taxi.server.TripListReservationsParams} TripListReservationsParams instance
             */
            TripListReservationsParams.create = function create(properties) {
                return new TripListReservationsParams(properties);
            };

            /**
             * Creates a TripListReservationsParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListReservationsParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListReservationsParams} TripListReservationsParams
             */
            TripListReservationsParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListReservationsParams)
                    return d;
                var m = new $root.taxi.server.TripListReservationsParams();
                if (d.nextToken != null) {
                    if (typeof d.nextToken !== "object")
                        throw TypeError(".taxi.server.TripListReservationsParams.nextToken: object expected");
                    m.nextToken = $root.taxi.StringValue.fromObject(d.nextToken);
                }
                if (d.limit != null) {
                    if (typeof d.limit !== "object")
                        throw TypeError(".taxi.server.TripListReservationsParams.limit: object expected");
                    m.limit = $root.taxi.Int32Value.fromObject(d.limit);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListReservationsParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListReservationsParams
             * @static
             * @param {taxi.server.TripListReservationsParams} m TripListReservationsParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListReservationsParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.nextToken = null;
                    d.limit = null;
                }
                if (m.nextToken != null && m.hasOwnProperty("nextToken")) {
                    d.nextToken = $root.taxi.StringValue.toObject(m.nextToken, o);
                }
                if (m.limit != null && m.hasOwnProperty("limit")) {
                    d.limit = $root.taxi.Int32Value.toObject(m.limit, o);
                }
                return d;
            };

            /**
             * Converts this TripListReservationsParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListReservationsParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListReservationsParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListReservationsParams;
        })();

        server.TripListReservationsResult = (function() {

            /**
             * Properties of a TripListReservationsResult.
             * @memberof taxi.server
             * @interface ITripListReservationsResult
             * @property {Array.<taxi.ITripReservation>|null} [reservations] TripListReservationsResult reservations
             * @property {taxi.IStringValue|null} [nextToken] TripListReservationsResult nextToken
             */

            /**
             * Constructs a new TripListReservationsResult.
             * @memberof taxi.server
             * @classdesc Represents a TripListReservationsResult.
             * @implements ITripListReservationsResult
             * @constructor
             * @param {taxi.server.ITripListReservationsResult=} [p] Properties to set
             */
            function TripListReservationsResult(p) {
                this.reservations = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripListReservationsResult reservations.
             * @member {Array.<taxi.ITripReservation>} reservations
             * @memberof taxi.server.TripListReservationsResult
             * @instance
             */
            TripListReservationsResult.prototype.reservations = $util.emptyArray;

            /**
             * TripListReservationsResult nextToken.
             * @member {taxi.IStringValue|null|undefined} nextToken
             * @memberof taxi.server.TripListReservationsResult
             * @instance
             */
            TripListReservationsResult.prototype.nextToken = null;

            /**
             * Creates a new TripListReservationsResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripListReservationsResult
             * @static
             * @param {taxi.server.ITripListReservationsResult=} [properties] Properties to set
             * @returns {taxi.server.TripListReservationsResult} TripListReservationsResult instance
             */
            TripListReservationsResult.create = function create(properties) {
                return new TripListReservationsResult(properties);
            };

            /**
             * Creates a TripListReservationsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripListReservationsResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripListReservationsResult} TripListReservationsResult
             */
            TripListReservationsResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripListReservationsResult)
                    return d;
                var m = new $root.taxi.server.TripListReservationsResult();
                if (d.reservations) {
                    if (!Array.isArray(d.reservations))
                        throw TypeError(".taxi.server.TripListReservationsResult.reservations: array expected");
                    m.reservations = [];
                    for (var i = 0; i < d.reservations.length; ++i) {
                        if (typeof d.reservations[i] !== "object")
                            throw TypeError(".taxi.server.TripListReservationsResult.reservations: object expected");
                        m.reservations[i] = $root.taxi.TripReservation.fromObject(d.reservations[i]);
                    }
                }
                if (d.nextToken != null) {
                    if (typeof d.nextToken !== "object")
                        throw TypeError(".taxi.server.TripListReservationsResult.nextToken: object expected");
                    m.nextToken = $root.taxi.StringValue.fromObject(d.nextToken);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripListReservationsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripListReservationsResult
             * @static
             * @param {taxi.server.TripListReservationsResult} m TripListReservationsResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripListReservationsResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.reservations = [];
                }
                if (o.defaults) {
                    d.nextToken = null;
                }
                if (m.reservations && m.reservations.length) {
                    d.reservations = [];
                    for (var j = 0; j < m.reservations.length; ++j) {
                        d.reservations[j] = $root.taxi.TripReservation.toObject(m.reservations[j], o);
                    }
                }
                if (m.nextToken != null && m.hasOwnProperty("nextToken")) {
                    d.nextToken = $root.taxi.StringValue.toObject(m.nextToken, o);
                }
                return d;
            };

            /**
             * Converts this TripListReservationsResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripListReservationsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripListReservationsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripListReservationsResult;
        })();

        server.TripPrepareEditTripParams = (function() {

            /**
             * Properties of a TripPrepareEditTripParams.
             * @memberof taxi.server
             * @interface ITripPrepareEditTripParams
             * @property {string|null} [tripId] TripPrepareEditTripParams tripId
             */

            /**
             * Constructs a new TripPrepareEditTripParams.
             * @memberof taxi.server
             * @classdesc Represents a TripPrepareEditTripParams.
             * @implements ITripPrepareEditTripParams
             * @constructor
             * @param {taxi.server.ITripPrepareEditTripParams=} [p] Properties to set
             */
            function TripPrepareEditTripParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripPrepareEditTripParams tripId.
             * @member {string} tripId
             * @memberof taxi.server.TripPrepareEditTripParams
             * @instance
             */
            TripPrepareEditTripParams.prototype.tripId = "";

            /**
             * Creates a new TripPrepareEditTripParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripPrepareEditTripParams
             * @static
             * @param {taxi.server.ITripPrepareEditTripParams=} [properties] Properties to set
             * @returns {taxi.server.TripPrepareEditTripParams} TripPrepareEditTripParams instance
             */
            TripPrepareEditTripParams.create = function create(properties) {
                return new TripPrepareEditTripParams(properties);
            };

            /**
             * Creates a TripPrepareEditTripParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripPrepareEditTripParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripPrepareEditTripParams} TripPrepareEditTripParams
             */
            TripPrepareEditTripParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripPrepareEditTripParams)
                    return d;
                var m = new $root.taxi.server.TripPrepareEditTripParams();
                if (d.tripId != null) {
                    m.tripId = String(d.tripId);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripPrepareEditTripParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripPrepareEditTripParams
             * @static
             * @param {taxi.server.TripPrepareEditTripParams} m TripPrepareEditTripParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripPrepareEditTripParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripId = "";
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = m.tripId;
                }
                return d;
            };

            /**
             * Converts this TripPrepareEditTripParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripPrepareEditTripParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripPrepareEditTripParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripPrepareEditTripParams;
        })();

        server.TripPrepareEditTripResult = (function() {

            /**
             * Properties of a TripPrepareEditTripResult.
             * @memberof taxi.server
             * @interface ITripPrepareEditTripResult
             * @property {taxi.server.ITripEditTripParams|null} [trip] TripPrepareEditTripResult trip
             * @property {taxi.IPaymentProfileDTO|null} [paymentProfile] TripPrepareEditTripResult paymentProfile
             * @property {taxi.IPaymentMethodDTO|null} [paymentMethod] TripPrepareEditTripResult paymentMethod
             * @property {number|null} [totalFee] TripPrepareEditTripResult totalFee
             */

            /**
             * Constructs a new TripPrepareEditTripResult.
             * @memberof taxi.server
             * @classdesc Represents a TripPrepareEditTripResult.
             * @implements ITripPrepareEditTripResult
             * @constructor
             * @param {taxi.server.ITripPrepareEditTripResult=} [p] Properties to set
             */
            function TripPrepareEditTripResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripPrepareEditTripResult trip.
             * @member {taxi.server.ITripEditTripParams|null|undefined} trip
             * @memberof taxi.server.TripPrepareEditTripResult
             * @instance
             */
            TripPrepareEditTripResult.prototype.trip = null;

            /**
             * TripPrepareEditTripResult paymentProfile.
             * @member {taxi.IPaymentProfileDTO|null|undefined} paymentProfile
             * @memberof taxi.server.TripPrepareEditTripResult
             * @instance
             */
            TripPrepareEditTripResult.prototype.paymentProfile = null;

            /**
             * TripPrepareEditTripResult paymentMethod.
             * @member {taxi.IPaymentMethodDTO|null|undefined} paymentMethod
             * @memberof taxi.server.TripPrepareEditTripResult
             * @instance
             */
            TripPrepareEditTripResult.prototype.paymentMethod = null;

            /**
             * TripPrepareEditTripResult totalFee.
             * @member {number} totalFee
             * @memberof taxi.server.TripPrepareEditTripResult
             * @instance
             */
            TripPrepareEditTripResult.prototype.totalFee = 0;

            /**
             * Creates a new TripPrepareEditTripResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripPrepareEditTripResult
             * @static
             * @param {taxi.server.ITripPrepareEditTripResult=} [properties] Properties to set
             * @returns {taxi.server.TripPrepareEditTripResult} TripPrepareEditTripResult instance
             */
            TripPrepareEditTripResult.create = function create(properties) {
                return new TripPrepareEditTripResult(properties);
            };

            /**
             * Creates a TripPrepareEditTripResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripPrepareEditTripResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripPrepareEditTripResult} TripPrepareEditTripResult
             */
            TripPrepareEditTripResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripPrepareEditTripResult)
                    return d;
                var m = new $root.taxi.server.TripPrepareEditTripResult();
                if (d.trip != null) {
                    if (typeof d.trip !== "object")
                        throw TypeError(".taxi.server.TripPrepareEditTripResult.trip: object expected");
                    m.trip = $root.taxi.server.TripEditTripParams.fromObject(d.trip);
                }
                if (d.paymentProfile != null) {
                    if (typeof d.paymentProfile !== "object")
                        throw TypeError(".taxi.server.TripPrepareEditTripResult.paymentProfile: object expected");
                    m.paymentProfile = $root.taxi.PaymentProfileDTO.fromObject(d.paymentProfile);
                }
                if (d.paymentMethod != null) {
                    if (typeof d.paymentMethod !== "object")
                        throw TypeError(".taxi.server.TripPrepareEditTripResult.paymentMethod: object expected");
                    m.paymentMethod = $root.taxi.PaymentMethodDTO.fromObject(d.paymentMethod);
                }
                if (d.totalFee != null) {
                    m.totalFee = d.totalFee | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a TripPrepareEditTripResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripPrepareEditTripResult
             * @static
             * @param {taxi.server.TripPrepareEditTripResult} m TripPrepareEditTripResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripPrepareEditTripResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.trip = null;
                    d.paymentProfile = null;
                    d.paymentMethod = null;
                    d.totalFee = 0;
                }
                if (m.trip != null && m.hasOwnProperty("trip")) {
                    d.trip = $root.taxi.server.TripEditTripParams.toObject(m.trip, o);
                }
                if (m.paymentProfile != null && m.hasOwnProperty("paymentProfile")) {
                    d.paymentProfile = $root.taxi.PaymentProfileDTO.toObject(m.paymentProfile, o);
                }
                if (m.paymentMethod != null && m.hasOwnProperty("paymentMethod")) {
                    d.paymentMethod = $root.taxi.PaymentMethodDTO.toObject(m.paymentMethod, o);
                }
                if (m.totalFee != null && m.hasOwnProperty("totalFee")) {
                    d.totalFee = m.totalFee;
                }
                return d;
            };

            /**
             * Converts this TripPrepareEditTripResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripPrepareEditTripResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripPrepareEditTripResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripPrepareEditTripResult;
        })();

        server.TripSearchPlacesParams = (function() {

            /**
             * Properties of a TripSearchPlacesParams.
             * @memberof taxi.server
             * @interface ITripSearchPlacesParams
             * @property {taxi.TripTypeDescription.TripType|null} [tripType] TripSearchPlacesParams tripType
             * @property {taxi.TripVehicle.TripVehicleType|null} [vehicleType] TripSearchPlacesParams vehicleType
             * @property {string|null} [keyword] TripSearchPlacesParams keyword
             * @property {boolean|null} [isOrigin] TripSearchPlacesParams isOrigin
             * @property {boolean|null} [isWaypoint] TripSearchPlacesParams isWaypoint
             * @property {taxi.IInt32Value|null} [basicMinute] TripSearchPlacesParams basicMinute
             * @property {taxi.AirportCode|null} [airportCode] TripSearchPlacesParams airportCode
             * @property {boolean|null} [isGolfClub] TripSearchPlacesParams isGolfClub
             * @property {taxi.IAddressedLocation|null} [origin] TripSearchPlacesParams origin
             * @property {taxi.IAddressedLocation|null} [destination] TripSearchPlacesParams destination
             */

            /**
             * Constructs a new TripSearchPlacesParams.
             * @memberof taxi.server
             * @classdesc Represents a TripSearchPlacesParams.
             * @implements ITripSearchPlacesParams
             * @constructor
             * @param {taxi.server.ITripSearchPlacesParams=} [p] Properties to set
             */
            function TripSearchPlacesParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripSearchPlacesParams tripType.
             * @member {taxi.TripTypeDescription.TripType} tripType
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.tripType = 0;

            /**
             * TripSearchPlacesParams vehicleType.
             * @member {taxi.TripVehicle.TripVehicleType} vehicleType
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.vehicleType = 0;

            /**
             * TripSearchPlacesParams keyword.
             * @member {string} keyword
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.keyword = "";

            /**
             * TripSearchPlacesParams isOrigin.
             * @member {boolean} isOrigin
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.isOrigin = false;

            /**
             * TripSearchPlacesParams isWaypoint.
             * @member {boolean} isWaypoint
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.isWaypoint = false;

            /**
             * TripSearchPlacesParams basicMinute.
             * @member {taxi.IInt32Value|null|undefined} basicMinute
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.basicMinute = null;

            /**
             * TripSearchPlacesParams airportCode.
             * @member {taxi.AirportCode} airportCode
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.airportCode = 0;

            /**
             * TripSearchPlacesParams isGolfClub.
             * @member {boolean} isGolfClub
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.isGolfClub = false;

            /**
             * TripSearchPlacesParams origin.
             * @member {taxi.IAddressedLocation|null|undefined} origin
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.origin = null;

            /**
             * TripSearchPlacesParams destination.
             * @member {taxi.IAddressedLocation|null|undefined} destination
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             */
            TripSearchPlacesParams.prototype.destination = null;

            /**
             * Creates a new TripSearchPlacesParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripSearchPlacesParams
             * @static
             * @param {taxi.server.ITripSearchPlacesParams=} [properties] Properties to set
             * @returns {taxi.server.TripSearchPlacesParams} TripSearchPlacesParams instance
             */
            TripSearchPlacesParams.create = function create(properties) {
                return new TripSearchPlacesParams(properties);
            };

            /**
             * Creates a TripSearchPlacesParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripSearchPlacesParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripSearchPlacesParams} TripSearchPlacesParams
             */
            TripSearchPlacesParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripSearchPlacesParams)
                    return d;
                var m = new $root.taxi.server.TripSearchPlacesParams();
                switch (d.tripType) {
                case "UNSPECIFIED":
                case 0:
                    m.tripType = 0;
                    break;
                case "AIR":
                case 1:
                    m.tripType = 1;
                    break;
                case "CHARTER":
                case 2:
                    m.tripType = 2;
                    break;
                case "VAN":
                case 3:
                    m.tripType = 3;
                    break;
                }
                switch (d.vehicleType) {
                case "CARNIVAL":
                case 0:
                    m.vehicleType = 0;
                    break;
                case "SPRINTER":
                case 1:
                    m.vehicleType = 1;
                    break;
                case "SOLATI":
                case 2:
                    m.vehicleType = 2;
                    break;
                case "SEDAN":
                case 3:
                    m.vehicleType = 3;
                    break;
                }
                if (d.keyword != null) {
                    m.keyword = String(d.keyword);
                }
                if (d.isOrigin != null) {
                    m.isOrigin = Boolean(d.isOrigin);
                }
                if (d.isWaypoint != null) {
                    m.isWaypoint = Boolean(d.isWaypoint);
                }
                if (d.basicMinute != null) {
                    if (typeof d.basicMinute !== "object")
                        throw TypeError(".taxi.server.TripSearchPlacesParams.basicMinute: object expected");
                    m.basicMinute = $root.taxi.Int32Value.fromObject(d.basicMinute);
                }
                switch (d.airportCode) {
                case "ICN_1":
                case 0:
                    m.airportCode = 0;
                    break;
                case "ICN_2":
                case 1:
                    m.airportCode = 1;
                    break;
                case "GMP_INTERNATIONAL":
                case 3:
                    m.airportCode = 3;
                    break;
                case "GMP_DOMESTIC":
                case 4:
                    m.airportCode = 4;
                    break;
                case "CJU_INTERNATIONAL":
                case 5:
                    m.airportCode = 5;
                    break;
                case "CJU_DOMESTIC":
                case 6:
                    m.airportCode = 6;
                    break;
                case "PUS_INTERNATIONAL":
                case 7:
                    m.airportCode = 7;
                    break;
                case "PUS_DOMESTIC":
                case 8:
                    m.airportCode = 8;
                    break;
                }
                if (d.isGolfClub != null) {
                    m.isGolfClub = Boolean(d.isGolfClub);
                }
                if (d.origin != null) {
                    if (typeof d.origin !== "object")
                        throw TypeError(".taxi.server.TripSearchPlacesParams.origin: object expected");
                    m.origin = $root.taxi.AddressedLocation.fromObject(d.origin);
                }
                if (d.destination != null) {
                    if (typeof d.destination !== "object")
                        throw TypeError(".taxi.server.TripSearchPlacesParams.destination: object expected");
                    m.destination = $root.taxi.AddressedLocation.fromObject(d.destination);
                }
                return m;
            };

            /**
             * Creates a plain object from a TripSearchPlacesParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripSearchPlacesParams
             * @static
             * @param {taxi.server.TripSearchPlacesParams} m TripSearchPlacesParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripSearchPlacesParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripType = o.enums === String ? "UNSPECIFIED" : 0;
                    d.vehicleType = o.enums === String ? "CARNIVAL" : 0;
                    d.keyword = "";
                    d.isOrigin = false;
                    d.isWaypoint = false;
                    d.basicMinute = null;
                    d.airportCode = o.enums === String ? "ICN_1" : 0;
                    d.isGolfClub = false;
                    d.origin = null;
                    d.destination = null;
                }
                if (m.tripType != null && m.hasOwnProperty("tripType")) {
                    d.tripType = o.enums === String ? $root.taxi.TripTypeDescription.TripType[m.tripType] : m.tripType;
                }
                if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                    d.vehicleType = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.vehicleType] : m.vehicleType;
                }
                if (m.keyword != null && m.hasOwnProperty("keyword")) {
                    d.keyword = m.keyword;
                }
                if (m.isOrigin != null && m.hasOwnProperty("isOrigin")) {
                    d.isOrigin = m.isOrigin;
                }
                if (m.isWaypoint != null && m.hasOwnProperty("isWaypoint")) {
                    d.isWaypoint = m.isWaypoint;
                }
                if (m.basicMinute != null && m.hasOwnProperty("basicMinute")) {
                    d.basicMinute = $root.taxi.Int32Value.toObject(m.basicMinute, o);
                }
                if (m.airportCode != null && m.hasOwnProperty("airportCode")) {
                    d.airportCode = o.enums === String ? $root.taxi.AirportCode[m.airportCode] : m.airportCode;
                }
                if (m.isGolfClub != null && m.hasOwnProperty("isGolfClub")) {
                    d.isGolfClub = m.isGolfClub;
                }
                if (m.origin != null && m.hasOwnProperty("origin")) {
                    d.origin = $root.taxi.AddressedLocation.toObject(m.origin, o);
                }
                if (m.destination != null && m.hasOwnProperty("destination")) {
                    d.destination = $root.taxi.AddressedLocation.toObject(m.destination, o);
                }
                return d;
            };

            /**
             * Converts this TripSearchPlacesParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripSearchPlacesParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripSearchPlacesParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripSearchPlacesParams;
        })();

        server.TripSearchPlacesResult = (function() {

            /**
             * Properties of a TripSearchPlacesResult.
             * @memberof taxi.server
             * @interface ITripSearchPlacesResult
             * @property {Array.<taxi.server.TripSearchPlacesResult.IPlace>|null} [places] TripSearchPlacesResult places
             */

            /**
             * Constructs a new TripSearchPlacesResult.
             * @memberof taxi.server
             * @classdesc Represents a TripSearchPlacesResult.
             * @implements ITripSearchPlacesResult
             * @constructor
             * @param {taxi.server.ITripSearchPlacesResult=} [p] Properties to set
             */
            function TripSearchPlacesResult(p) {
                this.places = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripSearchPlacesResult places.
             * @member {Array.<taxi.server.TripSearchPlacesResult.IPlace>} places
             * @memberof taxi.server.TripSearchPlacesResult
             * @instance
             */
            TripSearchPlacesResult.prototype.places = $util.emptyArray;

            /**
             * Creates a new TripSearchPlacesResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripSearchPlacesResult
             * @static
             * @param {taxi.server.ITripSearchPlacesResult=} [properties] Properties to set
             * @returns {taxi.server.TripSearchPlacesResult} TripSearchPlacesResult instance
             */
            TripSearchPlacesResult.create = function create(properties) {
                return new TripSearchPlacesResult(properties);
            };

            /**
             * Creates a TripSearchPlacesResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripSearchPlacesResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripSearchPlacesResult} TripSearchPlacesResult
             */
            TripSearchPlacesResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripSearchPlacesResult)
                    return d;
                var m = new $root.taxi.server.TripSearchPlacesResult();
                if (d.places) {
                    if (!Array.isArray(d.places))
                        throw TypeError(".taxi.server.TripSearchPlacesResult.places: array expected");
                    m.places = [];
                    for (var i = 0; i < d.places.length; ++i) {
                        if (typeof d.places[i] !== "object")
                            throw TypeError(".taxi.server.TripSearchPlacesResult.places: object expected");
                        m.places[i] = $root.taxi.server.TripSearchPlacesResult.Place.fromObject(d.places[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a TripSearchPlacesResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripSearchPlacesResult
             * @static
             * @param {taxi.server.TripSearchPlacesResult} m TripSearchPlacesResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripSearchPlacesResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.places = [];
                }
                if (m.places && m.places.length) {
                    d.places = [];
                    for (var j = 0; j < m.places.length; ++j) {
                        d.places[j] = $root.taxi.server.TripSearchPlacesResult.Place.toObject(m.places[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this TripSearchPlacesResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripSearchPlacesResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripSearchPlacesResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            TripSearchPlacesResult.Place = (function() {

                /**
                 * Properties of a Place.
                 * @memberof taxi.server.TripSearchPlacesResult
                 * @interface IPlace
                 * @property {taxi.IAddressedLocation|null} [addressedLocation] Place addressedLocation
                 * @property {boolean|null} [isAvailable] Place isAvailable
                 */

                /**
                 * Constructs a new Place.
                 * @memberof taxi.server.TripSearchPlacesResult
                 * @classdesc Represents a Place.
                 * @implements IPlace
                 * @constructor
                 * @param {taxi.server.TripSearchPlacesResult.IPlace=} [p] Properties to set
                 */
                function Place(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Place addressedLocation.
                 * @member {taxi.IAddressedLocation|null|undefined} addressedLocation
                 * @memberof taxi.server.TripSearchPlacesResult.Place
                 * @instance
                 */
                Place.prototype.addressedLocation = null;

                /**
                 * Place isAvailable.
                 * @member {boolean} isAvailable
                 * @memberof taxi.server.TripSearchPlacesResult.Place
                 * @instance
                 */
                Place.prototype.isAvailable = false;

                /**
                 * Creates a new Place instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.TripSearchPlacesResult.Place
                 * @static
                 * @param {taxi.server.TripSearchPlacesResult.IPlace=} [properties] Properties to set
                 * @returns {taxi.server.TripSearchPlacesResult.Place} Place instance
                 */
                Place.create = function create(properties) {
                    return new Place(properties);
                };

                /**
                 * Creates a Place message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.TripSearchPlacesResult.Place
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.TripSearchPlacesResult.Place} Place
                 */
                Place.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.TripSearchPlacesResult.Place)
                        return d;
                    var m = new $root.taxi.server.TripSearchPlacesResult.Place();
                    if (d.addressedLocation != null) {
                        if (typeof d.addressedLocation !== "object")
                            throw TypeError(".taxi.server.TripSearchPlacesResult.Place.addressedLocation: object expected");
                        m.addressedLocation = $root.taxi.AddressedLocation.fromObject(d.addressedLocation);
                    }
                    if (d.isAvailable != null) {
                        m.isAvailable = Boolean(d.isAvailable);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Place message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.TripSearchPlacesResult.Place
                 * @static
                 * @param {taxi.server.TripSearchPlacesResult.Place} m Place
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Place.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.addressedLocation = null;
                        d.isAvailable = false;
                    }
                    if (m.addressedLocation != null && m.hasOwnProperty("addressedLocation")) {
                        d.addressedLocation = $root.taxi.AddressedLocation.toObject(m.addressedLocation, o);
                    }
                    if (m.isAvailable != null && m.hasOwnProperty("isAvailable")) {
                        d.isAvailable = m.isAvailable;
                    }
                    return d;
                };

                /**
                 * Converts this Place to JSON.
                 * @function toJSON
                 * @memberof taxi.server.TripSearchPlacesResult.Place
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Place.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Place;
            })();

            return TripSearchPlacesResult;
        })();

        server.TripSendTripReceiptParams = (function() {

            /**
             * Properties of a TripSendTripReceiptParams.
             * @memberof taxi.server
             * @interface ITripSendTripReceiptParams
             * @property {string|null} [tripId] TripSendTripReceiptParams tripId
             * @property {taxi.server.TripSendTripReceiptParams.TripReceiptType|null} [receiptType] TripSendTripReceiptParams receiptType
             */

            /**
             * Constructs a new TripSendTripReceiptParams.
             * @memberof taxi.server
             * @classdesc Represents a TripSendTripReceiptParams.
             * @implements ITripSendTripReceiptParams
             * @constructor
             * @param {taxi.server.ITripSendTripReceiptParams=} [p] Properties to set
             */
            function TripSendTripReceiptParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripSendTripReceiptParams tripId.
             * @member {string} tripId
             * @memberof taxi.server.TripSendTripReceiptParams
             * @instance
             */
            TripSendTripReceiptParams.prototype.tripId = "";

            /**
             * TripSendTripReceiptParams receiptType.
             * @member {taxi.server.TripSendTripReceiptParams.TripReceiptType} receiptType
             * @memberof taxi.server.TripSendTripReceiptParams
             * @instance
             */
            TripSendTripReceiptParams.prototype.receiptType = 0;

            /**
             * Creates a new TripSendTripReceiptParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripSendTripReceiptParams
             * @static
             * @param {taxi.server.ITripSendTripReceiptParams=} [properties] Properties to set
             * @returns {taxi.server.TripSendTripReceiptParams} TripSendTripReceiptParams instance
             */
            TripSendTripReceiptParams.create = function create(properties) {
                return new TripSendTripReceiptParams(properties);
            };

            /**
             * Creates a TripSendTripReceiptParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripSendTripReceiptParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripSendTripReceiptParams} TripSendTripReceiptParams
             */
            TripSendTripReceiptParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripSendTripReceiptParams)
                    return d;
                var m = new $root.taxi.server.TripSendTripReceiptParams();
                if (d.tripId != null) {
                    m.tripId = String(d.tripId);
                }
                switch (d.receiptType) {
                case "RESERVATION_FEE":
                case 0:
                    m.receiptType = 0;
                    break;
                case "REFUND_FEE":
                case 1:
                    m.receiptType = 1;
                    break;
                case "ADDITIONAL_FEE":
                case 2:
                    m.receiptType = 2;
                    break;
                case "TIP":
                case 3:
                    m.receiptType = 3;
                    break;
                case "RECHARGE_FEE":
                case 4:
                    m.receiptType = 4;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a TripSendTripReceiptParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripSendTripReceiptParams
             * @static
             * @param {taxi.server.TripSendTripReceiptParams} m TripSendTripReceiptParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripSendTripReceiptParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.tripId = "";
                    d.receiptType = o.enums === String ? "RESERVATION_FEE" : 0;
                }
                if (m.tripId != null && m.hasOwnProperty("tripId")) {
                    d.tripId = m.tripId;
                }
                if (m.receiptType != null && m.hasOwnProperty("receiptType")) {
                    d.receiptType = o.enums === String ? $root.taxi.server.TripSendTripReceiptParams.TripReceiptType[m.receiptType] : m.receiptType;
                }
                return d;
            };

            /**
             * Converts this TripSendTripReceiptParams to JSON.
             * @function toJSON
             * @memberof taxi.server.TripSendTripReceiptParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripSendTripReceiptParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * TripReceiptType enum.
             * @name taxi.server.TripSendTripReceiptParams.TripReceiptType
             * @enum {string}
             * @property {number} RESERVATION_FEE=0 RESERVATION_FEE value
             * @property {number} REFUND_FEE=1 REFUND_FEE value
             * @property {number} ADDITIONAL_FEE=2 ADDITIONAL_FEE value
             * @property {number} TIP=3 TIP value
             * @property {number} RECHARGE_FEE=4 RECHARGE_FEE value
             */
            TripSendTripReceiptParams.TripReceiptType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "RESERVATION_FEE"] = 0;
                values[valuesById[1] = "REFUND_FEE"] = 1;
                values[valuesById[2] = "ADDITIONAL_FEE"] = 2;
                values[valuesById[3] = "TIP"] = 3;
                values[valuesById[4] = "RECHARGE_FEE"] = 4;
                return values;
            })();

            return TripSendTripReceiptParams;
        })();

        server.TripSendTripReceiptResult = (function() {

            /**
             * Properties of a TripSendTripReceiptResult.
             * @memberof taxi.server
             * @interface ITripSendTripReceiptResult
             */

            /**
             * Constructs a new TripSendTripReceiptResult.
             * @memberof taxi.server
             * @classdesc Represents a TripSendTripReceiptResult.
             * @implements ITripSendTripReceiptResult
             * @constructor
             * @param {taxi.server.ITripSendTripReceiptResult=} [p] Properties to set
             */
            function TripSendTripReceiptResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new TripSendTripReceiptResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.TripSendTripReceiptResult
             * @static
             * @param {taxi.server.ITripSendTripReceiptResult=} [properties] Properties to set
             * @returns {taxi.server.TripSendTripReceiptResult} TripSendTripReceiptResult instance
             */
            TripSendTripReceiptResult.create = function create(properties) {
                return new TripSendTripReceiptResult(properties);
            };

            /**
             * Creates a TripSendTripReceiptResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.TripSendTripReceiptResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.TripSendTripReceiptResult} TripSendTripReceiptResult
             */
            TripSendTripReceiptResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.TripSendTripReceiptResult)
                    return d;
                return new $root.taxi.server.TripSendTripReceiptResult();
            };

            /**
             * Creates a plain object from a TripSendTripReceiptResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.TripSendTripReceiptResult
             * @static
             * @param {taxi.server.TripSendTripReceiptResult} m TripSendTripReceiptResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripSendTripReceiptResult.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TripSendTripReceiptResult to JSON.
             * @function toJSON
             * @memberof taxi.server.TripSendTripReceiptResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripSendTripReceiptResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripSendTripReceiptResult;
        })();

        server.trip = (function() {

            /**
             * Namespace trip.
             * @memberof taxi.server
             * @namespace
             */
            const trip = {};

            trip.ClearBrowserHistoryParams = (function() {

                /**
                 * Properties of a ClearBrowserHistoryParams.
                 * @memberof taxi.server.trip
                 * @interface IClearBrowserHistoryParams
                 */

                /**
                 * Constructs a new ClearBrowserHistoryParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a ClearBrowserHistoryParams.
                 * @implements IClearBrowserHistoryParams
                 * @constructor
                 * @param {taxi.server.trip.IClearBrowserHistoryParams=} [p] Properties to set
                 */
                function ClearBrowserHistoryParams(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new ClearBrowserHistoryParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.ClearBrowserHistoryParams
                 * @static
                 * @param {taxi.server.trip.IClearBrowserHistoryParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.ClearBrowserHistoryParams} ClearBrowserHistoryParams instance
                 */
                ClearBrowserHistoryParams.create = function create(properties) {
                    return new ClearBrowserHistoryParams(properties);
                };

                /**
                 * Creates a ClearBrowserHistoryParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.ClearBrowserHistoryParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.ClearBrowserHistoryParams} ClearBrowserHistoryParams
                 */
                ClearBrowserHistoryParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.ClearBrowserHistoryParams)
                        return d;
                    return new $root.taxi.server.trip.ClearBrowserHistoryParams();
                };

                /**
                 * Creates a plain object from a ClearBrowserHistoryParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.ClearBrowserHistoryParams
                 * @static
                 * @param {taxi.server.trip.ClearBrowserHistoryParams} m ClearBrowserHistoryParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClearBrowserHistoryParams.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ClearBrowserHistoryParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.ClearBrowserHistoryParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClearBrowserHistoryParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ClearBrowserHistoryParams;
            })();

            trip.ClearBrowserHistoryResult = (function() {

                /**
                 * Properties of a ClearBrowserHistoryResult.
                 * @memberof taxi.server.trip
                 * @interface IClearBrowserHistoryResult
                 */

                /**
                 * Constructs a new ClearBrowserHistoryResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a ClearBrowserHistoryResult.
                 * @implements IClearBrowserHistoryResult
                 * @constructor
                 * @param {taxi.server.trip.IClearBrowserHistoryResult=} [p] Properties to set
                 */
                function ClearBrowserHistoryResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new ClearBrowserHistoryResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.ClearBrowserHistoryResult
                 * @static
                 * @param {taxi.server.trip.IClearBrowserHistoryResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.ClearBrowserHistoryResult} ClearBrowserHistoryResult instance
                 */
                ClearBrowserHistoryResult.create = function create(properties) {
                    return new ClearBrowserHistoryResult(properties);
                };

                /**
                 * Creates a ClearBrowserHistoryResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.ClearBrowserHistoryResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.ClearBrowserHistoryResult} ClearBrowserHistoryResult
                 */
                ClearBrowserHistoryResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.ClearBrowserHistoryResult)
                        return d;
                    return new $root.taxi.server.trip.ClearBrowserHistoryResult();
                };

                /**
                 * Creates a plain object from a ClearBrowserHistoryResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.ClearBrowserHistoryResult
                 * @static
                 * @param {taxi.server.trip.ClearBrowserHistoryResult} m ClearBrowserHistoryResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClearBrowserHistoryResult.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ClearBrowserHistoryResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.ClearBrowserHistoryResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClearBrowserHistoryResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ClearBrowserHistoryResult;
            })();

            trip.OpenCouponSelectorParams = (function() {

                /**
                 * Properties of an OpenCouponSelectorParams.
                 * @memberof taxi.server.trip
                 * @interface IOpenCouponSelectorParams
                 * @property {taxi.IStringValue|null} [id] OpenCouponSelectorParams id
                 * @property {taxi.server.IUserListCouponsParams|null} [params] OpenCouponSelectorParams params
                 */

                /**
                 * Constructs a new OpenCouponSelectorParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents an OpenCouponSelectorParams.
                 * @implements IOpenCouponSelectorParams
                 * @constructor
                 * @param {taxi.server.trip.IOpenCouponSelectorParams=} [p] Properties to set
                 */
                function OpenCouponSelectorParams(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * OpenCouponSelectorParams id.
                 * @member {taxi.IStringValue|null|undefined} id
                 * @memberof taxi.server.trip.OpenCouponSelectorParams
                 * @instance
                 */
                OpenCouponSelectorParams.prototype.id = null;

                /**
                 * OpenCouponSelectorParams params.
                 * @member {taxi.server.IUserListCouponsParams|null|undefined} params
                 * @memberof taxi.server.trip.OpenCouponSelectorParams
                 * @instance
                 */
                OpenCouponSelectorParams.prototype.params = null;

                /**
                 * Creates a new OpenCouponSelectorParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.OpenCouponSelectorParams
                 * @static
                 * @param {taxi.server.trip.IOpenCouponSelectorParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.OpenCouponSelectorParams} OpenCouponSelectorParams instance
                 */
                OpenCouponSelectorParams.create = function create(properties) {
                    return new OpenCouponSelectorParams(properties);
                };

                /**
                 * Creates an OpenCouponSelectorParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.OpenCouponSelectorParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.OpenCouponSelectorParams} OpenCouponSelectorParams
                 */
                OpenCouponSelectorParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.OpenCouponSelectorParams)
                        return d;
                    var m = new $root.taxi.server.trip.OpenCouponSelectorParams();
                    if (d.id != null) {
                        if (typeof d.id !== "object")
                            throw TypeError(".taxi.server.trip.OpenCouponSelectorParams.id: object expected");
                        m.id = $root.taxi.StringValue.fromObject(d.id);
                    }
                    if (d.params != null) {
                        if (typeof d.params !== "object")
                            throw TypeError(".taxi.server.trip.OpenCouponSelectorParams.params: object expected");
                        m.params = $root.taxi.server.UserListCouponsParams.fromObject(d.params);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an OpenCouponSelectorParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.OpenCouponSelectorParams
                 * @static
                 * @param {taxi.server.trip.OpenCouponSelectorParams} m OpenCouponSelectorParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenCouponSelectorParams.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.id = null;
                        d.params = null;
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = $root.taxi.StringValue.toObject(m.id, o);
                    }
                    if (m.params != null && m.hasOwnProperty("params")) {
                        d.params = $root.taxi.server.UserListCouponsParams.toObject(m.params, o);
                    }
                    return d;
                };

                /**
                 * Converts this OpenCouponSelectorParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.OpenCouponSelectorParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenCouponSelectorParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OpenCouponSelectorParams;
            })();

            trip.OpenCouponSelectorResult = (function() {

                /**
                 * Properties of an OpenCouponSelectorResult.
                 * @memberof taxi.server.trip
                 * @interface IOpenCouponSelectorResult
                 * @property {taxi.IStringValue|null} [couponId] OpenCouponSelectorResult couponId
                 */

                /**
                 * Constructs a new OpenCouponSelectorResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents an OpenCouponSelectorResult.
                 * @implements IOpenCouponSelectorResult
                 * @constructor
                 * @param {taxi.server.trip.IOpenCouponSelectorResult=} [p] Properties to set
                 */
                function OpenCouponSelectorResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * OpenCouponSelectorResult couponId.
                 * @member {taxi.IStringValue|null|undefined} couponId
                 * @memberof taxi.server.trip.OpenCouponSelectorResult
                 * @instance
                 */
                OpenCouponSelectorResult.prototype.couponId = null;

                /**
                 * Creates a new OpenCouponSelectorResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.OpenCouponSelectorResult
                 * @static
                 * @param {taxi.server.trip.IOpenCouponSelectorResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.OpenCouponSelectorResult} OpenCouponSelectorResult instance
                 */
                OpenCouponSelectorResult.create = function create(properties) {
                    return new OpenCouponSelectorResult(properties);
                };

                /**
                 * Creates an OpenCouponSelectorResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.OpenCouponSelectorResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.OpenCouponSelectorResult} OpenCouponSelectorResult
                 */
                OpenCouponSelectorResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.OpenCouponSelectorResult)
                        return d;
                    var m = new $root.taxi.server.trip.OpenCouponSelectorResult();
                    if (d.couponId != null) {
                        if (typeof d.couponId !== "object")
                            throw TypeError(".taxi.server.trip.OpenCouponSelectorResult.couponId: object expected");
                        m.couponId = $root.taxi.StringValue.fromObject(d.couponId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an OpenCouponSelectorResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.OpenCouponSelectorResult
                 * @static
                 * @param {taxi.server.trip.OpenCouponSelectorResult} m OpenCouponSelectorResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenCouponSelectorResult.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.couponId = null;
                    }
                    if (m.couponId != null && m.hasOwnProperty("couponId")) {
                        d.couponId = $root.taxi.StringValue.toObject(m.couponId, o);
                    }
                    return d;
                };

                /**
                 * Converts this OpenCouponSelectorResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.OpenCouponSelectorResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenCouponSelectorResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OpenCouponSelectorResult;
            })();

            trip.OpenShareDialogParams = (function() {

                /**
                 * Properties of an OpenShareDialogParams.
                 * @memberof taxi.server.trip
                 * @interface IOpenShareDialogParams
                 * @property {taxi.IStringValue|null} [dialogTitle] OpenShareDialogParams dialogTitle
                 * @property {taxi.IStringValue|null} [dialogDescription] OpenShareDialogParams dialogDescription
                 * @property {taxi.server.trip.OpenShareDialogParams.IKakaoTalkShareMessage|null} [kakaoTalkShareMessage] OpenShareDialogParams kakaoTalkShareMessage
                 * @property {taxi.IStringValue|null} [textToShare] OpenShareDialogParams textToShare
                 * @property {Array.<taxi.IBrazeEvent>|null} [brazeEventsOnShare] OpenShareDialogParams brazeEventsOnShare
                 */

                /**
                 * Constructs a new OpenShareDialogParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents an OpenShareDialogParams.
                 * @implements IOpenShareDialogParams
                 * @constructor
                 * @param {taxi.server.trip.IOpenShareDialogParams=} [p] Properties to set
                 */
                function OpenShareDialogParams(p) {
                    this.brazeEventsOnShare = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * OpenShareDialogParams dialogTitle.
                 * @member {taxi.IStringValue|null|undefined} dialogTitle
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @instance
                 */
                OpenShareDialogParams.prototype.dialogTitle = null;

                /**
                 * OpenShareDialogParams dialogDescription.
                 * @member {taxi.IStringValue|null|undefined} dialogDescription
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @instance
                 */
                OpenShareDialogParams.prototype.dialogDescription = null;

                /**
                 * OpenShareDialogParams kakaoTalkShareMessage.
                 * @member {taxi.server.trip.OpenShareDialogParams.IKakaoTalkShareMessage|null|undefined} kakaoTalkShareMessage
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @instance
                 */
                OpenShareDialogParams.prototype.kakaoTalkShareMessage = null;

                /**
                 * OpenShareDialogParams textToShare.
                 * @member {taxi.IStringValue|null|undefined} textToShare
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @instance
                 */
                OpenShareDialogParams.prototype.textToShare = null;

                /**
                 * OpenShareDialogParams brazeEventsOnShare.
                 * @member {Array.<taxi.IBrazeEvent>} brazeEventsOnShare
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @instance
                 */
                OpenShareDialogParams.prototype.brazeEventsOnShare = $util.emptyArray;

                /**
                 * Creates a new OpenShareDialogParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @static
                 * @param {taxi.server.trip.IOpenShareDialogParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.OpenShareDialogParams} OpenShareDialogParams instance
                 */
                OpenShareDialogParams.create = function create(properties) {
                    return new OpenShareDialogParams(properties);
                };

                /**
                 * Creates an OpenShareDialogParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.OpenShareDialogParams} OpenShareDialogParams
                 */
                OpenShareDialogParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.OpenShareDialogParams)
                        return d;
                    var m = new $root.taxi.server.trip.OpenShareDialogParams();
                    if (d.dialogTitle != null) {
                        if (typeof d.dialogTitle !== "object")
                            throw TypeError(".taxi.server.trip.OpenShareDialogParams.dialogTitle: object expected");
                        m.dialogTitle = $root.taxi.StringValue.fromObject(d.dialogTitle);
                    }
                    if (d.dialogDescription != null) {
                        if (typeof d.dialogDescription !== "object")
                            throw TypeError(".taxi.server.trip.OpenShareDialogParams.dialogDescription: object expected");
                        m.dialogDescription = $root.taxi.StringValue.fromObject(d.dialogDescription);
                    }
                    if (d.kakaoTalkShareMessage != null) {
                        if (typeof d.kakaoTalkShareMessage !== "object")
                            throw TypeError(".taxi.server.trip.OpenShareDialogParams.kakaoTalkShareMessage: object expected");
                        m.kakaoTalkShareMessage = $root.taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.fromObject(d.kakaoTalkShareMessage);
                    }
                    if (d.textToShare != null) {
                        if (typeof d.textToShare !== "object")
                            throw TypeError(".taxi.server.trip.OpenShareDialogParams.textToShare: object expected");
                        m.textToShare = $root.taxi.StringValue.fromObject(d.textToShare);
                    }
                    if (d.brazeEventsOnShare) {
                        if (!Array.isArray(d.brazeEventsOnShare))
                            throw TypeError(".taxi.server.trip.OpenShareDialogParams.brazeEventsOnShare: array expected");
                        m.brazeEventsOnShare = [];
                        for (var i = 0; i < d.brazeEventsOnShare.length; ++i) {
                            if (typeof d.brazeEventsOnShare[i] !== "object")
                                throw TypeError(".taxi.server.trip.OpenShareDialogParams.brazeEventsOnShare: object expected");
                            m.brazeEventsOnShare[i] = $root.taxi.BrazeEvent.fromObject(d.brazeEventsOnShare[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an OpenShareDialogParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @static
                 * @param {taxi.server.trip.OpenShareDialogParams} m OpenShareDialogParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenShareDialogParams.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.brazeEventsOnShare = [];
                    }
                    if (o.defaults) {
                        d.dialogTitle = null;
                        d.dialogDescription = null;
                        d.kakaoTalkShareMessage = null;
                        d.textToShare = null;
                    }
                    if (m.dialogTitle != null && m.hasOwnProperty("dialogTitle")) {
                        d.dialogTitle = $root.taxi.StringValue.toObject(m.dialogTitle, o);
                    }
                    if (m.dialogDescription != null && m.hasOwnProperty("dialogDescription")) {
                        d.dialogDescription = $root.taxi.StringValue.toObject(m.dialogDescription, o);
                    }
                    if (m.kakaoTalkShareMessage != null && m.hasOwnProperty("kakaoTalkShareMessage")) {
                        d.kakaoTalkShareMessage = $root.taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.toObject(m.kakaoTalkShareMessage, o);
                    }
                    if (m.textToShare != null && m.hasOwnProperty("textToShare")) {
                        d.textToShare = $root.taxi.StringValue.toObject(m.textToShare, o);
                    }
                    if (m.brazeEventsOnShare && m.brazeEventsOnShare.length) {
                        d.brazeEventsOnShare = [];
                        for (var j = 0; j < m.brazeEventsOnShare.length; ++j) {
                            d.brazeEventsOnShare[j] = $root.taxi.BrazeEvent.toObject(m.brazeEventsOnShare[j], o);
                        }
                    }
                    return d;
                };

                /**
                 * Converts this OpenShareDialogParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.OpenShareDialogParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenShareDialogParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                OpenShareDialogParams.KakaoTalkShareMessage = (function() {

                    /**
                     * Properties of a KakaoTalkShareMessage.
                     * @memberof taxi.server.trip.OpenShareDialogParams
                     * @interface IKakaoTalkShareMessage
                     * @property {taxi.IStringValue|null} [title] KakaoTalkShareMessage title
                     * @property {taxi.IStringValue|null} [imageUrl] KakaoTalkShareMessage imageUrl
                     * @property {taxi.IStringValue|null} [description] KakaoTalkShareMessage description
                     * @property {taxi.IStringValue|null} [link] KakaoTalkShareMessage link
                     * @property {Array.<taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.IButton>|null} [buttons] KakaoTalkShareMessage buttons
                     */

                    /**
                     * Constructs a new KakaoTalkShareMessage.
                     * @memberof taxi.server.trip.OpenShareDialogParams
                     * @classdesc Represents a KakaoTalkShareMessage.
                     * @implements IKakaoTalkShareMessage
                     * @constructor
                     * @param {taxi.server.trip.OpenShareDialogParams.IKakaoTalkShareMessage=} [p] Properties to set
                     */
                    function KakaoTalkShareMessage(p) {
                        this.buttons = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * KakaoTalkShareMessage title.
                     * @member {taxi.IStringValue|null|undefined} title
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @instance
                     */
                    KakaoTalkShareMessage.prototype.title = null;

                    /**
                     * KakaoTalkShareMessage imageUrl.
                     * @member {taxi.IStringValue|null|undefined} imageUrl
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @instance
                     */
                    KakaoTalkShareMessage.prototype.imageUrl = null;

                    /**
                     * KakaoTalkShareMessage description.
                     * @member {taxi.IStringValue|null|undefined} description
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @instance
                     */
                    KakaoTalkShareMessage.prototype.description = null;

                    /**
                     * KakaoTalkShareMessage link.
                     * @member {taxi.IStringValue|null|undefined} link
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @instance
                     */
                    KakaoTalkShareMessage.prototype.link = null;

                    /**
                     * KakaoTalkShareMessage buttons.
                     * @member {Array.<taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.IButton>} buttons
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @instance
                     */
                    KakaoTalkShareMessage.prototype.buttons = $util.emptyArray;

                    /**
                     * Creates a new KakaoTalkShareMessage instance using the specified properties.
                     * @function create
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @static
                     * @param {taxi.server.trip.OpenShareDialogParams.IKakaoTalkShareMessage=} [properties] Properties to set
                     * @returns {taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage} KakaoTalkShareMessage instance
                     */
                    KakaoTalkShareMessage.create = function create(properties) {
                        return new KakaoTalkShareMessage(properties);
                    };

                    /**
                     * Creates a KakaoTalkShareMessage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage} KakaoTalkShareMessage
                     */
                    KakaoTalkShareMessage.fromObject = function fromObject(d) {
                        if (d instanceof $root.taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage)
                            return d;
                        var m = new $root.taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage();
                        if (d.title != null) {
                            if (typeof d.title !== "object")
                                throw TypeError(".taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.title: object expected");
                            m.title = $root.taxi.StringValue.fromObject(d.title);
                        }
                        if (d.imageUrl != null) {
                            if (typeof d.imageUrl !== "object")
                                throw TypeError(".taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.imageUrl: object expected");
                            m.imageUrl = $root.taxi.StringValue.fromObject(d.imageUrl);
                        }
                        if (d.description != null) {
                            if (typeof d.description !== "object")
                                throw TypeError(".taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.description: object expected");
                            m.description = $root.taxi.StringValue.fromObject(d.description);
                        }
                        if (d.link != null) {
                            if (typeof d.link !== "object")
                                throw TypeError(".taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.link: object expected");
                            m.link = $root.taxi.StringValue.fromObject(d.link);
                        }
                        if (d.buttons) {
                            if (!Array.isArray(d.buttons))
                                throw TypeError(".taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.buttons: array expected");
                            m.buttons = [];
                            for (var i = 0; i < d.buttons.length; ++i) {
                                if (typeof d.buttons[i] !== "object")
                                    throw TypeError(".taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.buttons: object expected");
                                m.buttons[i] = $root.taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button.fromObject(d.buttons[i]);
                            }
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a KakaoTalkShareMessage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @static
                     * @param {taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage} m KakaoTalkShareMessage
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    KakaoTalkShareMessage.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.arrays || o.defaults) {
                            d.buttons = [];
                        }
                        if (o.defaults) {
                            d.title = null;
                            d.imageUrl = null;
                            d.description = null;
                            d.link = null;
                        }
                        if (m.title != null && m.hasOwnProperty("title")) {
                            d.title = $root.taxi.StringValue.toObject(m.title, o);
                        }
                        if (m.imageUrl != null && m.hasOwnProperty("imageUrl")) {
                            d.imageUrl = $root.taxi.StringValue.toObject(m.imageUrl, o);
                        }
                        if (m.description != null && m.hasOwnProperty("description")) {
                            d.description = $root.taxi.StringValue.toObject(m.description, o);
                        }
                        if (m.buttons && m.buttons.length) {
                            d.buttons = [];
                            for (var j = 0; j < m.buttons.length; ++j) {
                                d.buttons[j] = $root.taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button.toObject(m.buttons[j], o);
                            }
                        }
                        if (m.link != null && m.hasOwnProperty("link")) {
                            d.link = $root.taxi.StringValue.toObject(m.link, o);
                        }
                        return d;
                    };

                    /**
                     * Converts this KakaoTalkShareMessage to JSON.
                     * @function toJSON
                     * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    KakaoTalkShareMessage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    KakaoTalkShareMessage.Button = (function() {

                        /**
                         * Properties of a Button.
                         * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                         * @interface IButton
                         * @property {string|null} [title] Button title
                         * @property {string|null} [url] Button url
                         */

                        /**
                         * Constructs a new Button.
                         * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage
                         * @classdesc Represents a Button.
                         * @implements IButton
                         * @constructor
                         * @param {taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.IButton=} [p] Properties to set
                         */
                        function Button(p) {
                            if (p)
                                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                    if (p[ks[i]] != null)
                                        this[ks[i]] = p[ks[i]];
                        }

                        /**
                         * Button title.
                         * @member {string} title
                         * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button
                         * @instance
                         */
                        Button.prototype.title = "";

                        /**
                         * Button url.
                         * @member {string} url
                         * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button
                         * @instance
                         */
                        Button.prototype.url = "";

                        /**
                         * Creates a new Button instance using the specified properties.
                         * @function create
                         * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button
                         * @static
                         * @param {taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.IButton=} [properties] Properties to set
                         * @returns {taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button} Button instance
                         */
                        Button.create = function create(properties) {
                            return new Button(properties);
                        };

                        /**
                         * Creates a Button message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button
                         * @static
                         * @param {Object.<string,*>} d Plain object
                         * @returns {taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button} Button
                         */
                        Button.fromObject = function fromObject(d) {
                            if (d instanceof $root.taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button)
                                return d;
                            var m = new $root.taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button();
                            if (d.title != null) {
                                m.title = String(d.title);
                            }
                            if (d.url != null) {
                                m.url = String(d.url);
                            }
                            return m;
                        };

                        /**
                         * Creates a plain object from a Button message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button
                         * @static
                         * @param {taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button} m Button
                         * @param {$protobuf.IConversionOptions} [o] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Button.toObject = function toObject(m, o) {
                            if (!o)
                                o = {};
                            var d = {};
                            if (o.defaults) {
                                d.title = "";
                                d.url = "";
                            }
                            if (m.title != null && m.hasOwnProperty("title")) {
                                d.title = m.title;
                            }
                            if (m.url != null && m.hasOwnProperty("url")) {
                                d.url = m.url;
                            }
                            return d;
                        };

                        /**
                         * Converts this Button to JSON.
                         * @function toJSON
                         * @memberof taxi.server.trip.OpenShareDialogParams.KakaoTalkShareMessage.Button
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Button.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Button;
                    })();

                    return KakaoTalkShareMessage;
                })();

                return OpenShareDialogParams;
            })();

            trip.OpenShareDialogResult = (function() {

                /**
                 * Properties of an OpenShareDialogResult.
                 * @memberof taxi.server.trip
                 * @interface IOpenShareDialogResult
                 */

                /**
                 * Constructs a new OpenShareDialogResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents an OpenShareDialogResult.
                 * @implements IOpenShareDialogResult
                 * @constructor
                 * @param {taxi.server.trip.IOpenShareDialogResult=} [p] Properties to set
                 */
                function OpenShareDialogResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new OpenShareDialogResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.OpenShareDialogResult
                 * @static
                 * @param {taxi.server.trip.IOpenShareDialogResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.OpenShareDialogResult} OpenShareDialogResult instance
                 */
                OpenShareDialogResult.create = function create(properties) {
                    return new OpenShareDialogResult(properties);
                };

                /**
                 * Creates an OpenShareDialogResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.OpenShareDialogResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.OpenShareDialogResult} OpenShareDialogResult
                 */
                OpenShareDialogResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.OpenShareDialogResult)
                        return d;
                    return new $root.taxi.server.trip.OpenShareDialogResult();
                };

                /**
                 * Creates a plain object from an OpenShareDialogResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.OpenShareDialogResult
                 * @static
                 * @param {taxi.server.trip.OpenShareDialogResult} m OpenShareDialogResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenShareDialogResult.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this OpenShareDialogResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.OpenShareDialogResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenShareDialogResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OpenShareDialogResult;
            })();

            trip.ReturnCertificationTokenParams = (function() {

                /**
                 * Properties of a ReturnCertificationTokenParams.
                 * @memberof taxi.server.trip
                 * @interface IReturnCertificationTokenParams
                 * @property {string|null} [token] ReturnCertificationTokenParams token
                 */

                /**
                 * Constructs a new ReturnCertificationTokenParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a ReturnCertificationTokenParams.
                 * @implements IReturnCertificationTokenParams
                 * @constructor
                 * @param {taxi.server.trip.IReturnCertificationTokenParams=} [p] Properties to set
                 */
                function ReturnCertificationTokenParams(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ReturnCertificationTokenParams token.
                 * @member {string} token
                 * @memberof taxi.server.trip.ReturnCertificationTokenParams
                 * @instance
                 */
                ReturnCertificationTokenParams.prototype.token = "";

                /**
                 * Creates a new ReturnCertificationTokenParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.ReturnCertificationTokenParams
                 * @static
                 * @param {taxi.server.trip.IReturnCertificationTokenParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.ReturnCertificationTokenParams} ReturnCertificationTokenParams instance
                 */
                ReturnCertificationTokenParams.create = function create(properties) {
                    return new ReturnCertificationTokenParams(properties);
                };

                /**
                 * Creates a ReturnCertificationTokenParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.ReturnCertificationTokenParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.ReturnCertificationTokenParams} ReturnCertificationTokenParams
                 */
                ReturnCertificationTokenParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.ReturnCertificationTokenParams)
                        return d;
                    var m = new $root.taxi.server.trip.ReturnCertificationTokenParams();
                    if (d.token != null) {
                        m.token = String(d.token);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ReturnCertificationTokenParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.ReturnCertificationTokenParams
                 * @static
                 * @param {taxi.server.trip.ReturnCertificationTokenParams} m ReturnCertificationTokenParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReturnCertificationTokenParams.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.token = "";
                    }
                    if (m.token != null && m.hasOwnProperty("token")) {
                        d.token = m.token;
                    }
                    return d;
                };

                /**
                 * Converts this ReturnCertificationTokenParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.ReturnCertificationTokenParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReturnCertificationTokenParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReturnCertificationTokenParams;
            })();

            trip.ReturnCertificationTokenResult = (function() {

                /**
                 * Properties of a ReturnCertificationTokenResult.
                 * @memberof taxi.server.trip
                 * @interface IReturnCertificationTokenResult
                 */

                /**
                 * Constructs a new ReturnCertificationTokenResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a ReturnCertificationTokenResult.
                 * @implements IReturnCertificationTokenResult
                 * @constructor
                 * @param {taxi.server.trip.IReturnCertificationTokenResult=} [p] Properties to set
                 */
                function ReturnCertificationTokenResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new ReturnCertificationTokenResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.ReturnCertificationTokenResult
                 * @static
                 * @param {taxi.server.trip.IReturnCertificationTokenResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.ReturnCertificationTokenResult} ReturnCertificationTokenResult instance
                 */
                ReturnCertificationTokenResult.create = function create(properties) {
                    return new ReturnCertificationTokenResult(properties);
                };

                /**
                 * Creates a ReturnCertificationTokenResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.ReturnCertificationTokenResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.ReturnCertificationTokenResult} ReturnCertificationTokenResult
                 */
                ReturnCertificationTokenResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.ReturnCertificationTokenResult)
                        return d;
                    return new $root.taxi.server.trip.ReturnCertificationTokenResult();
                };

                /**
                 * Creates a plain object from a ReturnCertificationTokenResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.ReturnCertificationTokenResult
                 * @static
                 * @param {taxi.server.trip.ReturnCertificationTokenResult} m ReturnCertificationTokenResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReturnCertificationTokenResult.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ReturnCertificationTokenResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.ReturnCertificationTokenResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReturnCertificationTokenResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReturnCertificationTokenResult;
            })();

            trip.ReturnEximbayResponseParams = (function() {

                /**
                 * Properties of a ReturnEximbayResponseParams.
                 * @memberof taxi.server.trip
                 * @interface IReturnEximbayResponseParams
                 * @property {boolean|null} [isSuccess] ReturnEximbayResponseParams isSuccess
                 * @property {taxi.IStringValue|null} [issueTokenRequestId] ReturnEximbayResponseParams issueTokenRequestId
                 */

                /**
                 * Constructs a new ReturnEximbayResponseParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a ReturnEximbayResponseParams.
                 * @implements IReturnEximbayResponseParams
                 * @constructor
                 * @param {taxi.server.trip.IReturnEximbayResponseParams=} [p] Properties to set
                 */
                function ReturnEximbayResponseParams(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ReturnEximbayResponseParams isSuccess.
                 * @member {boolean} isSuccess
                 * @memberof taxi.server.trip.ReturnEximbayResponseParams
                 * @instance
                 */
                ReturnEximbayResponseParams.prototype.isSuccess = false;

                /**
                 * ReturnEximbayResponseParams issueTokenRequestId.
                 * @member {taxi.IStringValue|null|undefined} issueTokenRequestId
                 * @memberof taxi.server.trip.ReturnEximbayResponseParams
                 * @instance
                 */
                ReturnEximbayResponseParams.prototype.issueTokenRequestId = null;

                /**
                 * Creates a new ReturnEximbayResponseParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.ReturnEximbayResponseParams
                 * @static
                 * @param {taxi.server.trip.IReturnEximbayResponseParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.ReturnEximbayResponseParams} ReturnEximbayResponseParams instance
                 */
                ReturnEximbayResponseParams.create = function create(properties) {
                    return new ReturnEximbayResponseParams(properties);
                };

                /**
                 * Creates a ReturnEximbayResponseParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.ReturnEximbayResponseParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.ReturnEximbayResponseParams} ReturnEximbayResponseParams
                 */
                ReturnEximbayResponseParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.ReturnEximbayResponseParams)
                        return d;
                    var m = new $root.taxi.server.trip.ReturnEximbayResponseParams();
                    if (d.isSuccess != null) {
                        m.isSuccess = Boolean(d.isSuccess);
                    }
                    if (d.issueTokenRequestId != null) {
                        if (typeof d.issueTokenRequestId !== "object")
                            throw TypeError(".taxi.server.trip.ReturnEximbayResponseParams.issueTokenRequestId: object expected");
                        m.issueTokenRequestId = $root.taxi.StringValue.fromObject(d.issueTokenRequestId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ReturnEximbayResponseParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.ReturnEximbayResponseParams
                 * @static
                 * @param {taxi.server.trip.ReturnEximbayResponseParams} m ReturnEximbayResponseParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReturnEximbayResponseParams.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.isSuccess = false;
                        d.issueTokenRequestId = null;
                    }
                    if (m.isSuccess != null && m.hasOwnProperty("isSuccess")) {
                        d.isSuccess = m.isSuccess;
                    }
                    if (m.issueTokenRequestId != null && m.hasOwnProperty("issueTokenRequestId")) {
                        d.issueTokenRequestId = $root.taxi.StringValue.toObject(m.issueTokenRequestId, o);
                    }
                    return d;
                };

                /**
                 * Converts this ReturnEximbayResponseParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.ReturnEximbayResponseParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReturnEximbayResponseParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReturnEximbayResponseParams;
            })();

            trip.ReturnEximbayResponseResult = (function() {

                /**
                 * Properties of a ReturnEximbayResponseResult.
                 * @memberof taxi.server.trip
                 * @interface IReturnEximbayResponseResult
                 */

                /**
                 * Constructs a new ReturnEximbayResponseResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a ReturnEximbayResponseResult.
                 * @implements IReturnEximbayResponseResult
                 * @constructor
                 * @param {taxi.server.trip.IReturnEximbayResponseResult=} [p] Properties to set
                 */
                function ReturnEximbayResponseResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new ReturnEximbayResponseResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.ReturnEximbayResponseResult
                 * @static
                 * @param {taxi.server.trip.IReturnEximbayResponseResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.ReturnEximbayResponseResult} ReturnEximbayResponseResult instance
                 */
                ReturnEximbayResponseResult.create = function create(properties) {
                    return new ReturnEximbayResponseResult(properties);
                };

                /**
                 * Creates a ReturnEximbayResponseResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.ReturnEximbayResponseResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.ReturnEximbayResponseResult} ReturnEximbayResponseResult
                 */
                ReturnEximbayResponseResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.ReturnEximbayResponseResult)
                        return d;
                    return new $root.taxi.server.trip.ReturnEximbayResponseResult();
                };

                /**
                 * Creates a plain object from a ReturnEximbayResponseResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.ReturnEximbayResponseResult
                 * @static
                 * @param {taxi.server.trip.ReturnEximbayResponseResult} m ReturnEximbayResponseResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReturnEximbayResponseResult.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ReturnEximbayResponseResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.ReturnEximbayResponseResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReturnEximbayResponseResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReturnEximbayResponseResult;
            })();

            trip.UserOpenCountryCodeSelectorParams = (function() {

                /**
                 * Properties of a UserOpenCountryCodeSelectorParams.
                 * @memberof taxi.server.trip
                 * @interface IUserOpenCountryCodeSelectorParams
                 */

                /**
                 * Constructs a new UserOpenCountryCodeSelectorParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a UserOpenCountryCodeSelectorParams.
                 * @implements IUserOpenCountryCodeSelectorParams
                 * @constructor
                 * @param {taxi.server.trip.IUserOpenCountryCodeSelectorParams=} [p] Properties to set
                 */
                function UserOpenCountryCodeSelectorParams(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new UserOpenCountryCodeSelectorParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorParams
                 * @static
                 * @param {taxi.server.trip.IUserOpenCountryCodeSelectorParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.UserOpenCountryCodeSelectorParams} UserOpenCountryCodeSelectorParams instance
                 */
                UserOpenCountryCodeSelectorParams.create = function create(properties) {
                    return new UserOpenCountryCodeSelectorParams(properties);
                };

                /**
                 * Creates a UserOpenCountryCodeSelectorParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.UserOpenCountryCodeSelectorParams} UserOpenCountryCodeSelectorParams
                 */
                UserOpenCountryCodeSelectorParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.UserOpenCountryCodeSelectorParams)
                        return d;
                    return new $root.taxi.server.trip.UserOpenCountryCodeSelectorParams();
                };

                /**
                 * Creates a plain object from a UserOpenCountryCodeSelectorParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorParams
                 * @static
                 * @param {taxi.server.trip.UserOpenCountryCodeSelectorParams} m UserOpenCountryCodeSelectorParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserOpenCountryCodeSelectorParams.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UserOpenCountryCodeSelectorParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserOpenCountryCodeSelectorParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserOpenCountryCodeSelectorParams;
            })();

            trip.UserOpenCountryCodeSelectorResult = (function() {

                /**
                 * Properties of a UserOpenCountryCodeSelectorResult.
                 * @memberof taxi.server.trip
                 * @interface IUserOpenCountryCodeSelectorResult
                 * @property {taxi.IStringValue|null} [countryCode] UserOpenCountryCodeSelectorResult countryCode
                 */

                /**
                 * Constructs a new UserOpenCountryCodeSelectorResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a UserOpenCountryCodeSelectorResult.
                 * @implements IUserOpenCountryCodeSelectorResult
                 * @constructor
                 * @param {taxi.server.trip.IUserOpenCountryCodeSelectorResult=} [p] Properties to set
                 */
                function UserOpenCountryCodeSelectorResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * UserOpenCountryCodeSelectorResult countryCode.
                 * @member {taxi.IStringValue|null|undefined} countryCode
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorResult
                 * @instance
                 */
                UserOpenCountryCodeSelectorResult.prototype.countryCode = null;

                /**
                 * Creates a new UserOpenCountryCodeSelectorResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorResult
                 * @static
                 * @param {taxi.server.trip.IUserOpenCountryCodeSelectorResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.UserOpenCountryCodeSelectorResult} UserOpenCountryCodeSelectorResult instance
                 */
                UserOpenCountryCodeSelectorResult.create = function create(properties) {
                    return new UserOpenCountryCodeSelectorResult(properties);
                };

                /**
                 * Creates a UserOpenCountryCodeSelectorResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.UserOpenCountryCodeSelectorResult} UserOpenCountryCodeSelectorResult
                 */
                UserOpenCountryCodeSelectorResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.UserOpenCountryCodeSelectorResult)
                        return d;
                    var m = new $root.taxi.server.trip.UserOpenCountryCodeSelectorResult();
                    if (d.countryCode != null) {
                        if (typeof d.countryCode !== "object")
                            throw TypeError(".taxi.server.trip.UserOpenCountryCodeSelectorResult.countryCode: object expected");
                        m.countryCode = $root.taxi.StringValue.fromObject(d.countryCode);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a UserOpenCountryCodeSelectorResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorResult
                 * @static
                 * @param {taxi.server.trip.UserOpenCountryCodeSelectorResult} m UserOpenCountryCodeSelectorResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserOpenCountryCodeSelectorResult.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.countryCode = null;
                    }
                    if (m.countryCode != null && m.hasOwnProperty("countryCode")) {
                        d.countryCode = $root.taxi.StringValue.toObject(m.countryCode, o);
                    }
                    return d;
                };

                /**
                 * Converts this UserOpenCountryCodeSelectorResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.UserOpenCountryCodeSelectorResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserOpenCountryCodeSelectorResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserOpenCountryCodeSelectorResult;
            })();

            trip.UserOpenPaymentOptionSelectorParams = (function() {

                /**
                 * Properties of a UserOpenPaymentOptionSelectorParams.
                 * @memberof taxi.server.trip
                 * @interface IUserOpenPaymentOptionSelectorParams
                 * @property {taxi.IStringValue|null} [paymentMethodId] UserOpenPaymentOptionSelectorParams paymentMethodId
                 * @property {taxi.IStringValue|null} [paymentProfileId] UserOpenPaymentOptionSelectorParams paymentProfileId
                 */

                /**
                 * Constructs a new UserOpenPaymentOptionSelectorParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a UserOpenPaymentOptionSelectorParams.
                 * @implements IUserOpenPaymentOptionSelectorParams
                 * @constructor
                 * @param {taxi.server.trip.IUserOpenPaymentOptionSelectorParams=} [p] Properties to set
                 */
                function UserOpenPaymentOptionSelectorParams(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * UserOpenPaymentOptionSelectorParams paymentMethodId.
                 * @member {taxi.IStringValue|null|undefined} paymentMethodId
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorParams
                 * @instance
                 */
                UserOpenPaymentOptionSelectorParams.prototype.paymentMethodId = null;

                /**
                 * UserOpenPaymentOptionSelectorParams paymentProfileId.
                 * @member {taxi.IStringValue|null|undefined} paymentProfileId
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorParams
                 * @instance
                 */
                UserOpenPaymentOptionSelectorParams.prototype.paymentProfileId = null;

                /**
                 * Creates a new UserOpenPaymentOptionSelectorParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorParams
                 * @static
                 * @param {taxi.server.trip.IUserOpenPaymentOptionSelectorParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.UserOpenPaymentOptionSelectorParams} UserOpenPaymentOptionSelectorParams instance
                 */
                UserOpenPaymentOptionSelectorParams.create = function create(properties) {
                    return new UserOpenPaymentOptionSelectorParams(properties);
                };

                /**
                 * Creates a UserOpenPaymentOptionSelectorParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.UserOpenPaymentOptionSelectorParams} UserOpenPaymentOptionSelectorParams
                 */
                UserOpenPaymentOptionSelectorParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.UserOpenPaymentOptionSelectorParams)
                        return d;
                    var m = new $root.taxi.server.trip.UserOpenPaymentOptionSelectorParams();
                    if (d.paymentMethodId != null) {
                        if (typeof d.paymentMethodId !== "object")
                            throw TypeError(".taxi.server.trip.UserOpenPaymentOptionSelectorParams.paymentMethodId: object expected");
                        m.paymentMethodId = $root.taxi.StringValue.fromObject(d.paymentMethodId);
                    }
                    if (d.paymentProfileId != null) {
                        if (typeof d.paymentProfileId !== "object")
                            throw TypeError(".taxi.server.trip.UserOpenPaymentOptionSelectorParams.paymentProfileId: object expected");
                        m.paymentProfileId = $root.taxi.StringValue.fromObject(d.paymentProfileId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a UserOpenPaymentOptionSelectorParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorParams
                 * @static
                 * @param {taxi.server.trip.UserOpenPaymentOptionSelectorParams} m UserOpenPaymentOptionSelectorParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserOpenPaymentOptionSelectorParams.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.paymentMethodId = null;
                        d.paymentProfileId = null;
                    }
                    if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                        d.paymentMethodId = $root.taxi.StringValue.toObject(m.paymentMethodId, o);
                    }
                    if (m.paymentProfileId != null && m.hasOwnProperty("paymentProfileId")) {
                        d.paymentProfileId = $root.taxi.StringValue.toObject(m.paymentProfileId, o);
                    }
                    return d;
                };

                /**
                 * Converts this UserOpenPaymentOptionSelectorParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserOpenPaymentOptionSelectorParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserOpenPaymentOptionSelectorParams;
            })();

            trip.UserOpenPaymentOptionSelectorResult = (function() {

                /**
                 * Properties of a UserOpenPaymentOptionSelectorResult.
                 * @memberof taxi.server.trip
                 * @interface IUserOpenPaymentOptionSelectorResult
                 * @property {taxi.server.trip.UserOpenPaymentOptionSelectorResult.IPaymentOption|null} [option] UserOpenPaymentOptionSelectorResult option
                 * @property {boolean|null} [hasBusinessProfile] UserOpenPaymentOptionSelectorResult hasBusinessProfile
                 */

                /**
                 * Constructs a new UserOpenPaymentOptionSelectorResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a UserOpenPaymentOptionSelectorResult.
                 * @implements IUserOpenPaymentOptionSelectorResult
                 * @constructor
                 * @param {taxi.server.trip.IUserOpenPaymentOptionSelectorResult=} [p] Properties to set
                 */
                function UserOpenPaymentOptionSelectorResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * UserOpenPaymentOptionSelectorResult option.
                 * @member {taxi.server.trip.UserOpenPaymentOptionSelectorResult.IPaymentOption|null|undefined} option
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult
                 * @instance
                 */
                UserOpenPaymentOptionSelectorResult.prototype.option = null;

                /**
                 * UserOpenPaymentOptionSelectorResult hasBusinessProfile.
                 * @member {boolean} hasBusinessProfile
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult
                 * @instance
                 */
                UserOpenPaymentOptionSelectorResult.prototype.hasBusinessProfile = false;

                /**
                 * Creates a new UserOpenPaymentOptionSelectorResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult
                 * @static
                 * @param {taxi.server.trip.IUserOpenPaymentOptionSelectorResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.UserOpenPaymentOptionSelectorResult} UserOpenPaymentOptionSelectorResult instance
                 */
                UserOpenPaymentOptionSelectorResult.create = function create(properties) {
                    return new UserOpenPaymentOptionSelectorResult(properties);
                };

                /**
                 * Creates a UserOpenPaymentOptionSelectorResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.UserOpenPaymentOptionSelectorResult} UserOpenPaymentOptionSelectorResult
                 */
                UserOpenPaymentOptionSelectorResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.UserOpenPaymentOptionSelectorResult)
                        return d;
                    var m = new $root.taxi.server.trip.UserOpenPaymentOptionSelectorResult();
                    if (d.option != null) {
                        if (typeof d.option !== "object")
                            throw TypeError(".taxi.server.trip.UserOpenPaymentOptionSelectorResult.option: object expected");
                        m.option = $root.taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption.fromObject(d.option);
                    }
                    if (d.hasBusinessProfile != null) {
                        m.hasBusinessProfile = Boolean(d.hasBusinessProfile);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a UserOpenPaymentOptionSelectorResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult
                 * @static
                 * @param {taxi.server.trip.UserOpenPaymentOptionSelectorResult} m UserOpenPaymentOptionSelectorResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserOpenPaymentOptionSelectorResult.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.option = null;
                        d.hasBusinessProfile = false;
                    }
                    if (m.option != null && m.hasOwnProperty("option")) {
                        d.option = $root.taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption.toObject(m.option, o);
                    }
                    if (m.hasBusinessProfile != null && m.hasOwnProperty("hasBusinessProfile")) {
                        d.hasBusinessProfile = m.hasBusinessProfile;
                    }
                    return d;
                };

                /**
                 * Converts this UserOpenPaymentOptionSelectorResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserOpenPaymentOptionSelectorResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                UserOpenPaymentOptionSelectorResult.PaymentOption = (function() {

                    /**
                     * Properties of a PaymentOption.
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult
                     * @interface IPaymentOption
                     * @property {string|null} [profileName] PaymentOption profileName
                     * @property {string|null} [methodName] PaymentOption methodName
                     * @property {string|null} [methodId] PaymentOption methodId
                     * @property {string|null} [profileId] PaymentOption profileId
                     * @property {taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption.ProfileType|null} [profileType] PaymentOption profileType
                     */

                    /**
                     * Constructs a new PaymentOption.
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult
                     * @classdesc Represents a PaymentOption.
                     * @implements IPaymentOption
                     * @constructor
                     * @param {taxi.server.trip.UserOpenPaymentOptionSelectorResult.IPaymentOption=} [p] Properties to set
                     */
                    function PaymentOption(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    /**
                     * PaymentOption profileName.
                     * @member {string} profileName
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @instance
                     */
                    PaymentOption.prototype.profileName = "";

                    /**
                     * PaymentOption methodName.
                     * @member {string} methodName
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @instance
                     */
                    PaymentOption.prototype.methodName = "";

                    /**
                     * PaymentOption methodId.
                     * @member {string} methodId
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @instance
                     */
                    PaymentOption.prototype.methodId = "";

                    /**
                     * PaymentOption profileId.
                     * @member {string} profileId
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @instance
                     */
                    PaymentOption.prototype.profileId = "";

                    /**
                     * PaymentOption profileType.
                     * @member {taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption.ProfileType} profileType
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @instance
                     */
                    PaymentOption.prototype.profileType = 0;

                    /**
                     * Creates a new PaymentOption instance using the specified properties.
                     * @function create
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @static
                     * @param {taxi.server.trip.UserOpenPaymentOptionSelectorResult.IPaymentOption=} [properties] Properties to set
                     * @returns {taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption} PaymentOption instance
                     */
                    PaymentOption.create = function create(properties) {
                        return new PaymentOption(properties);
                    };

                    /**
                     * Creates a PaymentOption message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @static
                     * @param {Object.<string,*>} d Plain object
                     * @returns {taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption} PaymentOption
                     */
                    PaymentOption.fromObject = function fromObject(d) {
                        if (d instanceof $root.taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption)
                            return d;
                        var m = new $root.taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption();
                        if (d.profileName != null) {
                            m.profileName = String(d.profileName);
                        }
                        if (d.methodName != null) {
                            m.methodName = String(d.methodName);
                        }
                        if (d.methodId != null) {
                            m.methodId = String(d.methodId);
                        }
                        if (d.profileId != null) {
                            m.profileId = String(d.profileId);
                        }
                        switch (d.profileType) {
                        case "PERSONAL":
                        case 0:
                            m.profileType = 0;
                            break;
                        case "BUSINESS":
                        case 1:
                            m.profileType = 1;
                            break;
                        }
                        return m;
                    };

                    /**
                     * Creates a plain object from a PaymentOption message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @static
                     * @param {taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption} m PaymentOption
                     * @param {$protobuf.IConversionOptions} [o] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PaymentOption.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.profileName = "";
                            d.methodName = "";
                            d.methodId = "";
                            d.profileId = "";
                            d.profileType = o.enums === String ? "PERSONAL" : 0;
                        }
                        if (m.profileName != null && m.hasOwnProperty("profileName")) {
                            d.profileName = m.profileName;
                        }
                        if (m.methodName != null && m.hasOwnProperty("methodName")) {
                            d.methodName = m.methodName;
                        }
                        if (m.methodId != null && m.hasOwnProperty("methodId")) {
                            d.methodId = m.methodId;
                        }
                        if (m.profileId != null && m.hasOwnProperty("profileId")) {
                            d.profileId = m.profileId;
                        }
                        if (m.profileType != null && m.hasOwnProperty("profileType")) {
                            d.profileType = o.enums === String ? $root.taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption.ProfileType[m.profileType] : m.profileType;
                        }
                        return d;
                    };

                    /**
                     * Converts this PaymentOption to JSON.
                     * @function toJSON
                     * @memberof taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PaymentOption.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * ProfileType enum.
                     * @name taxi.server.trip.UserOpenPaymentOptionSelectorResult.PaymentOption.ProfileType
                     * @enum {string}
                     * @property {number} PERSONAL=0 PERSONAL value
                     * @property {number} BUSINESS=1 BUSINESS value
                     */
                    PaymentOption.ProfileType = (function() {
                        const valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "PERSONAL"] = 0;
                        values[valuesById[1] = "BUSINESS"] = 1;
                        return values;
                    })();

                    return PaymentOption;
                })();

                return UserOpenPaymentOptionSelectorResult;
            })();

            trip.UserOpenPaymentProfileReverificationParams = (function() {

                /**
                 * Properties of a UserOpenPaymentProfileReverificationParams.
                 * @memberof taxi.server.trip
                 * @interface IUserOpenPaymentProfileReverificationParams
                 * @property {string|null} [paymentProfileId] UserOpenPaymentProfileReverificationParams paymentProfileId
                 */

                /**
                 * Constructs a new UserOpenPaymentProfileReverificationParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a UserOpenPaymentProfileReverificationParams.
                 * @implements IUserOpenPaymentProfileReverificationParams
                 * @constructor
                 * @param {taxi.server.trip.IUserOpenPaymentProfileReverificationParams=} [p] Properties to set
                 */
                function UserOpenPaymentProfileReverificationParams(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * UserOpenPaymentProfileReverificationParams paymentProfileId.
                 * @member {string} paymentProfileId
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationParams
                 * @instance
                 */
                UserOpenPaymentProfileReverificationParams.prototype.paymentProfileId = "";

                /**
                 * Creates a new UserOpenPaymentProfileReverificationParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationParams
                 * @static
                 * @param {taxi.server.trip.IUserOpenPaymentProfileReverificationParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.UserOpenPaymentProfileReverificationParams} UserOpenPaymentProfileReverificationParams instance
                 */
                UserOpenPaymentProfileReverificationParams.create = function create(properties) {
                    return new UserOpenPaymentProfileReverificationParams(properties);
                };

                /**
                 * Creates a UserOpenPaymentProfileReverificationParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.UserOpenPaymentProfileReverificationParams} UserOpenPaymentProfileReverificationParams
                 */
                UserOpenPaymentProfileReverificationParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.UserOpenPaymentProfileReverificationParams)
                        return d;
                    var m = new $root.taxi.server.trip.UserOpenPaymentProfileReverificationParams();
                    if (d.paymentProfileId != null) {
                        m.paymentProfileId = String(d.paymentProfileId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a UserOpenPaymentProfileReverificationParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationParams
                 * @static
                 * @param {taxi.server.trip.UserOpenPaymentProfileReverificationParams} m UserOpenPaymentProfileReverificationParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserOpenPaymentProfileReverificationParams.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.paymentProfileId = "";
                    }
                    if (m.paymentProfileId != null && m.hasOwnProperty("paymentProfileId")) {
                        d.paymentProfileId = m.paymentProfileId;
                    }
                    return d;
                };

                /**
                 * Converts this UserOpenPaymentProfileReverificationParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserOpenPaymentProfileReverificationParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserOpenPaymentProfileReverificationParams;
            })();

            trip.UserOpenPaymentProfileReverificationResult = (function() {

                /**
                 * Properties of a UserOpenPaymentProfileReverificationResult.
                 * @memberof taxi.server.trip
                 * @interface IUserOpenPaymentProfileReverificationResult
                 */

                /**
                 * Constructs a new UserOpenPaymentProfileReverificationResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a UserOpenPaymentProfileReverificationResult.
                 * @implements IUserOpenPaymentProfileReverificationResult
                 * @constructor
                 * @param {taxi.server.trip.IUserOpenPaymentProfileReverificationResult=} [p] Properties to set
                 */
                function UserOpenPaymentProfileReverificationResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Creates a new UserOpenPaymentProfileReverificationResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationResult
                 * @static
                 * @param {taxi.server.trip.IUserOpenPaymentProfileReverificationResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.UserOpenPaymentProfileReverificationResult} UserOpenPaymentProfileReverificationResult instance
                 */
                UserOpenPaymentProfileReverificationResult.create = function create(properties) {
                    return new UserOpenPaymentProfileReverificationResult(properties);
                };

                /**
                 * Creates a UserOpenPaymentProfileReverificationResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.UserOpenPaymentProfileReverificationResult} UserOpenPaymentProfileReverificationResult
                 */
                UserOpenPaymentProfileReverificationResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.UserOpenPaymentProfileReverificationResult)
                        return d;
                    return new $root.taxi.server.trip.UserOpenPaymentProfileReverificationResult();
                };

                /**
                 * Creates a plain object from a UserOpenPaymentProfileReverificationResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationResult
                 * @static
                 * @param {taxi.server.trip.UserOpenPaymentProfileReverificationResult} m UserOpenPaymentProfileReverificationResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserOpenPaymentProfileReverificationResult.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UserOpenPaymentProfileReverificationResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.UserOpenPaymentProfileReverificationResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserOpenPaymentProfileReverificationResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserOpenPaymentProfileReverificationResult;
            })();

            trip.UserOpenTripReviewParams = (function() {

                /**
                 * Properties of a UserOpenTripReviewParams.
                 * @memberof taxi.server.trip
                 * @interface IUserOpenTripReviewParams
                 * @property {string|null} [tripId] UserOpenTripReviewParams tripId
                 */

                /**
                 * Constructs a new UserOpenTripReviewParams.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a UserOpenTripReviewParams.
                 * @implements IUserOpenTripReviewParams
                 * @constructor
                 * @param {taxi.server.trip.IUserOpenTripReviewParams=} [p] Properties to set
                 */
                function UserOpenTripReviewParams(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * UserOpenTripReviewParams tripId.
                 * @member {string} tripId
                 * @memberof taxi.server.trip.UserOpenTripReviewParams
                 * @instance
                 */
                UserOpenTripReviewParams.prototype.tripId = "";

                /**
                 * Creates a new UserOpenTripReviewParams instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.UserOpenTripReviewParams
                 * @static
                 * @param {taxi.server.trip.IUserOpenTripReviewParams=} [properties] Properties to set
                 * @returns {taxi.server.trip.UserOpenTripReviewParams} UserOpenTripReviewParams instance
                 */
                UserOpenTripReviewParams.create = function create(properties) {
                    return new UserOpenTripReviewParams(properties);
                };

                /**
                 * Creates a UserOpenTripReviewParams message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.UserOpenTripReviewParams
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.UserOpenTripReviewParams} UserOpenTripReviewParams
                 */
                UserOpenTripReviewParams.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.UserOpenTripReviewParams)
                        return d;
                    var m = new $root.taxi.server.trip.UserOpenTripReviewParams();
                    if (d.tripId != null) {
                        m.tripId = String(d.tripId);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a UserOpenTripReviewParams message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.UserOpenTripReviewParams
                 * @static
                 * @param {taxi.server.trip.UserOpenTripReviewParams} m UserOpenTripReviewParams
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserOpenTripReviewParams.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.tripId = "";
                    }
                    if (m.tripId != null && m.hasOwnProperty("tripId")) {
                        d.tripId = m.tripId;
                    }
                    return d;
                };

                /**
                 * Converts this UserOpenTripReviewParams to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.UserOpenTripReviewParams
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserOpenTripReviewParams.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserOpenTripReviewParams;
            })();

            trip.UserOpenTripReviewResult = (function() {

                /**
                 * Properties of a UserOpenTripReviewResult.
                 * @memberof taxi.server.trip
                 * @interface IUserOpenTripReviewResult
                 * @property {taxi.IInt32Value|null} [rating] UserOpenTripReviewResult rating
                 */

                /**
                 * Constructs a new UserOpenTripReviewResult.
                 * @memberof taxi.server.trip
                 * @classdesc Represents a UserOpenTripReviewResult.
                 * @implements IUserOpenTripReviewResult
                 * @constructor
                 * @param {taxi.server.trip.IUserOpenTripReviewResult=} [p] Properties to set
                 */
                function UserOpenTripReviewResult(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * UserOpenTripReviewResult rating.
                 * @member {taxi.IInt32Value|null|undefined} rating
                 * @memberof taxi.server.trip.UserOpenTripReviewResult
                 * @instance
                 */
                UserOpenTripReviewResult.prototype.rating = null;

                /**
                 * Creates a new UserOpenTripReviewResult instance using the specified properties.
                 * @function create
                 * @memberof taxi.server.trip.UserOpenTripReviewResult
                 * @static
                 * @param {taxi.server.trip.IUserOpenTripReviewResult=} [properties] Properties to set
                 * @returns {taxi.server.trip.UserOpenTripReviewResult} UserOpenTripReviewResult instance
                 */
                UserOpenTripReviewResult.create = function create(properties) {
                    return new UserOpenTripReviewResult(properties);
                };

                /**
                 * Creates a UserOpenTripReviewResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof taxi.server.trip.UserOpenTripReviewResult
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {taxi.server.trip.UserOpenTripReviewResult} UserOpenTripReviewResult
                 */
                UserOpenTripReviewResult.fromObject = function fromObject(d) {
                    if (d instanceof $root.taxi.server.trip.UserOpenTripReviewResult)
                        return d;
                    var m = new $root.taxi.server.trip.UserOpenTripReviewResult();
                    if (d.rating != null) {
                        if (typeof d.rating !== "object")
                            throw TypeError(".taxi.server.trip.UserOpenTripReviewResult.rating: object expected");
                        m.rating = $root.taxi.Int32Value.fromObject(d.rating);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a UserOpenTripReviewResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof taxi.server.trip.UserOpenTripReviewResult
                 * @static
                 * @param {taxi.server.trip.UserOpenTripReviewResult} m UserOpenTripReviewResult
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserOpenTripReviewResult.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.rating = null;
                    }
                    if (m.rating != null && m.hasOwnProperty("rating")) {
                        d.rating = $root.taxi.Int32Value.toObject(m.rating, o);
                    }
                    return d;
                };

                /**
                 * Converts this UserOpenTripReviewResult to JSON.
                 * @function toJSON
                 * @memberof taxi.server.trip.UserOpenTripReviewResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserOpenTripReviewResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserOpenTripReviewResult;
            })();

            return trip;
        })();

        server.UserListCouponsParams = (function() {

            /**
             * Properties of a UserListCouponsParams.
             * @memberof taxi.server
             * @interface IUserListCouponsParams
             * @property {taxi.IStringValue|null} [rideType] UserListCouponsParams rideType
             * @property {taxi.IStringValue|null} [tripParameters] UserListCouponsParams tripParameters
             * @property {taxi.IAddressedLocation|null} [origin] UserListCouponsParams origin
             * @property {taxi.IAddressedLocation|null} [destination] UserListCouponsParams destination
             * @property {taxi.IStringValue|null} [paymentMethodId] UserListCouponsParams paymentMethodId
             * @property {taxi.IStringValue|null} [paymentProfileId] UserListCouponsParams paymentProfileId
             * @property {taxi.IInt32Value|null} [limit] UserListCouponsParams limit
             * @property {taxi.IStringValue|null} [nextToken] UserListCouponsParams nextToken
             * @property {boolean|null} [glimpse] UserListCouponsParams glimpse
             */

            /**
             * Constructs a new UserListCouponsParams.
             * @memberof taxi.server
             * @classdesc Represents a UserListCouponsParams.
             * @implements IUserListCouponsParams
             * @constructor
             * @param {taxi.server.IUserListCouponsParams=} [p] Properties to set
             */
            function UserListCouponsParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserListCouponsParams rideType.
             * @member {taxi.IStringValue|null|undefined} rideType
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.rideType = null;

            /**
             * UserListCouponsParams tripParameters.
             * @member {taxi.IStringValue|null|undefined} tripParameters
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.tripParameters = null;

            /**
             * UserListCouponsParams origin.
             * @member {taxi.IAddressedLocation|null|undefined} origin
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.origin = null;

            /**
             * UserListCouponsParams destination.
             * @member {taxi.IAddressedLocation|null|undefined} destination
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.destination = null;

            /**
             * UserListCouponsParams paymentMethodId.
             * @member {taxi.IStringValue|null|undefined} paymentMethodId
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.paymentMethodId = null;

            /**
             * UserListCouponsParams paymentProfileId.
             * @member {taxi.IStringValue|null|undefined} paymentProfileId
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.paymentProfileId = null;

            /**
             * UserListCouponsParams limit.
             * @member {taxi.IInt32Value|null|undefined} limit
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.limit = null;

            /**
             * UserListCouponsParams nextToken.
             * @member {taxi.IStringValue|null|undefined} nextToken
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.nextToken = null;

            /**
             * UserListCouponsParams glimpse.
             * @member {boolean} glimpse
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             */
            UserListCouponsParams.prototype.glimpse = false;

            /**
             * Creates a new UserListCouponsParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListCouponsParams
             * @static
             * @param {taxi.server.IUserListCouponsParams=} [properties] Properties to set
             * @returns {taxi.server.UserListCouponsParams} UserListCouponsParams instance
             */
            UserListCouponsParams.create = function create(properties) {
                return new UserListCouponsParams(properties);
            };

            /**
             * Creates a UserListCouponsParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListCouponsParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListCouponsParams} UserListCouponsParams
             */
            UserListCouponsParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListCouponsParams)
                    return d;
                var m = new $root.taxi.server.UserListCouponsParams();
                if (d.rideType != null) {
                    if (typeof d.rideType !== "object")
                        throw TypeError(".taxi.server.UserListCouponsParams.rideType: object expected");
                    m.rideType = $root.taxi.StringValue.fromObject(d.rideType);
                }
                if (d.tripParameters != null) {
                    if (typeof d.tripParameters !== "object")
                        throw TypeError(".taxi.server.UserListCouponsParams.tripParameters: object expected");
                    m.tripParameters = $root.taxi.StringValue.fromObject(d.tripParameters);
                }
                if (d.origin != null) {
                    if (typeof d.origin !== "object")
                        throw TypeError(".taxi.server.UserListCouponsParams.origin: object expected");
                    m.origin = $root.taxi.AddressedLocation.fromObject(d.origin);
                }
                if (d.destination != null) {
                    if (typeof d.destination !== "object")
                        throw TypeError(".taxi.server.UserListCouponsParams.destination: object expected");
                    m.destination = $root.taxi.AddressedLocation.fromObject(d.destination);
                }
                if (d.paymentMethodId != null) {
                    if (typeof d.paymentMethodId !== "object")
                        throw TypeError(".taxi.server.UserListCouponsParams.paymentMethodId: object expected");
                    m.paymentMethodId = $root.taxi.StringValue.fromObject(d.paymentMethodId);
                }
                if (d.paymentProfileId != null) {
                    if (typeof d.paymentProfileId !== "object")
                        throw TypeError(".taxi.server.UserListCouponsParams.paymentProfileId: object expected");
                    m.paymentProfileId = $root.taxi.StringValue.fromObject(d.paymentProfileId);
                }
                if (d.limit != null) {
                    if (typeof d.limit !== "object")
                        throw TypeError(".taxi.server.UserListCouponsParams.limit: object expected");
                    m.limit = $root.taxi.Int32Value.fromObject(d.limit);
                }
                if (d.nextToken != null) {
                    if (typeof d.nextToken !== "object")
                        throw TypeError(".taxi.server.UserListCouponsParams.nextToken: object expected");
                    m.nextToken = $root.taxi.StringValue.fromObject(d.nextToken);
                }
                if (d.glimpse != null) {
                    m.glimpse = Boolean(d.glimpse);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserListCouponsParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListCouponsParams
             * @static
             * @param {taxi.server.UserListCouponsParams} m UserListCouponsParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListCouponsParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.rideType = null;
                    d.tripParameters = null;
                    d.origin = null;
                    d.destination = null;
                    d.paymentMethodId = null;
                    d.paymentProfileId = null;
                    d.limit = null;
                    d.nextToken = null;
                    d.glimpse = false;
                }
                if (m.rideType != null && m.hasOwnProperty("rideType")) {
                    d.rideType = $root.taxi.StringValue.toObject(m.rideType, o);
                }
                if (m.tripParameters != null && m.hasOwnProperty("tripParameters")) {
                    d.tripParameters = $root.taxi.StringValue.toObject(m.tripParameters, o);
                }
                if (m.origin != null && m.hasOwnProperty("origin")) {
                    d.origin = $root.taxi.AddressedLocation.toObject(m.origin, o);
                }
                if (m.destination != null && m.hasOwnProperty("destination")) {
                    d.destination = $root.taxi.AddressedLocation.toObject(m.destination, o);
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = $root.taxi.StringValue.toObject(m.paymentMethodId, o);
                }
                if (m.paymentProfileId != null && m.hasOwnProperty("paymentProfileId")) {
                    d.paymentProfileId = $root.taxi.StringValue.toObject(m.paymentProfileId, o);
                }
                if (m.limit != null && m.hasOwnProperty("limit")) {
                    d.limit = $root.taxi.Int32Value.toObject(m.limit, o);
                }
                if (m.nextToken != null && m.hasOwnProperty("nextToken")) {
                    d.nextToken = $root.taxi.StringValue.toObject(m.nextToken, o);
                }
                if (m.glimpse != null && m.hasOwnProperty("glimpse")) {
                    d.glimpse = m.glimpse;
                }
                return d;
            };

            /**
             * Converts this UserListCouponsParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListCouponsParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListCouponsParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListCouponsParams;
        })();

        server.UserListCouponsResult = (function() {

            /**
             * Properties of a UserListCouponsResult.
             * @memberof taxi.server
             * @interface IUserListCouponsResult
             * @property {Array.<taxi.ICouponDTO>|null} [coupons] UserListCouponsResult coupons
             */

            /**
             * Constructs a new UserListCouponsResult.
             * @memberof taxi.server
             * @classdesc Represents a UserListCouponsResult.
             * @implements IUserListCouponsResult
             * @constructor
             * @param {taxi.server.IUserListCouponsResult=} [p] Properties to set
             */
            function UserListCouponsResult(p) {
                this.coupons = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserListCouponsResult coupons.
             * @member {Array.<taxi.ICouponDTO>} coupons
             * @memberof taxi.server.UserListCouponsResult
             * @instance
             */
            UserListCouponsResult.prototype.coupons = $util.emptyArray;

            /**
             * Creates a new UserListCouponsResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListCouponsResult
             * @static
             * @param {taxi.server.IUserListCouponsResult=} [properties] Properties to set
             * @returns {taxi.server.UserListCouponsResult} UserListCouponsResult instance
             */
            UserListCouponsResult.create = function create(properties) {
                return new UserListCouponsResult(properties);
            };

            /**
             * Creates a UserListCouponsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListCouponsResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListCouponsResult} UserListCouponsResult
             */
            UserListCouponsResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListCouponsResult)
                    return d;
                var m = new $root.taxi.server.UserListCouponsResult();
                if (d.coupons) {
                    if (!Array.isArray(d.coupons))
                        throw TypeError(".taxi.server.UserListCouponsResult.coupons: array expected");
                    m.coupons = [];
                    for (var i = 0; i < d.coupons.length; ++i) {
                        if (typeof d.coupons[i] !== "object")
                            throw TypeError(".taxi.server.UserListCouponsResult.coupons: object expected");
                        m.coupons[i] = $root.taxi.CouponDTO.fromObject(d.coupons[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a UserListCouponsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListCouponsResult
             * @static
             * @param {taxi.server.UserListCouponsResult} m UserListCouponsResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListCouponsResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.coupons = [];
                }
                if (m.coupons && m.coupons.length) {
                    d.coupons = [];
                    for (var j = 0; j < m.coupons.length; ++j) {
                        d.coupons[j] = $root.taxi.CouponDTO.toObject(m.coupons[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this UserListCouponsResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListCouponsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListCouponsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListCouponsResult;
        })();

        server.UserDeactivateUserSubscriptionParams = (function() {

            /**
             * Properties of a UserDeactivateUserSubscriptionParams.
             * @memberof taxi.server
             * @interface IUserDeactivateUserSubscriptionParams
             * @property {string|null} [subscriptionId] UserDeactivateUserSubscriptionParams subscriptionId
             */

            /**
             * Constructs a new UserDeactivateUserSubscriptionParams.
             * @memberof taxi.server
             * @classdesc Represents a UserDeactivateUserSubscriptionParams.
             * @implements IUserDeactivateUserSubscriptionParams
             * @constructor
             * @param {taxi.server.IUserDeactivateUserSubscriptionParams=} [p] Properties to set
             */
            function UserDeactivateUserSubscriptionParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserDeactivateUserSubscriptionParams subscriptionId.
             * @member {string} subscriptionId
             * @memberof taxi.server.UserDeactivateUserSubscriptionParams
             * @instance
             */
            UserDeactivateUserSubscriptionParams.prototype.subscriptionId = "";

            /**
             * Creates a new UserDeactivateUserSubscriptionParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserDeactivateUserSubscriptionParams
             * @static
             * @param {taxi.server.IUserDeactivateUserSubscriptionParams=} [properties] Properties to set
             * @returns {taxi.server.UserDeactivateUserSubscriptionParams} UserDeactivateUserSubscriptionParams instance
             */
            UserDeactivateUserSubscriptionParams.create = function create(properties) {
                return new UserDeactivateUserSubscriptionParams(properties);
            };

            /**
             * Creates a UserDeactivateUserSubscriptionParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserDeactivateUserSubscriptionParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserDeactivateUserSubscriptionParams} UserDeactivateUserSubscriptionParams
             */
            UserDeactivateUserSubscriptionParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserDeactivateUserSubscriptionParams)
                    return d;
                var m = new $root.taxi.server.UserDeactivateUserSubscriptionParams();
                if (d.subscriptionId != null) {
                    m.subscriptionId = String(d.subscriptionId);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserDeactivateUserSubscriptionParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserDeactivateUserSubscriptionParams
             * @static
             * @param {taxi.server.UserDeactivateUserSubscriptionParams} m UserDeactivateUserSubscriptionParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserDeactivateUserSubscriptionParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.subscriptionId = "";
                }
                if (m.subscriptionId != null && m.hasOwnProperty("subscriptionId")) {
                    d.subscriptionId = m.subscriptionId;
                }
                return d;
            };

            /**
             * Converts this UserDeactivateUserSubscriptionParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserDeactivateUserSubscriptionParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserDeactivateUserSubscriptionParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserDeactivateUserSubscriptionParams;
        })();

        server.UserDeactivateUserSubscriptionResult = (function() {

            /**
             * Properties of a UserDeactivateUserSubscriptionResult.
             * @memberof taxi.server
             * @interface IUserDeactivateUserSubscriptionResult
             */

            /**
             * Constructs a new UserDeactivateUserSubscriptionResult.
             * @memberof taxi.server
             * @classdesc Represents a UserDeactivateUserSubscriptionResult.
             * @implements IUserDeactivateUserSubscriptionResult
             * @constructor
             * @param {taxi.server.IUserDeactivateUserSubscriptionResult=} [p] Properties to set
             */
            function UserDeactivateUserSubscriptionResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserDeactivateUserSubscriptionResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserDeactivateUserSubscriptionResult
             * @static
             * @param {taxi.server.IUserDeactivateUserSubscriptionResult=} [properties] Properties to set
             * @returns {taxi.server.UserDeactivateUserSubscriptionResult} UserDeactivateUserSubscriptionResult instance
             */
            UserDeactivateUserSubscriptionResult.create = function create(properties) {
                return new UserDeactivateUserSubscriptionResult(properties);
            };

            /**
             * Creates a UserDeactivateUserSubscriptionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserDeactivateUserSubscriptionResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserDeactivateUserSubscriptionResult} UserDeactivateUserSubscriptionResult
             */
            UserDeactivateUserSubscriptionResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserDeactivateUserSubscriptionResult)
                    return d;
                return new $root.taxi.server.UserDeactivateUserSubscriptionResult();
            };

            /**
             * Creates a plain object from a UserDeactivateUserSubscriptionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserDeactivateUserSubscriptionResult
             * @static
             * @param {taxi.server.UserDeactivateUserSubscriptionResult} m UserDeactivateUserSubscriptionResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserDeactivateUserSubscriptionResult.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserDeactivateUserSubscriptionResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserDeactivateUserSubscriptionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserDeactivateUserSubscriptionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserDeactivateUserSubscriptionResult;
        })();

        server.UserEstimateRefundUserSubscriptionParams = (function() {

            /**
             * Properties of a UserEstimateRefundUserSubscriptionParams.
             * @memberof taxi.server
             * @interface IUserEstimateRefundUserSubscriptionParams
             * @property {string|null} [subscriptionId] UserEstimateRefundUserSubscriptionParams subscriptionId
             */

            /**
             * Constructs a new UserEstimateRefundUserSubscriptionParams.
             * @memberof taxi.server
             * @classdesc Represents a UserEstimateRefundUserSubscriptionParams.
             * @implements IUserEstimateRefundUserSubscriptionParams
             * @constructor
             * @param {taxi.server.IUserEstimateRefundUserSubscriptionParams=} [p] Properties to set
             */
            function UserEstimateRefundUserSubscriptionParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserEstimateRefundUserSubscriptionParams subscriptionId.
             * @member {string} subscriptionId
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionParams
             * @instance
             */
            UserEstimateRefundUserSubscriptionParams.prototype.subscriptionId = "";

            /**
             * Creates a new UserEstimateRefundUserSubscriptionParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionParams
             * @static
             * @param {taxi.server.IUserEstimateRefundUserSubscriptionParams=} [properties] Properties to set
             * @returns {taxi.server.UserEstimateRefundUserSubscriptionParams} UserEstimateRefundUserSubscriptionParams instance
             */
            UserEstimateRefundUserSubscriptionParams.create = function create(properties) {
                return new UserEstimateRefundUserSubscriptionParams(properties);
            };

            /**
             * Creates a UserEstimateRefundUserSubscriptionParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserEstimateRefundUserSubscriptionParams} UserEstimateRefundUserSubscriptionParams
             */
            UserEstimateRefundUserSubscriptionParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserEstimateRefundUserSubscriptionParams)
                    return d;
                var m = new $root.taxi.server.UserEstimateRefundUserSubscriptionParams();
                if (d.subscriptionId != null) {
                    m.subscriptionId = String(d.subscriptionId);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserEstimateRefundUserSubscriptionParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionParams
             * @static
             * @param {taxi.server.UserEstimateRefundUserSubscriptionParams} m UserEstimateRefundUserSubscriptionParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserEstimateRefundUserSubscriptionParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.subscriptionId = "";
                }
                if (m.subscriptionId != null && m.hasOwnProperty("subscriptionId")) {
                    d.subscriptionId = m.subscriptionId;
                }
                return d;
            };

            /**
             * Converts this UserEstimateRefundUserSubscriptionParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserEstimateRefundUserSubscriptionParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserEstimateRefundUserSubscriptionParams;
        })();

        server.UserEstimateRefundUserSubscriptionResult = (function() {

            /**
             * Properties of a UserEstimateRefundUserSubscriptionResult.
             * @memberof taxi.server
             * @interface IUserEstimateRefundUserSubscriptionResult
             * @property {Array.<number>|null} [refundAmounts] UserEstimateRefundUserSubscriptionResult refundAmounts
             */

            /**
             * Constructs a new UserEstimateRefundUserSubscriptionResult.
             * @memberof taxi.server
             * @classdesc Represents a UserEstimateRefundUserSubscriptionResult.
             * @implements IUserEstimateRefundUserSubscriptionResult
             * @constructor
             * @param {taxi.server.IUserEstimateRefundUserSubscriptionResult=} [p] Properties to set
             */
            function UserEstimateRefundUserSubscriptionResult(p) {
                this.refundAmounts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserEstimateRefundUserSubscriptionResult refundAmounts.
             * @member {Array.<number>} refundAmounts
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionResult
             * @instance
             */
            UserEstimateRefundUserSubscriptionResult.prototype.refundAmounts = $util.emptyArray;

            /**
             * Creates a new UserEstimateRefundUserSubscriptionResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionResult
             * @static
             * @param {taxi.server.IUserEstimateRefundUserSubscriptionResult=} [properties] Properties to set
             * @returns {taxi.server.UserEstimateRefundUserSubscriptionResult} UserEstimateRefundUserSubscriptionResult instance
             */
            UserEstimateRefundUserSubscriptionResult.create = function create(properties) {
                return new UserEstimateRefundUserSubscriptionResult(properties);
            };

            /**
             * Creates a UserEstimateRefundUserSubscriptionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserEstimateRefundUserSubscriptionResult} UserEstimateRefundUserSubscriptionResult
             */
            UserEstimateRefundUserSubscriptionResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserEstimateRefundUserSubscriptionResult)
                    return d;
                var m = new $root.taxi.server.UserEstimateRefundUserSubscriptionResult();
                if (d.refundAmounts) {
                    if (!Array.isArray(d.refundAmounts))
                        throw TypeError(".taxi.server.UserEstimateRefundUserSubscriptionResult.refundAmounts: array expected");
                    m.refundAmounts = [];
                    for (var i = 0; i < d.refundAmounts.length; ++i) {
                        m.refundAmounts[i] = d.refundAmounts[i] | 0;
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a UserEstimateRefundUserSubscriptionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionResult
             * @static
             * @param {taxi.server.UserEstimateRefundUserSubscriptionResult} m UserEstimateRefundUserSubscriptionResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserEstimateRefundUserSubscriptionResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.refundAmounts = [];
                }
                if (m.refundAmounts && m.refundAmounts.length) {
                    d.refundAmounts = [];
                    for (var j = 0; j < m.refundAmounts.length; ++j) {
                        d.refundAmounts[j] = m.refundAmounts[j];
                    }
                }
                return d;
            };

            /**
             * Converts this UserEstimateRefundUserSubscriptionResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserEstimateRefundUserSubscriptionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserEstimateRefundUserSubscriptionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserEstimateRefundUserSubscriptionResult;
        })();

        server.UserGetInvitationCouponCodeParams = (function() {

            /**
             * Properties of a UserGetInvitationCouponCodeParams.
             * @memberof taxi.server
             * @interface IUserGetInvitationCouponCodeParams
             */

            /**
             * Constructs a new UserGetInvitationCouponCodeParams.
             * @memberof taxi.server
             * @classdesc Represents a UserGetInvitationCouponCodeParams.
             * @implements IUserGetInvitationCouponCodeParams
             * @constructor
             * @param {taxi.server.IUserGetInvitationCouponCodeParams=} [p] Properties to set
             */
            function UserGetInvitationCouponCodeParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserGetInvitationCouponCodeParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetInvitationCouponCodeParams
             * @static
             * @param {taxi.server.IUserGetInvitationCouponCodeParams=} [properties] Properties to set
             * @returns {taxi.server.UserGetInvitationCouponCodeParams} UserGetInvitationCouponCodeParams instance
             */
            UserGetInvitationCouponCodeParams.create = function create(properties) {
                return new UserGetInvitationCouponCodeParams(properties);
            };

            /**
             * Creates a UserGetInvitationCouponCodeParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetInvitationCouponCodeParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetInvitationCouponCodeParams} UserGetInvitationCouponCodeParams
             */
            UserGetInvitationCouponCodeParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetInvitationCouponCodeParams)
                    return d;
                return new $root.taxi.server.UserGetInvitationCouponCodeParams();
            };

            /**
             * Creates a plain object from a UserGetInvitationCouponCodeParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetInvitationCouponCodeParams
             * @static
             * @param {taxi.server.UserGetInvitationCouponCodeParams} m UserGetInvitationCouponCodeParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetInvitationCouponCodeParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserGetInvitationCouponCodeParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetInvitationCouponCodeParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetInvitationCouponCodeParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetInvitationCouponCodeParams;
        })();

        server.UserGetInvitationCouponCodeResult = (function() {

            /**
             * Properties of a UserGetInvitationCouponCodeResult.
             * @memberof taxi.server
             * @interface IUserGetInvitationCouponCodeResult
             * @property {taxi.IStringValue|null} [couponCode] UserGetInvitationCouponCodeResult couponCode
             * @property {string|null} [userNameForSharing] UserGetInvitationCouponCodeResult userNameForSharing
             * @property {number|null} [rewardCount] UserGetInvitationCouponCodeResult rewardCount
             */

            /**
             * Constructs a new UserGetInvitationCouponCodeResult.
             * @memberof taxi.server
             * @classdesc Represents a UserGetInvitationCouponCodeResult.
             * @implements IUserGetInvitationCouponCodeResult
             * @constructor
             * @param {taxi.server.IUserGetInvitationCouponCodeResult=} [p] Properties to set
             */
            function UserGetInvitationCouponCodeResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserGetInvitationCouponCodeResult couponCode.
             * @member {taxi.IStringValue|null|undefined} couponCode
             * @memberof taxi.server.UserGetInvitationCouponCodeResult
             * @instance
             */
            UserGetInvitationCouponCodeResult.prototype.couponCode = null;

            /**
             * UserGetInvitationCouponCodeResult userNameForSharing.
             * @member {string} userNameForSharing
             * @memberof taxi.server.UserGetInvitationCouponCodeResult
             * @instance
             */
            UserGetInvitationCouponCodeResult.prototype.userNameForSharing = "";

            /**
             * UserGetInvitationCouponCodeResult rewardCount.
             * @member {number} rewardCount
             * @memberof taxi.server.UserGetInvitationCouponCodeResult
             * @instance
             */
            UserGetInvitationCouponCodeResult.prototype.rewardCount = 0;

            /**
             * Creates a new UserGetInvitationCouponCodeResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetInvitationCouponCodeResult
             * @static
             * @param {taxi.server.IUserGetInvitationCouponCodeResult=} [properties] Properties to set
             * @returns {taxi.server.UserGetInvitationCouponCodeResult} UserGetInvitationCouponCodeResult instance
             */
            UserGetInvitationCouponCodeResult.create = function create(properties) {
                return new UserGetInvitationCouponCodeResult(properties);
            };

            /**
             * Creates a UserGetInvitationCouponCodeResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetInvitationCouponCodeResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetInvitationCouponCodeResult} UserGetInvitationCouponCodeResult
             */
            UserGetInvitationCouponCodeResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetInvitationCouponCodeResult)
                    return d;
                var m = new $root.taxi.server.UserGetInvitationCouponCodeResult();
                if (d.couponCode != null) {
                    if (typeof d.couponCode !== "object")
                        throw TypeError(".taxi.server.UserGetInvitationCouponCodeResult.couponCode: object expected");
                    m.couponCode = $root.taxi.StringValue.fromObject(d.couponCode);
                }
                if (d.userNameForSharing != null) {
                    m.userNameForSharing = String(d.userNameForSharing);
                }
                if (d.rewardCount != null) {
                    m.rewardCount = d.rewardCount | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a UserGetInvitationCouponCodeResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetInvitationCouponCodeResult
             * @static
             * @param {taxi.server.UserGetInvitationCouponCodeResult} m UserGetInvitationCouponCodeResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetInvitationCouponCodeResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.couponCode = null;
                    d.userNameForSharing = "";
                    d.rewardCount = 0;
                }
                if (m.couponCode != null && m.hasOwnProperty("couponCode")) {
                    d.couponCode = $root.taxi.StringValue.toObject(m.couponCode, o);
                }
                if (m.userNameForSharing != null && m.hasOwnProperty("userNameForSharing")) {
                    d.userNameForSharing = m.userNameForSharing;
                }
                if (m.rewardCount != null && m.hasOwnProperty("rewardCount")) {
                    d.rewardCount = m.rewardCount;
                }
                return d;
            };

            /**
             * Converts this UserGetInvitationCouponCodeResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetInvitationCouponCodeResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetInvitationCouponCodeResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetInvitationCouponCodeResult;
        })();

        server.UserGetPaymentMethodParams = (function() {

            /**
             * Properties of a UserGetPaymentMethodParams.
             * @memberof taxi.server
             * @interface IUserGetPaymentMethodParams
             * @property {string|null} [paymentMethodId] UserGetPaymentMethodParams paymentMethodId
             */

            /**
             * Constructs a new UserGetPaymentMethodParams.
             * @memberof taxi.server
             * @classdesc Represents a UserGetPaymentMethodParams.
             * @implements IUserGetPaymentMethodParams
             * @constructor
             * @param {taxi.server.IUserGetPaymentMethodParams=} [p] Properties to set
             */
            function UserGetPaymentMethodParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserGetPaymentMethodParams paymentMethodId.
             * @member {string} paymentMethodId
             * @memberof taxi.server.UserGetPaymentMethodParams
             * @instance
             */
            UserGetPaymentMethodParams.prototype.paymentMethodId = "";

            /**
             * Creates a new UserGetPaymentMethodParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetPaymentMethodParams
             * @static
             * @param {taxi.server.IUserGetPaymentMethodParams=} [properties] Properties to set
             * @returns {taxi.server.UserGetPaymentMethodParams} UserGetPaymentMethodParams instance
             */
            UserGetPaymentMethodParams.create = function create(properties) {
                return new UserGetPaymentMethodParams(properties);
            };

            /**
             * Creates a UserGetPaymentMethodParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetPaymentMethodParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetPaymentMethodParams} UserGetPaymentMethodParams
             */
            UserGetPaymentMethodParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetPaymentMethodParams)
                    return d;
                var m = new $root.taxi.server.UserGetPaymentMethodParams();
                if (d.paymentMethodId != null) {
                    m.paymentMethodId = String(d.paymentMethodId);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserGetPaymentMethodParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetPaymentMethodParams
             * @static
             * @param {taxi.server.UserGetPaymentMethodParams} m UserGetPaymentMethodParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetPaymentMethodParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.paymentMethodId = "";
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = m.paymentMethodId;
                }
                return d;
            };

            /**
             * Converts this UserGetPaymentMethodParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetPaymentMethodParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetPaymentMethodParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetPaymentMethodParams;
        })();

        server.UserGetPaymentMethodResult = (function() {

            /**
             * Properties of a UserGetPaymentMethodResult.
             * @memberof taxi.server
             * @interface IUserGetPaymentMethodResult
             * @property {taxi.IPaymentMethodDTO|null} [paymentMethod] UserGetPaymentMethodResult paymentMethod
             */

            /**
             * Constructs a new UserGetPaymentMethodResult.
             * @memberof taxi.server
             * @classdesc Represents a UserGetPaymentMethodResult.
             * @implements IUserGetPaymentMethodResult
             * @constructor
             * @param {taxi.server.IUserGetPaymentMethodResult=} [p] Properties to set
             */
            function UserGetPaymentMethodResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserGetPaymentMethodResult paymentMethod.
             * @member {taxi.IPaymentMethodDTO|null|undefined} paymentMethod
             * @memberof taxi.server.UserGetPaymentMethodResult
             * @instance
             */
            UserGetPaymentMethodResult.prototype.paymentMethod = null;

            /**
             * Creates a new UserGetPaymentMethodResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetPaymentMethodResult
             * @static
             * @param {taxi.server.IUserGetPaymentMethodResult=} [properties] Properties to set
             * @returns {taxi.server.UserGetPaymentMethodResult} UserGetPaymentMethodResult instance
             */
            UserGetPaymentMethodResult.create = function create(properties) {
                return new UserGetPaymentMethodResult(properties);
            };

            /**
             * Creates a UserGetPaymentMethodResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetPaymentMethodResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetPaymentMethodResult} UserGetPaymentMethodResult
             */
            UserGetPaymentMethodResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetPaymentMethodResult)
                    return d;
                var m = new $root.taxi.server.UserGetPaymentMethodResult();
                if (d.paymentMethod != null) {
                    if (typeof d.paymentMethod !== "object")
                        throw TypeError(".taxi.server.UserGetPaymentMethodResult.paymentMethod: object expected");
                    m.paymentMethod = $root.taxi.PaymentMethodDTO.fromObject(d.paymentMethod);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserGetPaymentMethodResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetPaymentMethodResult
             * @static
             * @param {taxi.server.UserGetPaymentMethodResult} m UserGetPaymentMethodResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetPaymentMethodResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.paymentMethod = null;
                }
                if (m.paymentMethod != null && m.hasOwnProperty("paymentMethod")) {
                    d.paymentMethod = $root.taxi.PaymentMethodDTO.toObject(m.paymentMethod, o);
                }
                return d;
            };

            /**
             * Converts this UserGetPaymentMethodResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetPaymentMethodResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetPaymentMethodResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetPaymentMethodResult;
        })();

        server.UserGetUserSubscriptionParams = (function() {

            /**
             * Properties of a UserGetUserSubscriptionParams.
             * @memberof taxi.server
             * @interface IUserGetUserSubscriptionParams
             * @property {string|null} [subscriptionId] UserGetUserSubscriptionParams subscriptionId
             */

            /**
             * Constructs a new UserGetUserSubscriptionParams.
             * @memberof taxi.server
             * @classdesc Represents a UserGetUserSubscriptionParams.
             * @implements IUserGetUserSubscriptionParams
             * @constructor
             * @param {taxi.server.IUserGetUserSubscriptionParams=} [p] Properties to set
             */
            function UserGetUserSubscriptionParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserGetUserSubscriptionParams subscriptionId.
             * @member {string} subscriptionId
             * @memberof taxi.server.UserGetUserSubscriptionParams
             * @instance
             */
            UserGetUserSubscriptionParams.prototype.subscriptionId = "";

            /**
             * Creates a new UserGetUserSubscriptionParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetUserSubscriptionParams
             * @static
             * @param {taxi.server.IUserGetUserSubscriptionParams=} [properties] Properties to set
             * @returns {taxi.server.UserGetUserSubscriptionParams} UserGetUserSubscriptionParams instance
             */
            UserGetUserSubscriptionParams.create = function create(properties) {
                return new UserGetUserSubscriptionParams(properties);
            };

            /**
             * Creates a UserGetUserSubscriptionParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetUserSubscriptionParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetUserSubscriptionParams} UserGetUserSubscriptionParams
             */
            UserGetUserSubscriptionParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetUserSubscriptionParams)
                    return d;
                var m = new $root.taxi.server.UserGetUserSubscriptionParams();
                if (d.subscriptionId != null) {
                    m.subscriptionId = String(d.subscriptionId);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserGetUserSubscriptionParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetUserSubscriptionParams
             * @static
             * @param {taxi.server.UserGetUserSubscriptionParams} m UserGetUserSubscriptionParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetUserSubscriptionParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.subscriptionId = "";
                }
                if (m.subscriptionId != null && m.hasOwnProperty("subscriptionId")) {
                    d.subscriptionId = m.subscriptionId;
                }
                return d;
            };

            /**
             * Converts this UserGetUserSubscriptionParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetUserSubscriptionParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetUserSubscriptionParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetUserSubscriptionParams;
        })();

        server.UserGetUserSubscriptionResult = (function() {

            /**
             * Properties of a UserGetUserSubscriptionResult.
             * @memberof taxi.server
             * @interface IUserGetUserSubscriptionResult
             * @property {taxi.IUserSubscriptionDTO|null} [subscription] UserGetUserSubscriptionResult subscription
             */

            /**
             * Constructs a new UserGetUserSubscriptionResult.
             * @memberof taxi.server
             * @classdesc Represents a UserGetUserSubscriptionResult.
             * @implements IUserGetUserSubscriptionResult
             * @constructor
             * @param {taxi.server.IUserGetUserSubscriptionResult=} [p] Properties to set
             */
            function UserGetUserSubscriptionResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserGetUserSubscriptionResult subscription.
             * @member {taxi.IUserSubscriptionDTO|null|undefined} subscription
             * @memberof taxi.server.UserGetUserSubscriptionResult
             * @instance
             */
            UserGetUserSubscriptionResult.prototype.subscription = null;

            /**
             * Creates a new UserGetUserSubscriptionResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetUserSubscriptionResult
             * @static
             * @param {taxi.server.IUserGetUserSubscriptionResult=} [properties] Properties to set
             * @returns {taxi.server.UserGetUserSubscriptionResult} UserGetUserSubscriptionResult instance
             */
            UserGetUserSubscriptionResult.create = function create(properties) {
                return new UserGetUserSubscriptionResult(properties);
            };

            /**
             * Creates a UserGetUserSubscriptionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetUserSubscriptionResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetUserSubscriptionResult} UserGetUserSubscriptionResult
             */
            UserGetUserSubscriptionResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetUserSubscriptionResult)
                    return d;
                var m = new $root.taxi.server.UserGetUserSubscriptionResult();
                if (d.subscription != null) {
                    if (typeof d.subscription !== "object")
                        throw TypeError(".taxi.server.UserGetUserSubscriptionResult.subscription: object expected");
                    m.subscription = $root.taxi.UserSubscriptionDTO.fromObject(d.subscription);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserGetUserSubscriptionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetUserSubscriptionResult
             * @static
             * @param {taxi.server.UserGetUserSubscriptionResult} m UserGetUserSubscriptionResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetUserSubscriptionResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.subscription = null;
                }
                if (m.subscription != null && m.hasOwnProperty("subscription")) {
                    d.subscription = $root.taxi.UserSubscriptionDTO.toObject(m.subscription, o);
                }
                return d;
            };

            /**
             * Converts this UserGetUserSubscriptionResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetUserSubscriptionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetUserSubscriptionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetUserSubscriptionResult;
        })();

        server.UserGetUserSubscriptionProductParams = (function() {

            /**
             * Properties of a UserGetUserSubscriptionProductParams.
             * @memberof taxi.server
             * @interface IUserGetUserSubscriptionProductParams
             * @property {string|null} [productId] UserGetUserSubscriptionProductParams productId
             */

            /**
             * Constructs a new UserGetUserSubscriptionProductParams.
             * @memberof taxi.server
             * @classdesc Represents a UserGetUserSubscriptionProductParams.
             * @implements IUserGetUserSubscriptionProductParams
             * @constructor
             * @param {taxi.server.IUserGetUserSubscriptionProductParams=} [p] Properties to set
             */
            function UserGetUserSubscriptionProductParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserGetUserSubscriptionProductParams productId.
             * @member {string} productId
             * @memberof taxi.server.UserGetUserSubscriptionProductParams
             * @instance
             */
            UserGetUserSubscriptionProductParams.prototype.productId = "";

            /**
             * Creates a new UserGetUserSubscriptionProductParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetUserSubscriptionProductParams
             * @static
             * @param {taxi.server.IUserGetUserSubscriptionProductParams=} [properties] Properties to set
             * @returns {taxi.server.UserGetUserSubscriptionProductParams} UserGetUserSubscriptionProductParams instance
             */
            UserGetUserSubscriptionProductParams.create = function create(properties) {
                return new UserGetUserSubscriptionProductParams(properties);
            };

            /**
             * Creates a UserGetUserSubscriptionProductParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetUserSubscriptionProductParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetUserSubscriptionProductParams} UserGetUserSubscriptionProductParams
             */
            UserGetUserSubscriptionProductParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetUserSubscriptionProductParams)
                    return d;
                var m = new $root.taxi.server.UserGetUserSubscriptionProductParams();
                if (d.productId != null) {
                    m.productId = String(d.productId);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserGetUserSubscriptionProductParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetUserSubscriptionProductParams
             * @static
             * @param {taxi.server.UserGetUserSubscriptionProductParams} m UserGetUserSubscriptionProductParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetUserSubscriptionProductParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.productId = "";
                }
                if (m.productId != null && m.hasOwnProperty("productId")) {
                    d.productId = m.productId;
                }
                return d;
            };

            /**
             * Converts this UserGetUserSubscriptionProductParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetUserSubscriptionProductParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetUserSubscriptionProductParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetUserSubscriptionProductParams;
        })();

        server.UserGetUserSubscriptionProductResult = (function() {

            /**
             * Properties of a UserGetUserSubscriptionProductResult.
             * @memberof taxi.server
             * @interface IUserGetUserSubscriptionProductResult
             * @property {taxi.IUserSubscriptionProductDTO|null} [product] UserGetUserSubscriptionProductResult product
             */

            /**
             * Constructs a new UserGetUserSubscriptionProductResult.
             * @memberof taxi.server
             * @classdesc Represents a UserGetUserSubscriptionProductResult.
             * @implements IUserGetUserSubscriptionProductResult
             * @constructor
             * @param {taxi.server.IUserGetUserSubscriptionProductResult=} [p] Properties to set
             */
            function UserGetUserSubscriptionProductResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserGetUserSubscriptionProductResult product.
             * @member {taxi.IUserSubscriptionProductDTO|null|undefined} product
             * @memberof taxi.server.UserGetUserSubscriptionProductResult
             * @instance
             */
            UserGetUserSubscriptionProductResult.prototype.product = null;

            /**
             * Creates a new UserGetUserSubscriptionProductResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserGetUserSubscriptionProductResult
             * @static
             * @param {taxi.server.IUserGetUserSubscriptionProductResult=} [properties] Properties to set
             * @returns {taxi.server.UserGetUserSubscriptionProductResult} UserGetUserSubscriptionProductResult instance
             */
            UserGetUserSubscriptionProductResult.create = function create(properties) {
                return new UserGetUserSubscriptionProductResult(properties);
            };

            /**
             * Creates a UserGetUserSubscriptionProductResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserGetUserSubscriptionProductResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserGetUserSubscriptionProductResult} UserGetUserSubscriptionProductResult
             */
            UserGetUserSubscriptionProductResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserGetUserSubscriptionProductResult)
                    return d;
                var m = new $root.taxi.server.UserGetUserSubscriptionProductResult();
                if (d.product != null) {
                    if (typeof d.product !== "object")
                        throw TypeError(".taxi.server.UserGetUserSubscriptionProductResult.product: object expected");
                    m.product = $root.taxi.UserSubscriptionProductDTO.fromObject(d.product);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserGetUserSubscriptionProductResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserGetUserSubscriptionProductResult
             * @static
             * @param {taxi.server.UserGetUserSubscriptionProductResult} m UserGetUserSubscriptionProductResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserGetUserSubscriptionProductResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.product = null;
                }
                if (m.product != null && m.hasOwnProperty("product")) {
                    d.product = $root.taxi.UserSubscriptionProductDTO.toObject(m.product, o);
                }
                return d;
            };

            /**
             * Converts this UserGetUserSubscriptionProductResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserGetUserSubscriptionProductResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserGetUserSubscriptionProductResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserGetUserSubscriptionProductResult;
        })();

        server.UserListPaymentMethodsParams = (function() {

            /**
             * Properties of a UserListPaymentMethodsParams.
             * @memberof taxi.server
             * @interface IUserListPaymentMethodsParams
             */

            /**
             * Constructs a new UserListPaymentMethodsParams.
             * @memberof taxi.server
             * @classdesc Represents a UserListPaymentMethodsParams.
             * @implements IUserListPaymentMethodsParams
             * @constructor
             * @param {taxi.server.IUserListPaymentMethodsParams=} [p] Properties to set
             */
            function UserListPaymentMethodsParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserListPaymentMethodsParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListPaymentMethodsParams
             * @static
             * @param {taxi.server.IUserListPaymentMethodsParams=} [properties] Properties to set
             * @returns {taxi.server.UserListPaymentMethodsParams} UserListPaymentMethodsParams instance
             */
            UserListPaymentMethodsParams.create = function create(properties) {
                return new UserListPaymentMethodsParams(properties);
            };

            /**
             * Creates a UserListPaymentMethodsParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListPaymentMethodsParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListPaymentMethodsParams} UserListPaymentMethodsParams
             */
            UserListPaymentMethodsParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListPaymentMethodsParams)
                    return d;
                return new $root.taxi.server.UserListPaymentMethodsParams();
            };

            /**
             * Creates a plain object from a UserListPaymentMethodsParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListPaymentMethodsParams
             * @static
             * @param {taxi.server.UserListPaymentMethodsParams} m UserListPaymentMethodsParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListPaymentMethodsParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserListPaymentMethodsParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListPaymentMethodsParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListPaymentMethodsParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListPaymentMethodsParams;
        })();

        server.UserListPaymentMethodsResult = (function() {

            /**
             * Properties of a UserListPaymentMethodsResult.
             * @memberof taxi.server
             * @interface IUserListPaymentMethodsResult
             * @property {Array.<taxi.IPaymentMethodDTO>|null} [paymentMethods] UserListPaymentMethodsResult paymentMethods
             */

            /**
             * Constructs a new UserListPaymentMethodsResult.
             * @memberof taxi.server
             * @classdesc Represents a UserListPaymentMethodsResult.
             * @implements IUserListPaymentMethodsResult
             * @constructor
             * @param {taxi.server.IUserListPaymentMethodsResult=} [p] Properties to set
             */
            function UserListPaymentMethodsResult(p) {
                this.paymentMethods = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserListPaymentMethodsResult paymentMethods.
             * @member {Array.<taxi.IPaymentMethodDTO>} paymentMethods
             * @memberof taxi.server.UserListPaymentMethodsResult
             * @instance
             */
            UserListPaymentMethodsResult.prototype.paymentMethods = $util.emptyArray;

            /**
             * Creates a new UserListPaymentMethodsResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListPaymentMethodsResult
             * @static
             * @param {taxi.server.IUserListPaymentMethodsResult=} [properties] Properties to set
             * @returns {taxi.server.UserListPaymentMethodsResult} UserListPaymentMethodsResult instance
             */
            UserListPaymentMethodsResult.create = function create(properties) {
                return new UserListPaymentMethodsResult(properties);
            };

            /**
             * Creates a UserListPaymentMethodsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListPaymentMethodsResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListPaymentMethodsResult} UserListPaymentMethodsResult
             */
            UserListPaymentMethodsResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListPaymentMethodsResult)
                    return d;
                var m = new $root.taxi.server.UserListPaymentMethodsResult();
                if (d.paymentMethods) {
                    if (!Array.isArray(d.paymentMethods))
                        throw TypeError(".taxi.server.UserListPaymentMethodsResult.paymentMethods: array expected");
                    m.paymentMethods = [];
                    for (var i = 0; i < d.paymentMethods.length; ++i) {
                        if (typeof d.paymentMethods[i] !== "object")
                            throw TypeError(".taxi.server.UserListPaymentMethodsResult.paymentMethods: object expected");
                        m.paymentMethods[i] = $root.taxi.PaymentMethodDTO.fromObject(d.paymentMethods[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a UserListPaymentMethodsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListPaymentMethodsResult
             * @static
             * @param {taxi.server.UserListPaymentMethodsResult} m UserListPaymentMethodsResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListPaymentMethodsResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.paymentMethods = [];
                }
                if (m.paymentMethods && m.paymentMethods.length) {
                    d.paymentMethods = [];
                    for (var j = 0; j < m.paymentMethods.length; ++j) {
                        d.paymentMethods[j] = $root.taxi.PaymentMethodDTO.toObject(m.paymentMethods[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this UserListPaymentMethodsResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListPaymentMethodsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListPaymentMethodsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListPaymentMethodsResult;
        })();

        server.UserListPaymentProfilesParams = (function() {

            /**
             * Properties of a UserListPaymentProfilesParams.
             * @memberof taxi.server
             * @interface IUserListPaymentProfilesParams
             */

            /**
             * Constructs a new UserListPaymentProfilesParams.
             * @memberof taxi.server
             * @classdesc Represents a UserListPaymentProfilesParams.
             * @implements IUserListPaymentProfilesParams
             * @constructor
             * @param {taxi.server.IUserListPaymentProfilesParams=} [p] Properties to set
             */
            function UserListPaymentProfilesParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserListPaymentProfilesParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListPaymentProfilesParams
             * @static
             * @param {taxi.server.IUserListPaymentProfilesParams=} [properties] Properties to set
             * @returns {taxi.server.UserListPaymentProfilesParams} UserListPaymentProfilesParams instance
             */
            UserListPaymentProfilesParams.create = function create(properties) {
                return new UserListPaymentProfilesParams(properties);
            };

            /**
             * Creates a UserListPaymentProfilesParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListPaymentProfilesParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListPaymentProfilesParams} UserListPaymentProfilesParams
             */
            UserListPaymentProfilesParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListPaymentProfilesParams)
                    return d;
                return new $root.taxi.server.UserListPaymentProfilesParams();
            };

            /**
             * Creates a plain object from a UserListPaymentProfilesParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListPaymentProfilesParams
             * @static
             * @param {taxi.server.UserListPaymentProfilesParams} m UserListPaymentProfilesParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListPaymentProfilesParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserListPaymentProfilesParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListPaymentProfilesParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListPaymentProfilesParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListPaymentProfilesParams;
        })();

        server.UserListPaymentProfilesResult = (function() {

            /**
             * Properties of a UserListPaymentProfilesResult.
             * @memberof taxi.server
             * @interface IUserListPaymentProfilesResult
             * @property {Array.<taxi.IPaymentProfileDTO>|null} [paymentProfiles] UserListPaymentProfilesResult paymentProfiles
             */

            /**
             * Constructs a new UserListPaymentProfilesResult.
             * @memberof taxi.server
             * @classdesc Represents a UserListPaymentProfilesResult.
             * @implements IUserListPaymentProfilesResult
             * @constructor
             * @param {taxi.server.IUserListPaymentProfilesResult=} [p] Properties to set
             */
            function UserListPaymentProfilesResult(p) {
                this.paymentProfiles = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserListPaymentProfilesResult paymentProfiles.
             * @member {Array.<taxi.IPaymentProfileDTO>} paymentProfiles
             * @memberof taxi.server.UserListPaymentProfilesResult
             * @instance
             */
            UserListPaymentProfilesResult.prototype.paymentProfiles = $util.emptyArray;

            /**
             * Creates a new UserListPaymentProfilesResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListPaymentProfilesResult
             * @static
             * @param {taxi.server.IUserListPaymentProfilesResult=} [properties] Properties to set
             * @returns {taxi.server.UserListPaymentProfilesResult} UserListPaymentProfilesResult instance
             */
            UserListPaymentProfilesResult.create = function create(properties) {
                return new UserListPaymentProfilesResult(properties);
            };

            /**
             * Creates a UserListPaymentProfilesResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListPaymentProfilesResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListPaymentProfilesResult} UserListPaymentProfilesResult
             */
            UserListPaymentProfilesResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListPaymentProfilesResult)
                    return d;
                var m = new $root.taxi.server.UserListPaymentProfilesResult();
                if (d.paymentProfiles) {
                    if (!Array.isArray(d.paymentProfiles))
                        throw TypeError(".taxi.server.UserListPaymentProfilesResult.paymentProfiles: array expected");
                    m.paymentProfiles = [];
                    for (var i = 0; i < d.paymentProfiles.length; ++i) {
                        if (typeof d.paymentProfiles[i] !== "object")
                            throw TypeError(".taxi.server.UserListPaymentProfilesResult.paymentProfiles: object expected");
                        m.paymentProfiles[i] = $root.taxi.PaymentProfileDTO.fromObject(d.paymentProfiles[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a UserListPaymentProfilesResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListPaymentProfilesResult
             * @static
             * @param {taxi.server.UserListPaymentProfilesResult} m UserListPaymentProfilesResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListPaymentProfilesResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.paymentProfiles = [];
                }
                if (m.paymentProfiles && m.paymentProfiles.length) {
                    d.paymentProfiles = [];
                    for (var j = 0; j < m.paymentProfiles.length; ++j) {
                        d.paymentProfiles[j] = $root.taxi.PaymentProfileDTO.toObject(m.paymentProfiles[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this UserListPaymentProfilesResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListPaymentProfilesResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListPaymentProfilesResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListPaymentProfilesResult;
        })();

        server.UserListUserSubscriptionBillingsParams = (function() {

            /**
             * Properties of a UserListUserSubscriptionBillingsParams.
             * @memberof taxi.server
             * @interface IUserListUserSubscriptionBillingsParams
             */

            /**
             * Constructs a new UserListUserSubscriptionBillingsParams.
             * @memberof taxi.server
             * @classdesc Represents a UserListUserSubscriptionBillingsParams.
             * @implements IUserListUserSubscriptionBillingsParams
             * @constructor
             * @param {taxi.server.IUserListUserSubscriptionBillingsParams=} [p] Properties to set
             */
            function UserListUserSubscriptionBillingsParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserListUserSubscriptionBillingsParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListUserSubscriptionBillingsParams
             * @static
             * @param {taxi.server.IUserListUserSubscriptionBillingsParams=} [properties] Properties to set
             * @returns {taxi.server.UserListUserSubscriptionBillingsParams} UserListUserSubscriptionBillingsParams instance
             */
            UserListUserSubscriptionBillingsParams.create = function create(properties) {
                return new UserListUserSubscriptionBillingsParams(properties);
            };

            /**
             * Creates a UserListUserSubscriptionBillingsParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListUserSubscriptionBillingsParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListUserSubscriptionBillingsParams} UserListUserSubscriptionBillingsParams
             */
            UserListUserSubscriptionBillingsParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListUserSubscriptionBillingsParams)
                    return d;
                return new $root.taxi.server.UserListUserSubscriptionBillingsParams();
            };

            /**
             * Creates a plain object from a UserListUserSubscriptionBillingsParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListUserSubscriptionBillingsParams
             * @static
             * @param {taxi.server.UserListUserSubscriptionBillingsParams} m UserListUserSubscriptionBillingsParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListUserSubscriptionBillingsParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserListUserSubscriptionBillingsParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListUserSubscriptionBillingsParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListUserSubscriptionBillingsParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListUserSubscriptionBillingsParams;
        })();

        server.UserListUserSubscriptionBillingsResult = (function() {

            /**
             * Properties of a UserListUserSubscriptionBillingsResult.
             * @memberof taxi.server
             * @interface IUserListUserSubscriptionBillingsResult
             * @property {Array.<taxi.IUserSubscriptionBillingDTO>|null} [billings] UserListUserSubscriptionBillingsResult billings
             */

            /**
             * Constructs a new UserListUserSubscriptionBillingsResult.
             * @memberof taxi.server
             * @classdesc Represents a UserListUserSubscriptionBillingsResult.
             * @implements IUserListUserSubscriptionBillingsResult
             * @constructor
             * @param {taxi.server.IUserListUserSubscriptionBillingsResult=} [p] Properties to set
             */
            function UserListUserSubscriptionBillingsResult(p) {
                this.billings = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserListUserSubscriptionBillingsResult billings.
             * @member {Array.<taxi.IUserSubscriptionBillingDTO>} billings
             * @memberof taxi.server.UserListUserSubscriptionBillingsResult
             * @instance
             */
            UserListUserSubscriptionBillingsResult.prototype.billings = $util.emptyArray;

            /**
             * Creates a new UserListUserSubscriptionBillingsResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListUserSubscriptionBillingsResult
             * @static
             * @param {taxi.server.IUserListUserSubscriptionBillingsResult=} [properties] Properties to set
             * @returns {taxi.server.UserListUserSubscriptionBillingsResult} UserListUserSubscriptionBillingsResult instance
             */
            UserListUserSubscriptionBillingsResult.create = function create(properties) {
                return new UserListUserSubscriptionBillingsResult(properties);
            };

            /**
             * Creates a UserListUserSubscriptionBillingsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListUserSubscriptionBillingsResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListUserSubscriptionBillingsResult} UserListUserSubscriptionBillingsResult
             */
            UserListUserSubscriptionBillingsResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListUserSubscriptionBillingsResult)
                    return d;
                var m = new $root.taxi.server.UserListUserSubscriptionBillingsResult();
                if (d.billings) {
                    if (!Array.isArray(d.billings))
                        throw TypeError(".taxi.server.UserListUserSubscriptionBillingsResult.billings: array expected");
                    m.billings = [];
                    for (var i = 0; i < d.billings.length; ++i) {
                        if (typeof d.billings[i] !== "object")
                            throw TypeError(".taxi.server.UserListUserSubscriptionBillingsResult.billings: object expected");
                        m.billings[i] = $root.taxi.UserSubscriptionBillingDTO.fromObject(d.billings[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a UserListUserSubscriptionBillingsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListUserSubscriptionBillingsResult
             * @static
             * @param {taxi.server.UserListUserSubscriptionBillingsResult} m UserListUserSubscriptionBillingsResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListUserSubscriptionBillingsResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.billings = [];
                }
                if (m.billings && m.billings.length) {
                    d.billings = [];
                    for (var j = 0; j < m.billings.length; ++j) {
                        d.billings[j] = $root.taxi.UserSubscriptionBillingDTO.toObject(m.billings[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this UserListUserSubscriptionBillingsResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListUserSubscriptionBillingsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListUserSubscriptionBillingsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListUserSubscriptionBillingsResult;
        })();

        server.UserListUserSubscriptionProductsParams = (function() {

            /**
             * Properties of a UserListUserSubscriptionProductsParams.
             * @memberof taxi.server
             * @interface IUserListUserSubscriptionProductsParams
             */

            /**
             * Constructs a new UserListUserSubscriptionProductsParams.
             * @memberof taxi.server
             * @classdesc Represents a UserListUserSubscriptionProductsParams.
             * @implements IUserListUserSubscriptionProductsParams
             * @constructor
             * @param {taxi.server.IUserListUserSubscriptionProductsParams=} [p] Properties to set
             */
            function UserListUserSubscriptionProductsParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserListUserSubscriptionProductsParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListUserSubscriptionProductsParams
             * @static
             * @param {taxi.server.IUserListUserSubscriptionProductsParams=} [properties] Properties to set
             * @returns {taxi.server.UserListUserSubscriptionProductsParams} UserListUserSubscriptionProductsParams instance
             */
            UserListUserSubscriptionProductsParams.create = function create(properties) {
                return new UserListUserSubscriptionProductsParams(properties);
            };

            /**
             * Creates a UserListUserSubscriptionProductsParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListUserSubscriptionProductsParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListUserSubscriptionProductsParams} UserListUserSubscriptionProductsParams
             */
            UserListUserSubscriptionProductsParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListUserSubscriptionProductsParams)
                    return d;
                return new $root.taxi.server.UserListUserSubscriptionProductsParams();
            };

            /**
             * Creates a plain object from a UserListUserSubscriptionProductsParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListUserSubscriptionProductsParams
             * @static
             * @param {taxi.server.UserListUserSubscriptionProductsParams} m UserListUserSubscriptionProductsParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListUserSubscriptionProductsParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserListUserSubscriptionProductsParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListUserSubscriptionProductsParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListUserSubscriptionProductsParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListUserSubscriptionProductsParams;
        })();

        server.UserListUserSubscriptionProductsResult = (function() {

            /**
             * Properties of a UserListUserSubscriptionProductsResult.
             * @memberof taxi.server
             * @interface IUserListUserSubscriptionProductsResult
             * @property {Array.<taxi.IUserSubscriptionProductDTO>|null} [products] UserListUserSubscriptionProductsResult products
             */

            /**
             * Constructs a new UserListUserSubscriptionProductsResult.
             * @memberof taxi.server
             * @classdesc Represents a UserListUserSubscriptionProductsResult.
             * @implements IUserListUserSubscriptionProductsResult
             * @constructor
             * @param {taxi.server.IUserListUserSubscriptionProductsResult=} [p] Properties to set
             */
            function UserListUserSubscriptionProductsResult(p) {
                this.products = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserListUserSubscriptionProductsResult products.
             * @member {Array.<taxi.IUserSubscriptionProductDTO>} products
             * @memberof taxi.server.UserListUserSubscriptionProductsResult
             * @instance
             */
            UserListUserSubscriptionProductsResult.prototype.products = $util.emptyArray;

            /**
             * Creates a new UserListUserSubscriptionProductsResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListUserSubscriptionProductsResult
             * @static
             * @param {taxi.server.IUserListUserSubscriptionProductsResult=} [properties] Properties to set
             * @returns {taxi.server.UserListUserSubscriptionProductsResult} UserListUserSubscriptionProductsResult instance
             */
            UserListUserSubscriptionProductsResult.create = function create(properties) {
                return new UserListUserSubscriptionProductsResult(properties);
            };

            /**
             * Creates a UserListUserSubscriptionProductsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListUserSubscriptionProductsResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListUserSubscriptionProductsResult} UserListUserSubscriptionProductsResult
             */
            UserListUserSubscriptionProductsResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListUserSubscriptionProductsResult)
                    return d;
                var m = new $root.taxi.server.UserListUserSubscriptionProductsResult();
                if (d.products) {
                    if (!Array.isArray(d.products))
                        throw TypeError(".taxi.server.UserListUserSubscriptionProductsResult.products: array expected");
                    m.products = [];
                    for (var i = 0; i < d.products.length; ++i) {
                        if (typeof d.products[i] !== "object")
                            throw TypeError(".taxi.server.UserListUserSubscriptionProductsResult.products: object expected");
                        m.products[i] = $root.taxi.UserSubscriptionProductDTO.fromObject(d.products[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a UserListUserSubscriptionProductsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListUserSubscriptionProductsResult
             * @static
             * @param {taxi.server.UserListUserSubscriptionProductsResult} m UserListUserSubscriptionProductsResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListUserSubscriptionProductsResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.products = [];
                }
                if (m.products && m.products.length) {
                    d.products = [];
                    for (var j = 0; j < m.products.length; ++j) {
                        d.products[j] = $root.taxi.UserSubscriptionProductDTO.toObject(m.products[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this UserListUserSubscriptionProductsResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListUserSubscriptionProductsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListUserSubscriptionProductsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListUserSubscriptionProductsResult;
        })();

        server.UserListUserSubscriptionsParams = (function() {

            /**
             * Properties of a UserListUserSubscriptionsParams.
             * @memberof taxi.server
             * @interface IUserListUserSubscriptionsParams
             */

            /**
             * Constructs a new UserListUserSubscriptionsParams.
             * @memberof taxi.server
             * @classdesc Represents a UserListUserSubscriptionsParams.
             * @implements IUserListUserSubscriptionsParams
             * @constructor
             * @param {taxi.server.IUserListUserSubscriptionsParams=} [p] Properties to set
             */
            function UserListUserSubscriptionsParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserListUserSubscriptionsParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListUserSubscriptionsParams
             * @static
             * @param {taxi.server.IUserListUserSubscriptionsParams=} [properties] Properties to set
             * @returns {taxi.server.UserListUserSubscriptionsParams} UserListUserSubscriptionsParams instance
             */
            UserListUserSubscriptionsParams.create = function create(properties) {
                return new UserListUserSubscriptionsParams(properties);
            };

            /**
             * Creates a UserListUserSubscriptionsParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListUserSubscriptionsParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListUserSubscriptionsParams} UserListUserSubscriptionsParams
             */
            UserListUserSubscriptionsParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListUserSubscriptionsParams)
                    return d;
                return new $root.taxi.server.UserListUserSubscriptionsParams();
            };

            /**
             * Creates a plain object from a UserListUserSubscriptionsParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListUserSubscriptionsParams
             * @static
             * @param {taxi.server.UserListUserSubscriptionsParams} m UserListUserSubscriptionsParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListUserSubscriptionsParams.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserListUserSubscriptionsParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListUserSubscriptionsParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListUserSubscriptionsParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListUserSubscriptionsParams;
        })();

        server.UserListUserSubscriptionsResult = (function() {

            /**
             * Properties of a UserListUserSubscriptionsResult.
             * @memberof taxi.server
             * @interface IUserListUserSubscriptionsResult
             * @property {Array.<taxi.IUserSubscriptionDTO>|null} [subscriptions] UserListUserSubscriptionsResult subscriptions
             */

            /**
             * Constructs a new UserListUserSubscriptionsResult.
             * @memberof taxi.server
             * @classdesc Represents a UserListUserSubscriptionsResult.
             * @implements IUserListUserSubscriptionsResult
             * @constructor
             * @param {taxi.server.IUserListUserSubscriptionsResult=} [p] Properties to set
             */
            function UserListUserSubscriptionsResult(p) {
                this.subscriptions = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserListUserSubscriptionsResult subscriptions.
             * @member {Array.<taxi.IUserSubscriptionDTO>} subscriptions
             * @memberof taxi.server.UserListUserSubscriptionsResult
             * @instance
             */
            UserListUserSubscriptionsResult.prototype.subscriptions = $util.emptyArray;

            /**
             * Creates a new UserListUserSubscriptionsResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserListUserSubscriptionsResult
             * @static
             * @param {taxi.server.IUserListUserSubscriptionsResult=} [properties] Properties to set
             * @returns {taxi.server.UserListUserSubscriptionsResult} UserListUserSubscriptionsResult instance
             */
            UserListUserSubscriptionsResult.create = function create(properties) {
                return new UserListUserSubscriptionsResult(properties);
            };

            /**
             * Creates a UserListUserSubscriptionsResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserListUserSubscriptionsResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserListUserSubscriptionsResult} UserListUserSubscriptionsResult
             */
            UserListUserSubscriptionsResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserListUserSubscriptionsResult)
                    return d;
                var m = new $root.taxi.server.UserListUserSubscriptionsResult();
                if (d.subscriptions) {
                    if (!Array.isArray(d.subscriptions))
                        throw TypeError(".taxi.server.UserListUserSubscriptionsResult.subscriptions: array expected");
                    m.subscriptions = [];
                    for (var i = 0; i < d.subscriptions.length; ++i) {
                        if (typeof d.subscriptions[i] !== "object")
                            throw TypeError(".taxi.server.UserListUserSubscriptionsResult.subscriptions: object expected");
                        m.subscriptions[i] = $root.taxi.UserSubscriptionDTO.fromObject(d.subscriptions[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a UserListUserSubscriptionsResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserListUserSubscriptionsResult
             * @static
             * @param {taxi.server.UserListUserSubscriptionsResult} m UserListUserSubscriptionsResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserListUserSubscriptionsResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.subscriptions = [];
                }
                if (m.subscriptions && m.subscriptions.length) {
                    d.subscriptions = [];
                    for (var j = 0; j < m.subscriptions.length; ++j) {
                        d.subscriptions[j] = $root.taxi.UserSubscriptionDTO.toObject(m.subscriptions[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this UserListUserSubscriptionsResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserListUserSubscriptionsResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserListUserSubscriptionsResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserListUserSubscriptionsResult;
        })();

        server.UserPurchaseUserSubscriptionProductParams = (function() {

            /**
             * Properties of a UserPurchaseUserSubscriptionProductParams.
             * @memberof taxi.server
             * @interface IUserPurchaseUserSubscriptionProductParams
             * @property {string|null} [productId] UserPurchaseUserSubscriptionProductParams productId
             * @property {string|null} [paymentMethodId] UserPurchaseUserSubscriptionProductParams paymentMethodId
             */

            /**
             * Constructs a new UserPurchaseUserSubscriptionProductParams.
             * @memberof taxi.server
             * @classdesc Represents a UserPurchaseUserSubscriptionProductParams.
             * @implements IUserPurchaseUserSubscriptionProductParams
             * @constructor
             * @param {taxi.server.IUserPurchaseUserSubscriptionProductParams=} [p] Properties to set
             */
            function UserPurchaseUserSubscriptionProductParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserPurchaseUserSubscriptionProductParams productId.
             * @member {string} productId
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductParams
             * @instance
             */
            UserPurchaseUserSubscriptionProductParams.prototype.productId = "";

            /**
             * UserPurchaseUserSubscriptionProductParams paymentMethodId.
             * @member {string} paymentMethodId
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductParams
             * @instance
             */
            UserPurchaseUserSubscriptionProductParams.prototype.paymentMethodId = "";

            /**
             * Creates a new UserPurchaseUserSubscriptionProductParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductParams
             * @static
             * @param {taxi.server.IUserPurchaseUserSubscriptionProductParams=} [properties] Properties to set
             * @returns {taxi.server.UserPurchaseUserSubscriptionProductParams} UserPurchaseUserSubscriptionProductParams instance
             */
            UserPurchaseUserSubscriptionProductParams.create = function create(properties) {
                return new UserPurchaseUserSubscriptionProductParams(properties);
            };

            /**
             * Creates a UserPurchaseUserSubscriptionProductParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserPurchaseUserSubscriptionProductParams} UserPurchaseUserSubscriptionProductParams
             */
            UserPurchaseUserSubscriptionProductParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserPurchaseUserSubscriptionProductParams)
                    return d;
                var m = new $root.taxi.server.UserPurchaseUserSubscriptionProductParams();
                if (d.productId != null) {
                    m.productId = String(d.productId);
                }
                if (d.paymentMethodId != null) {
                    m.paymentMethodId = String(d.paymentMethodId);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserPurchaseUserSubscriptionProductParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductParams
             * @static
             * @param {taxi.server.UserPurchaseUserSubscriptionProductParams} m UserPurchaseUserSubscriptionProductParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserPurchaseUserSubscriptionProductParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.productId = "";
                    d.paymentMethodId = "";
                }
                if (m.productId != null && m.hasOwnProperty("productId")) {
                    d.productId = m.productId;
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = m.paymentMethodId;
                }
                return d;
            };

            /**
             * Converts this UserPurchaseUserSubscriptionProductParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserPurchaseUserSubscriptionProductParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserPurchaseUserSubscriptionProductParams;
        })();

        server.UserPurchaseUserSubscriptionProductResult = (function() {

            /**
             * Properties of a UserPurchaseUserSubscriptionProductResult.
             * @memberof taxi.server
             * @interface IUserPurchaseUserSubscriptionProductResult
             * @property {taxi.IUserSubscriptionDTO|null} [subscription] UserPurchaseUserSubscriptionProductResult subscription
             */

            /**
             * Constructs a new UserPurchaseUserSubscriptionProductResult.
             * @memberof taxi.server
             * @classdesc Represents a UserPurchaseUserSubscriptionProductResult.
             * @implements IUserPurchaseUserSubscriptionProductResult
             * @constructor
             * @param {taxi.server.IUserPurchaseUserSubscriptionProductResult=} [p] Properties to set
             */
            function UserPurchaseUserSubscriptionProductResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserPurchaseUserSubscriptionProductResult subscription.
             * @member {taxi.IUserSubscriptionDTO|null|undefined} subscription
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductResult
             * @instance
             */
            UserPurchaseUserSubscriptionProductResult.prototype.subscription = null;

            /**
             * Creates a new UserPurchaseUserSubscriptionProductResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductResult
             * @static
             * @param {taxi.server.IUserPurchaseUserSubscriptionProductResult=} [properties] Properties to set
             * @returns {taxi.server.UserPurchaseUserSubscriptionProductResult} UserPurchaseUserSubscriptionProductResult instance
             */
            UserPurchaseUserSubscriptionProductResult.create = function create(properties) {
                return new UserPurchaseUserSubscriptionProductResult(properties);
            };

            /**
             * Creates a UserPurchaseUserSubscriptionProductResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserPurchaseUserSubscriptionProductResult} UserPurchaseUserSubscriptionProductResult
             */
            UserPurchaseUserSubscriptionProductResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserPurchaseUserSubscriptionProductResult)
                    return d;
                var m = new $root.taxi.server.UserPurchaseUserSubscriptionProductResult();
                if (d.subscription != null) {
                    if (typeof d.subscription !== "object")
                        throw TypeError(".taxi.server.UserPurchaseUserSubscriptionProductResult.subscription: object expected");
                    m.subscription = $root.taxi.UserSubscriptionDTO.fromObject(d.subscription);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserPurchaseUserSubscriptionProductResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductResult
             * @static
             * @param {taxi.server.UserPurchaseUserSubscriptionProductResult} m UserPurchaseUserSubscriptionProductResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserPurchaseUserSubscriptionProductResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.subscription = null;
                }
                if (m.subscription != null && m.hasOwnProperty("subscription")) {
                    d.subscription = $root.taxi.UserSubscriptionDTO.toObject(m.subscription, o);
                }
                return d;
            };

            /**
             * Converts this UserPurchaseUserSubscriptionProductResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserPurchaseUserSubscriptionProductResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserPurchaseUserSubscriptionProductResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserPurchaseUserSubscriptionProductResult;
        })();

        server.UserRefundUserSubscriptionParams = (function() {

            /**
             * Properties of a UserRefundUserSubscriptionParams.
             * @memberof taxi.server
             * @interface IUserRefundUserSubscriptionParams
             * @property {string|null} [subscriptionId] UserRefundUserSubscriptionParams subscriptionId
             */

            /**
             * Constructs a new UserRefundUserSubscriptionParams.
             * @memberof taxi.server
             * @classdesc Represents a UserRefundUserSubscriptionParams.
             * @implements IUserRefundUserSubscriptionParams
             * @constructor
             * @param {taxi.server.IUserRefundUserSubscriptionParams=} [p] Properties to set
             */
            function UserRefundUserSubscriptionParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserRefundUserSubscriptionParams subscriptionId.
             * @member {string} subscriptionId
             * @memberof taxi.server.UserRefundUserSubscriptionParams
             * @instance
             */
            UserRefundUserSubscriptionParams.prototype.subscriptionId = "";

            /**
             * Creates a new UserRefundUserSubscriptionParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserRefundUserSubscriptionParams
             * @static
             * @param {taxi.server.IUserRefundUserSubscriptionParams=} [properties] Properties to set
             * @returns {taxi.server.UserRefundUserSubscriptionParams} UserRefundUserSubscriptionParams instance
             */
            UserRefundUserSubscriptionParams.create = function create(properties) {
                return new UserRefundUserSubscriptionParams(properties);
            };

            /**
             * Creates a UserRefundUserSubscriptionParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserRefundUserSubscriptionParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserRefundUserSubscriptionParams} UserRefundUserSubscriptionParams
             */
            UserRefundUserSubscriptionParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserRefundUserSubscriptionParams)
                    return d;
                var m = new $root.taxi.server.UserRefundUserSubscriptionParams();
                if (d.subscriptionId != null) {
                    m.subscriptionId = String(d.subscriptionId);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserRefundUserSubscriptionParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserRefundUserSubscriptionParams
             * @static
             * @param {taxi.server.UserRefundUserSubscriptionParams} m UserRefundUserSubscriptionParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserRefundUserSubscriptionParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.subscriptionId = "";
                }
                if (m.subscriptionId != null && m.hasOwnProperty("subscriptionId")) {
                    d.subscriptionId = m.subscriptionId;
                }
                return d;
            };

            /**
             * Converts this UserRefundUserSubscriptionParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserRefundUserSubscriptionParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserRefundUserSubscriptionParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserRefundUserSubscriptionParams;
        })();

        server.UserRefundUserSubscriptionResult = (function() {

            /**
             * Properties of a UserRefundUserSubscriptionResult.
             * @memberof taxi.server
             * @interface IUserRefundUserSubscriptionResult
             */

            /**
             * Constructs a new UserRefundUserSubscriptionResult.
             * @memberof taxi.server
             * @classdesc Represents a UserRefundUserSubscriptionResult.
             * @implements IUserRefundUserSubscriptionResult
             * @constructor
             * @param {taxi.server.IUserRefundUserSubscriptionResult=} [p] Properties to set
             */
            function UserRefundUserSubscriptionResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new UserRefundUserSubscriptionResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserRefundUserSubscriptionResult
             * @static
             * @param {taxi.server.IUserRefundUserSubscriptionResult=} [properties] Properties to set
             * @returns {taxi.server.UserRefundUserSubscriptionResult} UserRefundUserSubscriptionResult instance
             */
            UserRefundUserSubscriptionResult.create = function create(properties) {
                return new UserRefundUserSubscriptionResult(properties);
            };

            /**
             * Creates a UserRefundUserSubscriptionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserRefundUserSubscriptionResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserRefundUserSubscriptionResult} UserRefundUserSubscriptionResult
             */
            UserRefundUserSubscriptionResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserRefundUserSubscriptionResult)
                    return d;
                return new $root.taxi.server.UserRefundUserSubscriptionResult();
            };

            /**
             * Creates a plain object from a UserRefundUserSubscriptionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserRefundUserSubscriptionResult
             * @static
             * @param {taxi.server.UserRefundUserSubscriptionResult} m UserRefundUserSubscriptionResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserRefundUserSubscriptionResult.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UserRefundUserSubscriptionResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserRefundUserSubscriptionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserRefundUserSubscriptionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserRefundUserSubscriptionResult;
        })();

        server.UserUpdatePaymentMethodOfUserSubscriptionParams = (function() {

            /**
             * Properties of a UserUpdatePaymentMethodOfUserSubscriptionParams.
             * @memberof taxi.server
             * @interface IUserUpdatePaymentMethodOfUserSubscriptionParams
             * @property {string|null} [subscriptionId] UserUpdatePaymentMethodOfUserSubscriptionParams subscriptionId
             * @property {string|null} [paymentMethodId] UserUpdatePaymentMethodOfUserSubscriptionParams paymentMethodId
             */

            /**
             * Constructs a new UserUpdatePaymentMethodOfUserSubscriptionParams.
             * @memberof taxi.server
             * @classdesc Represents a UserUpdatePaymentMethodOfUserSubscriptionParams.
             * @implements IUserUpdatePaymentMethodOfUserSubscriptionParams
             * @constructor
             * @param {taxi.server.IUserUpdatePaymentMethodOfUserSubscriptionParams=} [p] Properties to set
             */
            function UserUpdatePaymentMethodOfUserSubscriptionParams(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserUpdatePaymentMethodOfUserSubscriptionParams subscriptionId.
             * @member {string} subscriptionId
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams
             * @instance
             */
            UserUpdatePaymentMethodOfUserSubscriptionParams.prototype.subscriptionId = "";

            /**
             * UserUpdatePaymentMethodOfUserSubscriptionParams paymentMethodId.
             * @member {string} paymentMethodId
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams
             * @instance
             */
            UserUpdatePaymentMethodOfUserSubscriptionParams.prototype.paymentMethodId = "";

            /**
             * Creates a new UserUpdatePaymentMethodOfUserSubscriptionParams instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams
             * @static
             * @param {taxi.server.IUserUpdatePaymentMethodOfUserSubscriptionParams=} [properties] Properties to set
             * @returns {taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams} UserUpdatePaymentMethodOfUserSubscriptionParams instance
             */
            UserUpdatePaymentMethodOfUserSubscriptionParams.create = function create(properties) {
                return new UserUpdatePaymentMethodOfUserSubscriptionParams(properties);
            };

            /**
             * Creates a UserUpdatePaymentMethodOfUserSubscriptionParams message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams} UserUpdatePaymentMethodOfUserSubscriptionParams
             */
            UserUpdatePaymentMethodOfUserSubscriptionParams.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams)
                    return d;
                var m = new $root.taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams();
                if (d.subscriptionId != null) {
                    m.subscriptionId = String(d.subscriptionId);
                }
                if (d.paymentMethodId != null) {
                    m.paymentMethodId = String(d.paymentMethodId);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserUpdatePaymentMethodOfUserSubscriptionParams message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams
             * @static
             * @param {taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams} m UserUpdatePaymentMethodOfUserSubscriptionParams
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserUpdatePaymentMethodOfUserSubscriptionParams.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.subscriptionId = "";
                    d.paymentMethodId = "";
                }
                if (m.subscriptionId != null && m.hasOwnProperty("subscriptionId")) {
                    d.subscriptionId = m.subscriptionId;
                }
                if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                    d.paymentMethodId = m.paymentMethodId;
                }
                return d;
            };

            /**
             * Converts this UserUpdatePaymentMethodOfUserSubscriptionParams to JSON.
             * @function toJSON
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionParams
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserUpdatePaymentMethodOfUserSubscriptionParams.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserUpdatePaymentMethodOfUserSubscriptionParams;
        })();

        server.UserUpdatePaymentMethodOfUserSubscriptionResult = (function() {

            /**
             * Properties of a UserUpdatePaymentMethodOfUserSubscriptionResult.
             * @memberof taxi.server
             * @interface IUserUpdatePaymentMethodOfUserSubscriptionResult
             * @property {taxi.IUserSubscriptionDTO|null} [subscription] UserUpdatePaymentMethodOfUserSubscriptionResult subscription
             */

            /**
             * Constructs a new UserUpdatePaymentMethodOfUserSubscriptionResult.
             * @memberof taxi.server
             * @classdesc Represents a UserUpdatePaymentMethodOfUserSubscriptionResult.
             * @implements IUserUpdatePaymentMethodOfUserSubscriptionResult
             * @constructor
             * @param {taxi.server.IUserUpdatePaymentMethodOfUserSubscriptionResult=} [p] Properties to set
             */
            function UserUpdatePaymentMethodOfUserSubscriptionResult(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserUpdatePaymentMethodOfUserSubscriptionResult subscription.
             * @member {taxi.IUserSubscriptionDTO|null|undefined} subscription
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult
             * @instance
             */
            UserUpdatePaymentMethodOfUserSubscriptionResult.prototype.subscription = null;

            /**
             * Creates a new UserUpdatePaymentMethodOfUserSubscriptionResult instance using the specified properties.
             * @function create
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult
             * @static
             * @param {taxi.server.IUserUpdatePaymentMethodOfUserSubscriptionResult=} [properties] Properties to set
             * @returns {taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult} UserUpdatePaymentMethodOfUserSubscriptionResult instance
             */
            UserUpdatePaymentMethodOfUserSubscriptionResult.create = function create(properties) {
                return new UserUpdatePaymentMethodOfUserSubscriptionResult(properties);
            };

            /**
             * Creates a UserUpdatePaymentMethodOfUserSubscriptionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult} UserUpdatePaymentMethodOfUserSubscriptionResult
             */
            UserUpdatePaymentMethodOfUserSubscriptionResult.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult)
                    return d;
                var m = new $root.taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult();
                if (d.subscription != null) {
                    if (typeof d.subscription !== "object")
                        throw TypeError(".taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult.subscription: object expected");
                    m.subscription = $root.taxi.UserSubscriptionDTO.fromObject(d.subscription);
                }
                return m;
            };

            /**
             * Creates a plain object from a UserUpdatePaymentMethodOfUserSubscriptionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult
             * @static
             * @param {taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult} m UserUpdatePaymentMethodOfUserSubscriptionResult
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserUpdatePaymentMethodOfUserSubscriptionResult.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.subscription = null;
                }
                if (m.subscription != null && m.hasOwnProperty("subscription")) {
                    d.subscription = $root.taxi.UserSubscriptionDTO.toObject(m.subscription, o);
                }
                return d;
            };

            /**
             * Converts this UserUpdatePaymentMethodOfUserSubscriptionResult to JSON.
             * @function toJSON
             * @memberof taxi.server.UserUpdatePaymentMethodOfUserSubscriptionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserUpdatePaymentMethodOfUserSubscriptionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UserUpdatePaymentMethodOfUserSubscriptionResult;
        })();

        return server;
    })();

    taxi.TripCancellationCause = (function() {

        /**
         * Properties of a TripCancellationCause.
         * @memberof taxi
         * @interface ITripCancellationCause
         * @property {taxi.TripCancellationCause.Value|null} [value] TripCancellationCause value
         */

        /**
         * Constructs a new TripCancellationCause.
         * @memberof taxi
         * @classdesc Represents a TripCancellationCause.
         * @implements ITripCancellationCause
         * @constructor
         * @param {taxi.ITripCancellationCause=} [p] Properties to set
         */
        function TripCancellationCause(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripCancellationCause value.
         * @member {taxi.TripCancellationCause.Value} value
         * @memberof taxi.TripCancellationCause
         * @instance
         */
        TripCancellationCause.prototype.value = 0;

        /**
         * Creates a new TripCancellationCause instance using the specified properties.
         * @function create
         * @memberof taxi.TripCancellationCause
         * @static
         * @param {taxi.ITripCancellationCause=} [properties] Properties to set
         * @returns {taxi.TripCancellationCause} TripCancellationCause instance
         */
        TripCancellationCause.create = function create(properties) {
            return new TripCancellationCause(properties);
        };

        /**
         * Creates a TripCancellationCause message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripCancellationCause
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripCancellationCause} TripCancellationCause
         */
        TripCancellationCause.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripCancellationCause)
                return d;
            var m = new $root.taxi.TripCancellationCause();
            switch (d.value) {
            case "UNSPECIFIED":
            case 0:
                m.value = 0;
                break;
            case "DRIVER_CANCELLED":
            case 1:
                m.value = 1;
                break;
            case "RIDER_CANCELLED":
            case 2:
                m.value = 2;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a TripCancellationCause message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripCancellationCause
         * @static
         * @param {taxi.TripCancellationCause} m TripCancellationCause
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripCancellationCause.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = o.enums === String ? "UNSPECIFIED" : 0;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = o.enums === String ? $root.taxi.TripCancellationCause.Value[m.value] : m.value;
            }
            return d;
        };

        /**
         * Converts this TripCancellationCause to JSON.
         * @function toJSON
         * @memberof taxi.TripCancellationCause
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripCancellationCause.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Value enum.
         * @name taxi.TripCancellationCause.Value
         * @enum {string}
         * @property {number} UNSPECIFIED=0 UNSPECIFIED value
         * @property {number} DRIVER_CANCELLED=1 DRIVER_CANCELLED value
         * @property {number} RIDER_CANCELLED=2 RIDER_CANCELLED value
         */
        TripCancellationCause.Value = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSPECIFIED"] = 0;
            values[valuesById[1] = "DRIVER_CANCELLED"] = 1;
            values[valuesById[2] = "RIDER_CANCELLED"] = 2;
            return values;
        })();

        return TripCancellationCause;
    })();

    taxi.TripTypeDescription = (function() {

        /**
         * Properties of a TripTypeDescription.
         * @memberof taxi
         * @interface ITripTypeDescription
         * @property {taxi.TripTypeDescription.TripType|null} [value] TripTypeDescription value
         * @property {string|null} [name] TripTypeDescription name
         */

        /**
         * Constructs a new TripTypeDescription.
         * @memberof taxi
         * @classdesc Represents a TripTypeDescription.
         * @implements ITripTypeDescription
         * @constructor
         * @param {taxi.ITripTypeDescription=} [p] Properties to set
         */
        function TripTypeDescription(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripTypeDescription value.
         * @member {taxi.TripTypeDescription.TripType} value
         * @memberof taxi.TripTypeDescription
         * @instance
         */
        TripTypeDescription.prototype.value = 0;

        /**
         * TripTypeDescription name.
         * @member {string} name
         * @memberof taxi.TripTypeDescription
         * @instance
         */
        TripTypeDescription.prototype.name = "";

        /**
         * Creates a new TripTypeDescription instance using the specified properties.
         * @function create
         * @memberof taxi.TripTypeDescription
         * @static
         * @param {taxi.ITripTypeDescription=} [properties] Properties to set
         * @returns {taxi.TripTypeDescription} TripTypeDescription instance
         */
        TripTypeDescription.create = function create(properties) {
            return new TripTypeDescription(properties);
        };

        /**
         * Creates a TripTypeDescription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripTypeDescription
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripTypeDescription} TripTypeDescription
         */
        TripTypeDescription.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripTypeDescription)
                return d;
            var m = new $root.taxi.TripTypeDescription();
            switch (d.value) {
            case "UNSPECIFIED":
            case 0:
                m.value = 0;
                break;
            case "AIR":
            case 1:
                m.value = 1;
                break;
            case "CHARTER":
            case 2:
                m.value = 2;
                break;
            case "VAN":
            case 3:
                m.value = 3;
                break;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripTypeDescription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripTypeDescription
         * @static
         * @param {taxi.TripTypeDescription} m TripTypeDescription
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripTypeDescription.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = o.enums === String ? "UNSPECIFIED" : 0;
                d.name = "";
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = o.enums === String ? $root.taxi.TripTypeDescription.TripType[m.value] : m.value;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        /**
         * Converts this TripTypeDescription to JSON.
         * @function toJSON
         * @memberof taxi.TripTypeDescription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripTypeDescription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * TripType enum.
         * @name taxi.TripTypeDescription.TripType
         * @enum {string}
         * @property {number} UNSPECIFIED=0 UNSPECIFIED value
         * @property {number} AIR=1 AIR value
         * @property {number} CHARTER=2 CHARTER value
         * @property {number} VAN=3 VAN value
         */
        TripTypeDescription.TripType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSPECIFIED"] = 0;
            values[valuesById[1] = "AIR"] = 1;
            values[valuesById[2] = "CHARTER"] = 2;
            values[valuesById[3] = "VAN"] = 3;
            return values;
        })();

        return TripTypeDescription;
    })();

    taxi.TripReceipt = (function() {

        /**
         * Properties of a TripReceipt.
         * @memberof taxi
         * @interface ITripReceipt
         * @property {Array.<taxi.TripReceipt.ILineItem>|null} [lineItems] TripReceipt lineItems
         * @property {taxi.IInt32Value|null} [totalPriceKRW] TripReceipt totalPriceKRW
         * @property {taxi.IInt32Value|null} [driverProfit_KRW] TripReceipt driverProfit_KRW
         * @property {taxi.IStringValue|null} [cancellationFeeDescription] TripReceipt cancellationFeeDescription
         */

        /**
         * Constructs a new TripReceipt.
         * @memberof taxi
         * @classdesc Represents a TripReceipt.
         * @implements ITripReceipt
         * @constructor
         * @param {taxi.ITripReceipt=} [p] Properties to set
         */
        function TripReceipt(p) {
            this.lineItems = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripReceipt lineItems.
         * @member {Array.<taxi.TripReceipt.ILineItem>} lineItems
         * @memberof taxi.TripReceipt
         * @instance
         */
        TripReceipt.prototype.lineItems = $util.emptyArray;

        /**
         * TripReceipt totalPriceKRW.
         * @member {taxi.IInt32Value|null|undefined} totalPriceKRW
         * @memberof taxi.TripReceipt
         * @instance
         */
        TripReceipt.prototype.totalPriceKRW = null;

        /**
         * TripReceipt driverProfit_KRW.
         * @member {taxi.IInt32Value|null|undefined} driverProfit_KRW
         * @memberof taxi.TripReceipt
         * @instance
         */
        TripReceipt.prototype.driverProfit_KRW = null;

        /**
         * TripReceipt cancellationFeeDescription.
         * @member {taxi.IStringValue|null|undefined} cancellationFeeDescription
         * @memberof taxi.TripReceipt
         * @instance
         */
        TripReceipt.prototype.cancellationFeeDescription = null;

        /**
         * Creates a new TripReceipt instance using the specified properties.
         * @function create
         * @memberof taxi.TripReceipt
         * @static
         * @param {taxi.ITripReceipt=} [properties] Properties to set
         * @returns {taxi.TripReceipt} TripReceipt instance
         */
        TripReceipt.create = function create(properties) {
            return new TripReceipt(properties);
        };

        /**
         * Creates a TripReceipt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripReceipt
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripReceipt} TripReceipt
         */
        TripReceipt.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripReceipt)
                return d;
            var m = new $root.taxi.TripReceipt();
            if (d.lineItems) {
                if (!Array.isArray(d.lineItems))
                    throw TypeError(".taxi.TripReceipt.lineItems: array expected");
                m.lineItems = [];
                for (var i = 0; i < d.lineItems.length; ++i) {
                    if (typeof d.lineItems[i] !== "object")
                        throw TypeError(".taxi.TripReceipt.lineItems: object expected");
                    m.lineItems[i] = $root.taxi.TripReceipt.LineItem.fromObject(d.lineItems[i]);
                }
            }
            if (d.totalPriceKRW != null) {
                if (typeof d.totalPriceKRW !== "object")
                    throw TypeError(".taxi.TripReceipt.totalPriceKRW: object expected");
                m.totalPriceKRW = $root.taxi.Int32Value.fromObject(d.totalPriceKRW);
            }
            if (d.driverProfit_KRW != null) {
                if (typeof d.driverProfit_KRW !== "object")
                    throw TypeError(".taxi.TripReceipt.driverProfit_KRW: object expected");
                m.driverProfit_KRW = $root.taxi.Int32Value.fromObject(d.driverProfit_KRW);
            }
            if (d.cancellationFeeDescription != null) {
                if (typeof d.cancellationFeeDescription !== "object")
                    throw TypeError(".taxi.TripReceipt.cancellationFeeDescription: object expected");
                m.cancellationFeeDescription = $root.taxi.StringValue.fromObject(d.cancellationFeeDescription);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripReceipt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripReceipt
         * @static
         * @param {taxi.TripReceipt} m TripReceipt
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripReceipt.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.lineItems = [];
            }
            if (o.defaults) {
                d.totalPriceKRW = null;
                d.driverProfit_KRW = null;
                d.cancellationFeeDescription = null;
            }
            if (m.lineItems && m.lineItems.length) {
                d.lineItems = [];
                for (var j = 0; j < m.lineItems.length; ++j) {
                    d.lineItems[j] = $root.taxi.TripReceipt.LineItem.toObject(m.lineItems[j], o);
                }
            }
            if (m.totalPriceKRW != null && m.hasOwnProperty("totalPriceKRW")) {
                d.totalPriceKRW = $root.taxi.Int32Value.toObject(m.totalPriceKRW, o);
            }
            if (m.driverProfit_KRW != null && m.hasOwnProperty("driverProfit_KRW")) {
                d.driverProfit_KRW = $root.taxi.Int32Value.toObject(m.driverProfit_KRW, o);
            }
            if (m.cancellationFeeDescription != null && m.hasOwnProperty("cancellationFeeDescription")) {
                d.cancellationFeeDescription = $root.taxi.StringValue.toObject(m.cancellationFeeDescription, o);
            }
            return d;
        };

        /**
         * Converts this TripReceipt to JSON.
         * @function toJSON
         * @memberof taxi.TripReceipt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripReceipt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TripReceipt.LineItem = (function() {

            /**
             * Properties of a LineItem.
             * @memberof taxi.TripReceipt
             * @interface ILineItem
             * @property {string|null} [name] LineItem name
             * @property {number|null} [amountKRW] LineItem amountKRW
             * @property {taxi.IInt32Value|null} [amountKRWValue] LineItem amountKRWValue
             */

            /**
             * Constructs a new LineItem.
             * @memberof taxi.TripReceipt
             * @classdesc Represents a LineItem.
             * @implements ILineItem
             * @constructor
             * @param {taxi.TripReceipt.ILineItem=} [p] Properties to set
             */
            function LineItem(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LineItem name.
             * @member {string} name
             * @memberof taxi.TripReceipt.LineItem
             * @instance
             */
            LineItem.prototype.name = "";

            /**
             * LineItem amountKRW.
             * @member {number} amountKRW
             * @memberof taxi.TripReceipt.LineItem
             * @instance
             */
            LineItem.prototype.amountKRW = 0;

            /**
             * LineItem amountKRWValue.
             * @member {taxi.IInt32Value|null|undefined} amountKRWValue
             * @memberof taxi.TripReceipt.LineItem
             * @instance
             */
            LineItem.prototype.amountKRWValue = null;

            /**
             * Creates a new LineItem instance using the specified properties.
             * @function create
             * @memberof taxi.TripReceipt.LineItem
             * @static
             * @param {taxi.TripReceipt.ILineItem=} [properties] Properties to set
             * @returns {taxi.TripReceipt.LineItem} LineItem instance
             */
            LineItem.create = function create(properties) {
                return new LineItem(properties);
            };

            /**
             * Creates a LineItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.TripReceipt.LineItem
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.TripReceipt.LineItem} LineItem
             */
            LineItem.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.TripReceipt.LineItem)
                    return d;
                var m = new $root.taxi.TripReceipt.LineItem();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.amountKRW != null) {
                    m.amountKRW = d.amountKRW | 0;
                }
                if (d.amountKRWValue != null) {
                    if (typeof d.amountKRWValue !== "object")
                        throw TypeError(".taxi.TripReceipt.LineItem.amountKRWValue: object expected");
                    m.amountKRWValue = $root.taxi.Int32Value.fromObject(d.amountKRWValue);
                }
                return m;
            };

            /**
             * Creates a plain object from a LineItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.TripReceipt.LineItem
             * @static
             * @param {taxi.TripReceipt.LineItem} m LineItem
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LineItem.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.amountKRW = 0;
                    d.amountKRWValue = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.amountKRW != null && m.hasOwnProperty("amountKRW")) {
                    d.amountKRW = m.amountKRW;
                }
                if (m.amountKRWValue != null && m.hasOwnProperty("amountKRWValue")) {
                    d.amountKRWValue = $root.taxi.Int32Value.toObject(m.amountKRWValue, o);
                }
                return d;
            };

            /**
             * Converts this LineItem to JSON.
             * @function toJSON
             * @memberof taxi.TripReceipt.LineItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LineItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LineItem;
        })();

        return TripReceipt;
    })();

    taxi.TripRoute = (function() {

        /**
         * Properties of a TripRoute.
         * @memberof taxi
         * @interface ITripRoute
         * @property {number|null} [durationSeconds] TripRoute durationSeconds
         * @property {number|null} [distanceMeters] TripRoute distanceMeters
         */

        /**
         * Constructs a new TripRoute.
         * @memberof taxi
         * @classdesc Represents a TripRoute.
         * @implements ITripRoute
         * @constructor
         * @param {taxi.ITripRoute=} [p] Properties to set
         */
        function TripRoute(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripRoute durationSeconds.
         * @member {number} durationSeconds
         * @memberof taxi.TripRoute
         * @instance
         */
        TripRoute.prototype.durationSeconds = 0;

        /**
         * TripRoute distanceMeters.
         * @member {number} distanceMeters
         * @memberof taxi.TripRoute
         * @instance
         */
        TripRoute.prototype.distanceMeters = 0;

        /**
         * Creates a new TripRoute instance using the specified properties.
         * @function create
         * @memberof taxi.TripRoute
         * @static
         * @param {taxi.ITripRoute=} [properties] Properties to set
         * @returns {taxi.TripRoute} TripRoute instance
         */
        TripRoute.create = function create(properties) {
            return new TripRoute(properties);
        };

        /**
         * Creates a TripRoute message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripRoute
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripRoute} TripRoute
         */
        TripRoute.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripRoute)
                return d;
            var m = new $root.taxi.TripRoute();
            if (d.durationSeconds != null) {
                m.durationSeconds = d.durationSeconds | 0;
            }
            if (d.distanceMeters != null) {
                m.distanceMeters = d.distanceMeters | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a TripRoute message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripRoute
         * @static
         * @param {taxi.TripRoute} m TripRoute
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripRoute.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.durationSeconds = 0;
                d.distanceMeters = 0;
            }
            if (m.durationSeconds != null && m.hasOwnProperty("durationSeconds")) {
                d.durationSeconds = m.durationSeconds;
            }
            if (m.distanceMeters != null && m.hasOwnProperty("distanceMeters")) {
                d.distanceMeters = m.distanceMeters;
            }
            return d;
        };

        /**
         * Converts this TripRoute to JSON.
         * @function toJSON
         * @memberof taxi.TripRoute
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripRoute.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TripRoute;
    })();

    taxi.TripDTO = (function() {

        /**
         * Properties of a TripDTO.
         * @memberof taxi
         * @interface ITripDTO
         * @property {string|null} [id] TripDTO id
         * @property {taxi.ITripTypeDescription|null} [typeDescription] TripDTO typeDescription
         * @property {taxi.TripDTO.TripRideStatus|null} [tripRideStatus] TripDTO tripRideStatus
         * @property {number|null} [version] TripDTO version
         * @property {number|null} [sequenceNumber] TripDTO sequenceNumber
         * @property {Array.<taxi.TripDTO.IDetailItem>|null} [detailItems] TripDTO detailItems
         * @property {taxi.IStringValue|null} [comment] TripDTO comment
         * @property {taxi.IStringValue|null} [waypoint] TripDTO waypoint
         * @property {taxi.IAddressedLocation|null} [origin] TripDTO origin
         * @property {taxi.IStringValue|null} [originDetails] TripDTO originDetails
         * @property {taxi.IAddressedLocation|null} [pickup] TripDTO pickup
         * @property {taxi.IAddressedLocation|null} [destination] TripDTO destination
         * @property {taxi.IStringValue|null} [destinationDetails] TripDTO destinationDetails
         * @property {taxi.IAddressedLocation|null} [dropoff] TripDTO dropoff
         * @property {taxi.ITripCancellationCause|null} [cancellationCause] TripDTO cancellationCause
         * @property {taxi.IUserDTO|null} [rider] TripDTO rider
         * @property {taxi.IDriverDTO|null} [driver] TripDTO driver
         * @property {taxi.IStringValue|null} [riderRealPhoneNumber] TripDTO riderRealPhoneNumber
         * @property {taxi.ITimestamp|null} [acceptedAt] TripDTO acceptedAt
         * @property {taxi.ITimestamp|null} [arrivedAt] TripDTO arrivedAt
         * @property {taxi.ITimestamp|null} [pickUpScheduledAt] TripDTO pickUpScheduledAt
         * @property {taxi.ITimestamp|null} [pickedUpAt] TripDTO pickedUpAt
         * @property {taxi.ITimestamp|null} [droppedOffAt] TripDTO droppedOffAt
         * @property {taxi.ITimestamp|null} [approachingDestinationSince] TripDTO approachingDestinationSince
         * @property {taxi.ITimestamp|null} [leavingOriginSince] TripDTO leavingOriginSince
         * @property {taxi.ITripRoute|null} [route] TripDTO route
         * @property {taxi.ITripReceipt|null} [receipt] TripDTO receipt
         * @property {taxi.IPaymentMethodDTO|null} [paymentMethod] TripDTO paymentMethod
         * @property {taxi.IPaymentProfileDTO|null} [paymentProfile] TripDTO paymentProfile
         * @property {taxi.IInt32Value|null} [km] TripDTO km
         */

        /**
         * Constructs a new TripDTO.
         * @memberof taxi
         * @classdesc Represents a TripDTO.
         * @implements ITripDTO
         * @constructor
         * @param {taxi.ITripDTO=} [p] Properties to set
         */
        function TripDTO(p) {
            this.detailItems = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripDTO id.
         * @member {string} id
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.id = "";

        /**
         * TripDTO typeDescription.
         * @member {taxi.ITripTypeDescription|null|undefined} typeDescription
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.typeDescription = null;

        /**
         * TripDTO tripRideStatus.
         * @member {taxi.TripDTO.TripRideStatus} tripRideStatus
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.tripRideStatus = 0;

        /**
         * TripDTO version.
         * @member {number} version
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.version = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TripDTO sequenceNumber.
         * @member {number} sequenceNumber
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.sequenceNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TripDTO detailItems.
         * @member {Array.<taxi.TripDTO.IDetailItem>} detailItems
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.detailItems = $util.emptyArray;

        /**
         * TripDTO comment.
         * @member {taxi.IStringValue|null|undefined} comment
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.comment = null;

        /**
         * TripDTO waypoint.
         * @member {taxi.IStringValue|null|undefined} waypoint
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.waypoint = null;

        /**
         * TripDTO origin.
         * @member {taxi.IAddressedLocation|null|undefined} origin
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.origin = null;

        /**
         * TripDTO originDetails.
         * @member {taxi.IStringValue|null|undefined} originDetails
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.originDetails = null;

        /**
         * TripDTO pickup.
         * @member {taxi.IAddressedLocation|null|undefined} pickup
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.pickup = null;

        /**
         * TripDTO destination.
         * @member {taxi.IAddressedLocation|null|undefined} destination
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.destination = null;

        /**
         * TripDTO destinationDetails.
         * @member {taxi.IStringValue|null|undefined} destinationDetails
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.destinationDetails = null;

        /**
         * TripDTO dropoff.
         * @member {taxi.IAddressedLocation|null|undefined} dropoff
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.dropoff = null;

        /**
         * TripDTO cancellationCause.
         * @member {taxi.ITripCancellationCause|null|undefined} cancellationCause
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.cancellationCause = null;

        /**
         * TripDTO rider.
         * @member {taxi.IUserDTO|null|undefined} rider
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.rider = null;

        /**
         * TripDTO driver.
         * @member {taxi.IDriverDTO|null|undefined} driver
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.driver = null;

        /**
         * TripDTO riderRealPhoneNumber.
         * @member {taxi.IStringValue|null|undefined} riderRealPhoneNumber
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.riderRealPhoneNumber = null;

        /**
         * TripDTO acceptedAt.
         * @member {taxi.ITimestamp|null|undefined} acceptedAt
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.acceptedAt = null;

        /**
         * TripDTO arrivedAt.
         * @member {taxi.ITimestamp|null|undefined} arrivedAt
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.arrivedAt = null;

        /**
         * TripDTO pickUpScheduledAt.
         * @member {taxi.ITimestamp|null|undefined} pickUpScheduledAt
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.pickUpScheduledAt = null;

        /**
         * TripDTO pickedUpAt.
         * @member {taxi.ITimestamp|null|undefined} pickedUpAt
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.pickedUpAt = null;

        /**
         * TripDTO droppedOffAt.
         * @member {taxi.ITimestamp|null|undefined} droppedOffAt
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.droppedOffAt = null;

        /**
         * TripDTO approachingDestinationSince.
         * @member {taxi.ITimestamp|null|undefined} approachingDestinationSince
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.approachingDestinationSince = null;

        /**
         * TripDTO leavingOriginSince.
         * @member {taxi.ITimestamp|null|undefined} leavingOriginSince
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.leavingOriginSince = null;

        /**
         * TripDTO route.
         * @member {taxi.ITripRoute|null|undefined} route
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.route = null;

        /**
         * TripDTO receipt.
         * @member {taxi.ITripReceipt|null|undefined} receipt
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.receipt = null;

        /**
         * TripDTO paymentMethod.
         * @member {taxi.IPaymentMethodDTO|null|undefined} paymentMethod
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.paymentMethod = null;

        /**
         * TripDTO paymentProfile.
         * @member {taxi.IPaymentProfileDTO|null|undefined} paymentProfile
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.paymentProfile = null;

        /**
         * TripDTO km.
         * @member {taxi.IInt32Value|null|undefined} km
         * @memberof taxi.TripDTO
         * @instance
         */
        TripDTO.prototype.km = null;

        /**
         * Creates a new TripDTO instance using the specified properties.
         * @function create
         * @memberof taxi.TripDTO
         * @static
         * @param {taxi.ITripDTO=} [properties] Properties to set
         * @returns {taxi.TripDTO} TripDTO instance
         */
        TripDTO.create = function create(properties) {
            return new TripDTO(properties);
        };

        /**
         * Creates a TripDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripDTO} TripDTO
         */
        TripDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripDTO)
                return d;
            var m = new $root.taxi.TripDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.typeDescription != null) {
                if (typeof d.typeDescription !== "object")
                    throw TypeError(".taxi.TripDTO.typeDescription: object expected");
                m.typeDescription = $root.taxi.TripTypeDescription.fromObject(d.typeDescription);
            }
            switch (d.tripRideStatus) {
            case "UNSPECIFIED":
            case 0:
                m.tripRideStatus = 0;
                break;
            case "PENDING":
            case 1:
                m.tripRideStatus = 1;
                break;
            case "ACCEPTED":
            case 2:
                m.tripRideStatus = 2;
                break;
            case "ARRIVED":
            case 3:
                m.tripRideStatus = 3;
                break;
            case "PICKED_UP":
            case 4:
                m.tripRideStatus = 4;
                break;
            case "DROPPED_OFF":
            case 5:
                m.tripRideStatus = 5;
                break;
            case "CANCELED":
            case 6:
                m.tripRideStatus = 6;
                break;
            }
            if (d.version != null) {
                if ($util.Long)
                    (m.version = $util.Long.fromValue(d.version)).unsigned = false;
                else if (typeof d.version === "string")
                    m.version = parseInt(d.version, 10);
                else if (typeof d.version === "number")
                    m.version = d.version;
                else if (typeof d.version === "object")
                    m.version = new $util.LongBits(d.version.low >>> 0, d.version.high >>> 0).toNumber();
            }
            if (d.sequenceNumber != null) {
                if ($util.Long)
                    (m.sequenceNumber = $util.Long.fromValue(d.sequenceNumber)).unsigned = false;
                else if (typeof d.sequenceNumber === "string")
                    m.sequenceNumber = parseInt(d.sequenceNumber, 10);
                else if (typeof d.sequenceNumber === "number")
                    m.sequenceNumber = d.sequenceNumber;
                else if (typeof d.sequenceNumber === "object")
                    m.sequenceNumber = new $util.LongBits(d.sequenceNumber.low >>> 0, d.sequenceNumber.high >>> 0).toNumber();
            }
            if (d.detailItems) {
                if (!Array.isArray(d.detailItems))
                    throw TypeError(".taxi.TripDTO.detailItems: array expected");
                m.detailItems = [];
                for (var i = 0; i < d.detailItems.length; ++i) {
                    if (typeof d.detailItems[i] !== "object")
                        throw TypeError(".taxi.TripDTO.detailItems: object expected");
                    m.detailItems[i] = $root.taxi.TripDTO.DetailItem.fromObject(d.detailItems[i]);
                }
            }
            if (d.comment != null) {
                if (typeof d.comment !== "object")
                    throw TypeError(".taxi.TripDTO.comment: object expected");
                m.comment = $root.taxi.StringValue.fromObject(d.comment);
            }
            if (d.waypoint != null) {
                if (typeof d.waypoint !== "object")
                    throw TypeError(".taxi.TripDTO.waypoint: object expected");
                m.waypoint = $root.taxi.StringValue.fromObject(d.waypoint);
            }
            if (d.origin != null) {
                if (typeof d.origin !== "object")
                    throw TypeError(".taxi.TripDTO.origin: object expected");
                m.origin = $root.taxi.AddressedLocation.fromObject(d.origin);
            }
            if (d.originDetails != null) {
                if (typeof d.originDetails !== "object")
                    throw TypeError(".taxi.TripDTO.originDetails: object expected");
                m.originDetails = $root.taxi.StringValue.fromObject(d.originDetails);
            }
            if (d.pickup != null) {
                if (typeof d.pickup !== "object")
                    throw TypeError(".taxi.TripDTO.pickup: object expected");
                m.pickup = $root.taxi.AddressedLocation.fromObject(d.pickup);
            }
            if (d.destination != null) {
                if (typeof d.destination !== "object")
                    throw TypeError(".taxi.TripDTO.destination: object expected");
                m.destination = $root.taxi.AddressedLocation.fromObject(d.destination);
            }
            if (d.destinationDetails != null) {
                if (typeof d.destinationDetails !== "object")
                    throw TypeError(".taxi.TripDTO.destinationDetails: object expected");
                m.destinationDetails = $root.taxi.StringValue.fromObject(d.destinationDetails);
            }
            if (d.dropoff != null) {
                if (typeof d.dropoff !== "object")
                    throw TypeError(".taxi.TripDTO.dropoff: object expected");
                m.dropoff = $root.taxi.AddressedLocation.fromObject(d.dropoff);
            }
            if (d.cancellationCause != null) {
                if (typeof d.cancellationCause !== "object")
                    throw TypeError(".taxi.TripDTO.cancellationCause: object expected");
                m.cancellationCause = $root.taxi.TripCancellationCause.fromObject(d.cancellationCause);
            }
            if (d.rider != null) {
                if (typeof d.rider !== "object")
                    throw TypeError(".taxi.TripDTO.rider: object expected");
                m.rider = $root.taxi.UserDTO.fromObject(d.rider);
            }
            if (d.driver != null) {
                if (typeof d.driver !== "object")
                    throw TypeError(".taxi.TripDTO.driver: object expected");
                m.driver = $root.taxi.DriverDTO.fromObject(d.driver);
            }
            if (d.riderRealPhoneNumber != null) {
                if (typeof d.riderRealPhoneNumber !== "object")
                    throw TypeError(".taxi.TripDTO.riderRealPhoneNumber: object expected");
                m.riderRealPhoneNumber = $root.taxi.StringValue.fromObject(d.riderRealPhoneNumber);
            }
            if (d.acceptedAt != null) {
                if (typeof d.acceptedAt !== "object")
                    throw TypeError(".taxi.TripDTO.acceptedAt: object expected");
                m.acceptedAt = $root.taxi.Timestamp.fromObject(d.acceptedAt);
            }
            if (d.arrivedAt != null) {
                if (typeof d.arrivedAt !== "object")
                    throw TypeError(".taxi.TripDTO.arrivedAt: object expected");
                m.arrivedAt = $root.taxi.Timestamp.fromObject(d.arrivedAt);
            }
            if (d.pickUpScheduledAt != null) {
                if (typeof d.pickUpScheduledAt !== "object")
                    throw TypeError(".taxi.TripDTO.pickUpScheduledAt: object expected");
                m.pickUpScheduledAt = $root.taxi.Timestamp.fromObject(d.pickUpScheduledAt);
            }
            if (d.pickedUpAt != null) {
                if (typeof d.pickedUpAt !== "object")
                    throw TypeError(".taxi.TripDTO.pickedUpAt: object expected");
                m.pickedUpAt = $root.taxi.Timestamp.fromObject(d.pickedUpAt);
            }
            if (d.droppedOffAt != null) {
                if (typeof d.droppedOffAt !== "object")
                    throw TypeError(".taxi.TripDTO.droppedOffAt: object expected");
                m.droppedOffAt = $root.taxi.Timestamp.fromObject(d.droppedOffAt);
            }
            if (d.approachingDestinationSince != null) {
                if (typeof d.approachingDestinationSince !== "object")
                    throw TypeError(".taxi.TripDTO.approachingDestinationSince: object expected");
                m.approachingDestinationSince = $root.taxi.Timestamp.fromObject(d.approachingDestinationSince);
            }
            if (d.leavingOriginSince != null) {
                if (typeof d.leavingOriginSince !== "object")
                    throw TypeError(".taxi.TripDTO.leavingOriginSince: object expected");
                m.leavingOriginSince = $root.taxi.Timestamp.fromObject(d.leavingOriginSince);
            }
            if (d.route != null) {
                if (typeof d.route !== "object")
                    throw TypeError(".taxi.TripDTO.route: object expected");
                m.route = $root.taxi.TripRoute.fromObject(d.route);
            }
            if (d.receipt != null) {
                if (typeof d.receipt !== "object")
                    throw TypeError(".taxi.TripDTO.receipt: object expected");
                m.receipt = $root.taxi.TripReceipt.fromObject(d.receipt);
            }
            if (d.paymentMethod != null) {
                if (typeof d.paymentMethod !== "object")
                    throw TypeError(".taxi.TripDTO.paymentMethod: object expected");
                m.paymentMethod = $root.taxi.PaymentMethodDTO.fromObject(d.paymentMethod);
            }
            if (d.paymentProfile != null) {
                if (typeof d.paymentProfile !== "object")
                    throw TypeError(".taxi.TripDTO.paymentProfile: object expected");
                m.paymentProfile = $root.taxi.PaymentProfileDTO.fromObject(d.paymentProfile);
            }
            if (d.km != null) {
                if (typeof d.km !== "object")
                    throw TypeError(".taxi.TripDTO.km: object expected");
                m.km = $root.taxi.Int32Value.fromObject(d.km);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripDTO
         * @static
         * @param {taxi.TripDTO} m TripDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.detailItems = [];
            }
            if (o.defaults) {
                d.id = "";
                d.typeDescription = null;
                d.tripRideStatus = o.enums === String ? "UNSPECIFIED" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.version = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.version = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.sequenceNumber = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.sequenceNumber = o.longs === String ? "0" : 0;
                d.comment = null;
                d.waypoint = null;
                d.origin = null;
                d.originDetails = null;
                d.pickup = null;
                d.destination = null;
                d.destinationDetails = null;
                d.dropoff = null;
                d.cancellationCause = null;
                d.rider = null;
                d.riderRealPhoneNumber = null;
                d.driver = null;
                d.acceptedAt = null;
                d.arrivedAt = null;
                d.pickUpScheduledAt = null;
                d.pickedUpAt = null;
                d.droppedOffAt = null;
                d.approachingDestinationSince = null;
                d.leavingOriginSince = null;
                d.route = null;
                d.receipt = null;
                d.paymentMethod = null;
                d.paymentProfile = null;
                d.km = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.typeDescription != null && m.hasOwnProperty("typeDescription")) {
                d.typeDescription = $root.taxi.TripTypeDescription.toObject(m.typeDescription, o);
            }
            if (m.tripRideStatus != null && m.hasOwnProperty("tripRideStatus")) {
                d.tripRideStatus = o.enums === String ? $root.taxi.TripDTO.TripRideStatus[m.tripRideStatus] : m.tripRideStatus;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                if (typeof m.version === "number")
                    d.version = o.longs === String ? String(m.version) : m.version;
                else
                    d.version = o.longs === String ? $util.Long.prototype.toString.call(m.version) : o.longs === Number ? new $util.LongBits(m.version.low >>> 0, m.version.high >>> 0).toNumber() : m.version;
            }
            if (m.sequenceNumber != null && m.hasOwnProperty("sequenceNumber")) {
                if (typeof m.sequenceNumber === "number")
                    d.sequenceNumber = o.longs === String ? String(m.sequenceNumber) : m.sequenceNumber;
                else
                    d.sequenceNumber = o.longs === String ? $util.Long.prototype.toString.call(m.sequenceNumber) : o.longs === Number ? new $util.LongBits(m.sequenceNumber.low >>> 0, m.sequenceNumber.high >>> 0).toNumber() : m.sequenceNumber;
            }
            if (m.detailItems && m.detailItems.length) {
                d.detailItems = [];
                for (var j = 0; j < m.detailItems.length; ++j) {
                    d.detailItems[j] = $root.taxi.TripDTO.DetailItem.toObject(m.detailItems[j], o);
                }
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = $root.taxi.StringValue.toObject(m.comment, o);
            }
            if (m.waypoint != null && m.hasOwnProperty("waypoint")) {
                d.waypoint = $root.taxi.StringValue.toObject(m.waypoint, o);
            }
            if (m.origin != null && m.hasOwnProperty("origin")) {
                d.origin = $root.taxi.AddressedLocation.toObject(m.origin, o);
            }
            if (m.originDetails != null && m.hasOwnProperty("originDetails")) {
                d.originDetails = $root.taxi.StringValue.toObject(m.originDetails, o);
            }
            if (m.pickup != null && m.hasOwnProperty("pickup")) {
                d.pickup = $root.taxi.AddressedLocation.toObject(m.pickup, o);
            }
            if (m.destination != null && m.hasOwnProperty("destination")) {
                d.destination = $root.taxi.AddressedLocation.toObject(m.destination, o);
            }
            if (m.destinationDetails != null && m.hasOwnProperty("destinationDetails")) {
                d.destinationDetails = $root.taxi.StringValue.toObject(m.destinationDetails, o);
            }
            if (m.dropoff != null && m.hasOwnProperty("dropoff")) {
                d.dropoff = $root.taxi.AddressedLocation.toObject(m.dropoff, o);
            }
            if (m.cancellationCause != null && m.hasOwnProperty("cancellationCause")) {
                d.cancellationCause = $root.taxi.TripCancellationCause.toObject(m.cancellationCause, o);
            }
            if (m.rider != null && m.hasOwnProperty("rider")) {
                d.rider = $root.taxi.UserDTO.toObject(m.rider, o);
            }
            if (m.riderRealPhoneNumber != null && m.hasOwnProperty("riderRealPhoneNumber")) {
                d.riderRealPhoneNumber = $root.taxi.StringValue.toObject(m.riderRealPhoneNumber, o);
            }
            if (m.driver != null && m.hasOwnProperty("driver")) {
                d.driver = $root.taxi.DriverDTO.toObject(m.driver, o);
            }
            if (m.acceptedAt != null && m.hasOwnProperty("acceptedAt")) {
                d.acceptedAt = $root.taxi.Timestamp.toObject(m.acceptedAt, o);
            }
            if (m.arrivedAt != null && m.hasOwnProperty("arrivedAt")) {
                d.arrivedAt = $root.taxi.Timestamp.toObject(m.arrivedAt, o);
            }
            if (m.pickUpScheduledAt != null && m.hasOwnProperty("pickUpScheduledAt")) {
                d.pickUpScheduledAt = $root.taxi.Timestamp.toObject(m.pickUpScheduledAt, o);
            }
            if (m.pickedUpAt != null && m.hasOwnProperty("pickedUpAt")) {
                d.pickedUpAt = $root.taxi.Timestamp.toObject(m.pickedUpAt, o);
            }
            if (m.droppedOffAt != null && m.hasOwnProperty("droppedOffAt")) {
                d.droppedOffAt = $root.taxi.Timestamp.toObject(m.droppedOffAt, o);
            }
            if (m.approachingDestinationSince != null && m.hasOwnProperty("approachingDestinationSince")) {
                d.approachingDestinationSince = $root.taxi.Timestamp.toObject(m.approachingDestinationSince, o);
            }
            if (m.leavingOriginSince != null && m.hasOwnProperty("leavingOriginSince")) {
                d.leavingOriginSince = $root.taxi.Timestamp.toObject(m.leavingOriginSince, o);
            }
            if (m.route != null && m.hasOwnProperty("route")) {
                d.route = $root.taxi.TripRoute.toObject(m.route, o);
            }
            if (m.receipt != null && m.hasOwnProperty("receipt")) {
                d.receipt = $root.taxi.TripReceipt.toObject(m.receipt, o);
            }
            if (m.paymentMethod != null && m.hasOwnProperty("paymentMethod")) {
                d.paymentMethod = $root.taxi.PaymentMethodDTO.toObject(m.paymentMethod, o);
            }
            if (m.paymentProfile != null && m.hasOwnProperty("paymentProfile")) {
                d.paymentProfile = $root.taxi.PaymentProfileDTO.toObject(m.paymentProfile, o);
            }
            if (m.km != null && m.hasOwnProperty("km")) {
                d.km = $root.taxi.Int32Value.toObject(m.km, o);
            }
            return d;
        };

        /**
         * Converts this TripDTO to JSON.
         * @function toJSON
         * @memberof taxi.TripDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * TripRideStatus enum.
         * @name taxi.TripDTO.TripRideStatus
         * @enum {string}
         * @property {number} UNSPECIFIED=0 UNSPECIFIED value
         * @property {number} PENDING=1 PENDING value
         * @property {number} ACCEPTED=2 ACCEPTED value
         * @property {number} ARRIVED=3 ARRIVED value
         * @property {number} PICKED_UP=4 PICKED_UP value
         * @property {number} DROPPED_OFF=5 DROPPED_OFF value
         * @property {number} CANCELED=6 CANCELED value
         */
        TripDTO.TripRideStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSPECIFIED"] = 0;
            values[valuesById[1] = "PENDING"] = 1;
            values[valuesById[2] = "ACCEPTED"] = 2;
            values[valuesById[3] = "ARRIVED"] = 3;
            values[valuesById[4] = "PICKED_UP"] = 4;
            values[valuesById[5] = "DROPPED_OFF"] = 5;
            values[valuesById[6] = "CANCELED"] = 6;
            return values;
        })();

        TripDTO.DetailItem = (function() {

            /**
             * Properties of a DetailItem.
             * @memberof taxi.TripDTO
             * @interface IDetailItem
             * @property {string|null} [key] DetailItem key
             * @property {string|null} [value] DetailItem value
             */

            /**
             * Constructs a new DetailItem.
             * @memberof taxi.TripDTO
             * @classdesc Represents a DetailItem.
             * @implements IDetailItem
             * @constructor
             * @param {taxi.TripDTO.IDetailItem=} [p] Properties to set
             */
            function DetailItem(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DetailItem key.
             * @member {string} key
             * @memberof taxi.TripDTO.DetailItem
             * @instance
             */
            DetailItem.prototype.key = "";

            /**
             * DetailItem value.
             * @member {string} value
             * @memberof taxi.TripDTO.DetailItem
             * @instance
             */
            DetailItem.prototype.value = "";

            /**
             * Creates a new DetailItem instance using the specified properties.
             * @function create
             * @memberof taxi.TripDTO.DetailItem
             * @static
             * @param {taxi.TripDTO.IDetailItem=} [properties] Properties to set
             * @returns {taxi.TripDTO.DetailItem} DetailItem instance
             */
            DetailItem.create = function create(properties) {
                return new DetailItem(properties);
            };

            /**
             * Creates a DetailItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.TripDTO.DetailItem
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.TripDTO.DetailItem} DetailItem
             */
            DetailItem.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.TripDTO.DetailItem)
                    return d;
                var m = new $root.taxi.TripDTO.DetailItem();
                if (d.key != null) {
                    m.key = String(d.key);
                }
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a DetailItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.TripDTO.DetailItem
             * @static
             * @param {taxi.TripDTO.DetailItem} m DetailItem
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DetailItem.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.key = "";
                    d.value = "";
                }
                if (m.key != null && m.hasOwnProperty("key")) {
                    d.key = m.key;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this DetailItem to JSON.
             * @function toJSON
             * @memberof taxi.TripDTO.DetailItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DetailItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DetailItem;
        })();

        return TripDTO;
    })();

    taxi.TripVehicle = (function() {

        /**
         * Properties of a TripVehicle.
         * @memberof taxi
         * @interface ITripVehicle
         * @property {taxi.TripVehicle.TripVehicleType|null} [type] TripVehicle type
         * @property {string|null} [brand] TripVehicle brand
         * @property {string|null} [model] TripVehicle model
         * @property {string|null} [publicName] TripVehicle publicName
         * @property {taxi.IImage|null} [image] TripVehicle image
         * @property {Array.<taxi.IImage>|null} [slideImages] TripVehicle slideImages
         * @property {number|null} [seats] TripVehicle seats
         */

        /**
         * Constructs a new TripVehicle.
         * @memberof taxi
         * @classdesc Represents a TripVehicle.
         * @implements ITripVehicle
         * @constructor
         * @param {taxi.ITripVehicle=} [p] Properties to set
         */
        function TripVehicle(p) {
            this.slideImages = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripVehicle type.
         * @member {taxi.TripVehicle.TripVehicleType} type
         * @memberof taxi.TripVehicle
         * @instance
         */
        TripVehicle.prototype.type = 0;

        /**
         * TripVehicle brand.
         * @member {string} brand
         * @memberof taxi.TripVehicle
         * @instance
         */
        TripVehicle.prototype.brand = "";

        /**
         * TripVehicle model.
         * @member {string} model
         * @memberof taxi.TripVehicle
         * @instance
         */
        TripVehicle.prototype.model = "";

        /**
         * TripVehicle publicName.
         * @member {string} publicName
         * @memberof taxi.TripVehicle
         * @instance
         */
        TripVehicle.prototype.publicName = "";

        /**
         * TripVehicle image.
         * @member {taxi.IImage|null|undefined} image
         * @memberof taxi.TripVehicle
         * @instance
         */
        TripVehicle.prototype.image = null;

        /**
         * TripVehicle slideImages.
         * @member {Array.<taxi.IImage>} slideImages
         * @memberof taxi.TripVehicle
         * @instance
         */
        TripVehicle.prototype.slideImages = $util.emptyArray;

        /**
         * TripVehicle seats.
         * @member {number} seats
         * @memberof taxi.TripVehicle
         * @instance
         */
        TripVehicle.prototype.seats = 0;

        /**
         * Creates a new TripVehicle instance using the specified properties.
         * @function create
         * @memberof taxi.TripVehicle
         * @static
         * @param {taxi.ITripVehicle=} [properties] Properties to set
         * @returns {taxi.TripVehicle} TripVehicle instance
         */
        TripVehicle.create = function create(properties) {
            return new TripVehicle(properties);
        };

        /**
         * Creates a TripVehicle message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripVehicle
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripVehicle} TripVehicle
         */
        TripVehicle.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripVehicle)
                return d;
            var m = new $root.taxi.TripVehicle();
            switch (d.type) {
            case "CARNIVAL":
            case 0:
                m.type = 0;
                break;
            case "SPRINTER":
            case 1:
                m.type = 1;
                break;
            case "SOLATI":
            case 2:
                m.type = 2;
                break;
            case "SEDAN":
            case 3:
                m.type = 3;
                break;
            }
            if (d.brand != null) {
                m.brand = String(d.brand);
            }
            if (d.model != null) {
                m.model = String(d.model);
            }
            if (d.publicName != null) {
                m.publicName = String(d.publicName);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".taxi.TripVehicle.image: object expected");
                m.image = $root.taxi.Image.fromObject(d.image);
            }
            if (d.slideImages) {
                if (!Array.isArray(d.slideImages))
                    throw TypeError(".taxi.TripVehicle.slideImages: array expected");
                m.slideImages = [];
                for (var i = 0; i < d.slideImages.length; ++i) {
                    if (typeof d.slideImages[i] !== "object")
                        throw TypeError(".taxi.TripVehicle.slideImages: object expected");
                    m.slideImages[i] = $root.taxi.Image.fromObject(d.slideImages[i]);
                }
            }
            if (d.seats != null) {
                m.seats = d.seats | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a TripVehicle message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripVehicle
         * @static
         * @param {taxi.TripVehicle} m TripVehicle
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripVehicle.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.slideImages = [];
            }
            if (o.defaults) {
                d.type = o.enums === String ? "CARNIVAL" : 0;
                d.brand = "";
                d.model = "";
                d.publicName = "";
                d.image = null;
                d.seats = 0;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.taxi.TripVehicle.TripVehicleType[m.type] : m.type;
            }
            if (m.brand != null && m.hasOwnProperty("brand")) {
                d.brand = m.brand;
            }
            if (m.model != null && m.hasOwnProperty("model")) {
                d.model = m.model;
            }
            if (m.publicName != null && m.hasOwnProperty("publicName")) {
                d.publicName = m.publicName;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.taxi.Image.toObject(m.image, o);
            }
            if (m.slideImages && m.slideImages.length) {
                d.slideImages = [];
                for (var j = 0; j < m.slideImages.length; ++j) {
                    d.slideImages[j] = $root.taxi.Image.toObject(m.slideImages[j], o);
                }
            }
            if (m.seats != null && m.hasOwnProperty("seats")) {
                d.seats = m.seats;
            }
            return d;
        };

        /**
         * Converts this TripVehicle to JSON.
         * @function toJSON
         * @memberof taxi.TripVehicle
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripVehicle.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * TripVehicleType enum.
         * @name taxi.TripVehicle.TripVehicleType
         * @enum {string}
         * @property {number} CARNIVAL=0 CARNIVAL value
         * @property {number} SPRINTER=1 SPRINTER value
         * @property {number} SOLATI=2 SOLATI value
         * @property {number} SEDAN=3 SEDAN value
         */
        TripVehicle.TripVehicleType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CARNIVAL"] = 0;
            values[valuesById[1] = "SPRINTER"] = 1;
            values[valuesById[2] = "SOLATI"] = 2;
            values[valuesById[3] = "SEDAN"] = 3;
            return values;
        })();

        return TripVehicle;
    })();

    /**
     * TripAirType enum.
     * @name taxi.TripAirType
     * @enum {string}
     * @property {number} SENDING=0 SENDING value
     * @property {number} PICK_UP=1 PICK_UP value
     */
    taxi.TripAirType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SENDING"] = 0;
        values[valuesById[1] = "PICK_UP"] = 1;
        return values;
    })();

    taxi.TripAddressedLocation = (function() {

        /**
         * Properties of a TripAddressedLocation.
         * @memberof taxi
         * @interface ITripAddressedLocation
         * @property {taxi.IAddressedLocation|null} [location] TripAddressedLocation location
         * @property {taxi.IStringValue|null} [details] TripAddressedLocation details
         */

        /**
         * Constructs a new TripAddressedLocation.
         * @memberof taxi
         * @classdesc Represents a TripAddressedLocation.
         * @implements ITripAddressedLocation
         * @constructor
         * @param {taxi.ITripAddressedLocation=} [p] Properties to set
         */
        function TripAddressedLocation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripAddressedLocation location.
         * @member {taxi.IAddressedLocation|null|undefined} location
         * @memberof taxi.TripAddressedLocation
         * @instance
         */
        TripAddressedLocation.prototype.location = null;

        /**
         * TripAddressedLocation details.
         * @member {taxi.IStringValue|null|undefined} details
         * @memberof taxi.TripAddressedLocation
         * @instance
         */
        TripAddressedLocation.prototype.details = null;

        /**
         * Creates a new TripAddressedLocation instance using the specified properties.
         * @function create
         * @memberof taxi.TripAddressedLocation
         * @static
         * @param {taxi.ITripAddressedLocation=} [properties] Properties to set
         * @returns {taxi.TripAddressedLocation} TripAddressedLocation instance
         */
        TripAddressedLocation.create = function create(properties) {
            return new TripAddressedLocation(properties);
        };

        /**
         * Creates a TripAddressedLocation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripAddressedLocation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripAddressedLocation} TripAddressedLocation
         */
        TripAddressedLocation.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripAddressedLocation)
                return d;
            var m = new $root.taxi.TripAddressedLocation();
            if (d.location != null) {
                if (typeof d.location !== "object")
                    throw TypeError(".taxi.TripAddressedLocation.location: object expected");
                m.location = $root.taxi.AddressedLocation.fromObject(d.location);
            }
            if (d.details != null) {
                if (typeof d.details !== "object")
                    throw TypeError(".taxi.TripAddressedLocation.details: object expected");
                m.details = $root.taxi.StringValue.fromObject(d.details);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripAddressedLocation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripAddressedLocation
         * @static
         * @param {taxi.TripAddressedLocation} m TripAddressedLocation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripAddressedLocation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.location = null;
                d.details = null;
            }
            if (m.location != null && m.hasOwnProperty("location")) {
                d.location = $root.taxi.AddressedLocation.toObject(m.location, o);
            }
            if (m.details != null && m.hasOwnProperty("details")) {
                d.details = $root.taxi.StringValue.toObject(m.details, o);
            }
            return d;
        };

        /**
         * Converts this TripAddressedLocation to JSON.
         * @function toJSON
         * @memberof taxi.TripAddressedLocation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripAddressedLocation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TripAddressedLocation;
    })();

    taxi.TripLuggage = (function() {

        /**
         * Properties of a TripLuggage.
         * @memberof taxi
         * @interface ITripLuggage
         * @property {number|null} [large] TripLuggage large
         * @property {number|null} [medium] TripLuggage medium
         * @property {number|null} [small] TripLuggage small
         * @property {number|null} [tiny] TripLuggage tiny
         */

        /**
         * Constructs a new TripLuggage.
         * @memberof taxi
         * @classdesc Represents a TripLuggage.
         * @implements ITripLuggage
         * @constructor
         * @param {taxi.ITripLuggage=} [p] Properties to set
         */
        function TripLuggage(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripLuggage large.
         * @member {number} large
         * @memberof taxi.TripLuggage
         * @instance
         */
        TripLuggage.prototype.large = 0;

        /**
         * TripLuggage medium.
         * @member {number} medium
         * @memberof taxi.TripLuggage
         * @instance
         */
        TripLuggage.prototype.medium = 0;

        /**
         * TripLuggage small.
         * @member {number} small
         * @memberof taxi.TripLuggage
         * @instance
         */
        TripLuggage.prototype.small = 0;

        /**
         * TripLuggage tiny.
         * @member {number} tiny
         * @memberof taxi.TripLuggage
         * @instance
         */
        TripLuggage.prototype.tiny = 0;

        /**
         * Creates a new TripLuggage instance using the specified properties.
         * @function create
         * @memberof taxi.TripLuggage
         * @static
         * @param {taxi.ITripLuggage=} [properties] Properties to set
         * @returns {taxi.TripLuggage} TripLuggage instance
         */
        TripLuggage.create = function create(properties) {
            return new TripLuggage(properties);
        };

        /**
         * Creates a TripLuggage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripLuggage
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripLuggage} TripLuggage
         */
        TripLuggage.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripLuggage)
                return d;
            var m = new $root.taxi.TripLuggage();
            if (d.large != null) {
                m.large = d.large | 0;
            }
            if (d.medium != null) {
                m.medium = d.medium | 0;
            }
            if (d.small != null) {
                m.small = d.small | 0;
            }
            if (d.tiny != null) {
                m.tiny = d.tiny | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a TripLuggage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripLuggage
         * @static
         * @param {taxi.TripLuggage} m TripLuggage
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripLuggage.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.large = 0;
                d.medium = 0;
                d.small = 0;
                d.tiny = 0;
            }
            if (m.large != null && m.hasOwnProperty("large")) {
                d.large = m.large;
            }
            if (m.medium != null && m.hasOwnProperty("medium")) {
                d.medium = m.medium;
            }
            if (m.small != null && m.hasOwnProperty("small")) {
                d.small = m.small;
            }
            if (m.tiny != null && m.hasOwnProperty("tiny")) {
                d.tiny = m.tiny;
            }
            return d;
        };

        /**
         * Converts this TripLuggage to JSON.
         * @function toJSON
         * @memberof taxi.TripLuggage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripLuggage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TripLuggage;
    })();

    taxi.TripEstimation = (function() {

        /**
         * Properties of a TripEstimation.
         * @memberof taxi
         * @interface ITripEstimation
         * @property {number|null} [basicFee] TripEstimation basicFee
         * @property {number|null} [basicFeeDiscount] TripEstimation basicFeeDiscount
         * @property {taxi.TripEstimation.TripBasicFeeDiscountType|null} [basicFeeDiscountType] TripEstimation basicFeeDiscountType
         * @property {number|null} [couponDiscount] TripEstimation couponDiscount
         * @property {number|null} [totalFee] TripEstimation totalFee
         * @property {number|null} [bizDiscount] TripEstimation bizDiscount
         * @property {taxi.IStringValue|null} [bizDiscountPercentage] TripEstimation bizDiscountPercentage
         */

        /**
         * Constructs a new TripEstimation.
         * @memberof taxi
         * @classdesc Represents a TripEstimation.
         * @implements ITripEstimation
         * @constructor
         * @param {taxi.ITripEstimation=} [p] Properties to set
         */
        function TripEstimation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripEstimation basicFee.
         * @member {number} basicFee
         * @memberof taxi.TripEstimation
         * @instance
         */
        TripEstimation.prototype.basicFee = 0;

        /**
         * TripEstimation basicFeeDiscount.
         * @member {number} basicFeeDiscount
         * @memberof taxi.TripEstimation
         * @instance
         */
        TripEstimation.prototype.basicFeeDiscount = 0;

        /**
         * TripEstimation basicFeeDiscountType.
         * @member {taxi.TripEstimation.TripBasicFeeDiscountType} basicFeeDiscountType
         * @memberof taxi.TripEstimation
         * @instance
         */
        TripEstimation.prototype.basicFeeDiscountType = 0;

        /**
         * TripEstimation couponDiscount.
         * @member {number} couponDiscount
         * @memberof taxi.TripEstimation
         * @instance
         */
        TripEstimation.prototype.couponDiscount = 0;

        /**
         * TripEstimation totalFee.
         * @member {number} totalFee
         * @memberof taxi.TripEstimation
         * @instance
         */
        TripEstimation.prototype.totalFee = 0;

        /**
         * TripEstimation bizDiscount.
         * @member {number} bizDiscount
         * @memberof taxi.TripEstimation
         * @instance
         */
        TripEstimation.prototype.bizDiscount = 0;

        /**
         * TripEstimation bizDiscountPercentage.
         * @member {taxi.IStringValue|null|undefined} bizDiscountPercentage
         * @memberof taxi.TripEstimation
         * @instance
         */
        TripEstimation.prototype.bizDiscountPercentage = null;

        /**
         * Creates a new TripEstimation instance using the specified properties.
         * @function create
         * @memberof taxi.TripEstimation
         * @static
         * @param {taxi.ITripEstimation=} [properties] Properties to set
         * @returns {taxi.TripEstimation} TripEstimation instance
         */
        TripEstimation.create = function create(properties) {
            return new TripEstimation(properties);
        };

        /**
         * Creates a TripEstimation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripEstimation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripEstimation} TripEstimation
         */
        TripEstimation.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripEstimation)
                return d;
            var m = new $root.taxi.TripEstimation();
            if (d.basicFee != null) {
                m.basicFee = d.basicFee | 0;
            }
            if (d.basicFeeDiscount != null) {
                m.basicFeeDiscount = d.basicFeeDiscount | 0;
            }
            switch (d.basicFeeDiscountType) {
            case "UNSPECIFIED":
            case 0:
                m.basicFeeDiscountType = 0;
                break;
            case "DATE_TIME":
            case 1:
                m.basicFeeDiscountType = 1;
                break;
            case "EMPLOYEE":
            case 2:
                m.basicFeeDiscountType = 2;
                break;
            case "AIR_DEAL":
            case 3:
                m.basicFeeDiscountType = 3;
                break;
            case "EARLY_BIRD":
            case 4:
                m.basicFeeDiscountType = 4;
                break;
            }
            if (d.couponDiscount != null) {
                m.couponDiscount = d.couponDiscount | 0;
            }
            if (d.totalFee != null) {
                m.totalFee = d.totalFee | 0;
            }
            if (d.bizDiscount != null) {
                m.bizDiscount = d.bizDiscount | 0;
            }
            if (d.bizDiscountPercentage != null) {
                if (typeof d.bizDiscountPercentage !== "object")
                    throw TypeError(".taxi.TripEstimation.bizDiscountPercentage: object expected");
                m.bizDiscountPercentage = $root.taxi.StringValue.fromObject(d.bizDiscountPercentage);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripEstimation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripEstimation
         * @static
         * @param {taxi.TripEstimation} m TripEstimation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripEstimation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.basicFee = 0;
                d.basicFeeDiscount = 0;
                d.basicFeeDiscountType = o.enums === String ? "UNSPECIFIED" : 0;
                d.couponDiscount = 0;
                d.totalFee = 0;
                d.bizDiscount = 0;
                d.bizDiscountPercentage = null;
            }
            if (m.basicFee != null && m.hasOwnProperty("basicFee")) {
                d.basicFee = m.basicFee;
            }
            if (m.basicFeeDiscount != null && m.hasOwnProperty("basicFeeDiscount")) {
                d.basicFeeDiscount = m.basicFeeDiscount;
            }
            if (m.basicFeeDiscountType != null && m.hasOwnProperty("basicFeeDiscountType")) {
                d.basicFeeDiscountType = o.enums === String ? $root.taxi.TripEstimation.TripBasicFeeDiscountType[m.basicFeeDiscountType] : m.basicFeeDiscountType;
            }
            if (m.couponDiscount != null && m.hasOwnProperty("couponDiscount")) {
                d.couponDiscount = m.couponDiscount;
            }
            if (m.totalFee != null && m.hasOwnProperty("totalFee")) {
                d.totalFee = m.totalFee;
            }
            if (m.bizDiscount != null && m.hasOwnProperty("bizDiscount")) {
                d.bizDiscount = m.bizDiscount;
            }
            if (m.bizDiscountPercentage != null && m.hasOwnProperty("bizDiscountPercentage")) {
                d.bizDiscountPercentage = $root.taxi.StringValue.toObject(m.bizDiscountPercentage, o);
            }
            return d;
        };

        /**
         * Converts this TripEstimation to JSON.
         * @function toJSON
         * @memberof taxi.TripEstimation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripEstimation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * TripBasicFeeDiscountType enum.
         * @name taxi.TripEstimation.TripBasicFeeDiscountType
         * @enum {string}
         * @property {number} UNSPECIFIED=0 UNSPECIFIED value
         * @property {number} DATE_TIME=1 DATE_TIME value
         * @property {number} EMPLOYEE=2 EMPLOYEE value
         * @property {number} AIR_DEAL=3 AIR_DEAL value
         * @property {number} EARLY_BIRD=4 EARLY_BIRD value
         */
        TripEstimation.TripBasicFeeDiscountType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSPECIFIED"] = 0;
            values[valuesById[1] = "DATE_TIME"] = 1;
            values[valuesById[2] = "EMPLOYEE"] = 2;
            values[valuesById[3] = "AIR_DEAL"] = 3;
            values[valuesById[4] = "EARLY_BIRD"] = 4;
            return values;
        })();

        return TripEstimation;
    })();

    taxi.TripRider = (function() {

        /**
         * Properties of a TripRider.
         * @memberof taxi
         * @interface ITripRider
         * @property {string|null} [name] TripRider name
         * @property {string|null} [phoneNumber] TripRider phoneNumber
         */

        /**
         * Constructs a new TripRider.
         * @memberof taxi
         * @classdesc Represents a TripRider.
         * @implements ITripRider
         * @constructor
         * @param {taxi.ITripRider=} [p] Properties to set
         */
        function TripRider(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripRider name.
         * @member {string} name
         * @memberof taxi.TripRider
         * @instance
         */
        TripRider.prototype.name = "";

        /**
         * TripRider phoneNumber.
         * @member {string} phoneNumber
         * @memberof taxi.TripRider
         * @instance
         */
        TripRider.prototype.phoneNumber = "";

        /**
         * Creates a new TripRider instance using the specified properties.
         * @function create
         * @memberof taxi.TripRider
         * @static
         * @param {taxi.ITripRider=} [properties] Properties to set
         * @returns {taxi.TripRider} TripRider instance
         */
        TripRider.create = function create(properties) {
            return new TripRider(properties);
        };

        /**
         * Creates a TripRider message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripRider
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripRider} TripRider
         */
        TripRider.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripRider)
                return d;
            var m = new $root.taxi.TripRider();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripRider message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripRider
         * @static
         * @param {taxi.TripRider} m TripRider
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripRider.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                d.phoneNumber = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            return d;
        };

        /**
         * Converts this TripRider to JSON.
         * @function toJSON
         * @memberof taxi.TripRider
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripRider.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TripRider;
    })();

    /**
     * TripAvailableDatetimeType enum.
     * @name taxi.TripAvailableDatetimeType
     * @enum {string}
     * @property {number} DAYS_IN_MONTH=0 DAYS_IN_MONTH value
     * @property {number} HOURS_IN_DAY=1 HOURS_IN_DAY value
     * @property {number} TEN_MINUTES_IN_HOUR=2 TEN_MINUTES_IN_HOUR value
     * @property {number} ALL_DAYS=3 ALL_DAYS value
     */
    taxi.TripAvailableDatetimeType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DAYS_IN_MONTH"] = 0;
        values[valuesById[1] = "HOURS_IN_DAY"] = 1;
        values[valuesById[2] = "TEN_MINUTES_IN_HOUR"] = 2;
        values[valuesById[3] = "ALL_DAYS"] = 3;
        return values;
    })();

    taxi.TripAvailableDatetime = (function() {

        /**
         * Properties of a TripAvailableDatetime.
         * @memberof taxi
         * @interface ITripAvailableDatetime
         * @property {boolean|null} [isAvailable] TripAvailableDatetime isAvailable
         * @property {taxi.ITimestamp|null} [datetime] TripAvailableDatetime datetime
         * @property {number|null} [priceRate] TripAvailableDatetime priceRate
         * @property {taxi.ITimestamp|null} [dropOffAt] TripAvailableDatetime dropOffAt
         */

        /**
         * Constructs a new TripAvailableDatetime.
         * @memberof taxi
         * @classdesc Represents a TripAvailableDatetime.
         * @implements ITripAvailableDatetime
         * @constructor
         * @param {taxi.ITripAvailableDatetime=} [p] Properties to set
         */
        function TripAvailableDatetime(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripAvailableDatetime isAvailable.
         * @member {boolean} isAvailable
         * @memberof taxi.TripAvailableDatetime
         * @instance
         */
        TripAvailableDatetime.prototype.isAvailable = false;

        /**
         * TripAvailableDatetime datetime.
         * @member {taxi.ITimestamp|null|undefined} datetime
         * @memberof taxi.TripAvailableDatetime
         * @instance
         */
        TripAvailableDatetime.prototype.datetime = null;

        /**
         * TripAvailableDatetime priceRate.
         * @member {number} priceRate
         * @memberof taxi.TripAvailableDatetime
         * @instance
         */
        TripAvailableDatetime.prototype.priceRate = 0;

        /**
         * TripAvailableDatetime dropOffAt.
         * @member {taxi.ITimestamp|null|undefined} dropOffAt
         * @memberof taxi.TripAvailableDatetime
         * @instance
         */
        TripAvailableDatetime.prototype.dropOffAt = null;

        /**
         * Creates a new TripAvailableDatetime instance using the specified properties.
         * @function create
         * @memberof taxi.TripAvailableDatetime
         * @static
         * @param {taxi.ITripAvailableDatetime=} [properties] Properties to set
         * @returns {taxi.TripAvailableDatetime} TripAvailableDatetime instance
         */
        TripAvailableDatetime.create = function create(properties) {
            return new TripAvailableDatetime(properties);
        };

        /**
         * Creates a TripAvailableDatetime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripAvailableDatetime
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripAvailableDatetime} TripAvailableDatetime
         */
        TripAvailableDatetime.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripAvailableDatetime)
                return d;
            var m = new $root.taxi.TripAvailableDatetime();
            if (d.isAvailable != null) {
                m.isAvailable = Boolean(d.isAvailable);
            }
            if (d.datetime != null) {
                if (typeof d.datetime !== "object")
                    throw TypeError(".taxi.TripAvailableDatetime.datetime: object expected");
                m.datetime = $root.taxi.Timestamp.fromObject(d.datetime);
            }
            if (d.priceRate != null) {
                m.priceRate = d.priceRate | 0;
            }
            if (d.dropOffAt != null) {
                if (typeof d.dropOffAt !== "object")
                    throw TypeError(".taxi.TripAvailableDatetime.dropOffAt: object expected");
                m.dropOffAt = $root.taxi.Timestamp.fromObject(d.dropOffAt);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripAvailableDatetime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripAvailableDatetime
         * @static
         * @param {taxi.TripAvailableDatetime} m TripAvailableDatetime
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripAvailableDatetime.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.isAvailable = false;
                d.datetime = null;
                d.priceRate = 0;
                d.dropOffAt = null;
            }
            if (m.isAvailable != null && m.hasOwnProperty("isAvailable")) {
                d.isAvailable = m.isAvailable;
            }
            if (m.datetime != null && m.hasOwnProperty("datetime")) {
                d.datetime = $root.taxi.Timestamp.toObject(m.datetime, o);
            }
            if (m.priceRate != null && m.hasOwnProperty("priceRate")) {
                d.priceRate = m.priceRate;
            }
            if (m.dropOffAt != null && m.hasOwnProperty("dropOffAt")) {
                d.dropOffAt = $root.taxi.Timestamp.toObject(m.dropOffAt, o);
            }
            return d;
        };

        /**
         * Converts this TripAvailableDatetime to JSON.
         * @function toJSON
         * @memberof taxi.TripAvailableDatetime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripAvailableDatetime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TripAvailableDatetime;
    })();

    taxi.TripReservation = (function() {

        /**
         * Properties of a TripReservation.
         * @memberof taxi
         * @interface ITripReservation
         * @property {string|null} [id] TripReservation id
         * @property {string|null} [name] TripReservation name
         * @property {string|null} [shortName] TripReservation shortName
         * @property {taxi.TripTypeDescription.TripType|null} [type] TripReservation type
         * @property {taxi.TripReservation.TripReservationStatus|null} [status] TripReservation status
         * @property {taxi.TripDTO.TripRideStatus|null} [rideStatus] TripReservation rideStatus
         * @property {taxi.ITimestamp|null} [pickUpAt] TripReservation pickUpAt
         * @property {taxi.ITripVehicle|null} [vehicle] TripReservation vehicle
         * @property {taxi.ITripAddressedLocation|null} [origin] TripReservation origin
         * @property {Array.<taxi.ITripAddressedLocation>|null} [waypoints] TripReservation waypoints
         * @property {taxi.ITripAddressedLocation|null} [destination] TripReservation destination
         * @property {taxi.IStringValue|null} [driverName] TripReservation driverName
         * @property {Array.<taxi.TripReservation.ILineItem>|null} [driverInformation] TripReservation driverInformation
         * @property {taxi.IInt32Value|null} [reviewRating] TripReservation reviewRating
         * @property {Array.<taxi.TripReservation.ILineItem>|null} [riderInformation] TripReservation riderInformation
         * @property {Array.<taxi.TripReservation.ILineItem>|null} [reservationInformation] TripReservation reservationInformation
         * @property {taxi.IStringValue|null} [comment] TripReservation comment
         * @property {taxi.IStringValue|null} [designatedDriverComment] TripReservation designatedDriverComment
         * @property {taxi.IStringValue|null} [bizReason] TripReservation bizReason
         * @property {taxi.TripReservation.IPayment|null} [payment] TripReservation payment
         * @property {taxi.ITripReceipt|null} [receipt] TripReservation receipt
         * @property {taxi.TripReservation.IPayment|null} [additionalPayment] TripReservation additionalPayment
         * @property {taxi.ITripReceipt|null} [additionalReceipt] TripReservation additionalReceipt
         * @property {taxi.TripReservation.IPayment|null} [refundPayment] TripReservation refundPayment
         * @property {taxi.ITripReceipt|null} [refundReceipt] TripReservation refundReceipt
         * @property {taxi.TripReservation.IPayment|null} [tipPayment] TripReservation tipPayment
         * @property {taxi.ITripReceipt|null} [tipReceipt] TripReservation tipReceipt
         * @property {taxi.TripReservation.IPayment|null} [previousPayment] TripReservation previousPayment
         * @property {taxi.ITripReceipt|null} [previousReceipt] TripReservation previousReceipt
         * @property {number|null} [km] TripReservation km
         * @property {number|null} [feePerKm] TripReservation feePerKm
         * @property {taxi.IInt32Value|null} [minute] TripReservation minute
         * @property {taxi.IInt32Value|null} [feePerHour] TripReservation feePerHour
         * @property {string|null} [additionalFeeDescription] TripReservation additionalFeeDescription
         * @property {boolean|null} [hasAirPickUpNudge] TripReservation hasAirPickUpNudge
         * @property {boolean|null} [needReview] TripReservation needReview
         * @property {boolean|null} [editable] TripReservation editable
         * @property {boolean|null} [isB2b] TripReservation isB2b
         */

        /**
         * Constructs a new TripReservation.
         * @memberof taxi
         * @classdesc Represents a TripReservation.
         * @implements ITripReservation
         * @constructor
         * @param {taxi.ITripReservation=} [p] Properties to set
         */
        function TripReservation(p) {
            this.waypoints = [];
            this.driverInformation = [];
            this.riderInformation = [];
            this.reservationInformation = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripReservation id.
         * @member {string} id
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.id = "";

        /**
         * TripReservation name.
         * @member {string} name
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.name = "";

        /**
         * TripReservation shortName.
         * @member {string} shortName
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.shortName = "";

        /**
         * TripReservation type.
         * @member {taxi.TripTypeDescription.TripType} type
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.type = 0;

        /**
         * TripReservation status.
         * @member {taxi.TripReservation.TripReservationStatus} status
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.status = 0;

        /**
         * TripReservation rideStatus.
         * @member {taxi.TripDTO.TripRideStatus} rideStatus
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.rideStatus = 0;

        /**
         * TripReservation pickUpAt.
         * @member {taxi.ITimestamp|null|undefined} pickUpAt
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.pickUpAt = null;

        /**
         * TripReservation vehicle.
         * @member {taxi.ITripVehicle|null|undefined} vehicle
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.vehicle = null;

        /**
         * TripReservation origin.
         * @member {taxi.ITripAddressedLocation|null|undefined} origin
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.origin = null;

        /**
         * TripReservation waypoints.
         * @member {Array.<taxi.ITripAddressedLocation>} waypoints
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.waypoints = $util.emptyArray;

        /**
         * TripReservation destination.
         * @member {taxi.ITripAddressedLocation|null|undefined} destination
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.destination = null;

        /**
         * TripReservation driverName.
         * @member {taxi.IStringValue|null|undefined} driverName
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.driverName = null;

        /**
         * TripReservation driverInformation.
         * @member {Array.<taxi.TripReservation.ILineItem>} driverInformation
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.driverInformation = $util.emptyArray;

        /**
         * TripReservation reviewRating.
         * @member {taxi.IInt32Value|null|undefined} reviewRating
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.reviewRating = null;

        /**
         * TripReservation riderInformation.
         * @member {Array.<taxi.TripReservation.ILineItem>} riderInformation
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.riderInformation = $util.emptyArray;

        /**
         * TripReservation reservationInformation.
         * @member {Array.<taxi.TripReservation.ILineItem>} reservationInformation
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.reservationInformation = $util.emptyArray;

        /**
         * TripReservation comment.
         * @member {taxi.IStringValue|null|undefined} comment
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.comment = null;

        /**
         * TripReservation designatedDriverComment.
         * @member {taxi.IStringValue|null|undefined} designatedDriverComment
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.designatedDriverComment = null;

        /**
         * TripReservation bizReason.
         * @member {taxi.IStringValue|null|undefined} bizReason
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.bizReason = null;

        /**
         * TripReservation payment.
         * @member {taxi.TripReservation.IPayment|null|undefined} payment
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.payment = null;

        /**
         * TripReservation receipt.
         * @member {taxi.ITripReceipt|null|undefined} receipt
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.receipt = null;

        /**
         * TripReservation additionalPayment.
         * @member {taxi.TripReservation.IPayment|null|undefined} additionalPayment
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.additionalPayment = null;

        /**
         * TripReservation additionalReceipt.
         * @member {taxi.ITripReceipt|null|undefined} additionalReceipt
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.additionalReceipt = null;

        /**
         * TripReservation refundPayment.
         * @member {taxi.TripReservation.IPayment|null|undefined} refundPayment
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.refundPayment = null;

        /**
         * TripReservation refundReceipt.
         * @member {taxi.ITripReceipt|null|undefined} refundReceipt
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.refundReceipt = null;

        /**
         * TripReservation tipPayment.
         * @member {taxi.TripReservation.IPayment|null|undefined} tipPayment
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.tipPayment = null;

        /**
         * TripReservation tipReceipt.
         * @member {taxi.ITripReceipt|null|undefined} tipReceipt
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.tipReceipt = null;

        /**
         * TripReservation previousPayment.
         * @member {taxi.TripReservation.IPayment|null|undefined} previousPayment
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.previousPayment = null;

        /**
         * TripReservation previousReceipt.
         * @member {taxi.ITripReceipt|null|undefined} previousReceipt
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.previousReceipt = null;

        /**
         * TripReservation km.
         * @member {number} km
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.km = 0;

        /**
         * TripReservation feePerKm.
         * @member {number} feePerKm
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.feePerKm = 0;

        /**
         * TripReservation minute.
         * @member {taxi.IInt32Value|null|undefined} minute
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.minute = null;

        /**
         * TripReservation feePerHour.
         * @member {taxi.IInt32Value|null|undefined} feePerHour
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.feePerHour = null;

        /**
         * TripReservation additionalFeeDescription.
         * @member {string} additionalFeeDescription
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.additionalFeeDescription = "";

        /**
         * TripReservation hasAirPickUpNudge.
         * @member {boolean} hasAirPickUpNudge
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.hasAirPickUpNudge = false;

        /**
         * TripReservation needReview.
         * @member {boolean} needReview
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.needReview = false;

        /**
         * TripReservation editable.
         * @member {boolean} editable
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.editable = false;

        /**
         * TripReservation isB2b.
         * @member {boolean} isB2b
         * @memberof taxi.TripReservation
         * @instance
         */
        TripReservation.prototype.isB2b = false;

        /**
         * Creates a new TripReservation instance using the specified properties.
         * @function create
         * @memberof taxi.TripReservation
         * @static
         * @param {taxi.ITripReservation=} [properties] Properties to set
         * @returns {taxi.TripReservation} TripReservation instance
         */
        TripReservation.create = function create(properties) {
            return new TripReservation(properties);
        };

        /**
         * Creates a TripReservation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripReservation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripReservation} TripReservation
         */
        TripReservation.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripReservation)
                return d;
            var m = new $root.taxi.TripReservation();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.shortName != null) {
                m.shortName = String(d.shortName);
            }
            switch (d.type) {
            case "UNSPECIFIED":
            case 0:
                m.type = 0;
                break;
            case "AIR":
            case 1:
                m.type = 1;
                break;
            case "CHARTER":
            case 2:
                m.type = 2;
                break;
            case "VAN":
            case 3:
                m.type = 3;
                break;
            }
            switch (d.status) {
            case "PENDING":
            case 0:
                m.status = 0;
                break;
            case "CONFIRMED":
            case 1:
                m.status = 1;
                break;
            case "COMPLETED":
            case 2:
                m.status = 2;
                break;
            case "CANCELLED":
            case 3:
                m.status = 3;
                break;
            }
            switch (d.rideStatus) {
            case "UNSPECIFIED":
            case 0:
                m.rideStatus = 0;
                break;
            case "PENDING":
            case 1:
                m.rideStatus = 1;
                break;
            case "ACCEPTED":
            case 2:
                m.rideStatus = 2;
                break;
            case "ARRIVED":
            case 3:
                m.rideStatus = 3;
                break;
            case "PICKED_UP":
            case 4:
                m.rideStatus = 4;
                break;
            case "DROPPED_OFF":
            case 5:
                m.rideStatus = 5;
                break;
            case "CANCELED":
            case 6:
                m.rideStatus = 6;
                break;
            }
            if (d.pickUpAt != null) {
                if (typeof d.pickUpAt !== "object")
                    throw TypeError(".taxi.TripReservation.pickUpAt: object expected");
                m.pickUpAt = $root.taxi.Timestamp.fromObject(d.pickUpAt);
            }
            if (d.vehicle != null) {
                if (typeof d.vehicle !== "object")
                    throw TypeError(".taxi.TripReservation.vehicle: object expected");
                m.vehicle = $root.taxi.TripVehicle.fromObject(d.vehicle);
            }
            if (d.origin != null) {
                if (typeof d.origin !== "object")
                    throw TypeError(".taxi.TripReservation.origin: object expected");
                m.origin = $root.taxi.TripAddressedLocation.fromObject(d.origin);
            }
            if (d.waypoints) {
                if (!Array.isArray(d.waypoints))
                    throw TypeError(".taxi.TripReservation.waypoints: array expected");
                m.waypoints = [];
                for (var i = 0; i < d.waypoints.length; ++i) {
                    if (typeof d.waypoints[i] !== "object")
                        throw TypeError(".taxi.TripReservation.waypoints: object expected");
                    m.waypoints[i] = $root.taxi.TripAddressedLocation.fromObject(d.waypoints[i]);
                }
            }
            if (d.destination != null) {
                if (typeof d.destination !== "object")
                    throw TypeError(".taxi.TripReservation.destination: object expected");
                m.destination = $root.taxi.TripAddressedLocation.fromObject(d.destination);
            }
            if (d.driverName != null) {
                if (typeof d.driverName !== "object")
                    throw TypeError(".taxi.TripReservation.driverName: object expected");
                m.driverName = $root.taxi.StringValue.fromObject(d.driverName);
            }
            if (d.driverInformation) {
                if (!Array.isArray(d.driverInformation))
                    throw TypeError(".taxi.TripReservation.driverInformation: array expected");
                m.driverInformation = [];
                for (var i = 0; i < d.driverInformation.length; ++i) {
                    if (typeof d.driverInformation[i] !== "object")
                        throw TypeError(".taxi.TripReservation.driverInformation: object expected");
                    m.driverInformation[i] = $root.taxi.TripReservation.LineItem.fromObject(d.driverInformation[i]);
                }
            }
            if (d.reviewRating != null) {
                if (typeof d.reviewRating !== "object")
                    throw TypeError(".taxi.TripReservation.reviewRating: object expected");
                m.reviewRating = $root.taxi.Int32Value.fromObject(d.reviewRating);
            }
            if (d.riderInformation) {
                if (!Array.isArray(d.riderInformation))
                    throw TypeError(".taxi.TripReservation.riderInformation: array expected");
                m.riderInformation = [];
                for (var i = 0; i < d.riderInformation.length; ++i) {
                    if (typeof d.riderInformation[i] !== "object")
                        throw TypeError(".taxi.TripReservation.riderInformation: object expected");
                    m.riderInformation[i] = $root.taxi.TripReservation.LineItem.fromObject(d.riderInformation[i]);
                }
            }
            if (d.reservationInformation) {
                if (!Array.isArray(d.reservationInformation))
                    throw TypeError(".taxi.TripReservation.reservationInformation: array expected");
                m.reservationInformation = [];
                for (var i = 0; i < d.reservationInformation.length; ++i) {
                    if (typeof d.reservationInformation[i] !== "object")
                        throw TypeError(".taxi.TripReservation.reservationInformation: object expected");
                    m.reservationInformation[i] = $root.taxi.TripReservation.LineItem.fromObject(d.reservationInformation[i]);
                }
            }
            if (d.comment != null) {
                if (typeof d.comment !== "object")
                    throw TypeError(".taxi.TripReservation.comment: object expected");
                m.comment = $root.taxi.StringValue.fromObject(d.comment);
            }
            if (d.designatedDriverComment != null) {
                if (typeof d.designatedDriverComment !== "object")
                    throw TypeError(".taxi.TripReservation.designatedDriverComment: object expected");
                m.designatedDriverComment = $root.taxi.StringValue.fromObject(d.designatedDriverComment);
            }
            if (d.bizReason != null) {
                if (typeof d.bizReason !== "object")
                    throw TypeError(".taxi.TripReservation.bizReason: object expected");
                m.bizReason = $root.taxi.StringValue.fromObject(d.bizReason);
            }
            if (d.payment != null) {
                if (typeof d.payment !== "object")
                    throw TypeError(".taxi.TripReservation.payment: object expected");
                m.payment = $root.taxi.TripReservation.Payment.fromObject(d.payment);
            }
            if (d.receipt != null) {
                if (typeof d.receipt !== "object")
                    throw TypeError(".taxi.TripReservation.receipt: object expected");
                m.receipt = $root.taxi.TripReceipt.fromObject(d.receipt);
            }
            if (d.additionalPayment != null) {
                if (typeof d.additionalPayment !== "object")
                    throw TypeError(".taxi.TripReservation.additionalPayment: object expected");
                m.additionalPayment = $root.taxi.TripReservation.Payment.fromObject(d.additionalPayment);
            }
            if (d.additionalReceipt != null) {
                if (typeof d.additionalReceipt !== "object")
                    throw TypeError(".taxi.TripReservation.additionalReceipt: object expected");
                m.additionalReceipt = $root.taxi.TripReceipt.fromObject(d.additionalReceipt);
            }
            if (d.refundPayment != null) {
                if (typeof d.refundPayment !== "object")
                    throw TypeError(".taxi.TripReservation.refundPayment: object expected");
                m.refundPayment = $root.taxi.TripReservation.Payment.fromObject(d.refundPayment);
            }
            if (d.refundReceipt != null) {
                if (typeof d.refundReceipt !== "object")
                    throw TypeError(".taxi.TripReservation.refundReceipt: object expected");
                m.refundReceipt = $root.taxi.TripReceipt.fromObject(d.refundReceipt);
            }
            if (d.tipPayment != null) {
                if (typeof d.tipPayment !== "object")
                    throw TypeError(".taxi.TripReservation.tipPayment: object expected");
                m.tipPayment = $root.taxi.TripReservation.Payment.fromObject(d.tipPayment);
            }
            if (d.tipReceipt != null) {
                if (typeof d.tipReceipt !== "object")
                    throw TypeError(".taxi.TripReservation.tipReceipt: object expected");
                m.tipReceipt = $root.taxi.TripReceipt.fromObject(d.tipReceipt);
            }
            if (d.previousPayment != null) {
                if (typeof d.previousPayment !== "object")
                    throw TypeError(".taxi.TripReservation.previousPayment: object expected");
                m.previousPayment = $root.taxi.TripReservation.Payment.fromObject(d.previousPayment);
            }
            if (d.previousReceipt != null) {
                if (typeof d.previousReceipt !== "object")
                    throw TypeError(".taxi.TripReservation.previousReceipt: object expected");
                m.previousReceipt = $root.taxi.TripReceipt.fromObject(d.previousReceipt);
            }
            if (d.km != null) {
                m.km = d.km | 0;
            }
            if (d.feePerKm != null) {
                m.feePerKm = d.feePerKm | 0;
            }
            if (d.minute != null) {
                if (typeof d.minute !== "object")
                    throw TypeError(".taxi.TripReservation.minute: object expected");
                m.minute = $root.taxi.Int32Value.fromObject(d.minute);
            }
            if (d.feePerHour != null) {
                if (typeof d.feePerHour !== "object")
                    throw TypeError(".taxi.TripReservation.feePerHour: object expected");
                m.feePerHour = $root.taxi.Int32Value.fromObject(d.feePerHour);
            }
            if (d.additionalFeeDescription != null) {
                m.additionalFeeDescription = String(d.additionalFeeDescription);
            }
            if (d.hasAirPickUpNudge != null) {
                m.hasAirPickUpNudge = Boolean(d.hasAirPickUpNudge);
            }
            if (d.needReview != null) {
                m.needReview = Boolean(d.needReview);
            }
            if (d.editable != null) {
                m.editable = Boolean(d.editable);
            }
            if (d.isB2b != null) {
                m.isB2b = Boolean(d.isB2b);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripReservation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripReservation
         * @static
         * @param {taxi.TripReservation} m TripReservation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripReservation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.waypoints = [];
                d.driverInformation = [];
                d.riderInformation = [];
                d.reservationInformation = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.type = o.enums === String ? "UNSPECIFIED" : 0;
                d.status = o.enums === String ? "PENDING" : 0;
                d.rideStatus = o.enums === String ? "UNSPECIFIED" : 0;
                d.pickUpAt = null;
                d.shortName = "";
                d.vehicle = null;
                d.origin = null;
                d.destination = null;
                d.driverName = null;
                d.reviewRating = null;
                d.comment = null;
                d.designatedDriverComment = null;
                d.bizReason = null;
                d.payment = null;
                d.receipt = null;
                d.additionalPayment = null;
                d.additionalReceipt = null;
                d.refundPayment = null;
                d.refundReceipt = null;
                d.tipPayment = null;
                d.tipReceipt = null;
                d.feePerKm = 0;
                d.minute = null;
                d.feePerHour = null;
                d.additionalFeeDescription = "";
                d.km = 0;
                d.hasAirPickUpNudge = false;
                d.needReview = false;
                d.editable = false;
                d.previousPayment = null;
                d.previousReceipt = null;
                d.isB2b = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.taxi.TripTypeDescription.TripType[m.type] : m.type;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.taxi.TripReservation.TripReservationStatus[m.status] : m.status;
            }
            if (m.rideStatus != null && m.hasOwnProperty("rideStatus")) {
                d.rideStatus = o.enums === String ? $root.taxi.TripDTO.TripRideStatus[m.rideStatus] : m.rideStatus;
            }
            if (m.pickUpAt != null && m.hasOwnProperty("pickUpAt")) {
                d.pickUpAt = $root.taxi.Timestamp.toObject(m.pickUpAt, o);
            }
            if (m.shortName != null && m.hasOwnProperty("shortName")) {
                d.shortName = m.shortName;
            }
            if (m.vehicle != null && m.hasOwnProperty("vehicle")) {
                d.vehicle = $root.taxi.TripVehicle.toObject(m.vehicle, o);
            }
            if (m.origin != null && m.hasOwnProperty("origin")) {
                d.origin = $root.taxi.TripAddressedLocation.toObject(m.origin, o);
            }
            if (m.waypoints && m.waypoints.length) {
                d.waypoints = [];
                for (var j = 0; j < m.waypoints.length; ++j) {
                    d.waypoints[j] = $root.taxi.TripAddressedLocation.toObject(m.waypoints[j], o);
                }
            }
            if (m.destination != null && m.hasOwnProperty("destination")) {
                d.destination = $root.taxi.TripAddressedLocation.toObject(m.destination, o);
            }
            if (m.driverName != null && m.hasOwnProperty("driverName")) {
                d.driverName = $root.taxi.StringValue.toObject(m.driverName, o);
            }
            if (m.driverInformation && m.driverInformation.length) {
                d.driverInformation = [];
                for (var j = 0; j < m.driverInformation.length; ++j) {
                    d.driverInformation[j] = $root.taxi.TripReservation.LineItem.toObject(m.driverInformation[j], o);
                }
            }
            if (m.reviewRating != null && m.hasOwnProperty("reviewRating")) {
                d.reviewRating = $root.taxi.Int32Value.toObject(m.reviewRating, o);
            }
            if (m.riderInformation && m.riderInformation.length) {
                d.riderInformation = [];
                for (var j = 0; j < m.riderInformation.length; ++j) {
                    d.riderInformation[j] = $root.taxi.TripReservation.LineItem.toObject(m.riderInformation[j], o);
                }
            }
            if (m.reservationInformation && m.reservationInformation.length) {
                d.reservationInformation = [];
                for (var j = 0; j < m.reservationInformation.length; ++j) {
                    d.reservationInformation[j] = $root.taxi.TripReservation.LineItem.toObject(m.reservationInformation[j], o);
                }
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = $root.taxi.StringValue.toObject(m.comment, o);
            }
            if (m.designatedDriverComment != null && m.hasOwnProperty("designatedDriverComment")) {
                d.designatedDriverComment = $root.taxi.StringValue.toObject(m.designatedDriverComment, o);
            }
            if (m.bizReason != null && m.hasOwnProperty("bizReason")) {
                d.bizReason = $root.taxi.StringValue.toObject(m.bizReason, o);
            }
            if (m.payment != null && m.hasOwnProperty("payment")) {
                d.payment = $root.taxi.TripReservation.Payment.toObject(m.payment, o);
            }
            if (m.receipt != null && m.hasOwnProperty("receipt")) {
                d.receipt = $root.taxi.TripReceipt.toObject(m.receipt, o);
            }
            if (m.additionalPayment != null && m.hasOwnProperty("additionalPayment")) {
                d.additionalPayment = $root.taxi.TripReservation.Payment.toObject(m.additionalPayment, o);
            }
            if (m.additionalReceipt != null && m.hasOwnProperty("additionalReceipt")) {
                d.additionalReceipt = $root.taxi.TripReceipt.toObject(m.additionalReceipt, o);
            }
            if (m.refundPayment != null && m.hasOwnProperty("refundPayment")) {
                d.refundPayment = $root.taxi.TripReservation.Payment.toObject(m.refundPayment, o);
            }
            if (m.refundReceipt != null && m.hasOwnProperty("refundReceipt")) {
                d.refundReceipt = $root.taxi.TripReceipt.toObject(m.refundReceipt, o);
            }
            if (m.tipPayment != null && m.hasOwnProperty("tipPayment")) {
                d.tipPayment = $root.taxi.TripReservation.Payment.toObject(m.tipPayment, o);
            }
            if (m.tipReceipt != null && m.hasOwnProperty("tipReceipt")) {
                d.tipReceipt = $root.taxi.TripReceipt.toObject(m.tipReceipt, o);
            }
            if (m.feePerKm != null && m.hasOwnProperty("feePerKm")) {
                d.feePerKm = m.feePerKm;
            }
            if (m.minute != null && m.hasOwnProperty("minute")) {
                d.minute = $root.taxi.Int32Value.toObject(m.minute, o);
            }
            if (m.feePerHour != null && m.hasOwnProperty("feePerHour")) {
                d.feePerHour = $root.taxi.Int32Value.toObject(m.feePerHour, o);
            }
            if (m.additionalFeeDescription != null && m.hasOwnProperty("additionalFeeDescription")) {
                d.additionalFeeDescription = m.additionalFeeDescription;
            }
            if (m.km != null && m.hasOwnProperty("km")) {
                d.km = m.km;
            }
            if (m.hasAirPickUpNudge != null && m.hasOwnProperty("hasAirPickUpNudge")) {
                d.hasAirPickUpNudge = m.hasAirPickUpNudge;
            }
            if (m.needReview != null && m.hasOwnProperty("needReview")) {
                d.needReview = m.needReview;
            }
            if (m.editable != null && m.hasOwnProperty("editable")) {
                d.editable = m.editable;
            }
            if (m.previousPayment != null && m.hasOwnProperty("previousPayment")) {
                d.previousPayment = $root.taxi.TripReservation.Payment.toObject(m.previousPayment, o);
            }
            if (m.previousReceipt != null && m.hasOwnProperty("previousReceipt")) {
                d.previousReceipt = $root.taxi.TripReceipt.toObject(m.previousReceipt, o);
            }
            if (m.isB2b != null && m.hasOwnProperty("isB2b")) {
                d.isB2b = m.isB2b;
            }
            return d;
        };

        /**
         * Converts this TripReservation to JSON.
         * @function toJSON
         * @memberof taxi.TripReservation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripReservation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * TripReservationStatus enum.
         * @name taxi.TripReservation.TripReservationStatus
         * @enum {string}
         * @property {number} PENDING=0 PENDING value
         * @property {number} CONFIRMED=1 CONFIRMED value
         * @property {number} COMPLETED=2 COMPLETED value
         * @property {number} CANCELLED=3 CANCELLED value
         */
        TripReservation.TripReservationStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PENDING"] = 0;
            values[valuesById[1] = "CONFIRMED"] = 1;
            values[valuesById[2] = "COMPLETED"] = 2;
            values[valuesById[3] = "CANCELLED"] = 3;
            return values;
        })();

        TripReservation.LineItem = (function() {

            /**
             * Properties of a LineItem.
             * @memberof taxi.TripReservation
             * @interface ILineItem
             * @property {string|null} [name] LineItem name
             * @property {string|null} [value] LineItem value
             */

            /**
             * Constructs a new LineItem.
             * @memberof taxi.TripReservation
             * @classdesc Represents a LineItem.
             * @implements ILineItem
             * @constructor
             * @param {taxi.TripReservation.ILineItem=} [p] Properties to set
             */
            function LineItem(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LineItem name.
             * @member {string} name
             * @memberof taxi.TripReservation.LineItem
             * @instance
             */
            LineItem.prototype.name = "";

            /**
             * LineItem value.
             * @member {string} value
             * @memberof taxi.TripReservation.LineItem
             * @instance
             */
            LineItem.prototype.value = "";

            /**
             * Creates a new LineItem instance using the specified properties.
             * @function create
             * @memberof taxi.TripReservation.LineItem
             * @static
             * @param {taxi.TripReservation.ILineItem=} [properties] Properties to set
             * @returns {taxi.TripReservation.LineItem} LineItem instance
             */
            LineItem.create = function create(properties) {
                return new LineItem(properties);
            };

            /**
             * Creates a LineItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.TripReservation.LineItem
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.TripReservation.LineItem} LineItem
             */
            LineItem.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.TripReservation.LineItem)
                    return d;
                var m = new $root.taxi.TripReservation.LineItem();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a LineItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.TripReservation.LineItem
             * @static
             * @param {taxi.TripReservation.LineItem} m LineItem
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LineItem.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.value = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this LineItem to JSON.
             * @function toJSON
             * @memberof taxi.TripReservation.LineItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LineItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LineItem;
        })();

        TripReservation.Payment = (function() {

            /**
             * Properties of a Payment.
             * @memberof taxi.TripReservation
             * @interface IPayment
             * @property {taxi.IPaymentMethodDTO|null} [method] Payment method
             * @property {taxi.IPaymentProfileDTO|null} [profile] Payment profile
             */

            /**
             * Constructs a new Payment.
             * @memberof taxi.TripReservation
             * @classdesc Represents a Payment.
             * @implements IPayment
             * @constructor
             * @param {taxi.TripReservation.IPayment=} [p] Properties to set
             */
            function Payment(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Payment method.
             * @member {taxi.IPaymentMethodDTO|null|undefined} method
             * @memberof taxi.TripReservation.Payment
             * @instance
             */
            Payment.prototype.method = null;

            /**
             * Payment profile.
             * @member {taxi.IPaymentProfileDTO|null|undefined} profile
             * @memberof taxi.TripReservation.Payment
             * @instance
             */
            Payment.prototype.profile = null;

            /**
             * Creates a new Payment instance using the specified properties.
             * @function create
             * @memberof taxi.TripReservation.Payment
             * @static
             * @param {taxi.TripReservation.IPayment=} [properties] Properties to set
             * @returns {taxi.TripReservation.Payment} Payment instance
             */
            Payment.create = function create(properties) {
                return new Payment(properties);
            };

            /**
             * Creates a Payment message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.TripReservation.Payment
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.TripReservation.Payment} Payment
             */
            Payment.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.TripReservation.Payment)
                    return d;
                var m = new $root.taxi.TripReservation.Payment();
                if (d.method != null) {
                    if (typeof d.method !== "object")
                        throw TypeError(".taxi.TripReservation.Payment.method: object expected");
                    m.method = $root.taxi.PaymentMethodDTO.fromObject(d.method);
                }
                if (d.profile != null) {
                    if (typeof d.profile !== "object")
                        throw TypeError(".taxi.TripReservation.Payment.profile: object expected");
                    m.profile = $root.taxi.PaymentProfileDTO.fromObject(d.profile);
                }
                return m;
            };

            /**
             * Creates a plain object from a Payment message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.TripReservation.Payment
             * @static
             * @param {taxi.TripReservation.Payment} m Payment
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Payment.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.method = null;
                    d.profile = null;
                }
                if (m.method != null && m.hasOwnProperty("method")) {
                    d.method = $root.taxi.PaymentMethodDTO.toObject(m.method, o);
                }
                if (m.profile != null && m.hasOwnProperty("profile")) {
                    d.profile = $root.taxi.PaymentProfileDTO.toObject(m.profile, o);
                }
                return d;
            };

            /**
             * Converts this Payment to JSON.
             * @function toJSON
             * @memberof taxi.TripReservation.Payment
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Payment.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Payment;
        })();

        return TripReservation;
    })();

    taxi.TripProduct = (function() {

        /**
         * Properties of a TripProduct.
         * @memberof taxi
         * @interface ITripProduct
         * @property {taxi.IImage|null} [image] TripProduct image
         * @property {taxi.ITripVehicle|null} [vehicle] TripProduct vehicle
         * @property {taxi.IInt32Value|null} [hourUnit] TripProduct hourUnit
         * @property {number|null} [waypointCount] TripProduct waypointCount
         * @property {number|null} [minCost] TripProduct minCost
         * @property {taxi.IInt32Value|null} [originalMinCost] TripProduct originalMinCost
         * @property {Array.<taxi.TripProduct.ILineItem>|null} [productNotices] TripProduct productNotices
         * @property {Array.<string>|null} [reservationNotices] TripProduct reservationNotices
         * @property {Array.<string>|null} [feeNotices] TripProduct feeNotices
         * @property {string|null} [additionalFeeDescription] TripProduct additionalFeeDescription
         * @property {taxi.TripProduct.ILuggageDescription|null} [luggageDescription] TripProduct luggageDescription
         */

        /**
         * Constructs a new TripProduct.
         * @memberof taxi
         * @classdesc Represents a TripProduct.
         * @implements ITripProduct
         * @constructor
         * @param {taxi.ITripProduct=} [p] Properties to set
         */
        function TripProduct(p) {
            this.productNotices = [];
            this.reservationNotices = [];
            this.feeNotices = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TripProduct image.
         * @member {taxi.IImage|null|undefined} image
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.image = null;

        /**
         * TripProduct vehicle.
         * @member {taxi.ITripVehicle|null|undefined} vehicle
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.vehicle = null;

        /**
         * TripProduct hourUnit.
         * @member {taxi.IInt32Value|null|undefined} hourUnit
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.hourUnit = null;

        /**
         * TripProduct waypointCount.
         * @member {number} waypointCount
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.waypointCount = 0;

        /**
         * TripProduct minCost.
         * @member {number} minCost
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.minCost = 0;

        /**
         * TripProduct originalMinCost.
         * @member {taxi.IInt32Value|null|undefined} originalMinCost
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.originalMinCost = null;

        /**
         * TripProduct productNotices.
         * @member {Array.<taxi.TripProduct.ILineItem>} productNotices
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.productNotices = $util.emptyArray;

        /**
         * TripProduct reservationNotices.
         * @member {Array.<string>} reservationNotices
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.reservationNotices = $util.emptyArray;

        /**
         * TripProduct feeNotices.
         * @member {Array.<string>} feeNotices
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.feeNotices = $util.emptyArray;

        /**
         * TripProduct additionalFeeDescription.
         * @member {string} additionalFeeDescription
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.additionalFeeDescription = "";

        /**
         * TripProduct luggageDescription.
         * @member {taxi.TripProduct.ILuggageDescription|null|undefined} luggageDescription
         * @memberof taxi.TripProduct
         * @instance
         */
        TripProduct.prototype.luggageDescription = null;

        /**
         * Creates a new TripProduct instance using the specified properties.
         * @function create
         * @memberof taxi.TripProduct
         * @static
         * @param {taxi.ITripProduct=} [properties] Properties to set
         * @returns {taxi.TripProduct} TripProduct instance
         */
        TripProduct.create = function create(properties) {
            return new TripProduct(properties);
        };

        /**
         * Creates a TripProduct message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TripProduct
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TripProduct} TripProduct
         */
        TripProduct.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TripProduct)
                return d;
            var m = new $root.taxi.TripProduct();
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".taxi.TripProduct.image: object expected");
                m.image = $root.taxi.Image.fromObject(d.image);
            }
            if (d.vehicle != null) {
                if (typeof d.vehicle !== "object")
                    throw TypeError(".taxi.TripProduct.vehicle: object expected");
                m.vehicle = $root.taxi.TripVehicle.fromObject(d.vehicle);
            }
            if (d.hourUnit != null) {
                if (typeof d.hourUnit !== "object")
                    throw TypeError(".taxi.TripProduct.hourUnit: object expected");
                m.hourUnit = $root.taxi.Int32Value.fromObject(d.hourUnit);
            }
            if (d.waypointCount != null) {
                m.waypointCount = d.waypointCount | 0;
            }
            if (d.minCost != null) {
                m.minCost = d.minCost | 0;
            }
            if (d.originalMinCost != null) {
                if (typeof d.originalMinCost !== "object")
                    throw TypeError(".taxi.TripProduct.originalMinCost: object expected");
                m.originalMinCost = $root.taxi.Int32Value.fromObject(d.originalMinCost);
            }
            if (d.productNotices) {
                if (!Array.isArray(d.productNotices))
                    throw TypeError(".taxi.TripProduct.productNotices: array expected");
                m.productNotices = [];
                for (var i = 0; i < d.productNotices.length; ++i) {
                    if (typeof d.productNotices[i] !== "object")
                        throw TypeError(".taxi.TripProduct.productNotices: object expected");
                    m.productNotices[i] = $root.taxi.TripProduct.LineItem.fromObject(d.productNotices[i]);
                }
            }
            if (d.reservationNotices) {
                if (!Array.isArray(d.reservationNotices))
                    throw TypeError(".taxi.TripProduct.reservationNotices: array expected");
                m.reservationNotices = [];
                for (var i = 0; i < d.reservationNotices.length; ++i) {
                    m.reservationNotices[i] = String(d.reservationNotices[i]);
                }
            }
            if (d.feeNotices) {
                if (!Array.isArray(d.feeNotices))
                    throw TypeError(".taxi.TripProduct.feeNotices: array expected");
                m.feeNotices = [];
                for (var i = 0; i < d.feeNotices.length; ++i) {
                    m.feeNotices[i] = String(d.feeNotices[i]);
                }
            }
            if (d.additionalFeeDescription != null) {
                m.additionalFeeDescription = String(d.additionalFeeDescription);
            }
            if (d.luggageDescription != null) {
                if (typeof d.luggageDescription !== "object")
                    throw TypeError(".taxi.TripProduct.luggageDescription: object expected");
                m.luggageDescription = $root.taxi.TripProduct.LuggageDescription.fromObject(d.luggageDescription);
            }
            return m;
        };

        /**
         * Creates a plain object from a TripProduct message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TripProduct
         * @static
         * @param {taxi.TripProduct} m TripProduct
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TripProduct.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.productNotices = [];
                d.reservationNotices = [];
                d.feeNotices = [];
            }
            if (o.defaults) {
                d.image = null;
                d.vehicle = null;
                d.hourUnit = null;
                d.waypointCount = 0;
                d.minCost = 0;
                d.originalMinCost = null;
                d.additionalFeeDescription = "";
                d.luggageDescription = null;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.taxi.Image.toObject(m.image, o);
            }
            if (m.vehicle != null && m.hasOwnProperty("vehicle")) {
                d.vehicle = $root.taxi.TripVehicle.toObject(m.vehicle, o);
            }
            if (m.hourUnit != null && m.hasOwnProperty("hourUnit")) {
                d.hourUnit = $root.taxi.Int32Value.toObject(m.hourUnit, o);
            }
            if (m.waypointCount != null && m.hasOwnProperty("waypointCount")) {
                d.waypointCount = m.waypointCount;
            }
            if (m.minCost != null && m.hasOwnProperty("minCost")) {
                d.minCost = m.minCost;
            }
            if (m.originalMinCost != null && m.hasOwnProperty("originalMinCost")) {
                d.originalMinCost = $root.taxi.Int32Value.toObject(m.originalMinCost, o);
            }
            if (m.productNotices && m.productNotices.length) {
                d.productNotices = [];
                for (var j = 0; j < m.productNotices.length; ++j) {
                    d.productNotices[j] = $root.taxi.TripProduct.LineItem.toObject(m.productNotices[j], o);
                }
            }
            if (m.reservationNotices && m.reservationNotices.length) {
                d.reservationNotices = [];
                for (var j = 0; j < m.reservationNotices.length; ++j) {
                    d.reservationNotices[j] = m.reservationNotices[j];
                }
            }
            if (m.feeNotices && m.feeNotices.length) {
                d.feeNotices = [];
                for (var j = 0; j < m.feeNotices.length; ++j) {
                    d.feeNotices[j] = m.feeNotices[j];
                }
            }
            if (m.additionalFeeDescription != null && m.hasOwnProperty("additionalFeeDescription")) {
                d.additionalFeeDescription = m.additionalFeeDescription;
            }
            if (m.luggageDescription != null && m.hasOwnProperty("luggageDescription")) {
                d.luggageDescription = $root.taxi.TripProduct.LuggageDescription.toObject(m.luggageDescription, o);
            }
            return d;
        };

        /**
         * Converts this TripProduct to JSON.
         * @function toJSON
         * @memberof taxi.TripProduct
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TripProduct.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TripProduct.LineItem = (function() {

            /**
             * Properties of a LineItem.
             * @memberof taxi.TripProduct
             * @interface ILineItem
             * @property {string|null} [key] LineItem key
             * @property {string|null} [value] LineItem value
             */

            /**
             * Constructs a new LineItem.
             * @memberof taxi.TripProduct
             * @classdesc Represents a LineItem.
             * @implements ILineItem
             * @constructor
             * @param {taxi.TripProduct.ILineItem=} [p] Properties to set
             */
            function LineItem(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LineItem key.
             * @member {string} key
             * @memberof taxi.TripProduct.LineItem
             * @instance
             */
            LineItem.prototype.key = "";

            /**
             * LineItem value.
             * @member {string} value
             * @memberof taxi.TripProduct.LineItem
             * @instance
             */
            LineItem.prototype.value = "";

            /**
             * Creates a new LineItem instance using the specified properties.
             * @function create
             * @memberof taxi.TripProduct.LineItem
             * @static
             * @param {taxi.TripProduct.ILineItem=} [properties] Properties to set
             * @returns {taxi.TripProduct.LineItem} LineItem instance
             */
            LineItem.create = function create(properties) {
                return new LineItem(properties);
            };

            /**
             * Creates a LineItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.TripProduct.LineItem
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.TripProduct.LineItem} LineItem
             */
            LineItem.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.TripProduct.LineItem)
                    return d;
                var m = new $root.taxi.TripProduct.LineItem();
                if (d.key != null) {
                    m.key = String(d.key);
                }
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a LineItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.TripProduct.LineItem
             * @static
             * @param {taxi.TripProduct.LineItem} m LineItem
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LineItem.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.key = "";
                    d.value = "";
                }
                if (m.key != null && m.hasOwnProperty("key")) {
                    d.key = m.key;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this LineItem to JSON.
             * @function toJSON
             * @memberof taxi.TripProduct.LineItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LineItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LineItem;
        })();

        TripProduct.LuggageDescription = (function() {

            /**
             * Properties of a LuggageDescription.
             * @memberof taxi.TripProduct
             * @interface ILuggageDescription
             * @property {string|null} [small] LuggageDescription small
             * @property {string|null} [medium] LuggageDescription medium
             * @property {string|null} [large] LuggageDescription large
             */

            /**
             * Constructs a new LuggageDescription.
             * @memberof taxi.TripProduct
             * @classdesc Represents a LuggageDescription.
             * @implements ILuggageDescription
             * @constructor
             * @param {taxi.TripProduct.ILuggageDescription=} [p] Properties to set
             */
            function LuggageDescription(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LuggageDescription small.
             * @member {string} small
             * @memberof taxi.TripProduct.LuggageDescription
             * @instance
             */
            LuggageDescription.prototype.small = "";

            /**
             * LuggageDescription medium.
             * @member {string} medium
             * @memberof taxi.TripProduct.LuggageDescription
             * @instance
             */
            LuggageDescription.prototype.medium = "";

            /**
             * LuggageDescription large.
             * @member {string} large
             * @memberof taxi.TripProduct.LuggageDescription
             * @instance
             */
            LuggageDescription.prototype.large = "";

            /**
             * Creates a new LuggageDescription instance using the specified properties.
             * @function create
             * @memberof taxi.TripProduct.LuggageDescription
             * @static
             * @param {taxi.TripProduct.ILuggageDescription=} [properties] Properties to set
             * @returns {taxi.TripProduct.LuggageDescription} LuggageDescription instance
             */
            LuggageDescription.create = function create(properties) {
                return new LuggageDescription(properties);
            };

            /**
             * Creates a LuggageDescription message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.TripProduct.LuggageDescription
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.TripProduct.LuggageDescription} LuggageDescription
             */
            LuggageDescription.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.TripProduct.LuggageDescription)
                    return d;
                var m = new $root.taxi.TripProduct.LuggageDescription();
                if (d.small != null) {
                    m.small = String(d.small);
                }
                if (d.medium != null) {
                    m.medium = String(d.medium);
                }
                if (d.large != null) {
                    m.large = String(d.large);
                }
                return m;
            };

            /**
             * Creates a plain object from a LuggageDescription message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.TripProduct.LuggageDescription
             * @static
             * @param {taxi.TripProduct.LuggageDescription} m LuggageDescription
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LuggageDescription.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.small = "";
                    d.medium = "";
                    d.large = "";
                }
                if (m.small != null && m.hasOwnProperty("small")) {
                    d.small = m.small;
                }
                if (m.medium != null && m.hasOwnProperty("medium")) {
                    d.medium = m.medium;
                }
                if (m.large != null && m.hasOwnProperty("large")) {
                    d.large = m.large;
                }
                return d;
            };

            /**
             * Converts this LuggageDescription to JSON.
             * @function toJSON
             * @memberof taxi.TripProduct.LuggageDescription
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LuggageDescription.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LuggageDescription;
        })();

        return TripProduct;
    })();

    taxi.Location = (function() {

        /**
         * Properties of a Location.
         * @memberof taxi
         * @interface ILocation
         * @property {number|null} [lng] Location lng
         * @property {number|null} [lat] Location lat
         */

        /**
         * Constructs a new Location.
         * @memberof taxi
         * @classdesc Represents a Location.
         * @implements ILocation
         * @constructor
         * @param {taxi.ILocation=} [p] Properties to set
         */
        function Location(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Location lng.
         * @member {number} lng
         * @memberof taxi.Location
         * @instance
         */
        Location.prototype.lng = 0;

        /**
         * Location lat.
         * @member {number} lat
         * @memberof taxi.Location
         * @instance
         */
        Location.prototype.lat = 0;

        /**
         * Creates a new Location instance using the specified properties.
         * @function create
         * @memberof taxi.Location
         * @static
         * @param {taxi.ILocation=} [properties] Properties to set
         * @returns {taxi.Location} Location instance
         */
        Location.create = function create(properties) {
            return new Location(properties);
        };

        /**
         * Creates a Location message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.Location
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.Location} Location
         */
        Location.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.Location)
                return d;
            var m = new $root.taxi.Location();
            if (d.lng != null) {
                m.lng = Number(d.lng);
            }
            if (d.lat != null) {
                m.lat = Number(d.lat);
            }
            return m;
        };

        /**
         * Creates a plain object from a Location message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.Location
         * @static
         * @param {taxi.Location} m Location
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Location.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.lng = 0;
                d.lat = 0;
            }
            if (m.lng != null && m.hasOwnProperty("lng")) {
                d.lng = o.json && !isFinite(m.lng) ? String(m.lng) : m.lng;
            }
            if (m.lat != null && m.hasOwnProperty("lat")) {
                d.lat = o.json && !isFinite(m.lat) ? String(m.lat) : m.lat;
            }
            return d;
        };

        /**
         * Converts this Location to JSON.
         * @function toJSON
         * @memberof taxi.Location
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Location.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Location;
    })();

    taxi.AddressedLocation = (function() {

        /**
         * Properties of an AddressedLocation.
         * @memberof taxi
         * @interface IAddressedLocation
         * @property {number|null} [lng] AddressedLocation lng
         * @property {number|null} [lat] AddressedLocation lat
         * @property {string|null} [name] AddressedLocation name
         * @property {string|null} [address] AddressedLocation address
         */

        /**
         * Constructs a new AddressedLocation.
         * @memberof taxi
         * @classdesc Represents an AddressedLocation.
         * @implements IAddressedLocation
         * @constructor
         * @param {taxi.IAddressedLocation=} [p] Properties to set
         */
        function AddressedLocation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AddressedLocation lng.
         * @member {number} lng
         * @memberof taxi.AddressedLocation
         * @instance
         */
        AddressedLocation.prototype.lng = 0;

        /**
         * AddressedLocation lat.
         * @member {number} lat
         * @memberof taxi.AddressedLocation
         * @instance
         */
        AddressedLocation.prototype.lat = 0;

        /**
         * AddressedLocation name.
         * @member {string} name
         * @memberof taxi.AddressedLocation
         * @instance
         */
        AddressedLocation.prototype.name = "";

        /**
         * AddressedLocation address.
         * @member {string} address
         * @memberof taxi.AddressedLocation
         * @instance
         */
        AddressedLocation.prototype.address = "";

        /**
         * Creates a new AddressedLocation instance using the specified properties.
         * @function create
         * @memberof taxi.AddressedLocation
         * @static
         * @param {taxi.IAddressedLocation=} [properties] Properties to set
         * @returns {taxi.AddressedLocation} AddressedLocation instance
         */
        AddressedLocation.create = function create(properties) {
            return new AddressedLocation(properties);
        };

        /**
         * Creates an AddressedLocation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.AddressedLocation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.AddressedLocation} AddressedLocation
         */
        AddressedLocation.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.AddressedLocation)
                return d;
            var m = new $root.taxi.AddressedLocation();
            if (d.lng != null) {
                m.lng = Number(d.lng);
            }
            if (d.lat != null) {
                m.lat = Number(d.lat);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            return m;
        };

        /**
         * Creates a plain object from an AddressedLocation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.AddressedLocation
         * @static
         * @param {taxi.AddressedLocation} m AddressedLocation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddressedLocation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.lng = 0;
                d.lat = 0;
                d.name = "";
                d.address = "";
            }
            if (m.lng != null && m.hasOwnProperty("lng")) {
                d.lng = o.json && !isFinite(m.lng) ? String(m.lng) : m.lng;
            }
            if (m.lat != null && m.hasOwnProperty("lat")) {
                d.lat = o.json && !isFinite(m.lat) ? String(m.lat) : m.lat;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            return d;
        };

        /**
         * Converts this AddressedLocation to JSON.
         * @function toJSON
         * @memberof taxi.AddressedLocation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddressedLocation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddressedLocation;
    })();

    taxi.VehicleLocation = (function() {

        /**
         * Properties of a VehicleLocation.
         * @memberof taxi
         * @interface IVehicleLocation
         * @property {number|null} [lng] VehicleLocation lng
         * @property {number|null} [lat] VehicleLocation lat
         * @property {number|null} [bearing] VehicleLocation bearing
         * @property {number|null} [speedMeterPerSecond] VehicleLocation speedMeterPerSecond
         */

        /**
         * Constructs a new VehicleLocation.
         * @memberof taxi
         * @classdesc Represents a VehicleLocation.
         * @implements IVehicleLocation
         * @constructor
         * @param {taxi.IVehicleLocation=} [p] Properties to set
         */
        function VehicleLocation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VehicleLocation lng.
         * @member {number} lng
         * @memberof taxi.VehicleLocation
         * @instance
         */
        VehicleLocation.prototype.lng = 0;

        /**
         * VehicleLocation lat.
         * @member {number} lat
         * @memberof taxi.VehicleLocation
         * @instance
         */
        VehicleLocation.prototype.lat = 0;

        /**
         * VehicleLocation bearing.
         * @member {number} bearing
         * @memberof taxi.VehicleLocation
         * @instance
         */
        VehicleLocation.prototype.bearing = 0;

        /**
         * VehicleLocation speedMeterPerSecond.
         * @member {number} speedMeterPerSecond
         * @memberof taxi.VehicleLocation
         * @instance
         */
        VehicleLocation.prototype.speedMeterPerSecond = 0;

        /**
         * Creates a new VehicleLocation instance using the specified properties.
         * @function create
         * @memberof taxi.VehicleLocation
         * @static
         * @param {taxi.IVehicleLocation=} [properties] Properties to set
         * @returns {taxi.VehicleLocation} VehicleLocation instance
         */
        VehicleLocation.create = function create(properties) {
            return new VehicleLocation(properties);
        };

        /**
         * Creates a VehicleLocation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.VehicleLocation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.VehicleLocation} VehicleLocation
         */
        VehicleLocation.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.VehicleLocation)
                return d;
            var m = new $root.taxi.VehicleLocation();
            if (d.lng != null) {
                m.lng = Number(d.lng);
            }
            if (d.lat != null) {
                m.lat = Number(d.lat);
            }
            if (d.bearing != null) {
                m.bearing = Number(d.bearing);
            }
            if (d.speedMeterPerSecond != null) {
                m.speedMeterPerSecond = Number(d.speedMeterPerSecond);
            }
            return m;
        };

        /**
         * Creates a plain object from a VehicleLocation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.VehicleLocation
         * @static
         * @param {taxi.VehicleLocation} m VehicleLocation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VehicleLocation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.lng = 0;
                d.lat = 0;
                d.bearing = 0;
                d.speedMeterPerSecond = 0;
            }
            if (m.lng != null && m.hasOwnProperty("lng")) {
                d.lng = o.json && !isFinite(m.lng) ? String(m.lng) : m.lng;
            }
            if (m.lat != null && m.hasOwnProperty("lat")) {
                d.lat = o.json && !isFinite(m.lat) ? String(m.lat) : m.lat;
            }
            if (m.bearing != null && m.hasOwnProperty("bearing")) {
                d.bearing = o.json && !isFinite(m.bearing) ? String(m.bearing) : m.bearing;
            }
            if (m.speedMeterPerSecond != null && m.hasOwnProperty("speedMeterPerSecond")) {
                d.speedMeterPerSecond = o.json && !isFinite(m.speedMeterPerSecond) ? String(m.speedMeterPerSecond) : m.speedMeterPerSecond;
            }
            return d;
        };

        /**
         * Converts this VehicleLocation to JSON.
         * @function toJSON
         * @memberof taxi.VehicleLocation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VehicleLocation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VehicleLocation;
    })();

    taxi.VehicleDTO = (function() {

        /**
         * Properties of a VehicleDTO.
         * @memberof taxi
         * @interface IVehicleDTO
         * @property {string|null} [id] VehicleDTO id
         * @property {string|null} [model] VehicleDTO model
         * @property {string|null} [licensePlate] VehicleDTO licensePlate
         * @property {taxi.VehicleDTO.IconColor|null} [iconColor] VehicleDTO iconColor
         */

        /**
         * Constructs a new VehicleDTO.
         * @memberof taxi
         * @classdesc Represents a VehicleDTO.
         * @implements IVehicleDTO
         * @constructor
         * @param {taxi.IVehicleDTO=} [p] Properties to set
         */
        function VehicleDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VehicleDTO id.
         * @member {string} id
         * @memberof taxi.VehicleDTO
         * @instance
         */
        VehicleDTO.prototype.id = "";

        /**
         * VehicleDTO model.
         * @member {string} model
         * @memberof taxi.VehicleDTO
         * @instance
         */
        VehicleDTO.prototype.model = "";

        /**
         * VehicleDTO licensePlate.
         * @member {string} licensePlate
         * @memberof taxi.VehicleDTO
         * @instance
         */
        VehicleDTO.prototype.licensePlate = "";

        /**
         * VehicleDTO iconColor.
         * @member {taxi.VehicleDTO.IconColor} iconColor
         * @memberof taxi.VehicleDTO
         * @instance
         */
        VehicleDTO.prototype.iconColor = 0;

        /**
         * Creates a new VehicleDTO instance using the specified properties.
         * @function create
         * @memberof taxi.VehicleDTO
         * @static
         * @param {taxi.IVehicleDTO=} [properties] Properties to set
         * @returns {taxi.VehicleDTO} VehicleDTO instance
         */
        VehicleDTO.create = function create(properties) {
            return new VehicleDTO(properties);
        };

        /**
         * Creates a VehicleDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.VehicleDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.VehicleDTO} VehicleDTO
         */
        VehicleDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.VehicleDTO)
                return d;
            var m = new $root.taxi.VehicleDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.model != null) {
                m.model = String(d.model);
            }
            if (d.licensePlate != null) {
                m.licensePlate = String(d.licensePlate);
            }
            switch (d.iconColor) {
            case "LIGHT":
            case 0:
                m.iconColor = 0;
                break;
            case "DARK":
            case 1:
                m.iconColor = 1;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a VehicleDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.VehicleDTO
         * @static
         * @param {taxi.VehicleDTO} m VehicleDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VehicleDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.model = "";
                d.licensePlate = "";
                d.iconColor = o.enums === String ? "LIGHT" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.model != null && m.hasOwnProperty("model")) {
                d.model = m.model;
            }
            if (m.licensePlate != null && m.hasOwnProperty("licensePlate")) {
                d.licensePlate = m.licensePlate;
            }
            if (m.iconColor != null && m.hasOwnProperty("iconColor")) {
                d.iconColor = o.enums === String ? $root.taxi.VehicleDTO.IconColor[m.iconColor] : m.iconColor;
            }
            return d;
        };

        /**
         * Converts this VehicleDTO to JSON.
         * @function toJSON
         * @memberof taxi.VehicleDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VehicleDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * IconColor enum.
         * @name taxi.VehicleDTO.IconColor
         * @enum {string}
         * @property {number} LIGHT=0 LIGHT value
         * @property {number} DARK=1 DARK value
         */
        VehicleDTO.IconColor = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "LIGHT"] = 0;
            values[valuesById[1] = "DARK"] = 1;
            return values;
        })();

        return VehicleDTO;
    })();

    taxi.VehicleZoneDTO = (function() {

        /**
         * Properties of a VehicleZoneDTO.
         * @memberof taxi
         * @interface IVehicleZoneDTO
         * @property {string|null} [id] VehicleZoneDTO id
         * @property {string|null} [name] VehicleZoneDTO name
         * @property {taxi.IAddressedLocation|null} [location] VehicleZoneDTO location
         */

        /**
         * Constructs a new VehicleZoneDTO.
         * @memberof taxi
         * @classdesc Represents a VehicleZoneDTO.
         * @implements IVehicleZoneDTO
         * @constructor
         * @param {taxi.IVehicleZoneDTO=} [p] Properties to set
         */
        function VehicleZoneDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VehicleZoneDTO id.
         * @member {string} id
         * @memberof taxi.VehicleZoneDTO
         * @instance
         */
        VehicleZoneDTO.prototype.id = "";

        /**
         * VehicleZoneDTO name.
         * @member {string} name
         * @memberof taxi.VehicleZoneDTO
         * @instance
         */
        VehicleZoneDTO.prototype.name = "";

        /**
         * VehicleZoneDTO location.
         * @member {taxi.IAddressedLocation|null|undefined} location
         * @memberof taxi.VehicleZoneDTO
         * @instance
         */
        VehicleZoneDTO.prototype.location = null;

        /**
         * Creates a new VehicleZoneDTO instance using the specified properties.
         * @function create
         * @memberof taxi.VehicleZoneDTO
         * @static
         * @param {taxi.IVehicleZoneDTO=} [properties] Properties to set
         * @returns {taxi.VehicleZoneDTO} VehicleZoneDTO instance
         */
        VehicleZoneDTO.create = function create(properties) {
            return new VehicleZoneDTO(properties);
        };

        /**
         * Creates a VehicleZoneDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.VehicleZoneDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.VehicleZoneDTO} VehicleZoneDTO
         */
        VehicleZoneDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.VehicleZoneDTO)
                return d;
            var m = new $root.taxi.VehicleZoneDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.location != null) {
                if (typeof d.location !== "object")
                    throw TypeError(".taxi.VehicleZoneDTO.location: object expected");
                m.location = $root.taxi.AddressedLocation.fromObject(d.location);
            }
            return m;
        };

        /**
         * Creates a plain object from a VehicleZoneDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.VehicleZoneDTO
         * @static
         * @param {taxi.VehicleZoneDTO} m VehicleZoneDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VehicleZoneDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.location = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.location != null && m.hasOwnProperty("location")) {
                d.location = $root.taxi.AddressedLocation.toObject(m.location, o);
            }
            return d;
        };

        /**
         * Converts this VehicleZoneDTO to JSON.
         * @function toJSON
         * @memberof taxi.VehicleZoneDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VehicleZoneDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VehicleZoneDTO;
    })();

    taxi.UserDTO = (function() {

        /**
         * Properties of a UserDTO.
         * @memberof taxi
         * @interface IUserDTO
         * @property {string|null} [id] UserDTO id
         * @property {string|null} [email] UserDTO email
         * @property {string|null} [name] UserDTO name
         * @property {string|null} [phoneNumber] UserDTO phoneNumber
         * @property {number|null} [createdAt] UserDTO createdAt
         */

        /**
         * Constructs a new UserDTO.
         * @memberof taxi
         * @classdesc Represents a UserDTO.
         * @implements IUserDTO
         * @constructor
         * @param {taxi.IUserDTO=} [p] Properties to set
         */
        function UserDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserDTO id.
         * @member {string} id
         * @memberof taxi.UserDTO
         * @instance
         */
        UserDTO.prototype.id = "";

        /**
         * UserDTO email.
         * @member {string} email
         * @memberof taxi.UserDTO
         * @instance
         */
        UserDTO.prototype.email = "";

        /**
         * UserDTO name.
         * @member {string} name
         * @memberof taxi.UserDTO
         * @instance
         */
        UserDTO.prototype.name = "";

        /**
         * UserDTO phoneNumber.
         * @member {string} phoneNumber
         * @memberof taxi.UserDTO
         * @instance
         */
        UserDTO.prototype.phoneNumber = "";

        /**
         * UserDTO createdAt.
         * @member {number} createdAt
         * @memberof taxi.UserDTO
         * @instance
         */
        UserDTO.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new UserDTO instance using the specified properties.
         * @function create
         * @memberof taxi.UserDTO
         * @static
         * @param {taxi.IUserDTO=} [properties] Properties to set
         * @returns {taxi.UserDTO} UserDTO instance
         */
        UserDTO.create = function create(properties) {
            return new UserDTO(properties);
        };

        /**
         * Creates a UserDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UserDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UserDTO} UserDTO
         */
        UserDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UserDTO)
                return d;
            var m = new $root.taxi.UserDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.createdAt != null) {
                if ($util.Long)
                    (m.createdAt = $util.Long.fromValue(d.createdAt)).unsigned = false;
                else if (typeof d.createdAt === "string")
                    m.createdAt = parseInt(d.createdAt, 10);
                else if (typeof d.createdAt === "number")
                    m.createdAt = d.createdAt;
                else if (typeof d.createdAt === "object")
                    m.createdAt = new $util.LongBits(d.createdAt.low >>> 0, d.createdAt.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a UserDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UserDTO
         * @static
         * @param {taxi.UserDTO} m UserDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.email = "";
                d.name = "";
                d.phoneNumber = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.createdAt = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.createdAt = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                if (typeof m.createdAt === "number")
                    d.createdAt = o.longs === String ? String(m.createdAt) : m.createdAt;
                else
                    d.createdAt = o.longs === String ? $util.Long.prototype.toString.call(m.createdAt) : o.longs === Number ? new $util.LongBits(m.createdAt.low >>> 0, m.createdAt.high >>> 0).toNumber() : m.createdAt;
            }
            return d;
        };

        /**
         * Converts this UserDTO to JSON.
         * @function toJSON
         * @memberof taxi.UserDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserDTO;
    })();

    taxi.UserStatusDTO = (function() {

        /**
         * Properties of a UserStatusDTO.
         * @memberof taxi
         * @interface IUserStatusDTO
         * @property {boolean|null} [isPhoneNumberVerified] UserStatusDTO isPhoneNumberVerified
         * @property {boolean|null} [hasPaymentMethod] UserStatusDTO hasPaymentMethod
         * @property {boolean|null} [hasFailedPayment] UserStatusDTO hasFailedPayment
         * @property {boolean|null} [hasUpdatedTerms] UserStatusDTO hasUpdatedTerms
         */

        /**
         * Constructs a new UserStatusDTO.
         * @memberof taxi
         * @classdesc Represents a UserStatusDTO.
         * @implements IUserStatusDTO
         * @constructor
         * @param {taxi.IUserStatusDTO=} [p] Properties to set
         */
        function UserStatusDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserStatusDTO isPhoneNumberVerified.
         * @member {boolean} isPhoneNumberVerified
         * @memberof taxi.UserStatusDTO
         * @instance
         */
        UserStatusDTO.prototype.isPhoneNumberVerified = false;

        /**
         * UserStatusDTO hasPaymentMethod.
         * @member {boolean} hasPaymentMethod
         * @memberof taxi.UserStatusDTO
         * @instance
         */
        UserStatusDTO.prototype.hasPaymentMethod = false;

        /**
         * UserStatusDTO hasFailedPayment.
         * @member {boolean} hasFailedPayment
         * @memberof taxi.UserStatusDTO
         * @instance
         */
        UserStatusDTO.prototype.hasFailedPayment = false;

        /**
         * UserStatusDTO hasUpdatedTerms.
         * @member {boolean} hasUpdatedTerms
         * @memberof taxi.UserStatusDTO
         * @instance
         */
        UserStatusDTO.prototype.hasUpdatedTerms = false;

        /**
         * Creates a new UserStatusDTO instance using the specified properties.
         * @function create
         * @memberof taxi.UserStatusDTO
         * @static
         * @param {taxi.IUserStatusDTO=} [properties] Properties to set
         * @returns {taxi.UserStatusDTO} UserStatusDTO instance
         */
        UserStatusDTO.create = function create(properties) {
            return new UserStatusDTO(properties);
        };

        /**
         * Creates a UserStatusDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UserStatusDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UserStatusDTO} UserStatusDTO
         */
        UserStatusDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UserStatusDTO)
                return d;
            var m = new $root.taxi.UserStatusDTO();
            if (d.isPhoneNumberVerified != null) {
                m.isPhoneNumberVerified = Boolean(d.isPhoneNumberVerified);
            }
            if (d.hasPaymentMethod != null) {
                m.hasPaymentMethod = Boolean(d.hasPaymentMethod);
            }
            if (d.hasFailedPayment != null) {
                m.hasFailedPayment = Boolean(d.hasFailedPayment);
            }
            if (d.hasUpdatedTerms != null) {
                m.hasUpdatedTerms = Boolean(d.hasUpdatedTerms);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserStatusDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UserStatusDTO
         * @static
         * @param {taxi.UserStatusDTO} m UserStatusDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserStatusDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.isPhoneNumberVerified = false;
                d.hasPaymentMethod = false;
                d.hasFailedPayment = false;
                d.hasUpdatedTerms = false;
            }
            if (m.isPhoneNumberVerified != null && m.hasOwnProperty("isPhoneNumberVerified")) {
                d.isPhoneNumberVerified = m.isPhoneNumberVerified;
            }
            if (m.hasPaymentMethod != null && m.hasOwnProperty("hasPaymentMethod")) {
                d.hasPaymentMethod = m.hasPaymentMethod;
            }
            if (m.hasFailedPayment != null && m.hasOwnProperty("hasFailedPayment")) {
                d.hasFailedPayment = m.hasFailedPayment;
            }
            if (m.hasUpdatedTerms != null && m.hasOwnProperty("hasUpdatedTerms")) {
                d.hasUpdatedTerms = m.hasUpdatedTerms;
            }
            return d;
        };

        /**
         * Converts this UserStatusDTO to JSON.
         * @function toJSON
         * @memberof taxi.UserStatusDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserStatusDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserStatusDTO;
    })();

    taxi.DriverDTO = (function() {

        /**
         * Properties of a DriverDTO.
         * @memberof taxi
         * @interface IDriverDTO
         * @property {string|null} [id] DriverDTO id
         * @property {string|null} [email] DriverDTO email
         * @property {string|null} [name] DriverDTO name
         * @property {string|null} [phoneNumber] DriverDTO phoneNumber
         * @property {string|null} [profileUrl] DriverDTO profileUrl
         * @property {number|null} [createdAt] DriverDTO createdAt
         * @property {taxi.IDriverAgencyDTO|null} [agency] DriverDTO agency
         */

        /**
         * Constructs a new DriverDTO.
         * @memberof taxi
         * @classdesc Represents a DriverDTO.
         * @implements IDriverDTO
         * @constructor
         * @param {taxi.IDriverDTO=} [p] Properties to set
         */
        function DriverDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DriverDTO id.
         * @member {string} id
         * @memberof taxi.DriverDTO
         * @instance
         */
        DriverDTO.prototype.id = "";

        /**
         * DriverDTO email.
         * @member {string} email
         * @memberof taxi.DriverDTO
         * @instance
         */
        DriverDTO.prototype.email = "";

        /**
         * DriverDTO name.
         * @member {string} name
         * @memberof taxi.DriverDTO
         * @instance
         */
        DriverDTO.prototype.name = "";

        /**
         * DriverDTO phoneNumber.
         * @member {string} phoneNumber
         * @memberof taxi.DriverDTO
         * @instance
         */
        DriverDTO.prototype.phoneNumber = "";

        /**
         * DriverDTO profileUrl.
         * @member {string} profileUrl
         * @memberof taxi.DriverDTO
         * @instance
         */
        DriverDTO.prototype.profileUrl = "";

        /**
         * DriverDTO createdAt.
         * @member {number} createdAt
         * @memberof taxi.DriverDTO
         * @instance
         */
        DriverDTO.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DriverDTO agency.
         * @member {taxi.IDriverAgencyDTO|null|undefined} agency
         * @memberof taxi.DriverDTO
         * @instance
         */
        DriverDTO.prototype.agency = null;

        /**
         * Creates a new DriverDTO instance using the specified properties.
         * @function create
         * @memberof taxi.DriverDTO
         * @static
         * @param {taxi.IDriverDTO=} [properties] Properties to set
         * @returns {taxi.DriverDTO} DriverDTO instance
         */
        DriverDTO.create = function create(properties) {
            return new DriverDTO(properties);
        };

        /**
         * Creates a DriverDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.DriverDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.DriverDTO} DriverDTO
         */
        DriverDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.DriverDTO)
                return d;
            var m = new $root.taxi.DriverDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.profileUrl != null) {
                m.profileUrl = String(d.profileUrl);
            }
            if (d.createdAt != null) {
                if ($util.Long)
                    (m.createdAt = $util.Long.fromValue(d.createdAt)).unsigned = false;
                else if (typeof d.createdAt === "string")
                    m.createdAt = parseInt(d.createdAt, 10);
                else if (typeof d.createdAt === "number")
                    m.createdAt = d.createdAt;
                else if (typeof d.createdAt === "object")
                    m.createdAt = new $util.LongBits(d.createdAt.low >>> 0, d.createdAt.high >>> 0).toNumber();
            }
            if (d.agency != null) {
                if (typeof d.agency !== "object")
                    throw TypeError(".taxi.DriverDTO.agency: object expected");
                m.agency = $root.taxi.DriverAgencyDTO.fromObject(d.agency);
            }
            return m;
        };

        /**
         * Creates a plain object from a DriverDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.DriverDTO
         * @static
         * @param {taxi.DriverDTO} m DriverDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DriverDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.email = "";
                d.name = "";
                d.phoneNumber = "";
                d.profileUrl = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.createdAt = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.createdAt = o.longs === String ? "0" : 0;
                d.agency = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.profileUrl != null && m.hasOwnProperty("profileUrl")) {
                d.profileUrl = m.profileUrl;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                if (typeof m.createdAt === "number")
                    d.createdAt = o.longs === String ? String(m.createdAt) : m.createdAt;
                else
                    d.createdAt = o.longs === String ? $util.Long.prototype.toString.call(m.createdAt) : o.longs === Number ? new $util.LongBits(m.createdAt.low >>> 0, m.createdAt.high >>> 0).toNumber() : m.createdAt;
            }
            if (m.agency != null && m.hasOwnProperty("agency")) {
                d.agency = $root.taxi.DriverAgencyDTO.toObject(m.agency, o);
            }
            return d;
        };

        /**
         * Converts this DriverDTO to JSON.
         * @function toJSON
         * @memberof taxi.DriverDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DriverDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DriverDTO;
    })();

    taxi.DriverAgencyDTO = (function() {

        /**
         * Properties of a DriverAgencyDTO.
         * @memberof taxi
         * @interface IDriverAgencyDTO
         * @property {string|null} [id] DriverAgencyDTO id
         * @property {string|null} [name] DriverAgencyDTO name
         */

        /**
         * Constructs a new DriverAgencyDTO.
         * @memberof taxi
         * @classdesc Represents a DriverAgencyDTO.
         * @implements IDriverAgencyDTO
         * @constructor
         * @param {taxi.IDriverAgencyDTO=} [p] Properties to set
         */
        function DriverAgencyDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DriverAgencyDTO id.
         * @member {string} id
         * @memberof taxi.DriverAgencyDTO
         * @instance
         */
        DriverAgencyDTO.prototype.id = "";

        /**
         * DriverAgencyDTO name.
         * @member {string} name
         * @memberof taxi.DriverAgencyDTO
         * @instance
         */
        DriverAgencyDTO.prototype.name = "";

        /**
         * Creates a new DriverAgencyDTO instance using the specified properties.
         * @function create
         * @memberof taxi.DriverAgencyDTO
         * @static
         * @param {taxi.IDriverAgencyDTO=} [properties] Properties to set
         * @returns {taxi.DriverAgencyDTO} DriverAgencyDTO instance
         */
        DriverAgencyDTO.create = function create(properties) {
            return new DriverAgencyDTO(properties);
        };

        /**
         * Creates a DriverAgencyDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.DriverAgencyDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.DriverAgencyDTO} DriverAgencyDTO
         */
        DriverAgencyDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.DriverAgencyDTO)
                return d;
            var m = new $root.taxi.DriverAgencyDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        /**
         * Creates a plain object from a DriverAgencyDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.DriverAgencyDTO
         * @static
         * @param {taxi.DriverAgencyDTO} m DriverAgencyDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DriverAgencyDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        /**
         * Converts this DriverAgencyDTO to JSON.
         * @function toJSON
         * @memberof taxi.DriverAgencyDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DriverAgencyDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DriverAgencyDTO;
    })();

    /**
     * DriverActivityStatus enum.
     * @name taxi.DriverActivityStatus
     * @enum {string}
     * @property {number} OFF=0 OFF value
     * @property {number} IDLE=1 IDLE value
     * @property {number} DISPATCHING=2 DISPATCHING value
     * @property {number} RIDING=3 RIDING value
     * @property {number} TRIP_RETURNING=4 TRIP_RETURNING value
     * @property {number} TRIP_RIDING=5 TRIP_RIDING value
     */
    taxi.DriverActivityStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OFF"] = 0;
        values[valuesById[1] = "IDLE"] = 1;
        values[valuesById[2] = "DISPATCHING"] = 2;
        values[valuesById[3] = "RIDING"] = 3;
        values[valuesById[4] = "TRIP_RETURNING"] = 4;
        values[valuesById[5] = "TRIP_RIDING"] = 5;
        return values;
    })();

    /**
     * DriverLevel enum.
     * @name taxi.DriverLevel
     * @enum {string}
     * @property {number} BASIC=0 BASIC value
     * @property {number} GOOD=1 GOOD value
     * @property {number} BEST=2 BEST value
     * @property {number} PERFECT=3 PERFECT value
     */
    taxi.DriverLevel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BASIC"] = 0;
        values[valuesById[1] = "GOOD"] = 1;
        values[valuesById[2] = "BEST"] = 2;
        values[valuesById[3] = "PERFECT"] = 3;
        return values;
    })();

    taxi.DriverLevelDescription = (function() {

        /**
         * Properties of a DriverLevelDescription.
         * @memberof taxi
         * @interface IDriverLevelDescription
         * @property {taxi.DriverLevel|null} [value] DriverLevelDescription value
         * @property {string|null} [name] DriverLevelDescription name
         */

        /**
         * Constructs a new DriverLevelDescription.
         * @memberof taxi
         * @classdesc Represents a DriverLevelDescription.
         * @implements IDriverLevelDescription
         * @constructor
         * @param {taxi.IDriverLevelDescription=} [p] Properties to set
         */
        function DriverLevelDescription(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DriverLevelDescription value.
         * @member {taxi.DriverLevel} value
         * @memberof taxi.DriverLevelDescription
         * @instance
         */
        DriverLevelDescription.prototype.value = 0;

        /**
         * DriverLevelDescription name.
         * @member {string} name
         * @memberof taxi.DriverLevelDescription
         * @instance
         */
        DriverLevelDescription.prototype.name = "";

        /**
         * Creates a new DriverLevelDescription instance using the specified properties.
         * @function create
         * @memberof taxi.DriverLevelDescription
         * @static
         * @param {taxi.IDriverLevelDescription=} [properties] Properties to set
         * @returns {taxi.DriverLevelDescription} DriverLevelDescription instance
         */
        DriverLevelDescription.create = function create(properties) {
            return new DriverLevelDescription(properties);
        };

        /**
         * Creates a DriverLevelDescription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.DriverLevelDescription
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.DriverLevelDescription} DriverLevelDescription
         */
        DriverLevelDescription.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.DriverLevelDescription)
                return d;
            var m = new $root.taxi.DriverLevelDescription();
            switch (d.value) {
            case "BASIC":
            case 0:
                m.value = 0;
                break;
            case "GOOD":
            case 1:
                m.value = 1;
                break;
            case "BEST":
            case 2:
                m.value = 2;
                break;
            case "PERFECT":
            case 3:
                m.value = 3;
                break;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        /**
         * Creates a plain object from a DriverLevelDescription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.DriverLevelDescription
         * @static
         * @param {taxi.DriverLevelDescription} m DriverLevelDescription
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DriverLevelDescription.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = o.enums === String ? "BASIC" : 0;
                d.name = "";
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = o.enums === String ? $root.taxi.DriverLevel[m.value] : m.value;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        /**
         * Converts this DriverLevelDescription to JSON.
         * @function toJSON
         * @memberof taxi.DriverLevelDescription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DriverLevelDescription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DriverLevelDescription;
    })();

    taxi.DriverStatusDTO = (function() {

        /**
         * Properties of a DriverStatusDTO.
         * @memberof taxi
         * @interface IDriverStatusDTO
         * @property {taxi.DriverActivityStatus|null} [activityStatus] DriverStatusDTO activityStatus
         * @property {taxi.IDriverLevelDescription|null} [level] DriverStatusDTO level
         * @property {taxi.IDoubleValue|null} [rating] DriverStatusDTO rating
         * @property {taxi.IStringValue|null} [restingArea] DriverStatusDTO restingArea
         * @property {taxi.IAddressedLocation|null} [assignedArea] DriverStatusDTO assignedArea
         * @property {taxi.DriverStatusDTO.DriverType|null} [type] DriverStatusDTO type
         * @property {boolean|null} [isPrivate] DriverStatusDTO isPrivate
         * @property {taxi.IVehicleZoneDTO|null} [vehicleZone] DriverStatusDTO vehicleZone
         * @property {boolean|null} [hasTripReturning] DriverStatusDTO hasTripReturning
         * @property {taxi.IUInt32Value|null} [scheduledTripCount] DriverStatusDTO scheduledTripCount
         * @property {boolean|null} [isIndividualBusiness] DriverStatusDTO isIndividualBusiness
         * @property {boolean|null} [isIdentityCertificated] DriverStatusDTO isIdentityCertificated
         */

        /**
         * Constructs a new DriverStatusDTO.
         * @memberof taxi
         * @classdesc Represents a DriverStatusDTO.
         * @implements IDriverStatusDTO
         * @constructor
         * @param {taxi.IDriverStatusDTO=} [p] Properties to set
         */
        function DriverStatusDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DriverStatusDTO activityStatus.
         * @member {taxi.DriverActivityStatus} activityStatus
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.activityStatus = 0;

        /**
         * DriverStatusDTO level.
         * @member {taxi.IDriverLevelDescription|null|undefined} level
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.level = null;

        /**
         * DriverStatusDTO rating.
         * @member {taxi.IDoubleValue|null|undefined} rating
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.rating = null;

        /**
         * DriverStatusDTO restingArea.
         * @member {taxi.IStringValue|null|undefined} restingArea
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.restingArea = null;

        /**
         * DriverStatusDTO assignedArea.
         * @member {taxi.IAddressedLocation|null|undefined} assignedArea
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.assignedArea = null;

        /**
         * DriverStatusDTO type.
         * @member {taxi.DriverStatusDTO.DriverType} type
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.type = 0;

        /**
         * DriverStatusDTO isPrivate.
         * @member {boolean} isPrivate
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.isPrivate = false;

        /**
         * DriverStatusDTO vehicleZone.
         * @member {taxi.IVehicleZoneDTO|null|undefined} vehicleZone
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.vehicleZone = null;

        /**
         * DriverStatusDTO hasTripReturning.
         * @member {boolean} hasTripReturning
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.hasTripReturning = false;

        /**
         * DriverStatusDTO scheduledTripCount.
         * @member {taxi.IUInt32Value|null|undefined} scheduledTripCount
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.scheduledTripCount = null;

        /**
         * DriverStatusDTO isIndividualBusiness.
         * @member {boolean} isIndividualBusiness
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.isIndividualBusiness = false;

        /**
         * DriverStatusDTO isIdentityCertificated.
         * @member {boolean} isIdentityCertificated
         * @memberof taxi.DriverStatusDTO
         * @instance
         */
        DriverStatusDTO.prototype.isIdentityCertificated = false;

        /**
         * Creates a new DriverStatusDTO instance using the specified properties.
         * @function create
         * @memberof taxi.DriverStatusDTO
         * @static
         * @param {taxi.IDriverStatusDTO=} [properties] Properties to set
         * @returns {taxi.DriverStatusDTO} DriverStatusDTO instance
         */
        DriverStatusDTO.create = function create(properties) {
            return new DriverStatusDTO(properties);
        };

        /**
         * Creates a DriverStatusDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.DriverStatusDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.DriverStatusDTO} DriverStatusDTO
         */
        DriverStatusDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.DriverStatusDTO)
                return d;
            var m = new $root.taxi.DriverStatusDTO();
            switch (d.activityStatus) {
            case "OFF":
            case 0:
                m.activityStatus = 0;
                break;
            case "IDLE":
            case 1:
                m.activityStatus = 1;
                break;
            case "DISPATCHING":
            case 2:
                m.activityStatus = 2;
                break;
            case "RIDING":
            case 3:
                m.activityStatus = 3;
                break;
            case "TRIP_RETURNING":
            case 4:
                m.activityStatus = 4;
                break;
            case "TRIP_RIDING":
            case 5:
                m.activityStatus = 5;
                break;
            }
            if (d.level != null) {
                if (typeof d.level !== "object")
                    throw TypeError(".taxi.DriverStatusDTO.level: object expected");
                m.level = $root.taxi.DriverLevelDescription.fromObject(d.level);
            }
            if (d.rating != null) {
                if (typeof d.rating !== "object")
                    throw TypeError(".taxi.DriverStatusDTO.rating: object expected");
                m.rating = $root.taxi.DoubleValue.fromObject(d.rating);
            }
            if (d.restingArea != null) {
                if (typeof d.restingArea !== "object")
                    throw TypeError(".taxi.DriverStatusDTO.restingArea: object expected");
                m.restingArea = $root.taxi.StringValue.fromObject(d.restingArea);
            }
            if (d.assignedArea != null) {
                if (typeof d.assignedArea !== "object")
                    throw TypeError(".taxi.DriverStatusDTO.assignedArea: object expected");
                m.assignedArea = $root.taxi.AddressedLocation.fromObject(d.assignedArea);
            }
            switch (d.type) {
            case "BASIC":
            case 0:
                m.type = 0;
                break;
            case "ASSIST":
            case 1:
                m.type = 1;
                break;
            case "VIPVAN":
            case 2:
                m.type = 2;
                break;
            case "PREMIUM":
            case 3:
                m.type = 3;
                break;
            }
            if (d.isPrivate != null) {
                m.isPrivate = Boolean(d.isPrivate);
            }
            if (d.vehicleZone != null) {
                if (typeof d.vehicleZone !== "object")
                    throw TypeError(".taxi.DriverStatusDTO.vehicleZone: object expected");
                m.vehicleZone = $root.taxi.VehicleZoneDTO.fromObject(d.vehicleZone);
            }
            if (d.hasTripReturning != null) {
                m.hasTripReturning = Boolean(d.hasTripReturning);
            }
            if (d.scheduledTripCount != null) {
                if (typeof d.scheduledTripCount !== "object")
                    throw TypeError(".taxi.DriverStatusDTO.scheduledTripCount: object expected");
                m.scheduledTripCount = $root.taxi.UInt32Value.fromObject(d.scheduledTripCount);
            }
            if (d.isIndividualBusiness != null) {
                m.isIndividualBusiness = Boolean(d.isIndividualBusiness);
            }
            if (d.isIdentityCertificated != null) {
                m.isIdentityCertificated = Boolean(d.isIdentityCertificated);
            }
            return m;
        };

        /**
         * Creates a plain object from a DriverStatusDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.DriverStatusDTO
         * @static
         * @param {taxi.DriverStatusDTO} m DriverStatusDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DriverStatusDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.activityStatus = o.enums === String ? "OFF" : 0;
                d.rating = null;
                d.restingArea = null;
                d.isPrivate = false;
                d.level = null;
                d.assignedArea = null;
                d.type = o.enums === String ? "BASIC" : 0;
                d.vehicleZone = null;
                d.hasTripReturning = false;
                d.scheduledTripCount = null;
                d.isIndividualBusiness = false;
                d.isIdentityCertificated = false;
            }
            if (m.activityStatus != null && m.hasOwnProperty("activityStatus")) {
                d.activityStatus = o.enums === String ? $root.taxi.DriverActivityStatus[m.activityStatus] : m.activityStatus;
            }
            if (m.rating != null && m.hasOwnProperty("rating")) {
                d.rating = $root.taxi.DoubleValue.toObject(m.rating, o);
            }
            if (m.restingArea != null && m.hasOwnProperty("restingArea")) {
                d.restingArea = $root.taxi.StringValue.toObject(m.restingArea, o);
            }
            if (m.isPrivate != null && m.hasOwnProperty("isPrivate")) {
                d.isPrivate = m.isPrivate;
            }
            if (m.level != null && m.hasOwnProperty("level")) {
                d.level = $root.taxi.DriverLevelDescription.toObject(m.level, o);
            }
            if (m.assignedArea != null && m.hasOwnProperty("assignedArea")) {
                d.assignedArea = $root.taxi.AddressedLocation.toObject(m.assignedArea, o);
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.taxi.DriverStatusDTO.DriverType[m.type] : m.type;
            }
            if (m.vehicleZone != null && m.hasOwnProperty("vehicleZone")) {
                d.vehicleZone = $root.taxi.VehicleZoneDTO.toObject(m.vehicleZone, o);
            }
            if (m.hasTripReturning != null && m.hasOwnProperty("hasTripReturning")) {
                d.hasTripReturning = m.hasTripReturning;
            }
            if (m.scheduledTripCount != null && m.hasOwnProperty("scheduledTripCount")) {
                d.scheduledTripCount = $root.taxi.UInt32Value.toObject(m.scheduledTripCount, o);
            }
            if (m.isIndividualBusiness != null && m.hasOwnProperty("isIndividualBusiness")) {
                d.isIndividualBusiness = m.isIndividualBusiness;
            }
            if (m.isIdentityCertificated != null && m.hasOwnProperty("isIdentityCertificated")) {
                d.isIdentityCertificated = m.isIdentityCertificated;
            }
            return d;
        };

        /**
         * Converts this DriverStatusDTO to JSON.
         * @function toJSON
         * @memberof taxi.DriverStatusDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DriverStatusDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * DriverType enum.
         * @name taxi.DriverStatusDTO.DriverType
         * @enum {string}
         * @property {number} BASIC=0 BASIC value
         * @property {number} ASSIST=1 ASSIST value
         * @property {number} VIPVAN=2 VIPVAN value
         * @property {number} PREMIUM=3 PREMIUM value
         */
        DriverStatusDTO.DriverType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BASIC"] = 0;
            values[valuesById[1] = "ASSIST"] = 1;
            values[valuesById[2] = "VIPVAN"] = 2;
            values[valuesById[3] = "PREMIUM"] = 3;
            return values;
        })();

        return DriverStatusDTO;
    })();

    taxi.DriverScheduleDTO = (function() {

        /**
         * Properties of a DriverScheduleDTO.
         * @memberof taxi
         * @interface IDriverScheduleDTO
         * @property {number|null} [startAt] DriverScheduleDTO startAt
         * @property {number|null} [endAt] DriverScheduleDTO endAt
         * @property {taxi.IVehicleZoneDTO|null} [vehicleZone] DriverScheduleDTO vehicleZone
         * @property {taxi.IVehicleDTO|null} [vehicle] DriverScheduleDTO vehicle
         */

        /**
         * Constructs a new DriverScheduleDTO.
         * @memberof taxi
         * @classdesc Represents a DriverScheduleDTO.
         * @implements IDriverScheduleDTO
         * @constructor
         * @param {taxi.IDriverScheduleDTO=} [p] Properties to set
         */
        function DriverScheduleDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DriverScheduleDTO startAt.
         * @member {number} startAt
         * @memberof taxi.DriverScheduleDTO
         * @instance
         */
        DriverScheduleDTO.prototype.startAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DriverScheduleDTO endAt.
         * @member {number} endAt
         * @memberof taxi.DriverScheduleDTO
         * @instance
         */
        DriverScheduleDTO.prototype.endAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DriverScheduleDTO vehicleZone.
         * @member {taxi.IVehicleZoneDTO|null|undefined} vehicleZone
         * @memberof taxi.DriverScheduleDTO
         * @instance
         */
        DriverScheduleDTO.prototype.vehicleZone = null;

        /**
         * DriverScheduleDTO vehicle.
         * @member {taxi.IVehicleDTO|null|undefined} vehicle
         * @memberof taxi.DriverScheduleDTO
         * @instance
         */
        DriverScheduleDTO.prototype.vehicle = null;

        /**
         * Creates a new DriverScheduleDTO instance using the specified properties.
         * @function create
         * @memberof taxi.DriverScheduleDTO
         * @static
         * @param {taxi.IDriverScheduleDTO=} [properties] Properties to set
         * @returns {taxi.DriverScheduleDTO} DriverScheduleDTO instance
         */
        DriverScheduleDTO.create = function create(properties) {
            return new DriverScheduleDTO(properties);
        };

        /**
         * Creates a DriverScheduleDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.DriverScheduleDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.DriverScheduleDTO} DriverScheduleDTO
         */
        DriverScheduleDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.DriverScheduleDTO)
                return d;
            var m = new $root.taxi.DriverScheduleDTO();
            if (d.startAt != null) {
                if ($util.Long)
                    (m.startAt = $util.Long.fromValue(d.startAt)).unsigned = false;
                else if (typeof d.startAt === "string")
                    m.startAt = parseInt(d.startAt, 10);
                else if (typeof d.startAt === "number")
                    m.startAt = d.startAt;
                else if (typeof d.startAt === "object")
                    m.startAt = new $util.LongBits(d.startAt.low >>> 0, d.startAt.high >>> 0).toNumber();
            }
            if (d.endAt != null) {
                if ($util.Long)
                    (m.endAt = $util.Long.fromValue(d.endAt)).unsigned = false;
                else if (typeof d.endAt === "string")
                    m.endAt = parseInt(d.endAt, 10);
                else if (typeof d.endAt === "number")
                    m.endAt = d.endAt;
                else if (typeof d.endAt === "object")
                    m.endAt = new $util.LongBits(d.endAt.low >>> 0, d.endAt.high >>> 0).toNumber();
            }
            if (d.vehicleZone != null) {
                if (typeof d.vehicleZone !== "object")
                    throw TypeError(".taxi.DriverScheduleDTO.vehicleZone: object expected");
                m.vehicleZone = $root.taxi.VehicleZoneDTO.fromObject(d.vehicleZone);
            }
            if (d.vehicle != null) {
                if (typeof d.vehicle !== "object")
                    throw TypeError(".taxi.DriverScheduleDTO.vehicle: object expected");
                m.vehicle = $root.taxi.VehicleDTO.fromObject(d.vehicle);
            }
            return m;
        };

        /**
         * Creates a plain object from a DriverScheduleDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.DriverScheduleDTO
         * @static
         * @param {taxi.DriverScheduleDTO} m DriverScheduleDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DriverScheduleDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startAt = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startAt = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endAt = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endAt = o.longs === String ? "0" : 0;
                d.vehicleZone = null;
                d.vehicle = null;
            }
            if (m.startAt != null && m.hasOwnProperty("startAt")) {
                if (typeof m.startAt === "number")
                    d.startAt = o.longs === String ? String(m.startAt) : m.startAt;
                else
                    d.startAt = o.longs === String ? $util.Long.prototype.toString.call(m.startAt) : o.longs === Number ? new $util.LongBits(m.startAt.low >>> 0, m.startAt.high >>> 0).toNumber() : m.startAt;
            }
            if (m.endAt != null && m.hasOwnProperty("endAt")) {
                if (typeof m.endAt === "number")
                    d.endAt = o.longs === String ? String(m.endAt) : m.endAt;
                else
                    d.endAt = o.longs === String ? $util.Long.prototype.toString.call(m.endAt) : o.longs === Number ? new $util.LongBits(m.endAt.low >>> 0, m.endAt.high >>> 0).toNumber() : m.endAt;
            }
            if (m.vehicleZone != null && m.hasOwnProperty("vehicleZone")) {
                d.vehicleZone = $root.taxi.VehicleZoneDTO.toObject(m.vehicleZone, o);
            }
            if (m.vehicle != null && m.hasOwnProperty("vehicle")) {
                d.vehicle = $root.taxi.VehicleDTO.toObject(m.vehicle, o);
            }
            return d;
        };

        /**
         * Converts this DriverScheduleDTO to JSON.
         * @function toJSON
         * @memberof taxi.DriverScheduleDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DriverScheduleDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DriverScheduleDTO;
    })();

    taxi.PaymentMethodDTO = (function() {

        /**
         * Properties of a PaymentMethodDTO.
         * @memberof taxi
         * @interface IPaymentMethodDTO
         * @property {string|null} [id] PaymentMethodDTO id
         * @property {taxi.PaymentMethodDTO.Status|null} [status] PaymentMethodDTO status
         * @property {taxi.PaymentMethodDTO.Type|null} [type] PaymentMethodDTO type
         * @property {number|null} [createdAt] PaymentMethodDTO createdAt
         * @property {taxi.PaymentMethodDTO.ICard|null} [card] PaymentMethodDTO card
         * @property {taxi.PaymentMethodDTO.IBizCard|null} [bizCard] PaymentMethodDTO bizCard
         * @property {taxi.PaymentMethodDTO.IBizCredit|null} [bizCredit] PaymentMethodDTO bizCredit
         */

        /**
         * Constructs a new PaymentMethodDTO.
         * @memberof taxi
         * @classdesc Represents a PaymentMethodDTO.
         * @implements IPaymentMethodDTO
         * @constructor
         * @param {taxi.IPaymentMethodDTO=} [p] Properties to set
         */
        function PaymentMethodDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentMethodDTO id.
         * @member {string} id
         * @memberof taxi.PaymentMethodDTO
         * @instance
         */
        PaymentMethodDTO.prototype.id = "";

        /**
         * PaymentMethodDTO status.
         * @member {taxi.PaymentMethodDTO.Status} status
         * @memberof taxi.PaymentMethodDTO
         * @instance
         */
        PaymentMethodDTO.prototype.status = 0;

        /**
         * PaymentMethodDTO type.
         * @member {taxi.PaymentMethodDTO.Type} type
         * @memberof taxi.PaymentMethodDTO
         * @instance
         */
        PaymentMethodDTO.prototype.type = 0;

        /**
         * PaymentMethodDTO createdAt.
         * @member {number} createdAt
         * @memberof taxi.PaymentMethodDTO
         * @instance
         */
        PaymentMethodDTO.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PaymentMethodDTO card.
         * @member {taxi.PaymentMethodDTO.ICard|null|undefined} card
         * @memberof taxi.PaymentMethodDTO
         * @instance
         */
        PaymentMethodDTO.prototype.card = null;

        /**
         * PaymentMethodDTO bizCard.
         * @member {taxi.PaymentMethodDTO.IBizCard|null|undefined} bizCard
         * @memberof taxi.PaymentMethodDTO
         * @instance
         */
        PaymentMethodDTO.prototype.bizCard = null;

        /**
         * PaymentMethodDTO bizCredit.
         * @member {taxi.PaymentMethodDTO.IBizCredit|null|undefined} bizCredit
         * @memberof taxi.PaymentMethodDTO
         * @instance
         */
        PaymentMethodDTO.prototype.bizCredit = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * PaymentMethodDTO method.
         * @member {"card"|"bizCard"|"bizCredit"|undefined} method
         * @memberof taxi.PaymentMethodDTO
         * @instance
         */
        Object.defineProperty(PaymentMethodDTO.prototype, "method", {
            get: $util.oneOfGetter($oneOfFields = ["card", "bizCard", "bizCredit"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PaymentMethodDTO instance using the specified properties.
         * @function create
         * @memberof taxi.PaymentMethodDTO
         * @static
         * @param {taxi.IPaymentMethodDTO=} [properties] Properties to set
         * @returns {taxi.PaymentMethodDTO} PaymentMethodDTO instance
         */
        PaymentMethodDTO.create = function create(properties) {
            return new PaymentMethodDTO(properties);
        };

        /**
         * Creates a PaymentMethodDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.PaymentMethodDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.PaymentMethodDTO} PaymentMethodDTO
         */
        PaymentMethodDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.PaymentMethodDTO)
                return d;
            var m = new $root.taxi.PaymentMethodDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.status) {
            case "ACTIVE":
            case 0:
                m.status = 0;
                break;
            case "EXPIRED":
            case 1:
                m.status = 1;
                break;
            case "PAYMENT_FAILED":
            case 2:
                m.status = 2;
                break;
            case "DISABLED":
            case 3:
                m.status = 3;
                break;
            }
            switch (d.type) {
            case "CARD":
            case 0:
                m.type = 0;
                break;
            case "BIZ_CARD":
            case 1:
                m.type = 1;
                break;
            case "BIZ_CREDIT":
            case 2:
                m.type = 2;
                break;
            }
            if (d.createdAt != null) {
                if ($util.Long)
                    (m.createdAt = $util.Long.fromValue(d.createdAt)).unsigned = false;
                else if (typeof d.createdAt === "string")
                    m.createdAt = parseInt(d.createdAt, 10);
                else if (typeof d.createdAt === "number")
                    m.createdAt = d.createdAt;
                else if (typeof d.createdAt === "object")
                    m.createdAt = new $util.LongBits(d.createdAt.low >>> 0, d.createdAt.high >>> 0).toNumber();
            }
            if (d.card != null) {
                if (typeof d.card !== "object")
                    throw TypeError(".taxi.PaymentMethodDTO.card: object expected");
                m.card = $root.taxi.PaymentMethodDTO.Card.fromObject(d.card);
            }
            if (d.bizCard != null) {
                if (typeof d.bizCard !== "object")
                    throw TypeError(".taxi.PaymentMethodDTO.bizCard: object expected");
                m.bizCard = $root.taxi.PaymentMethodDTO.BizCard.fromObject(d.bizCard);
            }
            if (d.bizCredit != null) {
                if (typeof d.bizCredit !== "object")
                    throw TypeError(".taxi.PaymentMethodDTO.bizCredit: object expected");
                m.bizCredit = $root.taxi.PaymentMethodDTO.BizCredit.fromObject(d.bizCredit);
            }
            return m;
        };

        /**
         * Creates a plain object from a PaymentMethodDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.PaymentMethodDTO
         * @static
         * @param {taxi.PaymentMethodDTO} m PaymentMethodDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentMethodDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.status = o.enums === String ? "ACTIVE" : 0;
                d.type = o.enums === String ? "CARD" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.createdAt = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.createdAt = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.taxi.PaymentMethodDTO.Status[m.status] : m.status;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.taxi.PaymentMethodDTO.Type[m.type] : m.type;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                if (typeof m.createdAt === "number")
                    d.createdAt = o.longs === String ? String(m.createdAt) : m.createdAt;
                else
                    d.createdAt = o.longs === String ? $util.Long.prototype.toString.call(m.createdAt) : o.longs === Number ? new $util.LongBits(m.createdAt.low >>> 0, m.createdAt.high >>> 0).toNumber() : m.createdAt;
            }
            if (m.card != null && m.hasOwnProperty("card")) {
                d.card = $root.taxi.PaymentMethodDTO.Card.toObject(m.card, o);
                if (o.oneofs)
                    d.method = "card";
            }
            if (m.bizCard != null && m.hasOwnProperty("bizCard")) {
                d.bizCard = $root.taxi.PaymentMethodDTO.BizCard.toObject(m.bizCard, o);
                if (o.oneofs)
                    d.method = "bizCard";
            }
            if (m.bizCredit != null && m.hasOwnProperty("bizCredit")) {
                d.bizCredit = $root.taxi.PaymentMethodDTO.BizCredit.toObject(m.bizCredit, o);
                if (o.oneofs)
                    d.method = "bizCredit";
            }
            return d;
        };

        /**
         * Converts this PaymentMethodDTO to JSON.
         * @function toJSON
         * @memberof taxi.PaymentMethodDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentMethodDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Status enum.
         * @name taxi.PaymentMethodDTO.Status
         * @enum {string}
         * @property {number} ACTIVE=0 ACTIVE value
         * @property {number} EXPIRED=1 EXPIRED value
         * @property {number} PAYMENT_FAILED=2 PAYMENT_FAILED value
         * @property {number} DISABLED=3 DISABLED value
         */
        PaymentMethodDTO.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ACTIVE"] = 0;
            values[valuesById[1] = "EXPIRED"] = 1;
            values[valuesById[2] = "PAYMENT_FAILED"] = 2;
            values[valuesById[3] = "DISABLED"] = 3;
            return values;
        })();

        /**
         * Type enum.
         * @name taxi.PaymentMethodDTO.Type
         * @enum {string}
         * @property {number} CARD=0 CARD value
         * @property {number} BIZ_CARD=1 BIZ_CARD value
         * @property {number} BIZ_CREDIT=2 BIZ_CREDIT value
         */
        PaymentMethodDTO.Type = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CARD"] = 0;
            values[valuesById[1] = "BIZ_CARD"] = 1;
            values[valuesById[2] = "BIZ_CREDIT"] = 2;
            return values;
        })();

        /**
         * CardType enum.
         * @name taxi.PaymentMethodDTO.CardType
         * @enum {string}
         * @property {number} CORPORATION=0 CORPORATION value
         * @property {number} PERSONAL=1 PERSONAL value
         */
        PaymentMethodDTO.CardType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CORPORATION"] = 0;
            values[valuesById[1] = "PERSONAL"] = 1;
            return values;
        })();

        PaymentMethodDTO.CardExpiry = (function() {

            /**
             * Properties of a CardExpiry.
             * @memberof taxi.PaymentMethodDTO
             * @interface ICardExpiry
             * @property {number|null} [year] CardExpiry year
             * @property {number|null} [month] CardExpiry month
             */

            /**
             * Constructs a new CardExpiry.
             * @memberof taxi.PaymentMethodDTO
             * @classdesc Represents a CardExpiry.
             * @implements ICardExpiry
             * @constructor
             * @param {taxi.PaymentMethodDTO.ICardExpiry=} [p] Properties to set
             */
            function CardExpiry(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * CardExpiry year.
             * @member {number} year
             * @memberof taxi.PaymentMethodDTO.CardExpiry
             * @instance
             */
            CardExpiry.prototype.year = 0;

            /**
             * CardExpiry month.
             * @member {number} month
             * @memberof taxi.PaymentMethodDTO.CardExpiry
             * @instance
             */
            CardExpiry.prototype.month = 0;

            /**
             * Creates a new CardExpiry instance using the specified properties.
             * @function create
             * @memberof taxi.PaymentMethodDTO.CardExpiry
             * @static
             * @param {taxi.PaymentMethodDTO.ICardExpiry=} [properties] Properties to set
             * @returns {taxi.PaymentMethodDTO.CardExpiry} CardExpiry instance
             */
            CardExpiry.create = function create(properties) {
                return new CardExpiry(properties);
            };

            /**
             * Creates a CardExpiry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.PaymentMethodDTO.CardExpiry
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.PaymentMethodDTO.CardExpiry} CardExpiry
             */
            CardExpiry.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.PaymentMethodDTO.CardExpiry)
                    return d;
                var m = new $root.taxi.PaymentMethodDTO.CardExpiry();
                if (d.year != null) {
                    m.year = d.year | 0;
                }
                if (d.month != null) {
                    m.month = d.month | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a CardExpiry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.PaymentMethodDTO.CardExpiry
             * @static
             * @param {taxi.PaymentMethodDTO.CardExpiry} m CardExpiry
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CardExpiry.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.year = 0;
                    d.month = 0;
                }
                if (m.year != null && m.hasOwnProperty("year")) {
                    d.year = m.year;
                }
                if (m.month != null && m.hasOwnProperty("month")) {
                    d.month = m.month;
                }
                return d;
            };

            /**
             * Converts this CardExpiry to JSON.
             * @function toJSON
             * @memberof taxi.PaymentMethodDTO.CardExpiry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CardExpiry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CardExpiry;
        })();

        /**
         * CardPartialNumberType enum.
         * @name taxi.PaymentMethodDTO.CardPartialNumberType
         * @enum {string}
         * @property {number} SUFFIX=0 SUFFIX value
         * @property {number} PREFIX=1 PREFIX value
         */
        PaymentMethodDTO.CardPartialNumberType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SUFFIX"] = 0;
            values[valuesById[1] = "PREFIX"] = 1;
            return values;
        })();

        PaymentMethodDTO.Card = (function() {

            /**
             * Properties of a Card.
             * @memberof taxi.PaymentMethodDTO
             * @interface ICard
             * @property {taxi.PaymentMethodDTO.CardType|null} [type] Card type
             * @property {string|null} [publisher] Card publisher
             * @property {string|null} [partialNumber] Card partialNumber
             * @property {taxi.PaymentMethodDTO.ICardExpiry|null} [expiry] Card expiry
             * @property {taxi.PaymentMethodDTO.CardPartialNumberType|null} [partialNumberType] Card partialNumberType
             */

            /**
             * Constructs a new Card.
             * @memberof taxi.PaymentMethodDTO
             * @classdesc Represents a Card.
             * @implements ICard
             * @constructor
             * @param {taxi.PaymentMethodDTO.ICard=} [p] Properties to set
             */
            function Card(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Card type.
             * @member {taxi.PaymentMethodDTO.CardType} type
             * @memberof taxi.PaymentMethodDTO.Card
             * @instance
             */
            Card.prototype.type = 0;

            /**
             * Card publisher.
             * @member {string} publisher
             * @memberof taxi.PaymentMethodDTO.Card
             * @instance
             */
            Card.prototype.publisher = "";

            /**
             * Card partialNumber.
             * @member {string} partialNumber
             * @memberof taxi.PaymentMethodDTO.Card
             * @instance
             */
            Card.prototype.partialNumber = "";

            /**
             * Card expiry.
             * @member {taxi.PaymentMethodDTO.ICardExpiry|null|undefined} expiry
             * @memberof taxi.PaymentMethodDTO.Card
             * @instance
             */
            Card.prototype.expiry = null;

            /**
             * Card partialNumberType.
             * @member {taxi.PaymentMethodDTO.CardPartialNumberType} partialNumberType
             * @memberof taxi.PaymentMethodDTO.Card
             * @instance
             */
            Card.prototype.partialNumberType = 0;

            /**
             * Creates a new Card instance using the specified properties.
             * @function create
             * @memberof taxi.PaymentMethodDTO.Card
             * @static
             * @param {taxi.PaymentMethodDTO.ICard=} [properties] Properties to set
             * @returns {taxi.PaymentMethodDTO.Card} Card instance
             */
            Card.create = function create(properties) {
                return new Card(properties);
            };

            /**
             * Creates a Card message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.PaymentMethodDTO.Card
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.PaymentMethodDTO.Card} Card
             */
            Card.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.PaymentMethodDTO.Card)
                    return d;
                var m = new $root.taxi.PaymentMethodDTO.Card();
                switch (d.type) {
                case "CORPORATION":
                case 0:
                    m.type = 0;
                    break;
                case "PERSONAL":
                case 1:
                    m.type = 1;
                    break;
                }
                if (d.publisher != null) {
                    m.publisher = String(d.publisher);
                }
                if (d.partialNumber != null) {
                    m.partialNumber = String(d.partialNumber);
                }
                if (d.expiry != null) {
                    if (typeof d.expiry !== "object")
                        throw TypeError(".taxi.PaymentMethodDTO.Card.expiry: object expected");
                    m.expiry = $root.taxi.PaymentMethodDTO.CardExpiry.fromObject(d.expiry);
                }
                switch (d.partialNumberType) {
                case "SUFFIX":
                case 0:
                    m.partialNumberType = 0;
                    break;
                case "PREFIX":
                case 1:
                    m.partialNumberType = 1;
                    break;
                }
                return m;
            };

            /**
             * Creates a plain object from a Card message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.PaymentMethodDTO.Card
             * @static
             * @param {taxi.PaymentMethodDTO.Card} m Card
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Card.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.type = o.enums === String ? "CORPORATION" : 0;
                    d.publisher = "";
                    d.partialNumber = "";
                    d.expiry = null;
                    d.partialNumberType = o.enums === String ? "SUFFIX" : 0;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.taxi.PaymentMethodDTO.CardType[m.type] : m.type;
                }
                if (m.publisher != null && m.hasOwnProperty("publisher")) {
                    d.publisher = m.publisher;
                }
                if (m.partialNumber != null && m.hasOwnProperty("partialNumber")) {
                    d.partialNumber = m.partialNumber;
                }
                if (m.expiry != null && m.hasOwnProperty("expiry")) {
                    d.expiry = $root.taxi.PaymentMethodDTO.CardExpiry.toObject(m.expiry, o);
                }
                if (m.partialNumberType != null && m.hasOwnProperty("partialNumberType")) {
                    d.partialNumberType = o.enums === String ? $root.taxi.PaymentMethodDTO.CardPartialNumberType[m.partialNumberType] : m.partialNumberType;
                }
                return d;
            };

            /**
             * Converts this Card to JSON.
             * @function toJSON
             * @memberof taxi.PaymentMethodDTO.Card
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Card.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Card;
        })();

        PaymentMethodDTO.BizCard = (function() {

            /**
             * Properties of a BizCard.
             * @memberof taxi.PaymentMethodDTO
             * @interface IBizCard
             */

            /**
             * Constructs a new BizCard.
             * @memberof taxi.PaymentMethodDTO
             * @classdesc Represents a BizCard.
             * @implements IBizCard
             * @constructor
             * @param {taxi.PaymentMethodDTO.IBizCard=} [p] Properties to set
             */
            function BizCard(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Creates a new BizCard instance using the specified properties.
             * @function create
             * @memberof taxi.PaymentMethodDTO.BizCard
             * @static
             * @param {taxi.PaymentMethodDTO.IBizCard=} [properties] Properties to set
             * @returns {taxi.PaymentMethodDTO.BizCard} BizCard instance
             */
            BizCard.create = function create(properties) {
                return new BizCard(properties);
            };

            /**
             * Creates a BizCard message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.PaymentMethodDTO.BizCard
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.PaymentMethodDTO.BizCard} BizCard
             */
            BizCard.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.PaymentMethodDTO.BizCard)
                    return d;
                return new $root.taxi.PaymentMethodDTO.BizCard();
            };

            /**
             * Creates a plain object from a BizCard message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.PaymentMethodDTO.BizCard
             * @static
             * @param {taxi.PaymentMethodDTO.BizCard} m BizCard
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BizCard.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BizCard to JSON.
             * @function toJSON
             * @memberof taxi.PaymentMethodDTO.BizCard
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BizCard.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BizCard;
        })();

        PaymentMethodDTO.BizCredit = (function() {

            /**
             * Properties of a BizCredit.
             * @memberof taxi.PaymentMethodDTO
             * @interface IBizCredit
             * @property {boolean|null} [insufficientCredit] BizCredit insufficientCredit
             */

            /**
             * Constructs a new BizCredit.
             * @memberof taxi.PaymentMethodDTO
             * @classdesc Represents a BizCredit.
             * @implements IBizCredit
             * @constructor
             * @param {taxi.PaymentMethodDTO.IBizCredit=} [p] Properties to set
             */
            function BizCredit(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * BizCredit insufficientCredit.
             * @member {boolean} insufficientCredit
             * @memberof taxi.PaymentMethodDTO.BizCredit
             * @instance
             */
            BizCredit.prototype.insufficientCredit = false;

            /**
             * Creates a new BizCredit instance using the specified properties.
             * @function create
             * @memberof taxi.PaymentMethodDTO.BizCredit
             * @static
             * @param {taxi.PaymentMethodDTO.IBizCredit=} [properties] Properties to set
             * @returns {taxi.PaymentMethodDTO.BizCredit} BizCredit instance
             */
            BizCredit.create = function create(properties) {
                return new BizCredit(properties);
            };

            /**
             * Creates a BizCredit message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.PaymentMethodDTO.BizCredit
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.PaymentMethodDTO.BizCredit} BizCredit
             */
            BizCredit.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.PaymentMethodDTO.BizCredit)
                    return d;
                var m = new $root.taxi.PaymentMethodDTO.BizCredit();
                if (d.insufficientCredit != null) {
                    m.insufficientCredit = Boolean(d.insufficientCredit);
                }
                return m;
            };

            /**
             * Creates a plain object from a BizCredit message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.PaymentMethodDTO.BizCredit
             * @static
             * @param {taxi.PaymentMethodDTO.BizCredit} m BizCredit
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BizCredit.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.insufficientCredit = false;
                }
                if (m.insufficientCredit != null && m.hasOwnProperty("insufficientCredit")) {
                    d.insufficientCredit = m.insufficientCredit;
                }
                return d;
            };

            /**
             * Converts this BizCredit to JSON.
             * @function toJSON
             * @memberof taxi.PaymentMethodDTO.BizCredit
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BizCredit.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BizCredit;
        })();

        return PaymentMethodDTO;
    })();

    taxi.PaymentDTO = (function() {

        /**
         * Properties of a PaymentDTO.
         * @memberof taxi
         * @interface IPaymentDTO
         * @property {string|null} [id] PaymentDTO id
         * @property {number|null} [amount] PaymentDTO amount
         * @property {taxi.PaymentDTO.Status|null} [status] PaymentDTO status
         * @property {number|null} [createdAt] PaymentDTO createdAt
         * @property {string|null} [description] PaymentDTO description
         * @property {taxi.IPaymentMethodDTO|null} [paymentMethod] PaymentDTO paymentMethod
         * @property {taxi.IPaymentProfileDTO|null} [paymentProfile] PaymentDTO paymentProfile
         */

        /**
         * Constructs a new PaymentDTO.
         * @memberof taxi
         * @classdesc Represents a PaymentDTO.
         * @implements IPaymentDTO
         * @constructor
         * @param {taxi.IPaymentDTO=} [p] Properties to set
         */
        function PaymentDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentDTO id.
         * @member {string} id
         * @memberof taxi.PaymentDTO
         * @instance
         */
        PaymentDTO.prototype.id = "";

        /**
         * PaymentDTO amount.
         * @member {number} amount
         * @memberof taxi.PaymentDTO
         * @instance
         */
        PaymentDTO.prototype.amount = 0;

        /**
         * PaymentDTO status.
         * @member {taxi.PaymentDTO.Status} status
         * @memberof taxi.PaymentDTO
         * @instance
         */
        PaymentDTO.prototype.status = 0;

        /**
         * PaymentDTO createdAt.
         * @member {number} createdAt
         * @memberof taxi.PaymentDTO
         * @instance
         */
        PaymentDTO.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PaymentDTO description.
         * @member {string} description
         * @memberof taxi.PaymentDTO
         * @instance
         */
        PaymentDTO.prototype.description = "";

        /**
         * PaymentDTO paymentMethod.
         * @member {taxi.IPaymentMethodDTO|null|undefined} paymentMethod
         * @memberof taxi.PaymentDTO
         * @instance
         */
        PaymentDTO.prototype.paymentMethod = null;

        /**
         * PaymentDTO paymentProfile.
         * @member {taxi.IPaymentProfileDTO|null|undefined} paymentProfile
         * @memberof taxi.PaymentDTO
         * @instance
         */
        PaymentDTO.prototype.paymentProfile = null;

        /**
         * Creates a new PaymentDTO instance using the specified properties.
         * @function create
         * @memberof taxi.PaymentDTO
         * @static
         * @param {taxi.IPaymentDTO=} [properties] Properties to set
         * @returns {taxi.PaymentDTO} PaymentDTO instance
         */
        PaymentDTO.create = function create(properties) {
            return new PaymentDTO(properties);
        };

        /**
         * Creates a PaymentDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.PaymentDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.PaymentDTO} PaymentDTO
         */
        PaymentDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.PaymentDTO)
                return d;
            var m = new $root.taxi.PaymentDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.amount != null) {
                m.amount = d.amount | 0;
            }
            switch (d.status) {
            case "PENDING":
            case 0:
                m.status = 0;
                break;
            case "COMPLETED":
            case 1:
                m.status = 1;
                break;
            case "FAILED":
            case 2:
                m.status = 2;
                break;
            case "RETRYING":
            case 3:
                m.status = 3;
                break;
            case "REFUND_FAILED":
            case 4:
                m.status = 4;
                break;
            }
            if (d.createdAt != null) {
                if ($util.Long)
                    (m.createdAt = $util.Long.fromValue(d.createdAt)).unsigned = false;
                else if (typeof d.createdAt === "string")
                    m.createdAt = parseInt(d.createdAt, 10);
                else if (typeof d.createdAt === "number")
                    m.createdAt = d.createdAt;
                else if (typeof d.createdAt === "object")
                    m.createdAt = new $util.LongBits(d.createdAt.low >>> 0, d.createdAt.high >>> 0).toNumber();
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.paymentMethod != null) {
                if (typeof d.paymentMethod !== "object")
                    throw TypeError(".taxi.PaymentDTO.paymentMethod: object expected");
                m.paymentMethod = $root.taxi.PaymentMethodDTO.fromObject(d.paymentMethod);
            }
            if (d.paymentProfile != null) {
                if (typeof d.paymentProfile !== "object")
                    throw TypeError(".taxi.PaymentDTO.paymentProfile: object expected");
                m.paymentProfile = $root.taxi.PaymentProfileDTO.fromObject(d.paymentProfile);
            }
            return m;
        };

        /**
         * Creates a plain object from a PaymentDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.PaymentDTO
         * @static
         * @param {taxi.PaymentDTO} m PaymentDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.amount = 0;
                d.status = o.enums === String ? "PENDING" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.createdAt = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.createdAt = o.longs === String ? "0" : 0;
                d.description = "";
                d.paymentMethod = null;
                d.paymentProfile = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.amount != null && m.hasOwnProperty("amount")) {
                d.amount = m.amount;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.taxi.PaymentDTO.Status[m.status] : m.status;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                if (typeof m.createdAt === "number")
                    d.createdAt = o.longs === String ? String(m.createdAt) : m.createdAt;
                else
                    d.createdAt = o.longs === String ? $util.Long.prototype.toString.call(m.createdAt) : o.longs === Number ? new $util.LongBits(m.createdAt.low >>> 0, m.createdAt.high >>> 0).toNumber() : m.createdAt;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.paymentMethod != null && m.hasOwnProperty("paymentMethod")) {
                d.paymentMethod = $root.taxi.PaymentMethodDTO.toObject(m.paymentMethod, o);
            }
            if (m.paymentProfile != null && m.hasOwnProperty("paymentProfile")) {
                d.paymentProfile = $root.taxi.PaymentProfileDTO.toObject(m.paymentProfile, o);
            }
            return d;
        };

        /**
         * Converts this PaymentDTO to JSON.
         * @function toJSON
         * @memberof taxi.PaymentDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Status enum.
         * @name taxi.PaymentDTO.Status
         * @enum {string}
         * @property {number} PENDING=0 PENDING value
         * @property {number} COMPLETED=1 COMPLETED value
         * @property {number} FAILED=2 FAILED value
         * @property {number} RETRYING=3 RETRYING value
         * @property {number} REFUND_FAILED=4 REFUND_FAILED value
         */
        PaymentDTO.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PENDING"] = 0;
            values[valuesById[1] = "COMPLETED"] = 1;
            values[valuesById[2] = "FAILED"] = 2;
            values[valuesById[3] = "RETRYING"] = 3;
            values[valuesById[4] = "REFUND_FAILED"] = 4;
            return values;
        })();

        return PaymentDTO;
    })();

    taxi.DriverActivityStatisticsEntryDTO = (function() {

        /**
         * Properties of a DriverActivityStatisticsEntryDTO.
         * @memberof taxi
         * @interface IDriverActivityStatisticsEntryDTO
         * @property {string|null} [period] DriverActivityStatisticsEntryDTO period
         * @property {taxi.DriverActivityStatisticsEntryDTO.PeriodType|null} [periodType] DriverActivityStatisticsEntryDTO periodType
         * @property {taxi.DriverActivityStatisticsEntryDTO.ITimeRange|null} [timeRange] DriverActivityStatisticsEntryDTO timeRange
         * @property {taxi.DriverActivityStatisticsEntryDTO.IRideStatistics|null} [statistics] DriverActivityStatisticsEntryDTO statistics
         * @property {taxi.DriverActivityStatusStatisticsEntryDTO.IDriverActivityStatusStatistics|null} [activityStatistics] DriverActivityStatisticsEntryDTO activityStatistics
         * @property {taxi.DriverActivityStatisticsEntryDTO.ITripStatistics|null} [tripStatistics] DriverActivityStatisticsEntryDTO tripStatistics
         * @property {string|null} [date] DriverActivityStatisticsEntryDTO date
         */

        /**
         * Constructs a new DriverActivityStatisticsEntryDTO.
         * @memberof taxi
         * @classdesc Represents a DriverActivityStatisticsEntryDTO.
         * @implements IDriverActivityStatisticsEntryDTO
         * @constructor
         * @param {taxi.IDriverActivityStatisticsEntryDTO=} [p] Properties to set
         */
        function DriverActivityStatisticsEntryDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DriverActivityStatisticsEntryDTO period.
         * @member {string} period
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatisticsEntryDTO.prototype.period = "";

        /**
         * DriverActivityStatisticsEntryDTO periodType.
         * @member {taxi.DriverActivityStatisticsEntryDTO.PeriodType} periodType
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatisticsEntryDTO.prototype.periodType = 0;

        /**
         * DriverActivityStatisticsEntryDTO timeRange.
         * @member {taxi.DriverActivityStatisticsEntryDTO.ITimeRange|null|undefined} timeRange
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatisticsEntryDTO.prototype.timeRange = null;

        /**
         * DriverActivityStatisticsEntryDTO statistics.
         * @member {taxi.DriverActivityStatisticsEntryDTO.IRideStatistics|null|undefined} statistics
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatisticsEntryDTO.prototype.statistics = null;

        /**
         * DriverActivityStatisticsEntryDTO activityStatistics.
         * @member {taxi.DriverActivityStatusStatisticsEntryDTO.IDriverActivityStatusStatistics|null|undefined} activityStatistics
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatisticsEntryDTO.prototype.activityStatistics = null;

        /**
         * DriverActivityStatisticsEntryDTO tripStatistics.
         * @member {taxi.DriverActivityStatisticsEntryDTO.ITripStatistics|null|undefined} tripStatistics
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatisticsEntryDTO.prototype.tripStatistics = null;

        /**
         * DriverActivityStatisticsEntryDTO date.
         * @member {string} date
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatisticsEntryDTO.prototype.date = "";

        /**
         * Creates a new DriverActivityStatisticsEntryDTO instance using the specified properties.
         * @function create
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @static
         * @param {taxi.IDriverActivityStatisticsEntryDTO=} [properties] Properties to set
         * @returns {taxi.DriverActivityStatisticsEntryDTO} DriverActivityStatisticsEntryDTO instance
         */
        DriverActivityStatisticsEntryDTO.create = function create(properties) {
            return new DriverActivityStatisticsEntryDTO(properties);
        };

        /**
         * Creates a DriverActivityStatisticsEntryDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.DriverActivityStatisticsEntryDTO} DriverActivityStatisticsEntryDTO
         */
        DriverActivityStatisticsEntryDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.DriverActivityStatisticsEntryDTO)
                return d;
            var m = new $root.taxi.DriverActivityStatisticsEntryDTO();
            if (d.period != null) {
                m.period = String(d.period);
            }
            switch (d.periodType) {
            case "DAILY":
            case 0:
                m.periodType = 0;
                break;
            case "MONTHLY":
            case 1:
                m.periodType = 1;
                break;
            }
            if (d.timeRange != null) {
                if (typeof d.timeRange !== "object")
                    throw TypeError(".taxi.DriverActivityStatisticsEntryDTO.timeRange: object expected");
                m.timeRange = $root.taxi.DriverActivityStatisticsEntryDTO.TimeRange.fromObject(d.timeRange);
            }
            if (d.statistics != null) {
                if (typeof d.statistics !== "object")
                    throw TypeError(".taxi.DriverActivityStatisticsEntryDTO.statistics: object expected");
                m.statistics = $root.taxi.DriverActivityStatisticsEntryDTO.RideStatistics.fromObject(d.statistics);
            }
            if (d.activityStatistics != null) {
                if (typeof d.activityStatistics !== "object")
                    throw TypeError(".taxi.DriverActivityStatisticsEntryDTO.activityStatistics: object expected");
                m.activityStatistics = $root.taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics.fromObject(d.activityStatistics);
            }
            if (d.tripStatistics != null) {
                if (typeof d.tripStatistics !== "object")
                    throw TypeError(".taxi.DriverActivityStatisticsEntryDTO.tripStatistics: object expected");
                m.tripStatistics = $root.taxi.DriverActivityStatisticsEntryDTO.TripStatistics.fromObject(d.tripStatistics);
            }
            if (d.date != null) {
                m.date = String(d.date);
            }
            return m;
        };

        /**
         * Creates a plain object from a DriverActivityStatisticsEntryDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @static
         * @param {taxi.DriverActivityStatisticsEntryDTO} m DriverActivityStatisticsEntryDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DriverActivityStatisticsEntryDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.period = "";
                d.periodType = o.enums === String ? "DAILY" : 0;
                d.timeRange = null;
                d.statistics = null;
                d.activityStatistics = null;
                d.tripStatistics = null;
                d.date = "";
            }
            if (m.period != null && m.hasOwnProperty("period")) {
                d.period = m.period;
            }
            if (m.periodType != null && m.hasOwnProperty("periodType")) {
                d.periodType = o.enums === String ? $root.taxi.DriverActivityStatisticsEntryDTO.PeriodType[m.periodType] : m.periodType;
            }
            if (m.timeRange != null && m.hasOwnProperty("timeRange")) {
                d.timeRange = $root.taxi.DriverActivityStatisticsEntryDTO.TimeRange.toObject(m.timeRange, o);
            }
            if (m.statistics != null && m.hasOwnProperty("statistics")) {
                d.statistics = $root.taxi.DriverActivityStatisticsEntryDTO.RideStatistics.toObject(m.statistics, o);
            }
            if (m.activityStatistics != null && m.hasOwnProperty("activityStatistics")) {
                d.activityStatistics = $root.taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics.toObject(m.activityStatistics, o);
            }
            if (m.tripStatistics != null && m.hasOwnProperty("tripStatistics")) {
                d.tripStatistics = $root.taxi.DriverActivityStatisticsEntryDTO.TripStatistics.toObject(m.tripStatistics, o);
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                d.date = m.date;
            }
            return d;
        };

        /**
         * Converts this DriverActivityStatisticsEntryDTO to JSON.
         * @function toJSON
         * @memberof taxi.DriverActivityStatisticsEntryDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DriverActivityStatisticsEntryDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * PeriodType enum.
         * @name taxi.DriverActivityStatisticsEntryDTO.PeriodType
         * @enum {string}
         * @property {number} DAILY=0 DAILY value
         * @property {number} MONTHLY=1 MONTHLY value
         */
        DriverActivityStatisticsEntryDTO.PeriodType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAILY"] = 0;
            values[valuesById[1] = "MONTHLY"] = 1;
            return values;
        })();

        DriverActivityStatisticsEntryDTO.TimeRange = (function() {

            /**
             * Properties of a TimeRange.
             * @memberof taxi.DriverActivityStatisticsEntryDTO
             * @interface ITimeRange
             * @property {number|null} [startMs] TimeRange startMs
             * @property {number|null} [endMs] TimeRange endMs
             */

            /**
             * Constructs a new TimeRange.
             * @memberof taxi.DriverActivityStatisticsEntryDTO
             * @classdesc Represents a TimeRange.
             * @implements ITimeRange
             * @constructor
             * @param {taxi.DriverActivityStatisticsEntryDTO.ITimeRange=} [p] Properties to set
             */
            function TimeRange(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TimeRange startMs.
             * @member {number} startMs
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TimeRange
             * @instance
             */
            TimeRange.prototype.startMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TimeRange endMs.
             * @member {number} endMs
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TimeRange
             * @instance
             */
            TimeRange.prototype.endMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new TimeRange instance using the specified properties.
             * @function create
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TimeRange
             * @static
             * @param {taxi.DriverActivityStatisticsEntryDTO.ITimeRange=} [properties] Properties to set
             * @returns {taxi.DriverActivityStatisticsEntryDTO.TimeRange} TimeRange instance
             */
            TimeRange.create = function create(properties) {
                return new TimeRange(properties);
            };

            /**
             * Creates a TimeRange message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TimeRange
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.DriverActivityStatisticsEntryDTO.TimeRange} TimeRange
             */
            TimeRange.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.DriverActivityStatisticsEntryDTO.TimeRange)
                    return d;
                var m = new $root.taxi.DriverActivityStatisticsEntryDTO.TimeRange();
                if (d.startMs != null) {
                    if ($util.Long)
                        (m.startMs = $util.Long.fromValue(d.startMs)).unsigned = false;
                    else if (typeof d.startMs === "string")
                        m.startMs = parseInt(d.startMs, 10);
                    else if (typeof d.startMs === "number")
                        m.startMs = d.startMs;
                    else if (typeof d.startMs === "object")
                        m.startMs = new $util.LongBits(d.startMs.low >>> 0, d.startMs.high >>> 0).toNumber();
                }
                if (d.endMs != null) {
                    if ($util.Long)
                        (m.endMs = $util.Long.fromValue(d.endMs)).unsigned = false;
                    else if (typeof d.endMs === "string")
                        m.endMs = parseInt(d.endMs, 10);
                    else if (typeof d.endMs === "number")
                        m.endMs = d.endMs;
                    else if (typeof d.endMs === "object")
                        m.endMs = new $util.LongBits(d.endMs.low >>> 0, d.endMs.high >>> 0).toNumber();
                }
                return m;
            };

            /**
             * Creates a plain object from a TimeRange message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TimeRange
             * @static
             * @param {taxi.DriverActivityStatisticsEntryDTO.TimeRange} m TimeRange
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TimeRange.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.startMs = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.startMs = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.endMs = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.endMs = o.longs === String ? "0" : 0;
                }
                if (m.startMs != null && m.hasOwnProperty("startMs")) {
                    if (typeof m.startMs === "number")
                        d.startMs = o.longs === String ? String(m.startMs) : m.startMs;
                    else
                        d.startMs = o.longs === String ? $util.Long.prototype.toString.call(m.startMs) : o.longs === Number ? new $util.LongBits(m.startMs.low >>> 0, m.startMs.high >>> 0).toNumber() : m.startMs;
                }
                if (m.endMs != null && m.hasOwnProperty("endMs")) {
                    if (typeof m.endMs === "number")
                        d.endMs = o.longs === String ? String(m.endMs) : m.endMs;
                    else
                        d.endMs = o.longs === String ? $util.Long.prototype.toString.call(m.endMs) : o.longs === Number ? new $util.LongBits(m.endMs.low >>> 0, m.endMs.high >>> 0).toNumber() : m.endMs;
                }
                return d;
            };

            /**
             * Converts this TimeRange to JSON.
             * @function toJSON
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TimeRange
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TimeRange.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TimeRange;
        })();

        DriverActivityStatisticsEntryDTO.RideStatistics = (function() {

            /**
             * Properties of a RideStatistics.
             * @memberof taxi.DriverActivityStatisticsEntryDTO
             * @interface IRideStatistics
             * @property {number|null} [totalCount] RideStatistics totalCount
             * @property {number|null} [totalDurationSeconds] RideStatistics totalDurationSeconds
             * @property {number|null} [totalDistanceMeters] RideStatistics totalDistanceMeters
             * @property {number|null} [totalCost] RideStatistics totalCost
             * @property {number|null} [totalDriverCancelledCount] RideStatistics totalDriverCancelledCount
             * @property {number|null} [totalRiderCancelledCount] RideStatistics totalRiderCancelledCount
             * @property {number|null} [totalDispatchCount] RideStatistics totalDispatchCount
             * @property {number|null} [totalDispatchAcceptCount] RideStatistics totalDispatchAcceptCount
             * @property {number|null} [totalDispatchRejectCount] RideStatistics totalDispatchRejectCount
             * @property {number|null} [totalDispatchTimeoutCount] RideStatistics totalDispatchTimeoutCount
             */

            /**
             * Constructs a new RideStatistics.
             * @memberof taxi.DriverActivityStatisticsEntryDTO
             * @classdesc Represents a RideStatistics.
             * @implements IRideStatistics
             * @constructor
             * @param {taxi.DriverActivityStatisticsEntryDTO.IRideStatistics=} [p] Properties to set
             */
            function RideStatistics(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RideStatistics totalCount.
             * @member {number} totalCount
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalCount = 0;

            /**
             * RideStatistics totalDurationSeconds.
             * @member {number} totalDurationSeconds
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalDurationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * RideStatistics totalDistanceMeters.
             * @member {number} totalDistanceMeters
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalDistanceMeters = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * RideStatistics totalCost.
             * @member {number} totalCost
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalCost = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * RideStatistics totalDriverCancelledCount.
             * @member {number} totalDriverCancelledCount
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalDriverCancelledCount = 0;

            /**
             * RideStatistics totalRiderCancelledCount.
             * @member {number} totalRiderCancelledCount
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalRiderCancelledCount = 0;

            /**
             * RideStatistics totalDispatchCount.
             * @member {number} totalDispatchCount
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalDispatchCount = 0;

            /**
             * RideStatistics totalDispatchAcceptCount.
             * @member {number} totalDispatchAcceptCount
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalDispatchAcceptCount = 0;

            /**
             * RideStatistics totalDispatchRejectCount.
             * @member {number} totalDispatchRejectCount
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalDispatchRejectCount = 0;

            /**
             * RideStatistics totalDispatchTimeoutCount.
             * @member {number} totalDispatchTimeoutCount
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             */
            RideStatistics.prototype.totalDispatchTimeoutCount = 0;

            /**
             * Creates a new RideStatistics instance using the specified properties.
             * @function create
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @static
             * @param {taxi.DriverActivityStatisticsEntryDTO.IRideStatistics=} [properties] Properties to set
             * @returns {taxi.DriverActivityStatisticsEntryDTO.RideStatistics} RideStatistics instance
             */
            RideStatistics.create = function create(properties) {
                return new RideStatistics(properties);
            };

            /**
             * Creates a RideStatistics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.DriverActivityStatisticsEntryDTO.RideStatistics} RideStatistics
             */
            RideStatistics.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.DriverActivityStatisticsEntryDTO.RideStatistics)
                    return d;
                var m = new $root.taxi.DriverActivityStatisticsEntryDTO.RideStatistics();
                if (d.totalCount != null) {
                    m.totalCount = d.totalCount | 0;
                }
                if (d.totalDurationSeconds != null) {
                    if ($util.Long)
                        (m.totalDurationSeconds = $util.Long.fromValue(d.totalDurationSeconds)).unsigned = false;
                    else if (typeof d.totalDurationSeconds === "string")
                        m.totalDurationSeconds = parseInt(d.totalDurationSeconds, 10);
                    else if (typeof d.totalDurationSeconds === "number")
                        m.totalDurationSeconds = d.totalDurationSeconds;
                    else if (typeof d.totalDurationSeconds === "object")
                        m.totalDurationSeconds = new $util.LongBits(d.totalDurationSeconds.low >>> 0, d.totalDurationSeconds.high >>> 0).toNumber();
                }
                if (d.totalDistanceMeters != null) {
                    if ($util.Long)
                        (m.totalDistanceMeters = $util.Long.fromValue(d.totalDistanceMeters)).unsigned = false;
                    else if (typeof d.totalDistanceMeters === "string")
                        m.totalDistanceMeters = parseInt(d.totalDistanceMeters, 10);
                    else if (typeof d.totalDistanceMeters === "number")
                        m.totalDistanceMeters = d.totalDistanceMeters;
                    else if (typeof d.totalDistanceMeters === "object")
                        m.totalDistanceMeters = new $util.LongBits(d.totalDistanceMeters.low >>> 0, d.totalDistanceMeters.high >>> 0).toNumber();
                }
                if (d.totalCost != null) {
                    if ($util.Long)
                        (m.totalCost = $util.Long.fromValue(d.totalCost)).unsigned = false;
                    else if (typeof d.totalCost === "string")
                        m.totalCost = parseInt(d.totalCost, 10);
                    else if (typeof d.totalCost === "number")
                        m.totalCost = d.totalCost;
                    else if (typeof d.totalCost === "object")
                        m.totalCost = new $util.LongBits(d.totalCost.low >>> 0, d.totalCost.high >>> 0).toNumber();
                }
                if (d.totalDriverCancelledCount != null) {
                    m.totalDriverCancelledCount = d.totalDriverCancelledCount | 0;
                }
                if (d.totalRiderCancelledCount != null) {
                    m.totalRiderCancelledCount = d.totalRiderCancelledCount | 0;
                }
                if (d.totalDispatchCount != null) {
                    m.totalDispatchCount = d.totalDispatchCount | 0;
                }
                if (d.totalDispatchAcceptCount != null) {
                    m.totalDispatchAcceptCount = d.totalDispatchAcceptCount | 0;
                }
                if (d.totalDispatchRejectCount != null) {
                    m.totalDispatchRejectCount = d.totalDispatchRejectCount | 0;
                }
                if (d.totalDispatchTimeoutCount != null) {
                    m.totalDispatchTimeoutCount = d.totalDispatchTimeoutCount | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a RideStatistics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @static
             * @param {taxi.DriverActivityStatisticsEntryDTO.RideStatistics} m RideStatistics
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RideStatistics.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.totalCount = 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalDurationSeconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalDurationSeconds = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalDistanceMeters = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalDistanceMeters = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalCost = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalCost = o.longs === String ? "0" : 0;
                    d.totalDriverCancelledCount = 0;
                    d.totalRiderCancelledCount = 0;
                    d.totalDispatchCount = 0;
                    d.totalDispatchAcceptCount = 0;
                    d.totalDispatchRejectCount = 0;
                    d.totalDispatchTimeoutCount = 0;
                }
                if (m.totalCount != null && m.hasOwnProperty("totalCount")) {
                    d.totalCount = m.totalCount;
                }
                if (m.totalDurationSeconds != null && m.hasOwnProperty("totalDurationSeconds")) {
                    if (typeof m.totalDurationSeconds === "number")
                        d.totalDurationSeconds = o.longs === String ? String(m.totalDurationSeconds) : m.totalDurationSeconds;
                    else
                        d.totalDurationSeconds = o.longs === String ? $util.Long.prototype.toString.call(m.totalDurationSeconds) : o.longs === Number ? new $util.LongBits(m.totalDurationSeconds.low >>> 0, m.totalDurationSeconds.high >>> 0).toNumber() : m.totalDurationSeconds;
                }
                if (m.totalDistanceMeters != null && m.hasOwnProperty("totalDistanceMeters")) {
                    if (typeof m.totalDistanceMeters === "number")
                        d.totalDistanceMeters = o.longs === String ? String(m.totalDistanceMeters) : m.totalDistanceMeters;
                    else
                        d.totalDistanceMeters = o.longs === String ? $util.Long.prototype.toString.call(m.totalDistanceMeters) : o.longs === Number ? new $util.LongBits(m.totalDistanceMeters.low >>> 0, m.totalDistanceMeters.high >>> 0).toNumber() : m.totalDistanceMeters;
                }
                if (m.totalCost != null && m.hasOwnProperty("totalCost")) {
                    if (typeof m.totalCost === "number")
                        d.totalCost = o.longs === String ? String(m.totalCost) : m.totalCost;
                    else
                        d.totalCost = o.longs === String ? $util.Long.prototype.toString.call(m.totalCost) : o.longs === Number ? new $util.LongBits(m.totalCost.low >>> 0, m.totalCost.high >>> 0).toNumber() : m.totalCost;
                }
                if (m.totalDriverCancelledCount != null && m.hasOwnProperty("totalDriverCancelledCount")) {
                    d.totalDriverCancelledCount = m.totalDriverCancelledCount;
                }
                if (m.totalRiderCancelledCount != null && m.hasOwnProperty("totalRiderCancelledCount")) {
                    d.totalRiderCancelledCount = m.totalRiderCancelledCount;
                }
                if (m.totalDispatchCount != null && m.hasOwnProperty("totalDispatchCount")) {
                    d.totalDispatchCount = m.totalDispatchCount;
                }
                if (m.totalDispatchAcceptCount != null && m.hasOwnProperty("totalDispatchAcceptCount")) {
                    d.totalDispatchAcceptCount = m.totalDispatchAcceptCount;
                }
                if (m.totalDispatchRejectCount != null && m.hasOwnProperty("totalDispatchRejectCount")) {
                    d.totalDispatchRejectCount = m.totalDispatchRejectCount;
                }
                if (m.totalDispatchTimeoutCount != null && m.hasOwnProperty("totalDispatchTimeoutCount")) {
                    d.totalDispatchTimeoutCount = m.totalDispatchTimeoutCount;
                }
                return d;
            };

            /**
             * Converts this RideStatistics to JSON.
             * @function toJSON
             * @memberof taxi.DriverActivityStatisticsEntryDTO.RideStatistics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RideStatistics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RideStatistics;
        })();

        DriverActivityStatisticsEntryDTO.TripStatistics = (function() {

            /**
             * Properties of a TripStatistics.
             * @memberof taxi.DriverActivityStatisticsEntryDTO
             * @interface ITripStatistics
             * @property {number|null} [totalCount] TripStatistics totalCount
             * @property {number|null} [totalDurationSeconds] TripStatistics totalDurationSeconds
             * @property {number|null} [totalDistanceMeters] TripStatistics totalDistanceMeters
             * @property {number|null} [totalCost] TripStatistics totalCost
             */

            /**
             * Constructs a new TripStatistics.
             * @memberof taxi.DriverActivityStatisticsEntryDTO
             * @classdesc Represents a TripStatistics.
             * @implements ITripStatistics
             * @constructor
             * @param {taxi.DriverActivityStatisticsEntryDTO.ITripStatistics=} [p] Properties to set
             */
            function TripStatistics(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * TripStatistics totalCount.
             * @member {number} totalCount
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TripStatistics
             * @instance
             */
            TripStatistics.prototype.totalCount = 0;

            /**
             * TripStatistics totalDurationSeconds.
             * @member {number} totalDurationSeconds
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TripStatistics
             * @instance
             */
            TripStatistics.prototype.totalDurationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TripStatistics totalDistanceMeters.
             * @member {number} totalDistanceMeters
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TripStatistics
             * @instance
             */
            TripStatistics.prototype.totalDistanceMeters = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TripStatistics totalCost.
             * @member {number} totalCost
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TripStatistics
             * @instance
             */
            TripStatistics.prototype.totalCost = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new TripStatistics instance using the specified properties.
             * @function create
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TripStatistics
             * @static
             * @param {taxi.DriverActivityStatisticsEntryDTO.ITripStatistics=} [properties] Properties to set
             * @returns {taxi.DriverActivityStatisticsEntryDTO.TripStatistics} TripStatistics instance
             */
            TripStatistics.create = function create(properties) {
                return new TripStatistics(properties);
            };

            /**
             * Creates a TripStatistics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TripStatistics
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.DriverActivityStatisticsEntryDTO.TripStatistics} TripStatistics
             */
            TripStatistics.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.DriverActivityStatisticsEntryDTO.TripStatistics)
                    return d;
                var m = new $root.taxi.DriverActivityStatisticsEntryDTO.TripStatistics();
                if (d.totalCount != null) {
                    m.totalCount = d.totalCount | 0;
                }
                if (d.totalDurationSeconds != null) {
                    if ($util.Long)
                        (m.totalDurationSeconds = $util.Long.fromValue(d.totalDurationSeconds)).unsigned = false;
                    else if (typeof d.totalDurationSeconds === "string")
                        m.totalDurationSeconds = parseInt(d.totalDurationSeconds, 10);
                    else if (typeof d.totalDurationSeconds === "number")
                        m.totalDurationSeconds = d.totalDurationSeconds;
                    else if (typeof d.totalDurationSeconds === "object")
                        m.totalDurationSeconds = new $util.LongBits(d.totalDurationSeconds.low >>> 0, d.totalDurationSeconds.high >>> 0).toNumber();
                }
                if (d.totalDistanceMeters != null) {
                    if ($util.Long)
                        (m.totalDistanceMeters = $util.Long.fromValue(d.totalDistanceMeters)).unsigned = false;
                    else if (typeof d.totalDistanceMeters === "string")
                        m.totalDistanceMeters = parseInt(d.totalDistanceMeters, 10);
                    else if (typeof d.totalDistanceMeters === "number")
                        m.totalDistanceMeters = d.totalDistanceMeters;
                    else if (typeof d.totalDistanceMeters === "object")
                        m.totalDistanceMeters = new $util.LongBits(d.totalDistanceMeters.low >>> 0, d.totalDistanceMeters.high >>> 0).toNumber();
                }
                if (d.totalCost != null) {
                    if ($util.Long)
                        (m.totalCost = $util.Long.fromValue(d.totalCost)).unsigned = false;
                    else if (typeof d.totalCost === "string")
                        m.totalCost = parseInt(d.totalCost, 10);
                    else if (typeof d.totalCost === "number")
                        m.totalCost = d.totalCost;
                    else if (typeof d.totalCost === "object")
                        m.totalCost = new $util.LongBits(d.totalCost.low >>> 0, d.totalCost.high >>> 0).toNumber();
                }
                return m;
            };

            /**
             * Creates a plain object from a TripStatistics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TripStatistics
             * @static
             * @param {taxi.DriverActivityStatisticsEntryDTO.TripStatistics} m TripStatistics
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TripStatistics.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.totalCount = 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalDurationSeconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalDurationSeconds = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalDistanceMeters = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalDistanceMeters = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalCost = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalCost = o.longs === String ? "0" : 0;
                }
                if (m.totalCount != null && m.hasOwnProperty("totalCount")) {
                    d.totalCount = m.totalCount;
                }
                if (m.totalDurationSeconds != null && m.hasOwnProperty("totalDurationSeconds")) {
                    if (typeof m.totalDurationSeconds === "number")
                        d.totalDurationSeconds = o.longs === String ? String(m.totalDurationSeconds) : m.totalDurationSeconds;
                    else
                        d.totalDurationSeconds = o.longs === String ? $util.Long.prototype.toString.call(m.totalDurationSeconds) : o.longs === Number ? new $util.LongBits(m.totalDurationSeconds.low >>> 0, m.totalDurationSeconds.high >>> 0).toNumber() : m.totalDurationSeconds;
                }
                if (m.totalDistanceMeters != null && m.hasOwnProperty("totalDistanceMeters")) {
                    if (typeof m.totalDistanceMeters === "number")
                        d.totalDistanceMeters = o.longs === String ? String(m.totalDistanceMeters) : m.totalDistanceMeters;
                    else
                        d.totalDistanceMeters = o.longs === String ? $util.Long.prototype.toString.call(m.totalDistanceMeters) : o.longs === Number ? new $util.LongBits(m.totalDistanceMeters.low >>> 0, m.totalDistanceMeters.high >>> 0).toNumber() : m.totalDistanceMeters;
                }
                if (m.totalCost != null && m.hasOwnProperty("totalCost")) {
                    if (typeof m.totalCost === "number")
                        d.totalCost = o.longs === String ? String(m.totalCost) : m.totalCost;
                    else
                        d.totalCost = o.longs === String ? $util.Long.prototype.toString.call(m.totalCost) : o.longs === Number ? new $util.LongBits(m.totalCost.low >>> 0, m.totalCost.high >>> 0).toNumber() : m.totalCost;
                }
                return d;
            };

            /**
             * Converts this TripStatistics to JSON.
             * @function toJSON
             * @memberof taxi.DriverActivityStatisticsEntryDTO.TripStatistics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TripStatistics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TripStatistics;
        })();

        return DriverActivityStatisticsEntryDTO;
    })();

    taxi.ReviewStatisticsEntryDTO = (function() {

        /**
         * Properties of a ReviewStatisticsEntryDTO.
         * @memberof taxi
         * @interface IReviewStatisticsEntryDTO
         * @property {string|null} [period] ReviewStatisticsEntryDTO period
         * @property {taxi.ReviewStatisticsEntryDTO.IReviewStatistics|null} [statistics] ReviewStatisticsEntryDTO statistics
         */

        /**
         * Constructs a new ReviewStatisticsEntryDTO.
         * @memberof taxi
         * @classdesc Represents a ReviewStatisticsEntryDTO.
         * @implements IReviewStatisticsEntryDTO
         * @constructor
         * @param {taxi.IReviewStatisticsEntryDTO=} [p] Properties to set
         */
        function ReviewStatisticsEntryDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReviewStatisticsEntryDTO period.
         * @member {string} period
         * @memberof taxi.ReviewStatisticsEntryDTO
         * @instance
         */
        ReviewStatisticsEntryDTO.prototype.period = "";

        /**
         * ReviewStatisticsEntryDTO statistics.
         * @member {taxi.ReviewStatisticsEntryDTO.IReviewStatistics|null|undefined} statistics
         * @memberof taxi.ReviewStatisticsEntryDTO
         * @instance
         */
        ReviewStatisticsEntryDTO.prototype.statistics = null;

        /**
         * Creates a new ReviewStatisticsEntryDTO instance using the specified properties.
         * @function create
         * @memberof taxi.ReviewStatisticsEntryDTO
         * @static
         * @param {taxi.IReviewStatisticsEntryDTO=} [properties] Properties to set
         * @returns {taxi.ReviewStatisticsEntryDTO} ReviewStatisticsEntryDTO instance
         */
        ReviewStatisticsEntryDTO.create = function create(properties) {
            return new ReviewStatisticsEntryDTO(properties);
        };

        /**
         * Creates a ReviewStatisticsEntryDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.ReviewStatisticsEntryDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.ReviewStatisticsEntryDTO} ReviewStatisticsEntryDTO
         */
        ReviewStatisticsEntryDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.ReviewStatisticsEntryDTO)
                return d;
            var m = new $root.taxi.ReviewStatisticsEntryDTO();
            if (d.period != null) {
                m.period = String(d.period);
            }
            if (d.statistics != null) {
                if (typeof d.statistics !== "object")
                    throw TypeError(".taxi.ReviewStatisticsEntryDTO.statistics: object expected");
                m.statistics = $root.taxi.ReviewStatisticsEntryDTO.ReviewStatistics.fromObject(d.statistics);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReviewStatisticsEntryDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.ReviewStatisticsEntryDTO
         * @static
         * @param {taxi.ReviewStatisticsEntryDTO} m ReviewStatisticsEntryDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReviewStatisticsEntryDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.period = "";
                d.statistics = null;
            }
            if (m.period != null && m.hasOwnProperty("period")) {
                d.period = m.period;
            }
            if (m.statistics != null && m.hasOwnProperty("statistics")) {
                d.statistics = $root.taxi.ReviewStatisticsEntryDTO.ReviewStatistics.toObject(m.statistics, o);
            }
            return d;
        };

        /**
         * Converts this ReviewStatisticsEntryDTO to JSON.
         * @function toJSON
         * @memberof taxi.ReviewStatisticsEntryDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReviewStatisticsEntryDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO = (function() {

            /**
             * Properties of a ReviewReasonStatisticsEntryDTO.
             * @memberof taxi.ReviewStatisticsEntryDTO
             * @interface IReviewReasonStatisticsEntryDTO
             * @property {string|null} [reason] ReviewReasonStatisticsEntryDTO reason
             * @property {number|null} [count] ReviewReasonStatisticsEntryDTO count
             */

            /**
             * Constructs a new ReviewReasonStatisticsEntryDTO.
             * @memberof taxi.ReviewStatisticsEntryDTO
             * @classdesc Represents a ReviewReasonStatisticsEntryDTO.
             * @implements IReviewReasonStatisticsEntryDTO
             * @constructor
             * @param {taxi.ReviewStatisticsEntryDTO.IReviewReasonStatisticsEntryDTO=} [p] Properties to set
             */
            function ReviewReasonStatisticsEntryDTO(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ReviewReasonStatisticsEntryDTO reason.
             * @member {string} reason
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO
             * @instance
             */
            ReviewReasonStatisticsEntryDTO.prototype.reason = "";

            /**
             * ReviewReasonStatisticsEntryDTO count.
             * @member {number} count
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO
             * @instance
             */
            ReviewReasonStatisticsEntryDTO.prototype.count = 0;

            /**
             * Creates a new ReviewReasonStatisticsEntryDTO instance using the specified properties.
             * @function create
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO
             * @static
             * @param {taxi.ReviewStatisticsEntryDTO.IReviewReasonStatisticsEntryDTO=} [properties] Properties to set
             * @returns {taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO} ReviewReasonStatisticsEntryDTO instance
             */
            ReviewReasonStatisticsEntryDTO.create = function create(properties) {
                return new ReviewReasonStatisticsEntryDTO(properties);
            };

            /**
             * Creates a ReviewReasonStatisticsEntryDTO message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO} ReviewReasonStatisticsEntryDTO
             */
            ReviewReasonStatisticsEntryDTO.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO)
                    return d;
                var m = new $root.taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO();
                if (d.reason != null) {
                    m.reason = String(d.reason);
                }
                if (d.count != null) {
                    m.count = d.count | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a ReviewReasonStatisticsEntryDTO message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO
             * @static
             * @param {taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO} m ReviewReasonStatisticsEntryDTO
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReviewReasonStatisticsEntryDTO.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.reason = "";
                    d.count = 0;
                }
                if (m.reason != null && m.hasOwnProperty("reason")) {
                    d.reason = m.reason;
                }
                if (m.count != null && m.hasOwnProperty("count")) {
                    d.count = m.count;
                }
                return d;
            };

            /**
             * Converts this ReviewReasonStatisticsEntryDTO to JSON.
             * @function toJSON
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReviewReasonStatisticsEntryDTO.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ReviewReasonStatisticsEntryDTO;
        })();

        ReviewStatisticsEntryDTO.ReviewStatistics = (function() {

            /**
             * Properties of a ReviewStatistics.
             * @memberof taxi.ReviewStatisticsEntryDTO
             * @interface IReviewStatistics
             * @property {number|null} [totalCount] ReviewStatistics totalCount
             * @property {number|null} [totalSatisfiedCount] ReviewStatistics totalSatisfiedCount
             * @property {Array.<taxi.ReviewStatisticsEntryDTO.IReviewReasonStatisticsEntryDTO>|null} [reasonStatisticsEntries] ReviewStatistics reasonStatisticsEntries
             */

            /**
             * Constructs a new ReviewStatistics.
             * @memberof taxi.ReviewStatisticsEntryDTO
             * @classdesc Represents a ReviewStatistics.
             * @implements IReviewStatistics
             * @constructor
             * @param {taxi.ReviewStatisticsEntryDTO.IReviewStatistics=} [p] Properties to set
             */
            function ReviewStatistics(p) {
                this.reasonStatisticsEntries = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ReviewStatistics totalCount.
             * @member {number} totalCount
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewStatistics
             * @instance
             */
            ReviewStatistics.prototype.totalCount = 0;

            /**
             * ReviewStatistics totalSatisfiedCount.
             * @member {number} totalSatisfiedCount
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewStatistics
             * @instance
             */
            ReviewStatistics.prototype.totalSatisfiedCount = 0;

            /**
             * ReviewStatistics reasonStatisticsEntries.
             * @member {Array.<taxi.ReviewStatisticsEntryDTO.IReviewReasonStatisticsEntryDTO>} reasonStatisticsEntries
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewStatistics
             * @instance
             */
            ReviewStatistics.prototype.reasonStatisticsEntries = $util.emptyArray;

            /**
             * Creates a new ReviewStatistics instance using the specified properties.
             * @function create
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewStatistics
             * @static
             * @param {taxi.ReviewStatisticsEntryDTO.IReviewStatistics=} [properties] Properties to set
             * @returns {taxi.ReviewStatisticsEntryDTO.ReviewStatistics} ReviewStatistics instance
             */
            ReviewStatistics.create = function create(properties) {
                return new ReviewStatistics(properties);
            };

            /**
             * Creates a ReviewStatistics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewStatistics
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.ReviewStatisticsEntryDTO.ReviewStatistics} ReviewStatistics
             */
            ReviewStatistics.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.ReviewStatisticsEntryDTO.ReviewStatistics)
                    return d;
                var m = new $root.taxi.ReviewStatisticsEntryDTO.ReviewStatistics();
                if (d.totalCount != null) {
                    m.totalCount = d.totalCount | 0;
                }
                if (d.totalSatisfiedCount != null) {
                    m.totalSatisfiedCount = d.totalSatisfiedCount | 0;
                }
                if (d.reasonStatisticsEntries) {
                    if (!Array.isArray(d.reasonStatisticsEntries))
                        throw TypeError(".taxi.ReviewStatisticsEntryDTO.ReviewStatistics.reasonStatisticsEntries: array expected");
                    m.reasonStatisticsEntries = [];
                    for (var i = 0; i < d.reasonStatisticsEntries.length; ++i) {
                        if (typeof d.reasonStatisticsEntries[i] !== "object")
                            throw TypeError(".taxi.ReviewStatisticsEntryDTO.ReviewStatistics.reasonStatisticsEntries: object expected");
                        m.reasonStatisticsEntries[i] = $root.taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO.fromObject(d.reasonStatisticsEntries[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ReviewStatistics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewStatistics
             * @static
             * @param {taxi.ReviewStatisticsEntryDTO.ReviewStatistics} m ReviewStatistics
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReviewStatistics.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.reasonStatisticsEntries = [];
                }
                if (o.defaults) {
                    d.totalCount = 0;
                    d.totalSatisfiedCount = 0;
                }
                if (m.totalCount != null && m.hasOwnProperty("totalCount")) {
                    d.totalCount = m.totalCount;
                }
                if (m.totalSatisfiedCount != null && m.hasOwnProperty("totalSatisfiedCount")) {
                    d.totalSatisfiedCount = m.totalSatisfiedCount;
                }
                if (m.reasonStatisticsEntries && m.reasonStatisticsEntries.length) {
                    d.reasonStatisticsEntries = [];
                    for (var j = 0; j < m.reasonStatisticsEntries.length; ++j) {
                        d.reasonStatisticsEntries[j] = $root.taxi.ReviewStatisticsEntryDTO.ReviewReasonStatisticsEntryDTO.toObject(m.reasonStatisticsEntries[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ReviewStatistics to JSON.
             * @function toJSON
             * @memberof taxi.ReviewStatisticsEntryDTO.ReviewStatistics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReviewStatistics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ReviewStatistics;
        })();

        return ReviewStatisticsEntryDTO;
    })();

    taxi.DriverActivityStatusStatisticsEntryDTO = (function() {

        /**
         * Properties of a DriverActivityStatusStatisticsEntryDTO.
         * @memberof taxi
         * @interface IDriverActivityStatusStatisticsEntryDTO
         * @property {string|null} [date] DriverActivityStatusStatisticsEntryDTO date
         * @property {taxi.DriverActivityStatusStatisticsEntryDTO.IDriverActivityStatusStatistics|null} [statistics] DriverActivityStatusStatisticsEntryDTO statistics
         */

        /**
         * Constructs a new DriverActivityStatusStatisticsEntryDTO.
         * @memberof taxi
         * @classdesc Represents a DriverActivityStatusStatisticsEntryDTO.
         * @implements IDriverActivityStatusStatisticsEntryDTO
         * @constructor
         * @param {taxi.IDriverActivityStatusStatisticsEntryDTO=} [p] Properties to set
         */
        function DriverActivityStatusStatisticsEntryDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DriverActivityStatusStatisticsEntryDTO date.
         * @member {string} date
         * @memberof taxi.DriverActivityStatusStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatusStatisticsEntryDTO.prototype.date = "";

        /**
         * DriverActivityStatusStatisticsEntryDTO statistics.
         * @member {taxi.DriverActivityStatusStatisticsEntryDTO.IDriverActivityStatusStatistics|null|undefined} statistics
         * @memberof taxi.DriverActivityStatusStatisticsEntryDTO
         * @instance
         */
        DriverActivityStatusStatisticsEntryDTO.prototype.statistics = null;

        /**
         * Creates a new DriverActivityStatusStatisticsEntryDTO instance using the specified properties.
         * @function create
         * @memberof taxi.DriverActivityStatusStatisticsEntryDTO
         * @static
         * @param {taxi.IDriverActivityStatusStatisticsEntryDTO=} [properties] Properties to set
         * @returns {taxi.DriverActivityStatusStatisticsEntryDTO} DriverActivityStatusStatisticsEntryDTO instance
         */
        DriverActivityStatusStatisticsEntryDTO.create = function create(properties) {
            return new DriverActivityStatusStatisticsEntryDTO(properties);
        };

        /**
         * Creates a DriverActivityStatusStatisticsEntryDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.DriverActivityStatusStatisticsEntryDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.DriverActivityStatusStatisticsEntryDTO} DriverActivityStatusStatisticsEntryDTO
         */
        DriverActivityStatusStatisticsEntryDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.DriverActivityStatusStatisticsEntryDTO)
                return d;
            var m = new $root.taxi.DriverActivityStatusStatisticsEntryDTO();
            if (d.date != null) {
                m.date = String(d.date);
            }
            if (d.statistics != null) {
                if (typeof d.statistics !== "object")
                    throw TypeError(".taxi.DriverActivityStatusStatisticsEntryDTO.statistics: object expected");
                m.statistics = $root.taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics.fromObject(d.statistics);
            }
            return m;
        };

        /**
         * Creates a plain object from a DriverActivityStatusStatisticsEntryDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.DriverActivityStatusStatisticsEntryDTO
         * @static
         * @param {taxi.DriverActivityStatusStatisticsEntryDTO} m DriverActivityStatusStatisticsEntryDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DriverActivityStatusStatisticsEntryDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.date = "";
                d.statistics = null;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                d.date = m.date;
            }
            if (m.statistics != null && m.hasOwnProperty("statistics")) {
                d.statistics = $root.taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics.toObject(m.statistics, o);
            }
            return d;
        };

        /**
         * Converts this DriverActivityStatusStatisticsEntryDTO to JSON.
         * @function toJSON
         * @memberof taxi.DriverActivityStatusStatisticsEntryDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DriverActivityStatusStatisticsEntryDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics = (function() {

            /**
             * Properties of a DriverActivityStatusStatistics.
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO
             * @interface IDriverActivityStatusStatistics
             * @property {number|null} [totalOffDurationSeconds] DriverActivityStatusStatistics totalOffDurationSeconds
             * @property {number|null} [totalIdleDurationSeconds] DriverActivityStatusStatistics totalIdleDurationSeconds
             * @property {number|null} [totalDispatchingDurationSeconds] DriverActivityStatusStatistics totalDispatchingDurationSeconds
             * @property {number|null} [totalRidingDurationSeconds] DriverActivityStatusStatistics totalRidingDurationSeconds
             * @property {number|null} [totalTripRidingDurationSeconds] DriverActivityStatusStatistics totalTripRidingDurationSeconds
             */

            /**
             * Constructs a new DriverActivityStatusStatistics.
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO
             * @classdesc Represents a DriverActivityStatusStatistics.
             * @implements IDriverActivityStatusStatistics
             * @constructor
             * @param {taxi.DriverActivityStatusStatisticsEntryDTO.IDriverActivityStatusStatistics=} [p] Properties to set
             */
            function DriverActivityStatusStatistics(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DriverActivityStatusStatistics totalOffDurationSeconds.
             * @member {number} totalOffDurationSeconds
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @instance
             */
            DriverActivityStatusStatistics.prototype.totalOffDurationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DriverActivityStatusStatistics totalIdleDurationSeconds.
             * @member {number} totalIdleDurationSeconds
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @instance
             */
            DriverActivityStatusStatistics.prototype.totalIdleDurationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DriverActivityStatusStatistics totalDispatchingDurationSeconds.
             * @member {number} totalDispatchingDurationSeconds
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @instance
             */
            DriverActivityStatusStatistics.prototype.totalDispatchingDurationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DriverActivityStatusStatistics totalRidingDurationSeconds.
             * @member {number} totalRidingDurationSeconds
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @instance
             */
            DriverActivityStatusStatistics.prototype.totalRidingDurationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DriverActivityStatusStatistics totalTripRidingDurationSeconds.
             * @member {number} totalTripRidingDurationSeconds
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @instance
             */
            DriverActivityStatusStatistics.prototype.totalTripRidingDurationSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new DriverActivityStatusStatistics instance using the specified properties.
             * @function create
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @static
             * @param {taxi.DriverActivityStatusStatisticsEntryDTO.IDriverActivityStatusStatistics=} [properties] Properties to set
             * @returns {taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics} DriverActivityStatusStatistics instance
             */
            DriverActivityStatusStatistics.create = function create(properties) {
                return new DriverActivityStatusStatistics(properties);
            };

            /**
             * Creates a DriverActivityStatusStatistics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics} DriverActivityStatusStatistics
             */
            DriverActivityStatusStatistics.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics)
                    return d;
                var m = new $root.taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics();
                if (d.totalOffDurationSeconds != null) {
                    if ($util.Long)
                        (m.totalOffDurationSeconds = $util.Long.fromValue(d.totalOffDurationSeconds)).unsigned = false;
                    else if (typeof d.totalOffDurationSeconds === "string")
                        m.totalOffDurationSeconds = parseInt(d.totalOffDurationSeconds, 10);
                    else if (typeof d.totalOffDurationSeconds === "number")
                        m.totalOffDurationSeconds = d.totalOffDurationSeconds;
                    else if (typeof d.totalOffDurationSeconds === "object")
                        m.totalOffDurationSeconds = new $util.LongBits(d.totalOffDurationSeconds.low >>> 0, d.totalOffDurationSeconds.high >>> 0).toNumber();
                }
                if (d.totalIdleDurationSeconds != null) {
                    if ($util.Long)
                        (m.totalIdleDurationSeconds = $util.Long.fromValue(d.totalIdleDurationSeconds)).unsigned = false;
                    else if (typeof d.totalIdleDurationSeconds === "string")
                        m.totalIdleDurationSeconds = parseInt(d.totalIdleDurationSeconds, 10);
                    else if (typeof d.totalIdleDurationSeconds === "number")
                        m.totalIdleDurationSeconds = d.totalIdleDurationSeconds;
                    else if (typeof d.totalIdleDurationSeconds === "object")
                        m.totalIdleDurationSeconds = new $util.LongBits(d.totalIdleDurationSeconds.low >>> 0, d.totalIdleDurationSeconds.high >>> 0).toNumber();
                }
                if (d.totalDispatchingDurationSeconds != null) {
                    if ($util.Long)
                        (m.totalDispatchingDurationSeconds = $util.Long.fromValue(d.totalDispatchingDurationSeconds)).unsigned = false;
                    else if (typeof d.totalDispatchingDurationSeconds === "string")
                        m.totalDispatchingDurationSeconds = parseInt(d.totalDispatchingDurationSeconds, 10);
                    else if (typeof d.totalDispatchingDurationSeconds === "number")
                        m.totalDispatchingDurationSeconds = d.totalDispatchingDurationSeconds;
                    else if (typeof d.totalDispatchingDurationSeconds === "object")
                        m.totalDispatchingDurationSeconds = new $util.LongBits(d.totalDispatchingDurationSeconds.low >>> 0, d.totalDispatchingDurationSeconds.high >>> 0).toNumber();
                }
                if (d.totalRidingDurationSeconds != null) {
                    if ($util.Long)
                        (m.totalRidingDurationSeconds = $util.Long.fromValue(d.totalRidingDurationSeconds)).unsigned = false;
                    else if (typeof d.totalRidingDurationSeconds === "string")
                        m.totalRidingDurationSeconds = parseInt(d.totalRidingDurationSeconds, 10);
                    else if (typeof d.totalRidingDurationSeconds === "number")
                        m.totalRidingDurationSeconds = d.totalRidingDurationSeconds;
                    else if (typeof d.totalRidingDurationSeconds === "object")
                        m.totalRidingDurationSeconds = new $util.LongBits(d.totalRidingDurationSeconds.low >>> 0, d.totalRidingDurationSeconds.high >>> 0).toNumber();
                }
                if (d.totalTripRidingDurationSeconds != null) {
                    if ($util.Long)
                        (m.totalTripRidingDurationSeconds = $util.Long.fromValue(d.totalTripRidingDurationSeconds)).unsigned = false;
                    else if (typeof d.totalTripRidingDurationSeconds === "string")
                        m.totalTripRidingDurationSeconds = parseInt(d.totalTripRidingDurationSeconds, 10);
                    else if (typeof d.totalTripRidingDurationSeconds === "number")
                        m.totalTripRidingDurationSeconds = d.totalTripRidingDurationSeconds;
                    else if (typeof d.totalTripRidingDurationSeconds === "object")
                        m.totalTripRidingDurationSeconds = new $util.LongBits(d.totalTripRidingDurationSeconds.low >>> 0, d.totalTripRidingDurationSeconds.high >>> 0).toNumber();
                }
                return m;
            };

            /**
             * Creates a plain object from a DriverActivityStatusStatistics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @static
             * @param {taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics} m DriverActivityStatusStatistics
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DriverActivityStatusStatistics.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalOffDurationSeconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalOffDurationSeconds = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalIdleDurationSeconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalIdleDurationSeconds = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalDispatchingDurationSeconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalDispatchingDurationSeconds = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalRidingDurationSeconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalRidingDurationSeconds = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.totalTripRidingDurationSeconds = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.totalTripRidingDurationSeconds = o.longs === String ? "0" : 0;
                }
                if (m.totalOffDurationSeconds != null && m.hasOwnProperty("totalOffDurationSeconds")) {
                    if (typeof m.totalOffDurationSeconds === "number")
                        d.totalOffDurationSeconds = o.longs === String ? String(m.totalOffDurationSeconds) : m.totalOffDurationSeconds;
                    else
                        d.totalOffDurationSeconds = o.longs === String ? $util.Long.prototype.toString.call(m.totalOffDurationSeconds) : o.longs === Number ? new $util.LongBits(m.totalOffDurationSeconds.low >>> 0, m.totalOffDurationSeconds.high >>> 0).toNumber() : m.totalOffDurationSeconds;
                }
                if (m.totalIdleDurationSeconds != null && m.hasOwnProperty("totalIdleDurationSeconds")) {
                    if (typeof m.totalIdleDurationSeconds === "number")
                        d.totalIdleDurationSeconds = o.longs === String ? String(m.totalIdleDurationSeconds) : m.totalIdleDurationSeconds;
                    else
                        d.totalIdleDurationSeconds = o.longs === String ? $util.Long.prototype.toString.call(m.totalIdleDurationSeconds) : o.longs === Number ? new $util.LongBits(m.totalIdleDurationSeconds.low >>> 0, m.totalIdleDurationSeconds.high >>> 0).toNumber() : m.totalIdleDurationSeconds;
                }
                if (m.totalDispatchingDurationSeconds != null && m.hasOwnProperty("totalDispatchingDurationSeconds")) {
                    if (typeof m.totalDispatchingDurationSeconds === "number")
                        d.totalDispatchingDurationSeconds = o.longs === String ? String(m.totalDispatchingDurationSeconds) : m.totalDispatchingDurationSeconds;
                    else
                        d.totalDispatchingDurationSeconds = o.longs === String ? $util.Long.prototype.toString.call(m.totalDispatchingDurationSeconds) : o.longs === Number ? new $util.LongBits(m.totalDispatchingDurationSeconds.low >>> 0, m.totalDispatchingDurationSeconds.high >>> 0).toNumber() : m.totalDispatchingDurationSeconds;
                }
                if (m.totalRidingDurationSeconds != null && m.hasOwnProperty("totalRidingDurationSeconds")) {
                    if (typeof m.totalRidingDurationSeconds === "number")
                        d.totalRidingDurationSeconds = o.longs === String ? String(m.totalRidingDurationSeconds) : m.totalRidingDurationSeconds;
                    else
                        d.totalRidingDurationSeconds = o.longs === String ? $util.Long.prototype.toString.call(m.totalRidingDurationSeconds) : o.longs === Number ? new $util.LongBits(m.totalRidingDurationSeconds.low >>> 0, m.totalRidingDurationSeconds.high >>> 0).toNumber() : m.totalRidingDurationSeconds;
                }
                if (m.totalTripRidingDurationSeconds != null && m.hasOwnProperty("totalTripRidingDurationSeconds")) {
                    if (typeof m.totalTripRidingDurationSeconds === "number")
                        d.totalTripRidingDurationSeconds = o.longs === String ? String(m.totalTripRidingDurationSeconds) : m.totalTripRidingDurationSeconds;
                    else
                        d.totalTripRidingDurationSeconds = o.longs === String ? $util.Long.prototype.toString.call(m.totalTripRidingDurationSeconds) : o.longs === Number ? new $util.LongBits(m.totalTripRidingDurationSeconds.low >>> 0, m.totalTripRidingDurationSeconds.high >>> 0).toNumber() : m.totalTripRidingDurationSeconds;
                }
                return d;
            };

            /**
             * Converts this DriverActivityStatusStatistics to JSON.
             * @function toJSON
             * @memberof taxi.DriverActivityStatusStatisticsEntryDTO.DriverActivityStatusStatistics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DriverActivityStatusStatistics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DriverActivityStatusStatistics;
        })();

        return DriverActivityStatusStatisticsEntryDTO;
    })();

    taxi.UserPreferencesDTO = (function() {

        /**
         * Properties of a UserPreferencesDTO.
         * @memberof taxi
         * @interface IUserPreferencesDTO
         * @property {boolean|null} [isMarketingNotificationEnabled] UserPreferencesDTO isMarketingNotificationEnabled
         * @property {boolean|null} [isMarketingEmailEnabled] UserPreferencesDTO isMarketingEmailEnabled
         * @property {boolean|null} [isMarketingSmsEnabled] UserPreferencesDTO isMarketingSmsEnabled
         * @property {boolean|null} [isInformativeNotificationEnabled] UserPreferencesDTO isInformativeNotificationEnabled
         * @property {boolean|null} [isInformativeEmailEnabled] UserPreferencesDTO isInformativeEmailEnabled
         * @property {boolean|null} [isInformativeSmsEnabled] UserPreferencesDTO isInformativeSmsEnabled
         */

        /**
         * Constructs a new UserPreferencesDTO.
         * @memberof taxi
         * @classdesc Represents a UserPreferencesDTO.
         * @implements IUserPreferencesDTO
         * @constructor
         * @param {taxi.IUserPreferencesDTO=} [p] Properties to set
         */
        function UserPreferencesDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserPreferencesDTO isMarketingNotificationEnabled.
         * @member {boolean} isMarketingNotificationEnabled
         * @memberof taxi.UserPreferencesDTO
         * @instance
         */
        UserPreferencesDTO.prototype.isMarketingNotificationEnabled = false;

        /**
         * UserPreferencesDTO isMarketingEmailEnabled.
         * @member {boolean} isMarketingEmailEnabled
         * @memberof taxi.UserPreferencesDTO
         * @instance
         */
        UserPreferencesDTO.prototype.isMarketingEmailEnabled = false;

        /**
         * UserPreferencesDTO isMarketingSmsEnabled.
         * @member {boolean} isMarketingSmsEnabled
         * @memberof taxi.UserPreferencesDTO
         * @instance
         */
        UserPreferencesDTO.prototype.isMarketingSmsEnabled = false;

        /**
         * UserPreferencesDTO isInformativeNotificationEnabled.
         * @member {boolean} isInformativeNotificationEnabled
         * @memberof taxi.UserPreferencesDTO
         * @instance
         */
        UserPreferencesDTO.prototype.isInformativeNotificationEnabled = false;

        /**
         * UserPreferencesDTO isInformativeEmailEnabled.
         * @member {boolean} isInformativeEmailEnabled
         * @memberof taxi.UserPreferencesDTO
         * @instance
         */
        UserPreferencesDTO.prototype.isInformativeEmailEnabled = false;

        /**
         * UserPreferencesDTO isInformativeSmsEnabled.
         * @member {boolean} isInformativeSmsEnabled
         * @memberof taxi.UserPreferencesDTO
         * @instance
         */
        UserPreferencesDTO.prototype.isInformativeSmsEnabled = false;

        /**
         * Creates a new UserPreferencesDTO instance using the specified properties.
         * @function create
         * @memberof taxi.UserPreferencesDTO
         * @static
         * @param {taxi.IUserPreferencesDTO=} [properties] Properties to set
         * @returns {taxi.UserPreferencesDTO} UserPreferencesDTO instance
         */
        UserPreferencesDTO.create = function create(properties) {
            return new UserPreferencesDTO(properties);
        };

        /**
         * Creates a UserPreferencesDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UserPreferencesDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UserPreferencesDTO} UserPreferencesDTO
         */
        UserPreferencesDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UserPreferencesDTO)
                return d;
            var m = new $root.taxi.UserPreferencesDTO();
            if (d.isMarketingNotificationEnabled != null) {
                m.isMarketingNotificationEnabled = Boolean(d.isMarketingNotificationEnabled);
            }
            if (d.isMarketingEmailEnabled != null) {
                m.isMarketingEmailEnabled = Boolean(d.isMarketingEmailEnabled);
            }
            if (d.isMarketingSmsEnabled != null) {
                m.isMarketingSmsEnabled = Boolean(d.isMarketingSmsEnabled);
            }
            if (d.isInformativeNotificationEnabled != null) {
                m.isInformativeNotificationEnabled = Boolean(d.isInformativeNotificationEnabled);
            }
            if (d.isInformativeEmailEnabled != null) {
                m.isInformativeEmailEnabled = Boolean(d.isInformativeEmailEnabled);
            }
            if (d.isInformativeSmsEnabled != null) {
                m.isInformativeSmsEnabled = Boolean(d.isInformativeSmsEnabled);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserPreferencesDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UserPreferencesDTO
         * @static
         * @param {taxi.UserPreferencesDTO} m UserPreferencesDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPreferencesDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.isMarketingNotificationEnabled = false;
                d.isMarketingEmailEnabled = false;
                d.isMarketingSmsEnabled = false;
                d.isInformativeNotificationEnabled = false;
                d.isInformativeEmailEnabled = false;
                d.isInformativeSmsEnabled = false;
            }
            if (m.isMarketingNotificationEnabled != null && m.hasOwnProperty("isMarketingNotificationEnabled")) {
                d.isMarketingNotificationEnabled = m.isMarketingNotificationEnabled;
            }
            if (m.isMarketingEmailEnabled != null && m.hasOwnProperty("isMarketingEmailEnabled")) {
                d.isMarketingEmailEnabled = m.isMarketingEmailEnabled;
            }
            if (m.isMarketingSmsEnabled != null && m.hasOwnProperty("isMarketingSmsEnabled")) {
                d.isMarketingSmsEnabled = m.isMarketingSmsEnabled;
            }
            if (m.isInformativeNotificationEnabled != null && m.hasOwnProperty("isInformativeNotificationEnabled")) {
                d.isInformativeNotificationEnabled = m.isInformativeNotificationEnabled;
            }
            if (m.isInformativeEmailEnabled != null && m.hasOwnProperty("isInformativeEmailEnabled")) {
                d.isInformativeEmailEnabled = m.isInformativeEmailEnabled;
            }
            if (m.isInformativeSmsEnabled != null && m.hasOwnProperty("isInformativeSmsEnabled")) {
                d.isInformativeSmsEnabled = m.isInformativeSmsEnabled;
            }
            return d;
        };

        /**
         * Converts this UserPreferencesDTO to JSON.
         * @function toJSON
         * @memberof taxi.UserPreferencesDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPreferencesDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserPreferencesDTO;
    })();

    /**
     * SmartKeyCommand enum.
     * @name taxi.SmartKeyCommand
     * @enum {string}
     * @property {number} OPEN=0 OPEN value
     * @property {number} CLOSE=1 CLOSE value
     * @property {number} LIGHT=2 LIGHT value
     * @property {number} KLAXON=3 KLAXON value
     */
    taxi.SmartKeyCommand = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OPEN"] = 0;
        values[valuesById[1] = "CLOSE"] = 1;
        values[valuesById[2] = "LIGHT"] = 2;
        values[valuesById[3] = "KLAXON"] = 3;
        return values;
    })();

    taxi.TrackingIdentifiers = (function() {

        /**
         * Properties of a TrackingIdentifiers.
         * @memberof taxi
         * @interface ITrackingIdentifiers
         * @property {taxi.IStringValue|null} [gaid] TrackingIdentifiers gaid
         * @property {taxi.IStringValue|null} [idfa] TrackingIdentifiers idfa
         */

        /**
         * Constructs a new TrackingIdentifiers.
         * @memberof taxi
         * @classdesc Represents a TrackingIdentifiers.
         * @implements ITrackingIdentifiers
         * @constructor
         * @param {taxi.ITrackingIdentifiers=} [p] Properties to set
         */
        function TrackingIdentifiers(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TrackingIdentifiers gaid.
         * @member {taxi.IStringValue|null|undefined} gaid
         * @memberof taxi.TrackingIdentifiers
         * @instance
         */
        TrackingIdentifiers.prototype.gaid = null;

        /**
         * TrackingIdentifiers idfa.
         * @member {taxi.IStringValue|null|undefined} idfa
         * @memberof taxi.TrackingIdentifiers
         * @instance
         */
        TrackingIdentifiers.prototype.idfa = null;

        /**
         * Creates a new TrackingIdentifiers instance using the specified properties.
         * @function create
         * @memberof taxi.TrackingIdentifiers
         * @static
         * @param {taxi.ITrackingIdentifiers=} [properties] Properties to set
         * @returns {taxi.TrackingIdentifiers} TrackingIdentifiers instance
         */
        TrackingIdentifiers.create = function create(properties) {
            return new TrackingIdentifiers(properties);
        };

        /**
         * Creates a TrackingIdentifiers message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TrackingIdentifiers
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TrackingIdentifiers} TrackingIdentifiers
         */
        TrackingIdentifiers.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TrackingIdentifiers)
                return d;
            var m = new $root.taxi.TrackingIdentifiers();
            if (d.gaid != null) {
                if (typeof d.gaid !== "object")
                    throw TypeError(".taxi.TrackingIdentifiers.gaid: object expected");
                m.gaid = $root.taxi.StringValue.fromObject(d.gaid);
            }
            if (d.idfa != null) {
                if (typeof d.idfa !== "object")
                    throw TypeError(".taxi.TrackingIdentifiers.idfa: object expected");
                m.idfa = $root.taxi.StringValue.fromObject(d.idfa);
            }
            return m;
        };

        /**
         * Creates a plain object from a TrackingIdentifiers message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TrackingIdentifiers
         * @static
         * @param {taxi.TrackingIdentifiers} m TrackingIdentifiers
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TrackingIdentifiers.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.gaid = null;
                d.idfa = null;
            }
            if (m.gaid != null && m.hasOwnProperty("gaid")) {
                d.gaid = $root.taxi.StringValue.toObject(m.gaid, o);
            }
            if (m.idfa != null && m.hasOwnProperty("idfa")) {
                d.idfa = $root.taxi.StringValue.toObject(m.idfa, o);
            }
            return d;
        };

        /**
         * Converts this TrackingIdentifiers to JSON.
         * @function toJSON
         * @memberof taxi.TrackingIdentifiers
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TrackingIdentifiers.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TrackingIdentifiers;
    })();

    /**
     * ShareChannel enum.
     * @name taxi.ShareChannel
     * @enum {string}
     * @property {number} COPY=0 COPY value
     * @property {number} KAKAO_TALK=1 KAKAO_TALK value
     * @property {number} LINE=2 LINE value
     * @property {number} FB_MSG=3 FB_MSG value
     * @property {number} BETWEEN=4 BETWEEN value
     * @property {number} SMS=5 SMS value
     * @property {number} WHATS_APP=6 WHATS_APP value
     * @property {number} EMAIL=7 EMAIL value
     * @property {number} ETC=8 ETC value
     */
    taxi.ShareChannel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "COPY"] = 0;
        values[valuesById[1] = "KAKAO_TALK"] = 1;
        values[valuesById[2] = "LINE"] = 2;
        values[valuesById[3] = "FB_MSG"] = 3;
        values[valuesById[4] = "BETWEEN"] = 4;
        values[valuesById[5] = "SMS"] = 5;
        values[valuesById[6] = "WHATS_APP"] = 6;
        values[valuesById[7] = "EMAIL"] = 7;
        values[valuesById[8] = "ETC"] = 8;
        return values;
    })();

    /**
     * AssistRegistrationType enum.
     * @name taxi.AssistRegistrationType
     * @enum {string}
     * @property {number} SENIOR=0 SENIOR value
     * @property {number} DISABLED_PERSON=1 DISABLED_PERSON value
     */
    taxi.AssistRegistrationType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SENIOR"] = 0;
        values[valuesById[1] = "DISABLED_PERSON"] = 1;
        return values;
    })();

    /**
     * AssistRegistrationStatus enum.
     * @name taxi.AssistRegistrationStatus
     * @enum {string}
     * @property {number} NONE=0 NONE value
     * @property {number} PENDING=1 PENDING value
     * @property {number} ACCEPTED=2 ACCEPTED value
     * @property {number} REJECTED=3 REJECTED value
     */
    taxi.AssistRegistrationStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "PENDING"] = 1;
        values[valuesById[2] = "ACCEPTED"] = 2;
        values[valuesById[3] = "REJECTED"] = 3;
        return values;
    })();

    taxi.RideAvailabilityIssue = (function() {

        /**
         * Properties of a RideAvailabilityIssue.
         * @memberof taxi
         * @interface IRideAvailabilityIssue
         * @property {taxi.RideAvailabilityIssue.Type|null} [type] RideAvailabilityIssue type
         * @property {string|null} [message] RideAvailabilityIssue message
         * @property {string|null} [title] RideAvailabilityIssue title
         * @property {taxi.IImage|null} [image] RideAvailabilityIssue image
         * @property {taxi.RideAvailabilityIssue.IButton|null} [button] RideAvailabilityIssue button
         * @property {taxi.IStringValue|null} [imageUrl] RideAvailabilityIssue imageUrl
         */

        /**
         * Constructs a new RideAvailabilityIssue.
         * @memberof taxi
         * @classdesc Represents a RideAvailabilityIssue.
         * @implements IRideAvailabilityIssue
         * @constructor
         * @param {taxi.IRideAvailabilityIssue=} [p] Properties to set
         */
        function RideAvailabilityIssue(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * RideAvailabilityIssue type.
         * @member {taxi.RideAvailabilityIssue.Type} type
         * @memberof taxi.RideAvailabilityIssue
         * @instance
         */
        RideAvailabilityIssue.prototype.type = 0;

        /**
         * RideAvailabilityIssue message.
         * @member {string} message
         * @memberof taxi.RideAvailabilityIssue
         * @instance
         */
        RideAvailabilityIssue.prototype.message = "";

        /**
         * RideAvailabilityIssue title.
         * @member {string} title
         * @memberof taxi.RideAvailabilityIssue
         * @instance
         */
        RideAvailabilityIssue.prototype.title = "";

        /**
         * RideAvailabilityIssue image.
         * @member {taxi.IImage|null|undefined} image
         * @memberof taxi.RideAvailabilityIssue
         * @instance
         */
        RideAvailabilityIssue.prototype.image = null;

        /**
         * RideAvailabilityIssue button.
         * @member {taxi.RideAvailabilityIssue.IButton|null|undefined} button
         * @memberof taxi.RideAvailabilityIssue
         * @instance
         */
        RideAvailabilityIssue.prototype.button = null;

        /**
         * RideAvailabilityIssue imageUrl.
         * @member {taxi.IStringValue|null|undefined} imageUrl
         * @memberof taxi.RideAvailabilityIssue
         * @instance
         */
        RideAvailabilityIssue.prototype.imageUrl = null;

        /**
         * Creates a new RideAvailabilityIssue instance using the specified properties.
         * @function create
         * @memberof taxi.RideAvailabilityIssue
         * @static
         * @param {taxi.IRideAvailabilityIssue=} [properties] Properties to set
         * @returns {taxi.RideAvailabilityIssue} RideAvailabilityIssue instance
         */
        RideAvailabilityIssue.create = function create(properties) {
            return new RideAvailabilityIssue(properties);
        };

        /**
         * Creates a RideAvailabilityIssue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.RideAvailabilityIssue
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.RideAvailabilityIssue} RideAvailabilityIssue
         */
        RideAvailabilityIssue.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.RideAvailabilityIssue)
                return d;
            var m = new $root.taxi.RideAvailabilityIssue();
            switch (d.type) {
            case "UNSPECIFIED":
            case 0:
                m.type = 0;
                break;
            case "UNAVAILABLE_ORIGIN":
            case 1:
                m.type = 1;
                break;
            case "UNAVAILABLE_DESTINATION":
            case 2:
                m.type = 2;
                break;
            case "UNAVAILABLE_TIME":
            case 3:
                m.type = 3;
                break;
            case "UNMANAGED_ORIGIN":
            case 4:
                m.type = 4;
                break;
            case "ASSIST_REGISTRATION_REQUIRED":
            case 5:
                m.type = 5;
                break;
            case "HAS_UPDATED_TERMS":
            case 6:
                m.type = 6;
                break;
            case "INVALID_PAYMENT_METHOD":
            case 7:
                m.type = 7;
                break;
            }
            if (d.message != null) {
                m.message = String(d.message);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".taxi.RideAvailabilityIssue.image: object expected");
                m.image = $root.taxi.Image.fromObject(d.image);
            }
            if (d.button != null) {
                if (typeof d.button !== "object")
                    throw TypeError(".taxi.RideAvailabilityIssue.button: object expected");
                m.button = $root.taxi.RideAvailabilityIssue.Button.fromObject(d.button);
            }
            if (d.imageUrl != null) {
                if (typeof d.imageUrl !== "object")
                    throw TypeError(".taxi.RideAvailabilityIssue.imageUrl: object expected");
                m.imageUrl = $root.taxi.StringValue.fromObject(d.imageUrl);
            }
            return m;
        };

        /**
         * Creates a plain object from a RideAvailabilityIssue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.RideAvailabilityIssue
         * @static
         * @param {taxi.RideAvailabilityIssue} m RideAvailabilityIssue
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RideAvailabilityIssue.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "UNSPECIFIED" : 0;
                d.message = "";
                d.title = "";
                d.imageUrl = null;
                d.button = null;
                d.image = null;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.taxi.RideAvailabilityIssue.Type[m.type] : m.type;
            }
            if (m.message != null && m.hasOwnProperty("message")) {
                d.message = m.message;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.imageUrl != null && m.hasOwnProperty("imageUrl")) {
                d.imageUrl = $root.taxi.StringValue.toObject(m.imageUrl, o);
            }
            if (m.button != null && m.hasOwnProperty("button")) {
                d.button = $root.taxi.RideAvailabilityIssue.Button.toObject(m.button, o);
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.taxi.Image.toObject(m.image, o);
            }
            return d;
        };

        /**
         * Converts this RideAvailabilityIssue to JSON.
         * @function toJSON
         * @memberof taxi.RideAvailabilityIssue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RideAvailabilityIssue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Type enum.
         * @name taxi.RideAvailabilityIssue.Type
         * @enum {string}
         * @property {number} UNSPECIFIED=0 UNSPECIFIED value
         * @property {number} UNAVAILABLE_ORIGIN=1 UNAVAILABLE_ORIGIN value
         * @property {number} UNAVAILABLE_DESTINATION=2 UNAVAILABLE_DESTINATION value
         * @property {number} UNAVAILABLE_TIME=3 UNAVAILABLE_TIME value
         * @property {number} UNMANAGED_ORIGIN=4 UNMANAGED_ORIGIN value
         * @property {number} ASSIST_REGISTRATION_REQUIRED=5 ASSIST_REGISTRATION_REQUIRED value
         * @property {number} HAS_UPDATED_TERMS=6 HAS_UPDATED_TERMS value
         * @property {number} INVALID_PAYMENT_METHOD=7 INVALID_PAYMENT_METHOD value
         */
        RideAvailabilityIssue.Type = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNSPECIFIED"] = 0;
            values[valuesById[1] = "UNAVAILABLE_ORIGIN"] = 1;
            values[valuesById[2] = "UNAVAILABLE_DESTINATION"] = 2;
            values[valuesById[3] = "UNAVAILABLE_TIME"] = 3;
            values[valuesById[4] = "UNMANAGED_ORIGIN"] = 4;
            values[valuesById[5] = "ASSIST_REGISTRATION_REQUIRED"] = 5;
            values[valuesById[6] = "HAS_UPDATED_TERMS"] = 6;
            values[valuesById[7] = "INVALID_PAYMENT_METHOD"] = 7;
            return values;
        })();

        RideAvailabilityIssue.Button = (function() {

            /**
             * Properties of a Button.
             * @memberof taxi.RideAvailabilityIssue
             * @interface IButton
             * @property {string|null} [name] Button name
             * @property {taxi.IStringValue|null} [deepLink] Button deepLink
             */

            /**
             * Constructs a new Button.
             * @memberof taxi.RideAvailabilityIssue
             * @classdesc Represents a Button.
             * @implements IButton
             * @constructor
             * @param {taxi.RideAvailabilityIssue.IButton=} [p] Properties to set
             */
            function Button(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Button name.
             * @member {string} name
             * @memberof taxi.RideAvailabilityIssue.Button
             * @instance
             */
            Button.prototype.name = "";

            /**
             * Button deepLink.
             * @member {taxi.IStringValue|null|undefined} deepLink
             * @memberof taxi.RideAvailabilityIssue.Button
             * @instance
             */
            Button.prototype.deepLink = null;

            /**
             * Creates a new Button instance using the specified properties.
             * @function create
             * @memberof taxi.RideAvailabilityIssue.Button
             * @static
             * @param {taxi.RideAvailabilityIssue.IButton=} [properties] Properties to set
             * @returns {taxi.RideAvailabilityIssue.Button} Button instance
             */
            Button.create = function create(properties) {
                return new Button(properties);
            };

            /**
             * Creates a Button message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.RideAvailabilityIssue.Button
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.RideAvailabilityIssue.Button} Button
             */
            Button.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.RideAvailabilityIssue.Button)
                    return d;
                var m = new $root.taxi.RideAvailabilityIssue.Button();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.deepLink != null) {
                    if (typeof d.deepLink !== "object")
                        throw TypeError(".taxi.RideAvailabilityIssue.Button.deepLink: object expected");
                    m.deepLink = $root.taxi.StringValue.fromObject(d.deepLink);
                }
                return m;
            };

            /**
             * Creates a plain object from a Button message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.RideAvailabilityIssue.Button
             * @static
             * @param {taxi.RideAvailabilityIssue.Button} m Button
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Button.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.deepLink = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.deepLink != null && m.hasOwnProperty("deepLink")) {
                    d.deepLink = $root.taxi.StringValue.toObject(m.deepLink, o);
                }
                return d;
            };

            /**
             * Converts this Button to JSON.
             * @function toJSON
             * @memberof taxi.RideAvailabilityIssue.Button
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Button.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Button;
        })();

        return RideAvailabilityIssue;
    })();

    taxi.Terms = (function() {

        /**
         * Properties of a Terms.
         * @memberof taxi
         * @interface ITerms
         * @property {string|null} [id] Terms id
         * @property {string|null} [name] Terms name
         * @property {string|null} [url] Terms url
         * @property {boolean|null} [required] Terms required
         */

        /**
         * Constructs a new Terms.
         * @memberof taxi
         * @classdesc Represents a Terms.
         * @implements ITerms
         * @constructor
         * @param {taxi.ITerms=} [p] Properties to set
         */
        function Terms(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Terms id.
         * @member {string} id
         * @memberof taxi.Terms
         * @instance
         */
        Terms.prototype.id = "";

        /**
         * Terms name.
         * @member {string} name
         * @memberof taxi.Terms
         * @instance
         */
        Terms.prototype.name = "";

        /**
         * Terms url.
         * @member {string} url
         * @memberof taxi.Terms
         * @instance
         */
        Terms.prototype.url = "";

        /**
         * Terms required.
         * @member {boolean} required
         * @memberof taxi.Terms
         * @instance
         */
        Terms.prototype.required = false;

        /**
         * Creates a new Terms instance using the specified properties.
         * @function create
         * @memberof taxi.Terms
         * @static
         * @param {taxi.ITerms=} [properties] Properties to set
         * @returns {taxi.Terms} Terms instance
         */
        Terms.create = function create(properties) {
            return new Terms(properties);
        };

        /**
         * Creates a Terms message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.Terms
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.Terms} Terms
         */
        Terms.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.Terms)
                return d;
            var m = new $root.taxi.Terms();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.required != null) {
                m.required = Boolean(d.required);
            }
            return m;
        };

        /**
         * Creates a plain object from a Terms message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.Terms
         * @static
         * @param {taxi.Terms} m Terms
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Terms.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.url = "";
                d.required = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.required != null && m.hasOwnProperty("required")) {
                d.required = m.required;
            }
            return d;
        };

        /**
         * Converts this Terms to JSON.
         * @function toJSON
         * @memberof taxi.Terms
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Terms.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Terms;
    })();

    taxi.TermsEntry = (function() {

        /**
         * Properties of a TermsEntry.
         * @memberof taxi
         * @interface ITermsEntry
         * @property {string|null} [id] TermsEntry id
         * @property {string|null} [name] TermsEntry name
         * @property {boolean|null} [required] TermsEntry required
         * @property {taxi.IStringValue|null} [url] TermsEntry url
         * @property {Array.<taxi.ITermsEntry>|null} [terms] TermsEntry terms
         */

        /**
         * Constructs a new TermsEntry.
         * @memberof taxi
         * @classdesc Represents a TermsEntry.
         * @implements ITermsEntry
         * @constructor
         * @param {taxi.ITermsEntry=} [p] Properties to set
         */
        function TermsEntry(p) {
            this.terms = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TermsEntry id.
         * @member {string} id
         * @memberof taxi.TermsEntry
         * @instance
         */
        TermsEntry.prototype.id = "";

        /**
         * TermsEntry name.
         * @member {string} name
         * @memberof taxi.TermsEntry
         * @instance
         */
        TermsEntry.prototype.name = "";

        /**
         * TermsEntry required.
         * @member {boolean} required
         * @memberof taxi.TermsEntry
         * @instance
         */
        TermsEntry.prototype.required = false;

        /**
         * TermsEntry url.
         * @member {taxi.IStringValue|null|undefined} url
         * @memberof taxi.TermsEntry
         * @instance
         */
        TermsEntry.prototype.url = null;

        /**
         * TermsEntry terms.
         * @member {Array.<taxi.ITermsEntry>} terms
         * @memberof taxi.TermsEntry
         * @instance
         */
        TermsEntry.prototype.terms = $util.emptyArray;

        /**
         * Creates a new TermsEntry instance using the specified properties.
         * @function create
         * @memberof taxi.TermsEntry
         * @static
         * @param {taxi.ITermsEntry=} [properties] Properties to set
         * @returns {taxi.TermsEntry} TermsEntry instance
         */
        TermsEntry.create = function create(properties) {
            return new TermsEntry(properties);
        };

        /**
         * Creates a TermsEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TermsEntry
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TermsEntry} TermsEntry
         */
        TermsEntry.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TermsEntry)
                return d;
            var m = new $root.taxi.TermsEntry();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.required != null) {
                m.required = Boolean(d.required);
            }
            if (d.url != null) {
                if (typeof d.url !== "object")
                    throw TypeError(".taxi.TermsEntry.url: object expected");
                m.url = $root.taxi.StringValue.fromObject(d.url);
            }
            if (d.terms) {
                if (!Array.isArray(d.terms))
                    throw TypeError(".taxi.TermsEntry.terms: array expected");
                m.terms = [];
                for (var i = 0; i < d.terms.length; ++i) {
                    if (typeof d.terms[i] !== "object")
                        throw TypeError(".taxi.TermsEntry.terms: object expected");
                    m.terms[i] = $root.taxi.TermsEntry.fromObject(d.terms[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TermsEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TermsEntry
         * @static
         * @param {taxi.TermsEntry} m TermsEntry
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TermsEntry.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.terms = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.url = null;
                d.required = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = $root.taxi.StringValue.toObject(m.url, o);
            }
            if (m.required != null && m.hasOwnProperty("required")) {
                d.required = m.required;
            }
            if (m.terms && m.terms.length) {
                d.terms = [];
                for (var j = 0; j < m.terms.length; ++j) {
                    d.terms[j] = $root.taxi.TermsEntry.toObject(m.terms[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TermsEntry to JSON.
         * @function toJSON
         * @memberof taxi.TermsEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TermsEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TermsEntry;
    })();

    taxi.Image = (function() {

        /**
         * Properties of an Image.
         * @memberof taxi
         * @interface IImage
         * @property {string|null} [url] Image url
         * @property {number|null} [width] Image width
         * @property {number|null} [height] Image height
         */

        /**
         * Constructs a new Image.
         * @memberof taxi
         * @classdesc Represents an Image.
         * @implements IImage
         * @constructor
         * @param {taxi.IImage=} [p] Properties to set
         */
        function Image(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Image url.
         * @member {string} url
         * @memberof taxi.Image
         * @instance
         */
        Image.prototype.url = "";

        /**
         * Image width.
         * @member {number} width
         * @memberof taxi.Image
         * @instance
         */
        Image.prototype.width = 0;

        /**
         * Image height.
         * @member {number} height
         * @memberof taxi.Image
         * @instance
         */
        Image.prototype.height = 0;

        /**
         * Creates a new Image instance using the specified properties.
         * @function create
         * @memberof taxi.Image
         * @static
         * @param {taxi.IImage=} [properties] Properties to set
         * @returns {taxi.Image} Image instance
         */
        Image.create = function create(properties) {
            return new Image(properties);
        };

        /**
         * Creates an Image message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.Image
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.Image} Image
         */
        Image.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.Image)
                return d;
            var m = new $root.taxi.Image();
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.width != null) {
                m.width = d.width | 0;
            }
            if (d.height != null) {
                m.height = d.height | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from an Image message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.Image
         * @static
         * @param {taxi.Image} m Image
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Image.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.url = "";
                d.width = 0;
                d.height = 0;
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.width != null && m.hasOwnProperty("width")) {
                d.width = m.width;
            }
            if (m.height != null && m.hasOwnProperty("height")) {
                d.height = m.height;
            }
            return d;
        };

        /**
         * Converts this Image to JSON.
         * @function toJSON
         * @memberof taxi.Image
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Image.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Image;
    })();

    taxi.BrazeEvent = (function() {

        /**
         * Properties of a BrazeEvent.
         * @memberof taxi
         * @interface IBrazeEvent
         * @property {string|null} [eventName] BrazeEvent eventName
         * @property {Array.<taxi.BrazeEvent.IProperty>|null} [properties] BrazeEvent properties
         */

        /**
         * Constructs a new BrazeEvent.
         * @memberof taxi
         * @classdesc Represents a BrazeEvent.
         * @implements IBrazeEvent
         * @constructor
         * @param {taxi.IBrazeEvent=} [p] Properties to set
         */
        function BrazeEvent(p) {
            this.properties = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * BrazeEvent eventName.
         * @member {string} eventName
         * @memberof taxi.BrazeEvent
         * @instance
         */
        BrazeEvent.prototype.eventName = "";

        /**
         * BrazeEvent properties.
         * @member {Array.<taxi.BrazeEvent.IProperty>} properties
         * @memberof taxi.BrazeEvent
         * @instance
         */
        BrazeEvent.prototype.properties = $util.emptyArray;

        /**
         * Creates a new BrazeEvent instance using the specified properties.
         * @function create
         * @memberof taxi.BrazeEvent
         * @static
         * @param {taxi.IBrazeEvent=} [properties] Properties to set
         * @returns {taxi.BrazeEvent} BrazeEvent instance
         */
        BrazeEvent.create = function create(properties) {
            return new BrazeEvent(properties);
        };

        /**
         * Creates a BrazeEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.BrazeEvent
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.BrazeEvent} BrazeEvent
         */
        BrazeEvent.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.BrazeEvent)
                return d;
            var m = new $root.taxi.BrazeEvent();
            if (d.eventName != null) {
                m.eventName = String(d.eventName);
            }
            if (d.properties) {
                if (!Array.isArray(d.properties))
                    throw TypeError(".taxi.BrazeEvent.properties: array expected");
                m.properties = [];
                for (var i = 0; i < d.properties.length; ++i) {
                    if (typeof d.properties[i] !== "object")
                        throw TypeError(".taxi.BrazeEvent.properties: object expected");
                    m.properties[i] = $root.taxi.BrazeEvent.Property.fromObject(d.properties[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a BrazeEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.BrazeEvent
         * @static
         * @param {taxi.BrazeEvent} m BrazeEvent
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BrazeEvent.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.properties = [];
            }
            if (o.defaults) {
                d.eventName = "";
            }
            if (m.eventName != null && m.hasOwnProperty("eventName")) {
                d.eventName = m.eventName;
            }
            if (m.properties && m.properties.length) {
                d.properties = [];
                for (var j = 0; j < m.properties.length; ++j) {
                    d.properties[j] = $root.taxi.BrazeEvent.Property.toObject(m.properties[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this BrazeEvent to JSON.
         * @function toJSON
         * @memberof taxi.BrazeEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BrazeEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BrazeEvent.Property = (function() {

            /**
             * Properties of a Property.
             * @memberof taxi.BrazeEvent
             * @interface IProperty
             * @property {string|null} [key] Property key
             * @property {string|null} [value] Property value
             */

            /**
             * Constructs a new Property.
             * @memberof taxi.BrazeEvent
             * @classdesc Represents a Property.
             * @implements IProperty
             * @constructor
             * @param {taxi.BrazeEvent.IProperty=} [p] Properties to set
             */
            function Property(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Property key.
             * @member {string} key
             * @memberof taxi.BrazeEvent.Property
             * @instance
             */
            Property.prototype.key = "";

            /**
             * Property value.
             * @member {string} value
             * @memberof taxi.BrazeEvent.Property
             * @instance
             */
            Property.prototype.value = "";

            /**
             * Creates a new Property instance using the specified properties.
             * @function create
             * @memberof taxi.BrazeEvent.Property
             * @static
             * @param {taxi.BrazeEvent.IProperty=} [properties] Properties to set
             * @returns {taxi.BrazeEvent.Property} Property instance
             */
            Property.create = function create(properties) {
                return new Property(properties);
            };

            /**
             * Creates a Property message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.BrazeEvent.Property
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.BrazeEvent.Property} Property
             */
            Property.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.BrazeEvent.Property)
                    return d;
                var m = new $root.taxi.BrazeEvent.Property();
                if (d.key != null) {
                    m.key = String(d.key);
                }
                if (d.value != null) {
                    m.value = String(d.value);
                }
                return m;
            };

            /**
             * Creates a plain object from a Property message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.BrazeEvent.Property
             * @static
             * @param {taxi.BrazeEvent.Property} m Property
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Property.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.key = "";
                    d.value = "";
                }
                if (m.key != null && m.hasOwnProperty("key")) {
                    d.key = m.key;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = m.value;
                }
                return d;
            };

            /**
             * Converts this Property to JSON.
             * @function toJSON
             * @memberof taxi.BrazeEvent.Property
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Property.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Property;
        })();

        return BrazeEvent;
    })();

    taxi.PaymentProfileDTO = (function() {

        /**
         * Properties of a PaymentProfileDTO.
         * @memberof taxi
         * @interface IPaymentProfileDTO
         * @property {string|null} [id] PaymentProfileDTO id
         * @property {string|null} [name] PaymentProfileDTO name
         * @property {string|null} [email] PaymentProfileDTO email
         * @property {taxi.PaymentProfileDTO.Type|null} [type] PaymentProfileDTO type
         * @property {taxi.PaymentProfileDTO.Status|null} [status] PaymentProfileDTO status
         * @property {taxi.IPaymentMethodDTO|null} [paymentMethod] PaymentProfileDTO paymentMethod
         * @property {boolean|null} [isPaymentMethodFixed] PaymentProfileDTO isPaymentMethodFixed
         */

        /**
         * Constructs a new PaymentProfileDTO.
         * @memberof taxi
         * @classdesc Represents a PaymentProfileDTO.
         * @implements IPaymentProfileDTO
         * @constructor
         * @param {taxi.IPaymentProfileDTO=} [p] Properties to set
         */
        function PaymentProfileDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentProfileDTO id.
         * @member {string} id
         * @memberof taxi.PaymentProfileDTO
         * @instance
         */
        PaymentProfileDTO.prototype.id = "";

        /**
         * PaymentProfileDTO name.
         * @member {string} name
         * @memberof taxi.PaymentProfileDTO
         * @instance
         */
        PaymentProfileDTO.prototype.name = "";

        /**
         * PaymentProfileDTO email.
         * @member {string} email
         * @memberof taxi.PaymentProfileDTO
         * @instance
         */
        PaymentProfileDTO.prototype.email = "";

        /**
         * PaymentProfileDTO type.
         * @member {taxi.PaymentProfileDTO.Type} type
         * @memberof taxi.PaymentProfileDTO
         * @instance
         */
        PaymentProfileDTO.prototype.type = 0;

        /**
         * PaymentProfileDTO status.
         * @member {taxi.PaymentProfileDTO.Status} status
         * @memberof taxi.PaymentProfileDTO
         * @instance
         */
        PaymentProfileDTO.prototype.status = 0;

        /**
         * PaymentProfileDTO paymentMethod.
         * @member {taxi.IPaymentMethodDTO|null|undefined} paymentMethod
         * @memberof taxi.PaymentProfileDTO
         * @instance
         */
        PaymentProfileDTO.prototype.paymentMethod = null;

        /**
         * PaymentProfileDTO isPaymentMethodFixed.
         * @member {boolean} isPaymentMethodFixed
         * @memberof taxi.PaymentProfileDTO
         * @instance
         */
        PaymentProfileDTO.prototype.isPaymentMethodFixed = false;

        /**
         * Creates a new PaymentProfileDTO instance using the specified properties.
         * @function create
         * @memberof taxi.PaymentProfileDTO
         * @static
         * @param {taxi.IPaymentProfileDTO=} [properties] Properties to set
         * @returns {taxi.PaymentProfileDTO} PaymentProfileDTO instance
         */
        PaymentProfileDTO.create = function create(properties) {
            return new PaymentProfileDTO(properties);
        };

        /**
         * Creates a PaymentProfileDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.PaymentProfileDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.PaymentProfileDTO} PaymentProfileDTO
         */
        PaymentProfileDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.PaymentProfileDTO)
                return d;
            var m = new $root.taxi.PaymentProfileDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            switch (d.type) {
            case "PERSONAL":
            case 0:
                m.type = 0;
                break;
            case "BUSINESS":
            case 1:
                m.type = 1;
                break;
            }
            switch (d.status) {
            case "ACTIVE":
            case 0:
                m.status = 0;
                break;
            case "DEACTIVATED":
            case 1:
                m.status = 1;
                break;
            case "DELETED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.paymentMethod != null) {
                if (typeof d.paymentMethod !== "object")
                    throw TypeError(".taxi.PaymentProfileDTO.paymentMethod: object expected");
                m.paymentMethod = $root.taxi.PaymentMethodDTO.fromObject(d.paymentMethod);
            }
            if (d.isPaymentMethodFixed != null) {
                m.isPaymentMethodFixed = Boolean(d.isPaymentMethodFixed);
            }
            return m;
        };

        /**
         * Creates a plain object from a PaymentProfileDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.PaymentProfileDTO
         * @static
         * @param {taxi.PaymentProfileDTO} m PaymentProfileDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentProfileDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.email = "";
                d.type = o.enums === String ? "PERSONAL" : 0;
                d.status = o.enums === String ? "ACTIVE" : 0;
                d.paymentMethod = null;
                d.isPaymentMethodFixed = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.taxi.PaymentProfileDTO.Type[m.type] : m.type;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.taxi.PaymentProfileDTO.Status[m.status] : m.status;
            }
            if (m.paymentMethod != null && m.hasOwnProperty("paymentMethod")) {
                d.paymentMethod = $root.taxi.PaymentMethodDTO.toObject(m.paymentMethod, o);
            }
            if (m.isPaymentMethodFixed != null && m.hasOwnProperty("isPaymentMethodFixed")) {
                d.isPaymentMethodFixed = m.isPaymentMethodFixed;
            }
            return d;
        };

        /**
         * Converts this PaymentProfileDTO to JSON.
         * @function toJSON
         * @memberof taxi.PaymentProfileDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentProfileDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Type enum.
         * @name taxi.PaymentProfileDTO.Type
         * @enum {string}
         * @property {number} PERSONAL=0 PERSONAL value
         * @property {number} BUSINESS=1 BUSINESS value
         */
        PaymentProfileDTO.Type = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PERSONAL"] = 0;
            values[valuesById[1] = "BUSINESS"] = 1;
            return values;
        })();

        /**
         * Status enum.
         * @name taxi.PaymentProfileDTO.Status
         * @enum {string}
         * @property {number} ACTIVE=0 ACTIVE value
         * @property {number} DEACTIVATED=1 DEACTIVATED value
         * @property {number} DELETED=2 DELETED value
         */
        PaymentProfileDTO.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ACTIVE"] = 0;
            values[valuesById[1] = "DEACTIVATED"] = 1;
            values[valuesById[2] = "DELETED"] = 2;
            return values;
        })();

        return PaymentProfileDTO;
    })();

    taxi.FavoritePlaceDTO = (function() {

        /**
         * Properties of a FavoritePlaceDTO.
         * @memberof taxi
         * @interface IFavoritePlaceDTO
         * @property {string|null} [id] FavoritePlaceDTO id
         * @property {taxi.FavoritePlaceDTO.Type|null} [type] FavoritePlaceDTO type
         * @property {string|null} [name] FavoritePlaceDTO name
         * @property {taxi.IAddressedLocation|null} [location] FavoritePlaceDTO location
         * @property {number|null} [createdAt] FavoritePlaceDTO createdAt
         */

        /**
         * Constructs a new FavoritePlaceDTO.
         * @memberof taxi
         * @classdesc Represents a FavoritePlaceDTO.
         * @implements IFavoritePlaceDTO
         * @constructor
         * @param {taxi.IFavoritePlaceDTO=} [p] Properties to set
         */
        function FavoritePlaceDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FavoritePlaceDTO id.
         * @member {string} id
         * @memberof taxi.FavoritePlaceDTO
         * @instance
         */
        FavoritePlaceDTO.prototype.id = "";

        /**
         * FavoritePlaceDTO type.
         * @member {taxi.FavoritePlaceDTO.Type} type
         * @memberof taxi.FavoritePlaceDTO
         * @instance
         */
        FavoritePlaceDTO.prototype.type = 0;

        /**
         * FavoritePlaceDTO name.
         * @member {string} name
         * @memberof taxi.FavoritePlaceDTO
         * @instance
         */
        FavoritePlaceDTO.prototype.name = "";

        /**
         * FavoritePlaceDTO location.
         * @member {taxi.IAddressedLocation|null|undefined} location
         * @memberof taxi.FavoritePlaceDTO
         * @instance
         */
        FavoritePlaceDTO.prototype.location = null;

        /**
         * FavoritePlaceDTO createdAt.
         * @member {number} createdAt
         * @memberof taxi.FavoritePlaceDTO
         * @instance
         */
        FavoritePlaceDTO.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new FavoritePlaceDTO instance using the specified properties.
         * @function create
         * @memberof taxi.FavoritePlaceDTO
         * @static
         * @param {taxi.IFavoritePlaceDTO=} [properties] Properties to set
         * @returns {taxi.FavoritePlaceDTO} FavoritePlaceDTO instance
         */
        FavoritePlaceDTO.create = function create(properties) {
            return new FavoritePlaceDTO(properties);
        };

        /**
         * Creates a FavoritePlaceDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.FavoritePlaceDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.FavoritePlaceDTO} FavoritePlaceDTO
         */
        FavoritePlaceDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.FavoritePlaceDTO)
                return d;
            var m = new $root.taxi.FavoritePlaceDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.type) {
            case "CUSTOM":
            case 0:
                m.type = 0;
                break;
            case "HOME":
            case 1:
                m.type = 1;
                break;
            case "WORKPLACE":
            case 2:
                m.type = 2;
                break;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.location != null) {
                if (typeof d.location !== "object")
                    throw TypeError(".taxi.FavoritePlaceDTO.location: object expected");
                m.location = $root.taxi.AddressedLocation.fromObject(d.location);
            }
            if (d.createdAt != null) {
                if ($util.Long)
                    (m.createdAt = $util.Long.fromValue(d.createdAt)).unsigned = false;
                else if (typeof d.createdAt === "string")
                    m.createdAt = parseInt(d.createdAt, 10);
                else if (typeof d.createdAt === "number")
                    m.createdAt = d.createdAt;
                else if (typeof d.createdAt === "object")
                    m.createdAt = new $util.LongBits(d.createdAt.low >>> 0, d.createdAt.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a FavoritePlaceDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.FavoritePlaceDTO
         * @static
         * @param {taxi.FavoritePlaceDTO} m FavoritePlaceDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FavoritePlaceDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.type = o.enums === String ? "CUSTOM" : 0;
                d.name = "";
                d.location = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.createdAt = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.createdAt = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.taxi.FavoritePlaceDTO.Type[m.type] : m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.location != null && m.hasOwnProperty("location")) {
                d.location = $root.taxi.AddressedLocation.toObject(m.location, o);
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                if (typeof m.createdAt === "number")
                    d.createdAt = o.longs === String ? String(m.createdAt) : m.createdAt;
                else
                    d.createdAt = o.longs === String ? $util.Long.prototype.toString.call(m.createdAt) : o.longs === Number ? new $util.LongBits(m.createdAt.low >>> 0, m.createdAt.high >>> 0).toNumber() : m.createdAt;
            }
            return d;
        };

        /**
         * Converts this FavoritePlaceDTO to JSON.
         * @function toJSON
         * @memberof taxi.FavoritePlaceDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FavoritePlaceDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Type enum.
         * @name taxi.FavoritePlaceDTO.Type
         * @enum {string}
         * @property {number} CUSTOM=0 CUSTOM value
         * @property {number} HOME=1 HOME value
         * @property {number} WORKPLACE=2 WORKPLACE value
         */
        FavoritePlaceDTO.Type = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CUSTOM"] = 0;
            values[valuesById[1] = "HOME"] = 1;
            values[valuesById[2] = "WORKPLACE"] = 2;
            return values;
        })();

        return FavoritePlaceDTO;
    })();

    /**
     * AirportCode enum.
     * @name taxi.AirportCode
     * @enum {string}
     * @property {number} ICN_1=0 ICN_1 value
     * @property {number} ICN_2=1 ICN_2 value
     * @property {number} GMP_INTERNATIONAL=3 GMP_INTERNATIONAL value
     * @property {number} GMP_DOMESTIC=4 GMP_DOMESTIC value
     * @property {number} CJU_INTERNATIONAL=5 CJU_INTERNATIONAL value
     * @property {number} CJU_DOMESTIC=6 CJU_DOMESTIC value
     * @property {number} PUS_INTERNATIONAL=7 PUS_INTERNATIONAL value
     * @property {number} PUS_DOMESTIC=8 PUS_DOMESTIC value
     */
    taxi.AirportCode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ICN_1"] = 0;
        values[valuesById[1] = "ICN_2"] = 1;
        values[valuesById[3] = "GMP_INTERNATIONAL"] = 3;
        values[valuesById[4] = "GMP_DOMESTIC"] = 4;
        values[valuesById[5] = "CJU_INTERNATIONAL"] = 5;
        values[valuesById[6] = "CJU_DOMESTIC"] = 6;
        values[valuesById[7] = "PUS_INTERNATIONAL"] = 7;
        values[valuesById[8] = "PUS_DOMESTIC"] = 8;
        return values;
    })();

    taxi.TipPolicy = (function() {

        /**
         * Properties of a TipPolicy.
         * @memberof taxi
         * @interface ITipPolicy
         * @property {boolean|null} [enabled] TipPolicy enabled
         * @property {Array.<taxi.TipPolicy.IAmountEntry>|null} [amountEntries] TipPolicy amountEntries
         * @property {taxi.IBoolValue|null} [donation_201912CampaignEnabled] TipPolicy donation_201912CampaignEnabled
         */

        /**
         * Constructs a new TipPolicy.
         * @memberof taxi
         * @classdesc Represents a TipPolicy.
         * @implements ITipPolicy
         * @constructor
         * @param {taxi.ITipPolicy=} [p] Properties to set
         */
        function TipPolicy(p) {
            this.amountEntries = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TipPolicy enabled.
         * @member {boolean} enabled
         * @memberof taxi.TipPolicy
         * @instance
         */
        TipPolicy.prototype.enabled = false;

        /**
         * TipPolicy amountEntries.
         * @member {Array.<taxi.TipPolicy.IAmountEntry>} amountEntries
         * @memberof taxi.TipPolicy
         * @instance
         */
        TipPolicy.prototype.amountEntries = $util.emptyArray;

        /**
         * TipPolicy donation_201912CampaignEnabled.
         * @member {taxi.IBoolValue|null|undefined} donation_201912CampaignEnabled
         * @memberof taxi.TipPolicy
         * @instance
         */
        TipPolicy.prototype.donation_201912CampaignEnabled = null;

        /**
         * Creates a new TipPolicy instance using the specified properties.
         * @function create
         * @memberof taxi.TipPolicy
         * @static
         * @param {taxi.ITipPolicy=} [properties] Properties to set
         * @returns {taxi.TipPolicy} TipPolicy instance
         */
        TipPolicy.create = function create(properties) {
            return new TipPolicy(properties);
        };

        /**
         * Creates a TipPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.TipPolicy
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.TipPolicy} TipPolicy
         */
        TipPolicy.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.TipPolicy)
                return d;
            var m = new $root.taxi.TipPolicy();
            if (d.enabled != null) {
                m.enabled = Boolean(d.enabled);
            }
            if (d.amountEntries) {
                if (!Array.isArray(d.amountEntries))
                    throw TypeError(".taxi.TipPolicy.amountEntries: array expected");
                m.amountEntries = [];
                for (var i = 0; i < d.amountEntries.length; ++i) {
                    if (typeof d.amountEntries[i] !== "object")
                        throw TypeError(".taxi.TipPolicy.amountEntries: object expected");
                    m.amountEntries[i] = $root.taxi.TipPolicy.AmountEntry.fromObject(d.amountEntries[i]);
                }
            }
            if (d.donation_201912CampaignEnabled != null) {
                if (typeof d.donation_201912CampaignEnabled !== "object")
                    throw TypeError(".taxi.TipPolicy.donation_201912CampaignEnabled: object expected");
                m.donation_201912CampaignEnabled = $root.taxi.BoolValue.fromObject(d.donation_201912CampaignEnabled);
            }
            return m;
        };

        /**
         * Creates a plain object from a TipPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.TipPolicy
         * @static
         * @param {taxi.TipPolicy} m TipPolicy
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TipPolicy.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.amountEntries = [];
            }
            if (o.defaults) {
                d.enabled = false;
                d.donation_201912CampaignEnabled = null;
            }
            if (m.enabled != null && m.hasOwnProperty("enabled")) {
                d.enabled = m.enabled;
            }
            if (m.amountEntries && m.amountEntries.length) {
                d.amountEntries = [];
                for (var j = 0; j < m.amountEntries.length; ++j) {
                    d.amountEntries[j] = $root.taxi.TipPolicy.AmountEntry.toObject(m.amountEntries[j], o);
                }
            }
            if (m.donation_201912CampaignEnabled != null && m.hasOwnProperty("donation_201912CampaignEnabled")) {
                d.donation_201912CampaignEnabled = $root.taxi.BoolValue.toObject(m.donation_201912CampaignEnabled, o);
            }
            return d;
        };

        /**
         * Converts this TipPolicy to JSON.
         * @function toJSON
         * @memberof taxi.TipPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TipPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TipPolicy.AmountEntry = (function() {

            /**
             * Properties of an AmountEntry.
             * @memberof taxi.TipPolicy
             * @interface IAmountEntry
             * @property {number|null} [amount] AmountEntry amount
             */

            /**
             * Constructs a new AmountEntry.
             * @memberof taxi.TipPolicy
             * @classdesc Represents an AmountEntry.
             * @implements IAmountEntry
             * @constructor
             * @param {taxi.TipPolicy.IAmountEntry=} [p] Properties to set
             */
            function AmountEntry(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * AmountEntry amount.
             * @member {number} amount
             * @memberof taxi.TipPolicy.AmountEntry
             * @instance
             */
            AmountEntry.prototype.amount = 0;

            /**
             * Creates a new AmountEntry instance using the specified properties.
             * @function create
             * @memberof taxi.TipPolicy.AmountEntry
             * @static
             * @param {taxi.TipPolicy.IAmountEntry=} [properties] Properties to set
             * @returns {taxi.TipPolicy.AmountEntry} AmountEntry instance
             */
            AmountEntry.create = function create(properties) {
                return new AmountEntry(properties);
            };

            /**
             * Creates an AmountEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof taxi.TipPolicy.AmountEntry
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {taxi.TipPolicy.AmountEntry} AmountEntry
             */
            AmountEntry.fromObject = function fromObject(d) {
                if (d instanceof $root.taxi.TipPolicy.AmountEntry)
                    return d;
                var m = new $root.taxi.TipPolicy.AmountEntry();
                if (d.amount != null) {
                    m.amount = d.amount | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from an AmountEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof taxi.TipPolicy.AmountEntry
             * @static
             * @param {taxi.TipPolicy.AmountEntry} m AmountEntry
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AmountEntry.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.amount = 0;
                }
                if (m.amount != null && m.hasOwnProperty("amount")) {
                    d.amount = m.amount;
                }
                return d;
            };

            /**
             * Converts this AmountEntry to JSON.
             * @function toJSON
             * @memberof taxi.TipPolicy.AmountEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AmountEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AmountEntry;
        })();

        return TipPolicy;
    })();

    taxi.DoubleValue = (function() {

        /**
         * Properties of a DoubleValue.
         * @memberof taxi
         * @interface IDoubleValue
         * @property {number|null} [value] DoubleValue value
         */

        /**
         * Constructs a new DoubleValue.
         * @memberof taxi
         * @classdesc Represents a DoubleValue.
         * @implements IDoubleValue
         * @constructor
         * @param {taxi.IDoubleValue=} [p] Properties to set
         */
        function DoubleValue(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DoubleValue value.
         * @member {number} value
         * @memberof taxi.DoubleValue
         * @instance
         */
        DoubleValue.prototype.value = 0;

        /**
         * Creates a new DoubleValue instance using the specified properties.
         * @function create
         * @memberof taxi.DoubleValue
         * @static
         * @param {taxi.IDoubleValue=} [properties] Properties to set
         * @returns {taxi.DoubleValue} DoubleValue instance
         */
        DoubleValue.create = function create(properties) {
            return new DoubleValue(properties);
        };

        /**
         * Creates a DoubleValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.DoubleValue
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.DoubleValue} DoubleValue
         */
        DoubleValue.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.DoubleValue)
                return d;
            var m = new $root.taxi.DoubleValue();
            if (d.value != null) {
                m.value = Number(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from a DoubleValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.DoubleValue
         * @static
         * @param {taxi.DoubleValue} m DoubleValue
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DoubleValue.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = 0;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = o.json && !isFinite(m.value) ? String(m.value) : m.value;
            }
            return d;
        };

        /**
         * Converts this DoubleValue to JSON.
         * @function toJSON
         * @memberof taxi.DoubleValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DoubleValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DoubleValue;
    })();

    taxi.FloatValue = (function() {

        /**
         * Properties of a FloatValue.
         * @memberof taxi
         * @interface IFloatValue
         * @property {number|null} [value] FloatValue value
         */

        /**
         * Constructs a new FloatValue.
         * @memberof taxi
         * @classdesc Represents a FloatValue.
         * @implements IFloatValue
         * @constructor
         * @param {taxi.IFloatValue=} [p] Properties to set
         */
        function FloatValue(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FloatValue value.
         * @member {number} value
         * @memberof taxi.FloatValue
         * @instance
         */
        FloatValue.prototype.value = 0;

        /**
         * Creates a new FloatValue instance using the specified properties.
         * @function create
         * @memberof taxi.FloatValue
         * @static
         * @param {taxi.IFloatValue=} [properties] Properties to set
         * @returns {taxi.FloatValue} FloatValue instance
         */
        FloatValue.create = function create(properties) {
            return new FloatValue(properties);
        };

        /**
         * Creates a FloatValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.FloatValue
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.FloatValue} FloatValue
         */
        FloatValue.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.FloatValue)
                return d;
            var m = new $root.taxi.FloatValue();
            if (d.value != null) {
                m.value = Number(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from a FloatValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.FloatValue
         * @static
         * @param {taxi.FloatValue} m FloatValue
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FloatValue.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = 0;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = o.json && !isFinite(m.value) ? String(m.value) : m.value;
            }
            return d;
        };

        /**
         * Converts this FloatValue to JSON.
         * @function toJSON
         * @memberof taxi.FloatValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FloatValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FloatValue;
    })();

    taxi.Int64Value = (function() {

        /**
         * Properties of an Int64Value.
         * @memberof taxi
         * @interface IInt64Value
         * @property {number|null} [value] Int64Value value
         */

        /**
         * Constructs a new Int64Value.
         * @memberof taxi
         * @classdesc Represents an Int64Value.
         * @implements IInt64Value
         * @constructor
         * @param {taxi.IInt64Value=} [p] Properties to set
         */
        function Int64Value(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Int64Value value.
         * @member {number} value
         * @memberof taxi.Int64Value
         * @instance
         */
        Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Int64Value instance using the specified properties.
         * @function create
         * @memberof taxi.Int64Value
         * @static
         * @param {taxi.IInt64Value=} [properties] Properties to set
         * @returns {taxi.Int64Value} Int64Value instance
         */
        Int64Value.create = function create(properties) {
            return new Int64Value(properties);
        };

        /**
         * Creates an Int64Value message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.Int64Value
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.Int64Value} Int64Value
         */
        Int64Value.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.Int64Value)
                return d;
            var m = new $root.taxi.Int64Value();
            if (d.value != null) {
                if ($util.Long)
                    (m.value = $util.Long.fromValue(d.value)).unsigned = false;
                else if (typeof d.value === "string")
                    m.value = parseInt(d.value, 10);
                else if (typeof d.value === "number")
                    m.value = d.value;
                else if (typeof d.value === "object")
                    m.value = new $util.LongBits(d.value.low >>> 0, d.value.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from an Int64Value message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.Int64Value
         * @static
         * @param {taxi.Int64Value} m Int64Value
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Int64Value.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.value = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.value = o.longs === String ? "0" : 0;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                if (typeof m.value === "number")
                    d.value = o.longs === String ? String(m.value) : m.value;
                else
                    d.value = o.longs === String ? $util.Long.prototype.toString.call(m.value) : o.longs === Number ? new $util.LongBits(m.value.low >>> 0, m.value.high >>> 0).toNumber() : m.value;
            }
            return d;
        };

        /**
         * Converts this Int64Value to JSON.
         * @function toJSON
         * @memberof taxi.Int64Value
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Int64Value.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Int64Value;
    })();

    taxi.UInt64Value = (function() {

        /**
         * Properties of a UInt64Value.
         * @memberof taxi
         * @interface IUInt64Value
         * @property {number|null} [value] UInt64Value value
         */

        /**
         * Constructs a new UInt64Value.
         * @memberof taxi
         * @classdesc Represents a UInt64Value.
         * @implements IUInt64Value
         * @constructor
         * @param {taxi.IUInt64Value=} [p] Properties to set
         */
        function UInt64Value(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UInt64Value value.
         * @member {number} value
         * @memberof taxi.UInt64Value
         * @instance
         */
        UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new UInt64Value instance using the specified properties.
         * @function create
         * @memberof taxi.UInt64Value
         * @static
         * @param {taxi.IUInt64Value=} [properties] Properties to set
         * @returns {taxi.UInt64Value} UInt64Value instance
         */
        UInt64Value.create = function create(properties) {
            return new UInt64Value(properties);
        };

        /**
         * Creates a UInt64Value message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UInt64Value
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UInt64Value} UInt64Value
         */
        UInt64Value.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UInt64Value)
                return d;
            var m = new $root.taxi.UInt64Value();
            if (d.value != null) {
                if ($util.Long)
                    (m.value = $util.Long.fromValue(d.value)).unsigned = true;
                else if (typeof d.value === "string")
                    m.value = parseInt(d.value, 10);
                else if (typeof d.value === "number")
                    m.value = d.value;
                else if (typeof d.value === "object")
                    m.value = new $util.LongBits(d.value.low >>> 0, d.value.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a UInt64Value message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UInt64Value
         * @static
         * @param {taxi.UInt64Value} m UInt64Value
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UInt64Value.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.value = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.value = o.longs === String ? "0" : 0;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                if (typeof m.value === "number")
                    d.value = o.longs === String ? String(m.value) : m.value;
                else
                    d.value = o.longs === String ? $util.Long.prototype.toString.call(m.value) : o.longs === Number ? new $util.LongBits(m.value.low >>> 0, m.value.high >>> 0).toNumber(true) : m.value;
            }
            return d;
        };

        /**
         * Converts this UInt64Value to JSON.
         * @function toJSON
         * @memberof taxi.UInt64Value
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UInt64Value.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UInt64Value;
    })();

    taxi.Int32Value = (function() {

        /**
         * Properties of an Int32Value.
         * @memberof taxi
         * @interface IInt32Value
         * @property {number|null} [value] Int32Value value
         */

        /**
         * Constructs a new Int32Value.
         * @memberof taxi
         * @classdesc Represents an Int32Value.
         * @implements IInt32Value
         * @constructor
         * @param {taxi.IInt32Value=} [p] Properties to set
         */
        function Int32Value(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Int32Value value.
         * @member {number} value
         * @memberof taxi.Int32Value
         * @instance
         */
        Int32Value.prototype.value = 0;

        /**
         * Creates a new Int32Value instance using the specified properties.
         * @function create
         * @memberof taxi.Int32Value
         * @static
         * @param {taxi.IInt32Value=} [properties] Properties to set
         * @returns {taxi.Int32Value} Int32Value instance
         */
        Int32Value.create = function create(properties) {
            return new Int32Value(properties);
        };

        /**
         * Creates an Int32Value message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.Int32Value
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.Int32Value} Int32Value
         */
        Int32Value.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.Int32Value)
                return d;
            var m = new $root.taxi.Int32Value();
            if (d.value != null) {
                m.value = d.value | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from an Int32Value message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.Int32Value
         * @static
         * @param {taxi.Int32Value} m Int32Value
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Int32Value.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = 0;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this Int32Value to JSON.
         * @function toJSON
         * @memberof taxi.Int32Value
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Int32Value.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Int32Value;
    })();

    taxi.UInt32Value = (function() {

        /**
         * Properties of a UInt32Value.
         * @memberof taxi
         * @interface IUInt32Value
         * @property {number|null} [value] UInt32Value value
         */

        /**
         * Constructs a new UInt32Value.
         * @memberof taxi
         * @classdesc Represents a UInt32Value.
         * @implements IUInt32Value
         * @constructor
         * @param {taxi.IUInt32Value=} [p] Properties to set
         */
        function UInt32Value(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UInt32Value value.
         * @member {number} value
         * @memberof taxi.UInt32Value
         * @instance
         */
        UInt32Value.prototype.value = 0;

        /**
         * Creates a new UInt32Value instance using the specified properties.
         * @function create
         * @memberof taxi.UInt32Value
         * @static
         * @param {taxi.IUInt32Value=} [properties] Properties to set
         * @returns {taxi.UInt32Value} UInt32Value instance
         */
        UInt32Value.create = function create(properties) {
            return new UInt32Value(properties);
        };

        /**
         * Creates a UInt32Value message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UInt32Value
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UInt32Value} UInt32Value
         */
        UInt32Value.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UInt32Value)
                return d;
            var m = new $root.taxi.UInt32Value();
            if (d.value != null) {
                m.value = d.value >>> 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a UInt32Value message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UInt32Value
         * @static
         * @param {taxi.UInt32Value} m UInt32Value
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UInt32Value.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = 0;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this UInt32Value to JSON.
         * @function toJSON
         * @memberof taxi.UInt32Value
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UInt32Value.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UInt32Value;
    })();

    taxi.BoolValue = (function() {

        /**
         * Properties of a BoolValue.
         * @memberof taxi
         * @interface IBoolValue
         * @property {boolean|null} [value] BoolValue value
         */

        /**
         * Constructs a new BoolValue.
         * @memberof taxi
         * @classdesc Represents a BoolValue.
         * @implements IBoolValue
         * @constructor
         * @param {taxi.IBoolValue=} [p] Properties to set
         */
        function BoolValue(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * BoolValue value.
         * @member {boolean} value
         * @memberof taxi.BoolValue
         * @instance
         */
        BoolValue.prototype.value = false;

        /**
         * Creates a new BoolValue instance using the specified properties.
         * @function create
         * @memberof taxi.BoolValue
         * @static
         * @param {taxi.IBoolValue=} [properties] Properties to set
         * @returns {taxi.BoolValue} BoolValue instance
         */
        BoolValue.create = function create(properties) {
            return new BoolValue(properties);
        };

        /**
         * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.BoolValue
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.BoolValue} BoolValue
         */
        BoolValue.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.BoolValue)
                return d;
            var m = new $root.taxi.BoolValue();
            if (d.value != null) {
                m.value = Boolean(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.BoolValue
         * @static
         * @param {taxi.BoolValue} m BoolValue
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoolValue.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = false;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this BoolValue to JSON.
         * @function toJSON
         * @memberof taxi.BoolValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoolValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BoolValue;
    })();

    taxi.StringValue = (function() {

        /**
         * Properties of a StringValue.
         * @memberof taxi
         * @interface IStringValue
         * @property {string|null} [value] StringValue value
         */

        /**
         * Constructs a new StringValue.
         * @memberof taxi
         * @classdesc Represents a StringValue.
         * @implements IStringValue
         * @constructor
         * @param {taxi.IStringValue=} [p] Properties to set
         */
        function StringValue(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StringValue value.
         * @member {string} value
         * @memberof taxi.StringValue
         * @instance
         */
        StringValue.prototype.value = "";

        /**
         * Creates a new StringValue instance using the specified properties.
         * @function create
         * @memberof taxi.StringValue
         * @static
         * @param {taxi.IStringValue=} [properties] Properties to set
         * @returns {taxi.StringValue} StringValue instance
         */
        StringValue.create = function create(properties) {
            return new StringValue(properties);
        };

        /**
         * Creates a StringValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.StringValue
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.StringValue} StringValue
         */
        StringValue.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.StringValue)
                return d;
            var m = new $root.taxi.StringValue();
            if (d.value != null) {
                m.value = String(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from a StringValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.StringValue
         * @static
         * @param {taxi.StringValue} m StringValue
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StringValue.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.value = "";
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this StringValue to JSON.
         * @function toJSON
         * @memberof taxi.StringValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StringValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StringValue;
    })();

    taxi.BytesValue = (function() {

        /**
         * Properties of a BytesValue.
         * @memberof taxi
         * @interface IBytesValue
         * @property {Uint8Array|null} [value] BytesValue value
         */

        /**
         * Constructs a new BytesValue.
         * @memberof taxi
         * @classdesc Represents a BytesValue.
         * @implements IBytesValue
         * @constructor
         * @param {taxi.IBytesValue=} [p] Properties to set
         */
        function BytesValue(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * BytesValue value.
         * @member {Uint8Array} value
         * @memberof taxi.BytesValue
         * @instance
         */
        BytesValue.prototype.value = $util.newBuffer([]);

        /**
         * Creates a new BytesValue instance using the specified properties.
         * @function create
         * @memberof taxi.BytesValue
         * @static
         * @param {taxi.IBytesValue=} [properties] Properties to set
         * @returns {taxi.BytesValue} BytesValue instance
         */
        BytesValue.create = function create(properties) {
            return new BytesValue(properties);
        };

        /**
         * Creates a BytesValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.BytesValue
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.BytesValue} BytesValue
         */
        BytesValue.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.BytesValue)
                return d;
            var m = new $root.taxi.BytesValue();
            if (d.value != null) {
                if (typeof d.value === "string")
                    $util.base64.decode(d.value, m.value = $util.newBuffer($util.base64.length(d.value)), 0);
                else if (d.value.length)
                    m.value = d.value;
            }
            return m;
        };

        /**
         * Creates a plain object from a BytesValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.BytesValue
         * @static
         * @param {taxi.BytesValue} m BytesValue
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BytesValue.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if (o.bytes === String)
                    d.value = "";
                else {
                    d.value = [];
                    if (o.bytes !== Array)
                        d.value = $util.newBuffer(d.value);
                }
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = o.bytes === String ? $util.base64.encode(m.value, 0, m.value.length) : o.bytes === Array ? Array.prototype.slice.call(m.value) : m.value;
            }
            return d;
        };

        /**
         * Converts this BytesValue to JSON.
         * @function toJSON
         * @memberof taxi.BytesValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BytesValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BytesValue;
    })();

    taxi.Timestamp = (function() {

        /**
         * Properties of a Timestamp.
         * @memberof taxi
         * @interface ITimestamp
         * @property {number|null} [value] Timestamp value
         */

        /**
         * Constructs a new Timestamp.
         * @memberof taxi
         * @classdesc Represents a Timestamp.
         * @implements ITimestamp
         * @constructor
         * @param {taxi.ITimestamp=} [p] Properties to set
         */
        function Timestamp(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Timestamp value.
         * @member {number} value
         * @memberof taxi.Timestamp
         * @instance
         */
        Timestamp.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Timestamp instance using the specified properties.
         * @function create
         * @memberof taxi.Timestamp
         * @static
         * @param {taxi.ITimestamp=} [properties] Properties to set
         * @returns {taxi.Timestamp} Timestamp instance
         */
        Timestamp.create = function create(properties) {
            return new Timestamp(properties);
        };

        /**
         * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.Timestamp
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.Timestamp} Timestamp
         */
        Timestamp.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.Timestamp)
                return d;
            var m = new $root.taxi.Timestamp();
            if (d.value != null) {
                if ($util.Long)
                    (m.value = $util.Long.fromValue(d.value)).unsigned = false;
                else if (typeof d.value === "string")
                    m.value = parseInt(d.value, 10);
                else if (typeof d.value === "number")
                    m.value = d.value;
                else if (typeof d.value === "object")
                    m.value = new $util.LongBits(d.value.low >>> 0, d.value.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.Timestamp
         * @static
         * @param {taxi.Timestamp} m Timestamp
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Timestamp.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.value = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.value = o.longs === String ? "0" : 0;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                if (typeof m.value === "number")
                    d.value = o.longs === String ? String(m.value) : m.value;
                else
                    d.value = o.longs === String ? $util.Long.prototype.toString.call(m.value) : o.longs === Number ? new $util.LongBits(m.value.low >>> 0, m.value.high >>> 0).toNumber() : m.value;
            }
            return d;
        };

        /**
         * Converts this Timestamp to JSON.
         * @function toJSON
         * @memberof taxi.Timestamp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Timestamp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Timestamp;
    })();

    taxi.Datetime = (function() {

        /**
         * Properties of a Datetime.
         * @memberof taxi
         * @interface IDatetime
         * @property {number|null} [year] Datetime year
         * @property {number|null} [month] Datetime month
         * @property {number|null} [dayOfMonth] Datetime dayOfMonth
         * @property {number|null} [hour] Datetime hour
         * @property {number|null} [minute] Datetime minute
         * @property {taxi.Datetime.DayOfWeek|null} [dayOfWeek] Datetime dayOfWeek
         */

        /**
         * Constructs a new Datetime.
         * @memberof taxi
         * @classdesc Represents a Datetime.
         * @implements IDatetime
         * @constructor
         * @param {taxi.IDatetime=} [p] Properties to set
         */
        function Datetime(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Datetime year.
         * @member {number} year
         * @memberof taxi.Datetime
         * @instance
         */
        Datetime.prototype.year = 0;

        /**
         * Datetime month.
         * @member {number} month
         * @memberof taxi.Datetime
         * @instance
         */
        Datetime.prototype.month = 0;

        /**
         * Datetime dayOfMonth.
         * @member {number} dayOfMonth
         * @memberof taxi.Datetime
         * @instance
         */
        Datetime.prototype.dayOfMonth = 0;

        /**
         * Datetime hour.
         * @member {number} hour
         * @memberof taxi.Datetime
         * @instance
         */
        Datetime.prototype.hour = 0;

        /**
         * Datetime minute.
         * @member {number} minute
         * @memberof taxi.Datetime
         * @instance
         */
        Datetime.prototype.minute = 0;

        /**
         * Datetime dayOfWeek.
         * @member {taxi.Datetime.DayOfWeek} dayOfWeek
         * @memberof taxi.Datetime
         * @instance
         */
        Datetime.prototype.dayOfWeek = 0;

        /**
         * Creates a new Datetime instance using the specified properties.
         * @function create
         * @memberof taxi.Datetime
         * @static
         * @param {taxi.IDatetime=} [properties] Properties to set
         * @returns {taxi.Datetime} Datetime instance
         */
        Datetime.create = function create(properties) {
            return new Datetime(properties);
        };

        /**
         * Creates a Datetime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.Datetime
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.Datetime} Datetime
         */
        Datetime.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.Datetime)
                return d;
            var m = new $root.taxi.Datetime();
            if (d.year != null) {
                m.year = d.year | 0;
            }
            if (d.month != null) {
                m.month = d.month | 0;
            }
            if (d.dayOfMonth != null) {
                m.dayOfMonth = d.dayOfMonth | 0;
            }
            if (d.hour != null) {
                m.hour = d.hour | 0;
            }
            if (d.minute != null) {
                m.minute = d.minute | 0;
            }
            switch (d.dayOfWeek) {
            case "SUNDAY":
            case 0:
                m.dayOfWeek = 0;
                break;
            case "MONDAY":
            case 1:
                m.dayOfWeek = 1;
                break;
            case "TUESDAY":
            case 2:
                m.dayOfWeek = 2;
                break;
            case "WEDNESDAY":
            case 3:
                m.dayOfWeek = 3;
                break;
            case "THURSDAY":
            case 4:
                m.dayOfWeek = 4;
                break;
            case "FRIDAY":
            case 5:
                m.dayOfWeek = 5;
                break;
            case "SATURDAY":
            case 6:
                m.dayOfWeek = 6;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a Datetime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.Datetime
         * @static
         * @param {taxi.Datetime} m Datetime
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Datetime.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.year = 0;
                d.month = 0;
                d.dayOfMonth = 0;
                d.hour = 0;
                d.minute = 0;
                d.dayOfWeek = o.enums === String ? "SUNDAY" : 0;
            }
            if (m.year != null && m.hasOwnProperty("year")) {
                d.year = m.year;
            }
            if (m.month != null && m.hasOwnProperty("month")) {
                d.month = m.month;
            }
            if (m.dayOfMonth != null && m.hasOwnProperty("dayOfMonth")) {
                d.dayOfMonth = m.dayOfMonth;
            }
            if (m.hour != null && m.hasOwnProperty("hour")) {
                d.hour = m.hour;
            }
            if (m.minute != null && m.hasOwnProperty("minute")) {
                d.minute = m.minute;
            }
            if (m.dayOfWeek != null && m.hasOwnProperty("dayOfWeek")) {
                d.dayOfWeek = o.enums === String ? $root.taxi.Datetime.DayOfWeek[m.dayOfWeek] : m.dayOfWeek;
            }
            return d;
        };

        /**
         * Converts this Datetime to JSON.
         * @function toJSON
         * @memberof taxi.Datetime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Datetime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * DayOfWeek enum.
         * @name taxi.Datetime.DayOfWeek
         * @enum {string}
         * @property {number} SUNDAY=0 SUNDAY value
         * @property {number} MONDAY=1 MONDAY value
         * @property {number} TUESDAY=2 TUESDAY value
         * @property {number} WEDNESDAY=3 WEDNESDAY value
         * @property {number} THURSDAY=4 THURSDAY value
         * @property {number} FRIDAY=5 FRIDAY value
         * @property {number} SATURDAY=6 SATURDAY value
         */
        Datetime.DayOfWeek = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SUNDAY"] = 0;
            values[valuesById[1] = "MONDAY"] = 1;
            values[valuesById[2] = "TUESDAY"] = 2;
            values[valuesById[3] = "WEDNESDAY"] = 3;
            values[valuesById[4] = "THURSDAY"] = 4;
            values[valuesById[5] = "FRIDAY"] = 5;
            values[valuesById[6] = "SATURDAY"] = 6;
            return values;
        })();

        return Datetime;
    })();

    taxi.CouponDTO = (function() {

        /**
         * Properties of a CouponDTO.
         * @memberof taxi
         * @interface ICouponDTO
         * @property {string|null} [id] CouponDTO id
         * @property {string|null} [name] CouponDTO name
         * @property {taxi.ITimestamp|null} [validAfter] CouponDTO validAfter
         * @property {taxi.ITimestamp|null} [expiresAt] CouponDTO expiresAt
         * @property {string|null} [couponCode] CouponDTO couponCode
         * @property {boolean|null} [isActive] CouponDTO isActive
         * @property {Array.<string>|null} [availableTypes] CouponDTO availableTypes
         * @property {taxi.IStringValue|null} [conditionText] CouponDTO conditionText
         * @property {taxi.CouponDTO.BenefitType|null} [benefitType] CouponDTO benefitType
         * @property {number|null} [minBenefitAmount] CouponDTO minBenefitAmount
         * @property {number|null} [maxBenefitAmount] CouponDTO maxBenefitAmount
         * @property {number|null} [benefitAmount] CouponDTO benefitAmount
         * @property {taxi.IInt32Value|null} [remainingUseCount] CouponDTO remainingUseCount
         * @property {taxi.IInt32Value|null} [totalUseCount] CouponDTO totalUseCount
         * @property {taxi.IBoolValue|null} [isTadaPassCoupon] CouponDTO isTadaPassCoupon
         */

        /**
         * Constructs a new CouponDTO.
         * @memberof taxi
         * @classdesc Represents a CouponDTO.
         * @implements ICouponDTO
         * @constructor
         * @param {taxi.ICouponDTO=} [p] Properties to set
         */
        function CouponDTO(p) {
            this.availableTypes = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CouponDTO id.
         * @member {string} id
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.id = "";

        /**
         * CouponDTO name.
         * @member {string} name
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.name = "";

        /**
         * CouponDTO validAfter.
         * @member {taxi.ITimestamp|null|undefined} validAfter
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.validAfter = null;

        /**
         * CouponDTO expiresAt.
         * @member {taxi.ITimestamp|null|undefined} expiresAt
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.expiresAt = null;

        /**
         * CouponDTO couponCode.
         * @member {string} couponCode
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.couponCode = "";

        /**
         * CouponDTO isActive.
         * @member {boolean} isActive
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.isActive = false;

        /**
         * CouponDTO availableTypes.
         * @member {Array.<string>} availableTypes
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.availableTypes = $util.emptyArray;

        /**
         * CouponDTO conditionText.
         * @member {taxi.IStringValue|null|undefined} conditionText
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.conditionText = null;

        /**
         * CouponDTO benefitType.
         * @member {taxi.CouponDTO.BenefitType} benefitType
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.benefitType = 0;

        /**
         * CouponDTO minBenefitAmount.
         * @member {number} minBenefitAmount
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.minBenefitAmount = 0;

        /**
         * CouponDTO maxBenefitAmount.
         * @member {number} maxBenefitAmount
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.maxBenefitAmount = 0;

        /**
         * CouponDTO benefitAmount.
         * @member {number} benefitAmount
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.benefitAmount = 0;

        /**
         * CouponDTO remainingUseCount.
         * @member {taxi.IInt32Value|null|undefined} remainingUseCount
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.remainingUseCount = null;

        /**
         * CouponDTO totalUseCount.
         * @member {taxi.IInt32Value|null|undefined} totalUseCount
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.totalUseCount = null;

        /**
         * CouponDTO isTadaPassCoupon.
         * @member {taxi.IBoolValue|null|undefined} isTadaPassCoupon
         * @memberof taxi.CouponDTO
         * @instance
         */
        CouponDTO.prototype.isTadaPassCoupon = null;

        /**
         * Creates a new CouponDTO instance using the specified properties.
         * @function create
         * @memberof taxi.CouponDTO
         * @static
         * @param {taxi.ICouponDTO=} [properties] Properties to set
         * @returns {taxi.CouponDTO} CouponDTO instance
         */
        CouponDTO.create = function create(properties) {
            return new CouponDTO(properties);
        };

        /**
         * Creates a CouponDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.CouponDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.CouponDTO} CouponDTO
         */
        CouponDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.CouponDTO)
                return d;
            var m = new $root.taxi.CouponDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.validAfter != null) {
                if (typeof d.validAfter !== "object")
                    throw TypeError(".taxi.CouponDTO.validAfter: object expected");
                m.validAfter = $root.taxi.Timestamp.fromObject(d.validAfter);
            }
            if (d.expiresAt != null) {
                if (typeof d.expiresAt !== "object")
                    throw TypeError(".taxi.CouponDTO.expiresAt: object expected");
                m.expiresAt = $root.taxi.Timestamp.fromObject(d.expiresAt);
            }
            if (d.couponCode != null) {
                m.couponCode = String(d.couponCode);
            }
            if (d.isActive != null) {
                m.isActive = Boolean(d.isActive);
            }
            if (d.availableTypes) {
                if (!Array.isArray(d.availableTypes))
                    throw TypeError(".taxi.CouponDTO.availableTypes: array expected");
                m.availableTypes = [];
                for (var i = 0; i < d.availableTypes.length; ++i) {
                    m.availableTypes[i] = String(d.availableTypes[i]);
                }
            }
            if (d.conditionText != null) {
                if (typeof d.conditionText !== "object")
                    throw TypeError(".taxi.CouponDTO.conditionText: object expected");
                m.conditionText = $root.taxi.StringValue.fromObject(d.conditionText);
            }
            switch (d.benefitType) {
            case "DISCOUNT_RATE":
            case 0:
                m.benefitType = 0;
                break;
            case "DISCOUNT_AMOUNT":
            case 1:
                m.benefitType = 1;
                break;
            }
            if (d.minBenefitAmount != null) {
                m.minBenefitAmount = d.minBenefitAmount | 0;
            }
            if (d.maxBenefitAmount != null) {
                m.maxBenefitAmount = d.maxBenefitAmount | 0;
            }
            if (d.benefitAmount != null) {
                m.benefitAmount = d.benefitAmount | 0;
            }
            if (d.remainingUseCount != null) {
                if (typeof d.remainingUseCount !== "object")
                    throw TypeError(".taxi.CouponDTO.remainingUseCount: object expected");
                m.remainingUseCount = $root.taxi.Int32Value.fromObject(d.remainingUseCount);
            }
            if (d.totalUseCount != null) {
                if (typeof d.totalUseCount !== "object")
                    throw TypeError(".taxi.CouponDTO.totalUseCount: object expected");
                m.totalUseCount = $root.taxi.Int32Value.fromObject(d.totalUseCount);
            }
            if (d.isTadaPassCoupon != null) {
                if (typeof d.isTadaPassCoupon !== "object")
                    throw TypeError(".taxi.CouponDTO.isTadaPassCoupon: object expected");
                m.isTadaPassCoupon = $root.taxi.BoolValue.fromObject(d.isTadaPassCoupon);
            }
            return m;
        };

        /**
         * Creates a plain object from a CouponDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.CouponDTO
         * @static
         * @param {taxi.CouponDTO} m CouponDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CouponDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.availableTypes = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.validAfter = null;
                d.expiresAt = null;
                d.couponCode = "";
                d.isActive = false;
                d.conditionText = null;
                d.benefitType = o.enums === String ? "DISCOUNT_RATE" : 0;
                d.minBenefitAmount = 0;
                d.maxBenefitAmount = 0;
                d.benefitAmount = 0;
                d.remainingUseCount = null;
                d.totalUseCount = null;
                d.isTadaPassCoupon = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.validAfter != null && m.hasOwnProperty("validAfter")) {
                d.validAfter = $root.taxi.Timestamp.toObject(m.validAfter, o);
            }
            if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                d.expiresAt = $root.taxi.Timestamp.toObject(m.expiresAt, o);
            }
            if (m.couponCode != null && m.hasOwnProperty("couponCode")) {
                d.couponCode = m.couponCode;
            }
            if (m.isActive != null && m.hasOwnProperty("isActive")) {
                d.isActive = m.isActive;
            }
            if (m.availableTypes && m.availableTypes.length) {
                d.availableTypes = [];
                for (var j = 0; j < m.availableTypes.length; ++j) {
                    d.availableTypes[j] = m.availableTypes[j];
                }
            }
            if (m.conditionText != null && m.hasOwnProperty("conditionText")) {
                d.conditionText = $root.taxi.StringValue.toObject(m.conditionText, o);
            }
            if (m.benefitType != null && m.hasOwnProperty("benefitType")) {
                d.benefitType = o.enums === String ? $root.taxi.CouponDTO.BenefitType[m.benefitType] : m.benefitType;
            }
            if (m.minBenefitAmount != null && m.hasOwnProperty("minBenefitAmount")) {
                d.minBenefitAmount = m.minBenefitAmount;
            }
            if (m.maxBenefitAmount != null && m.hasOwnProperty("maxBenefitAmount")) {
                d.maxBenefitAmount = m.maxBenefitAmount;
            }
            if (m.benefitAmount != null && m.hasOwnProperty("benefitAmount")) {
                d.benefitAmount = m.benefitAmount;
            }
            if (m.remainingUseCount != null && m.hasOwnProperty("remainingUseCount")) {
                d.remainingUseCount = $root.taxi.Int32Value.toObject(m.remainingUseCount, o);
            }
            if (m.totalUseCount != null && m.hasOwnProperty("totalUseCount")) {
                d.totalUseCount = $root.taxi.Int32Value.toObject(m.totalUseCount, o);
            }
            if (m.isTadaPassCoupon != null && m.hasOwnProperty("isTadaPassCoupon")) {
                d.isTadaPassCoupon = $root.taxi.BoolValue.toObject(m.isTadaPassCoupon, o);
            }
            return d;
        };

        /**
         * Converts this CouponDTO to JSON.
         * @function toJSON
         * @memberof taxi.CouponDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CouponDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * BenefitType enum.
         * @name taxi.CouponDTO.BenefitType
         * @enum {string}
         * @property {number} DISCOUNT_RATE=0 DISCOUNT_RATE value
         * @property {number} DISCOUNT_AMOUNT=1 DISCOUNT_AMOUNT value
         */
        CouponDTO.BenefitType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DISCOUNT_RATE"] = 0;
            values[valuesById[1] = "DISCOUNT_AMOUNT"] = 1;
            return values;
        })();

        return CouponDTO;
    })();

    taxi.UserSubscriptionBillingReceipt = (function() {

        /**
         * Properties of a UserSubscriptionBillingReceipt.
         * @memberof taxi
         * @interface IUserSubscriptionBillingReceipt
         * @property {number|null} [price] UserSubscriptionBillingReceipt price
         * @property {taxi.IInt32Value|null} [refundAmount] UserSubscriptionBillingReceipt refundAmount
         * @property {number|null} [total] UserSubscriptionBillingReceipt total
         */

        /**
         * Constructs a new UserSubscriptionBillingReceipt.
         * @memberof taxi
         * @classdesc Represents a UserSubscriptionBillingReceipt.
         * @implements IUserSubscriptionBillingReceipt
         * @constructor
         * @param {taxi.IUserSubscriptionBillingReceipt=} [p] Properties to set
         */
        function UserSubscriptionBillingReceipt(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserSubscriptionBillingReceipt price.
         * @member {number} price
         * @memberof taxi.UserSubscriptionBillingReceipt
         * @instance
         */
        UserSubscriptionBillingReceipt.prototype.price = 0;

        /**
         * UserSubscriptionBillingReceipt refundAmount.
         * @member {taxi.IInt32Value|null|undefined} refundAmount
         * @memberof taxi.UserSubscriptionBillingReceipt
         * @instance
         */
        UserSubscriptionBillingReceipt.prototype.refundAmount = null;

        /**
         * UserSubscriptionBillingReceipt total.
         * @member {number} total
         * @memberof taxi.UserSubscriptionBillingReceipt
         * @instance
         */
        UserSubscriptionBillingReceipt.prototype.total = 0;

        /**
         * Creates a new UserSubscriptionBillingReceipt instance using the specified properties.
         * @function create
         * @memberof taxi.UserSubscriptionBillingReceipt
         * @static
         * @param {taxi.IUserSubscriptionBillingReceipt=} [properties] Properties to set
         * @returns {taxi.UserSubscriptionBillingReceipt} UserSubscriptionBillingReceipt instance
         */
        UserSubscriptionBillingReceipt.create = function create(properties) {
            return new UserSubscriptionBillingReceipt(properties);
        };

        /**
         * Creates a UserSubscriptionBillingReceipt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UserSubscriptionBillingReceipt
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UserSubscriptionBillingReceipt} UserSubscriptionBillingReceipt
         */
        UserSubscriptionBillingReceipt.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UserSubscriptionBillingReceipt)
                return d;
            var m = new $root.taxi.UserSubscriptionBillingReceipt();
            if (d.price != null) {
                m.price = d.price | 0;
            }
            if (d.refundAmount != null) {
                if (typeof d.refundAmount !== "object")
                    throw TypeError(".taxi.UserSubscriptionBillingReceipt.refundAmount: object expected");
                m.refundAmount = $root.taxi.Int32Value.fromObject(d.refundAmount);
            }
            if (d.total != null) {
                m.total = d.total | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a UserSubscriptionBillingReceipt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UserSubscriptionBillingReceipt
         * @static
         * @param {taxi.UserSubscriptionBillingReceipt} m UserSubscriptionBillingReceipt
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSubscriptionBillingReceipt.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.price = 0;
                d.refundAmount = null;
                d.total = 0;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = m.price;
            }
            if (m.refundAmount != null && m.hasOwnProperty("refundAmount")) {
                d.refundAmount = $root.taxi.Int32Value.toObject(m.refundAmount, o);
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                d.total = m.total;
            }
            return d;
        };

        /**
         * Converts this UserSubscriptionBillingReceipt to JSON.
         * @function toJSON
         * @memberof taxi.UserSubscriptionBillingReceipt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSubscriptionBillingReceipt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserSubscriptionBillingReceipt;
    })();

    taxi.UserSubscriptionProductDTO = (function() {

        /**
         * Properties of a UserSubscriptionProductDTO.
         * @memberof taxi
         * @interface IUserSubscriptionProductDTO
         * @property {string|null} [id] UserSubscriptionProductDTO id
         * @property {string|null} [name] UserSubscriptionProductDTO name
         * @property {number|null} [price] UserSubscriptionProductDTO price
         * @property {number|null} [originalPrice] UserSubscriptionProductDTO originalPrice
         * @property {boolean|null} [isAvailable] UserSubscriptionProductDTO isAvailable
         */

        /**
         * Constructs a new UserSubscriptionProductDTO.
         * @memberof taxi
         * @classdesc Represents a UserSubscriptionProductDTO.
         * @implements IUserSubscriptionProductDTO
         * @constructor
         * @param {taxi.IUserSubscriptionProductDTO=} [p] Properties to set
         */
        function UserSubscriptionProductDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserSubscriptionProductDTO id.
         * @member {string} id
         * @memberof taxi.UserSubscriptionProductDTO
         * @instance
         */
        UserSubscriptionProductDTO.prototype.id = "";

        /**
         * UserSubscriptionProductDTO name.
         * @member {string} name
         * @memberof taxi.UserSubscriptionProductDTO
         * @instance
         */
        UserSubscriptionProductDTO.prototype.name = "";

        /**
         * UserSubscriptionProductDTO price.
         * @member {number} price
         * @memberof taxi.UserSubscriptionProductDTO
         * @instance
         */
        UserSubscriptionProductDTO.prototype.price = 0;

        /**
         * UserSubscriptionProductDTO originalPrice.
         * @member {number} originalPrice
         * @memberof taxi.UserSubscriptionProductDTO
         * @instance
         */
        UserSubscriptionProductDTO.prototype.originalPrice = 0;

        /**
         * UserSubscriptionProductDTO isAvailable.
         * @member {boolean} isAvailable
         * @memberof taxi.UserSubscriptionProductDTO
         * @instance
         */
        UserSubscriptionProductDTO.prototype.isAvailable = false;

        /**
         * Creates a new UserSubscriptionProductDTO instance using the specified properties.
         * @function create
         * @memberof taxi.UserSubscriptionProductDTO
         * @static
         * @param {taxi.IUserSubscriptionProductDTO=} [properties] Properties to set
         * @returns {taxi.UserSubscriptionProductDTO} UserSubscriptionProductDTO instance
         */
        UserSubscriptionProductDTO.create = function create(properties) {
            return new UserSubscriptionProductDTO(properties);
        };

        /**
         * Creates a UserSubscriptionProductDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UserSubscriptionProductDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UserSubscriptionProductDTO} UserSubscriptionProductDTO
         */
        UserSubscriptionProductDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UserSubscriptionProductDTO)
                return d;
            var m = new $root.taxi.UserSubscriptionProductDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.price != null) {
                m.price = d.price | 0;
            }
            if (d.originalPrice != null) {
                m.originalPrice = d.originalPrice | 0;
            }
            if (d.isAvailable != null) {
                m.isAvailable = Boolean(d.isAvailable);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserSubscriptionProductDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UserSubscriptionProductDTO
         * @static
         * @param {taxi.UserSubscriptionProductDTO} m UserSubscriptionProductDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSubscriptionProductDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.price = 0;
                d.originalPrice = 0;
                d.isAvailable = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = m.price;
            }
            if (m.originalPrice != null && m.hasOwnProperty("originalPrice")) {
                d.originalPrice = m.originalPrice;
            }
            if (m.isAvailable != null && m.hasOwnProperty("isAvailable")) {
                d.isAvailable = m.isAvailable;
            }
            return d;
        };

        /**
         * Converts this UserSubscriptionProductDTO to JSON.
         * @function toJSON
         * @memberof taxi.UserSubscriptionProductDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSubscriptionProductDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserSubscriptionProductDTO;
    })();

    taxi.UserSubscriptionDTO = (function() {

        /**
         * Properties of a UserSubscriptionDTO.
         * @memberof taxi
         * @interface IUserSubscriptionDTO
         * @property {string|null} [id] UserSubscriptionDTO id
         * @property {string|null} [subscriptionProductName] UserSubscriptionDTO subscriptionProductName
         * @property {taxi.IPaymentMethodDTO|null} [paymentMethod] UserSubscriptionDTO paymentMethod
         * @property {taxi.UserSubscriptionDTO.Status|null} [status] UserSubscriptionDTO status
         * @property {number|null} [totalBillingCount] UserSubscriptionDTO totalBillingCount
         * @property {taxi.UserSubscriptionDTO.DeactivatedReasonType|null} [deactivatedReasonType] UserSubscriptionDTO deactivatedReasonType
         * @property {taxi.IUserSubscriptionBillingDTO|null} [currentBilling] UserSubscriptionDTO currentBilling
         * @property {taxi.ITimestamp|null} [nextBillingCreateAt] UserSubscriptionDTO nextBillingCreateAt
         */

        /**
         * Constructs a new UserSubscriptionDTO.
         * @memberof taxi
         * @classdesc Represents a UserSubscriptionDTO.
         * @implements IUserSubscriptionDTO
         * @constructor
         * @param {taxi.IUserSubscriptionDTO=} [p] Properties to set
         */
        function UserSubscriptionDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserSubscriptionDTO id.
         * @member {string} id
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         */
        UserSubscriptionDTO.prototype.id = "";

        /**
         * UserSubscriptionDTO subscriptionProductName.
         * @member {string} subscriptionProductName
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         */
        UserSubscriptionDTO.prototype.subscriptionProductName = "";

        /**
         * UserSubscriptionDTO paymentMethod.
         * @member {taxi.IPaymentMethodDTO|null|undefined} paymentMethod
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         */
        UserSubscriptionDTO.prototype.paymentMethod = null;

        /**
         * UserSubscriptionDTO status.
         * @member {taxi.UserSubscriptionDTO.Status} status
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         */
        UserSubscriptionDTO.prototype.status = 0;

        /**
         * UserSubscriptionDTO totalBillingCount.
         * @member {number} totalBillingCount
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         */
        UserSubscriptionDTO.prototype.totalBillingCount = 0;

        /**
         * UserSubscriptionDTO deactivatedReasonType.
         * @member {taxi.UserSubscriptionDTO.DeactivatedReasonType} deactivatedReasonType
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         */
        UserSubscriptionDTO.prototype.deactivatedReasonType = 0;

        /**
         * UserSubscriptionDTO currentBilling.
         * @member {taxi.IUserSubscriptionBillingDTO|null|undefined} currentBilling
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         */
        UserSubscriptionDTO.prototype.currentBilling = null;

        /**
         * UserSubscriptionDTO nextBillingCreateAt.
         * @member {taxi.ITimestamp|null|undefined} nextBillingCreateAt
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         */
        UserSubscriptionDTO.prototype.nextBillingCreateAt = null;

        /**
         * Creates a new UserSubscriptionDTO instance using the specified properties.
         * @function create
         * @memberof taxi.UserSubscriptionDTO
         * @static
         * @param {taxi.IUserSubscriptionDTO=} [properties] Properties to set
         * @returns {taxi.UserSubscriptionDTO} UserSubscriptionDTO instance
         */
        UserSubscriptionDTO.create = function create(properties) {
            return new UserSubscriptionDTO(properties);
        };

        /**
         * Creates a UserSubscriptionDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UserSubscriptionDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UserSubscriptionDTO} UserSubscriptionDTO
         */
        UserSubscriptionDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UserSubscriptionDTO)
                return d;
            var m = new $root.taxi.UserSubscriptionDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.subscriptionProductName != null) {
                m.subscriptionProductName = String(d.subscriptionProductName);
            }
            if (d.paymentMethod != null) {
                if (typeof d.paymentMethod !== "object")
                    throw TypeError(".taxi.UserSubscriptionDTO.paymentMethod: object expected");
                m.paymentMethod = $root.taxi.PaymentMethodDTO.fromObject(d.paymentMethod);
            }
            switch (d.status) {
            case "ACTIVE":
            case 0:
                m.status = 0;
                break;
            case "DEACTIVATED":
            case 1:
                m.status = 1;
                break;
            }
            if (d.totalBillingCount != null) {
                m.totalBillingCount = d.totalBillingCount | 0;
            }
            switch (d.deactivatedReasonType) {
            case "USER_REQUESTED":
            case 0:
                m.deactivatedReasonType = 0;
                break;
            case "PAY_FAILED":
            case 1:
                m.deactivatedReasonType = 1;
                break;
            case "PAY_TIMEOUT":
            case 2:
                m.deactivatedReasonType = 2;
                break;
            }
            if (d.currentBilling != null) {
                if (typeof d.currentBilling !== "object")
                    throw TypeError(".taxi.UserSubscriptionDTO.currentBilling: object expected");
                m.currentBilling = $root.taxi.UserSubscriptionBillingDTO.fromObject(d.currentBilling);
            }
            if (d.nextBillingCreateAt != null) {
                if (typeof d.nextBillingCreateAt !== "object")
                    throw TypeError(".taxi.UserSubscriptionDTO.nextBillingCreateAt: object expected");
                m.nextBillingCreateAt = $root.taxi.Timestamp.fromObject(d.nextBillingCreateAt);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserSubscriptionDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UserSubscriptionDTO
         * @static
         * @param {taxi.UserSubscriptionDTO} m UserSubscriptionDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSubscriptionDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.subscriptionProductName = "";
                d.paymentMethod = null;
                d.status = o.enums === String ? "ACTIVE" : 0;
                d.totalBillingCount = 0;
                d.deactivatedReasonType = o.enums === String ? "USER_REQUESTED" : 0;
                d.currentBilling = null;
                d.nextBillingCreateAt = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.subscriptionProductName != null && m.hasOwnProperty("subscriptionProductName")) {
                d.subscriptionProductName = m.subscriptionProductName;
            }
            if (m.paymentMethod != null && m.hasOwnProperty("paymentMethod")) {
                d.paymentMethod = $root.taxi.PaymentMethodDTO.toObject(m.paymentMethod, o);
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.taxi.UserSubscriptionDTO.Status[m.status] : m.status;
            }
            if (m.totalBillingCount != null && m.hasOwnProperty("totalBillingCount")) {
                d.totalBillingCount = m.totalBillingCount;
            }
            if (m.deactivatedReasonType != null && m.hasOwnProperty("deactivatedReasonType")) {
                d.deactivatedReasonType = o.enums === String ? $root.taxi.UserSubscriptionDTO.DeactivatedReasonType[m.deactivatedReasonType] : m.deactivatedReasonType;
            }
            if (m.currentBilling != null && m.hasOwnProperty("currentBilling")) {
                d.currentBilling = $root.taxi.UserSubscriptionBillingDTO.toObject(m.currentBilling, o);
            }
            if (m.nextBillingCreateAt != null && m.hasOwnProperty("nextBillingCreateAt")) {
                d.nextBillingCreateAt = $root.taxi.Timestamp.toObject(m.nextBillingCreateAt, o);
            }
            return d;
        };

        /**
         * Converts this UserSubscriptionDTO to JSON.
         * @function toJSON
         * @memberof taxi.UserSubscriptionDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSubscriptionDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Status enum.
         * @name taxi.UserSubscriptionDTO.Status
         * @enum {string}
         * @property {number} ACTIVE=0 ACTIVE value
         * @property {number} DEACTIVATED=1 DEACTIVATED value
         */
        UserSubscriptionDTO.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ACTIVE"] = 0;
            values[valuesById[1] = "DEACTIVATED"] = 1;
            return values;
        })();

        /**
         * DeactivatedReasonType enum.
         * @name taxi.UserSubscriptionDTO.DeactivatedReasonType
         * @enum {string}
         * @property {number} USER_REQUESTED=0 USER_REQUESTED value
         * @property {number} PAY_FAILED=1 PAY_FAILED value
         * @property {number} PAY_TIMEOUT=2 PAY_TIMEOUT value
         */
        UserSubscriptionDTO.DeactivatedReasonType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "USER_REQUESTED"] = 0;
            values[valuesById[1] = "PAY_FAILED"] = 1;
            values[valuesById[2] = "PAY_TIMEOUT"] = 2;
            return values;
        })();

        return UserSubscriptionDTO;
    })();

    taxi.UserSubscriptionBillingDTO = (function() {

        /**
         * Properties of a UserSubscriptionBillingDTO.
         * @memberof taxi
         * @interface IUserSubscriptionBillingDTO
         * @property {string|null} [id] UserSubscriptionBillingDTO id
         * @property {string|null} [subscriptionProductName] UserSubscriptionBillingDTO subscriptionProductName
         * @property {taxi.IPaymentMethodDTO|null} [paymentMethod] UserSubscriptionBillingDTO paymentMethod
         * @property {taxi.UserSubscriptionBillingDTO.Status|null} [status] UserSubscriptionBillingDTO status
         * @property {taxi.IUserSubscriptionBillingReceipt|null} [receipt] UserSubscriptionBillingDTO receipt
         * @property {number|null} [billingCount] UserSubscriptionBillingDTO billingCount
         * @property {taxi.ITimestamp|null} [validAfter] UserSubscriptionBillingDTO validAfter
         * @property {taxi.ITimestamp|null} [expiresAt] UserSubscriptionBillingDTO expiresAt
         * @property {taxi.ITimestamp|null} [chargedAt] UserSubscriptionBillingDTO chargedAt
         */

        /**
         * Constructs a new UserSubscriptionBillingDTO.
         * @memberof taxi
         * @classdesc Represents a UserSubscriptionBillingDTO.
         * @implements IUserSubscriptionBillingDTO
         * @constructor
         * @param {taxi.IUserSubscriptionBillingDTO=} [p] Properties to set
         */
        function UserSubscriptionBillingDTO(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserSubscriptionBillingDTO id.
         * @member {string} id
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.id = "";

        /**
         * UserSubscriptionBillingDTO subscriptionProductName.
         * @member {string} subscriptionProductName
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.subscriptionProductName = "";

        /**
         * UserSubscriptionBillingDTO paymentMethod.
         * @member {taxi.IPaymentMethodDTO|null|undefined} paymentMethod
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.paymentMethod = null;

        /**
         * UserSubscriptionBillingDTO status.
         * @member {taxi.UserSubscriptionBillingDTO.Status} status
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.status = 0;

        /**
         * UserSubscriptionBillingDTO receipt.
         * @member {taxi.IUserSubscriptionBillingReceipt|null|undefined} receipt
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.receipt = null;

        /**
         * UserSubscriptionBillingDTO billingCount.
         * @member {number} billingCount
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.billingCount = 0;

        /**
         * UserSubscriptionBillingDTO validAfter.
         * @member {taxi.ITimestamp|null|undefined} validAfter
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.validAfter = null;

        /**
         * UserSubscriptionBillingDTO expiresAt.
         * @member {taxi.ITimestamp|null|undefined} expiresAt
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.expiresAt = null;

        /**
         * UserSubscriptionBillingDTO chargedAt.
         * @member {taxi.ITimestamp|null|undefined} chargedAt
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         */
        UserSubscriptionBillingDTO.prototype.chargedAt = null;

        /**
         * Creates a new UserSubscriptionBillingDTO instance using the specified properties.
         * @function create
         * @memberof taxi.UserSubscriptionBillingDTO
         * @static
         * @param {taxi.IUserSubscriptionBillingDTO=} [properties] Properties to set
         * @returns {taxi.UserSubscriptionBillingDTO} UserSubscriptionBillingDTO instance
         */
        UserSubscriptionBillingDTO.create = function create(properties) {
            return new UserSubscriptionBillingDTO(properties);
        };

        /**
         * Creates a UserSubscriptionBillingDTO message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof taxi.UserSubscriptionBillingDTO
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {taxi.UserSubscriptionBillingDTO} UserSubscriptionBillingDTO
         */
        UserSubscriptionBillingDTO.fromObject = function fromObject(d) {
            if (d instanceof $root.taxi.UserSubscriptionBillingDTO)
                return d;
            var m = new $root.taxi.UserSubscriptionBillingDTO();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.subscriptionProductName != null) {
                m.subscriptionProductName = String(d.subscriptionProductName);
            }
            if (d.paymentMethod != null) {
                if (typeof d.paymentMethod !== "object")
                    throw TypeError(".taxi.UserSubscriptionBillingDTO.paymentMethod: object expected");
                m.paymentMethod = $root.taxi.PaymentMethodDTO.fromObject(d.paymentMethod);
            }
            switch (d.status) {
            case "COMPLETED":
            case 0:
                m.status = 0;
                break;
            case "FAILED":
            case 1:
                m.status = 1;
                break;
            case "REFUNDED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.receipt != null) {
                if (typeof d.receipt !== "object")
                    throw TypeError(".taxi.UserSubscriptionBillingDTO.receipt: object expected");
                m.receipt = $root.taxi.UserSubscriptionBillingReceipt.fromObject(d.receipt);
            }
            if (d.billingCount != null) {
                m.billingCount = d.billingCount | 0;
            }
            if (d.validAfter != null) {
                if (typeof d.validAfter !== "object")
                    throw TypeError(".taxi.UserSubscriptionBillingDTO.validAfter: object expected");
                m.validAfter = $root.taxi.Timestamp.fromObject(d.validAfter);
            }
            if (d.expiresAt != null) {
                if (typeof d.expiresAt !== "object")
                    throw TypeError(".taxi.UserSubscriptionBillingDTO.expiresAt: object expected");
                m.expiresAt = $root.taxi.Timestamp.fromObject(d.expiresAt);
            }
            if (d.chargedAt != null) {
                if (typeof d.chargedAt !== "object")
                    throw TypeError(".taxi.UserSubscriptionBillingDTO.chargedAt: object expected");
                m.chargedAt = $root.taxi.Timestamp.fromObject(d.chargedAt);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserSubscriptionBillingDTO message. Also converts values to other types if specified.
         * @function toObject
         * @memberof taxi.UserSubscriptionBillingDTO
         * @static
         * @param {taxi.UserSubscriptionBillingDTO} m UserSubscriptionBillingDTO
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSubscriptionBillingDTO.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.subscriptionProductName = "";
                d.paymentMethod = null;
                d.status = o.enums === String ? "COMPLETED" : 0;
                d.receipt = null;
                d.billingCount = 0;
                d.validAfter = null;
                d.expiresAt = null;
                d.chargedAt = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.subscriptionProductName != null && m.hasOwnProperty("subscriptionProductName")) {
                d.subscriptionProductName = m.subscriptionProductName;
            }
            if (m.paymentMethod != null && m.hasOwnProperty("paymentMethod")) {
                d.paymentMethod = $root.taxi.PaymentMethodDTO.toObject(m.paymentMethod, o);
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.taxi.UserSubscriptionBillingDTO.Status[m.status] : m.status;
            }
            if (m.receipt != null && m.hasOwnProperty("receipt")) {
                d.receipt = $root.taxi.UserSubscriptionBillingReceipt.toObject(m.receipt, o);
            }
            if (m.billingCount != null && m.hasOwnProperty("billingCount")) {
                d.billingCount = m.billingCount;
            }
            if (m.validAfter != null && m.hasOwnProperty("validAfter")) {
                d.validAfter = $root.taxi.Timestamp.toObject(m.validAfter, o);
            }
            if (m.expiresAt != null && m.hasOwnProperty("expiresAt")) {
                d.expiresAt = $root.taxi.Timestamp.toObject(m.expiresAt, o);
            }
            if (m.chargedAt != null && m.hasOwnProperty("chargedAt")) {
                d.chargedAt = $root.taxi.Timestamp.toObject(m.chargedAt, o);
            }
            return d;
        };

        /**
         * Converts this UserSubscriptionBillingDTO to JSON.
         * @function toJSON
         * @memberof taxi.UserSubscriptionBillingDTO
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSubscriptionBillingDTO.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Status enum.
         * @name taxi.UserSubscriptionBillingDTO.Status
         * @enum {string}
         * @property {number} COMPLETED=0 COMPLETED value
         * @property {number} FAILED=1 FAILED value
         * @property {number} REFUNDED=2 REFUNDED value
         */
        UserSubscriptionBillingDTO.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "COMPLETED"] = 0;
            values[valuesById[1] = "FAILED"] = 1;
            values[valuesById[2] = "REFUNDED"] = 2;
            return values;
        })();

        return UserSubscriptionBillingDTO;
    })();

    return taxi;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [p] Properties to set
             */
            function FileDescriptorSet(p) {
                this.file = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FileDescriptorSet)
                    return d;
                var m = new $root.google.protobuf.FileDescriptorSet();
                if (d.file) {
                    if (!Array.isArray(d.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    m.file = [];
                    for (var i = 0; i < d.file.length; ++i) {
                        if (typeof d.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        m.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(d.file[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} m FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.file = [];
                }
                if (m.file && m.file.length) {
                    d.file = [];
                    for (var j = 0; j < m.file.length; ++j) {
                        d.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(m.file[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [p] Properties to set
             */
            function FileDescriptorProto(p) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FileDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.FileDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d["package"] != null) {
                    m["package"] = String(d["package"]);
                }
                if (d.dependency) {
                    if (!Array.isArray(d.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    m.dependency = [];
                    for (var i = 0; i < d.dependency.length; ++i) {
                        m.dependency[i] = String(d.dependency[i]);
                    }
                }
                if (d.publicDependency) {
                    if (!Array.isArray(d.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    m.publicDependency = [];
                    for (var i = 0; i < d.publicDependency.length; ++i) {
                        m.publicDependency[i] = d.publicDependency[i] | 0;
                    }
                }
                if (d.weakDependency) {
                    if (!Array.isArray(d.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    m.weakDependency = [];
                    for (var i = 0; i < d.weakDependency.length; ++i) {
                        m.weakDependency[i] = d.weakDependency[i] | 0;
                    }
                }
                if (d.messageType) {
                    if (!Array.isArray(d.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    m.messageType = [];
                    for (var i = 0; i < d.messageType.length; ++i) {
                        if (typeof d.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        m.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(d.messageType[i]);
                    }
                }
                if (d.enumType) {
                    if (!Array.isArray(d.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    m.enumType = [];
                    for (var i = 0; i < d.enumType.length; ++i) {
                        if (typeof d.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        m.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(d.enumType[i]);
                    }
                }
                if (d.service) {
                    if (!Array.isArray(d.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    m.service = [];
                    for (var i = 0; i < d.service.length; ++i) {
                        if (typeof d.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        m.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(d.service[i]);
                    }
                }
                if (d.extension) {
                    if (!Array.isArray(d.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    m.extension = [];
                    for (var i = 0; i < d.extension.length; ++i) {
                        if (typeof d.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        m.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(d.extension[i]);
                    }
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.FileOptions.fromObject(d.options);
                }
                if (d.sourceCodeInfo != null) {
                    if (typeof d.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    m.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(d.sourceCodeInfo);
                }
                if (d.syntax != null) {
                    m.syntax = String(d.syntax);
                }
                return m;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} m FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.dependency = [];
                    d.messageType = [];
                    d.enumType = [];
                    d.service = [];
                    d.extension = [];
                    d.publicDependency = [];
                    d.weakDependency = [];
                }
                if (o.defaults) {
                    d.name = "";
                    d["package"] = "";
                    d.options = null;
                    d.sourceCodeInfo = null;
                    d.syntax = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m["package"] != null && m.hasOwnProperty("package")) {
                    d["package"] = m["package"];
                }
                if (m.dependency && m.dependency.length) {
                    d.dependency = [];
                    for (var j = 0; j < m.dependency.length; ++j) {
                        d.dependency[j] = m.dependency[j];
                    }
                }
                if (m.messageType && m.messageType.length) {
                    d.messageType = [];
                    for (var j = 0; j < m.messageType.length; ++j) {
                        d.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(m.messageType[j], o);
                    }
                }
                if (m.enumType && m.enumType.length) {
                    d.enumType = [];
                    for (var j = 0; j < m.enumType.length; ++j) {
                        d.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(m.enumType[j], o);
                    }
                }
                if (m.service && m.service.length) {
                    d.service = [];
                    for (var j = 0; j < m.service.length; ++j) {
                        d.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(m.service[j], o);
                    }
                }
                if (m.extension && m.extension.length) {
                    d.extension = [];
                    for (var j = 0; j < m.extension.length; ++j) {
                        d.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(m.extension[j], o);
                    }
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.FileOptions.toObject(m.options, o);
                }
                if (m.sourceCodeInfo != null && m.hasOwnProperty("sourceCodeInfo")) {
                    d.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(m.sourceCodeInfo, o);
                }
                if (m.publicDependency && m.publicDependency.length) {
                    d.publicDependency = [];
                    for (var j = 0; j < m.publicDependency.length; ++j) {
                        d.publicDependency[j] = m.publicDependency[j];
                    }
                }
                if (m.weakDependency && m.weakDependency.length) {
                    d.weakDependency = [];
                    for (var j = 0; j < m.weakDependency.length; ++j) {
                        d.weakDependency[j] = m.weakDependency[j];
                    }
                }
                if (m.syntax != null && m.hasOwnProperty("syntax")) {
                    d.syntax = m.syntax;
                }
                return d;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [p] Properties to set
             */
            function DescriptorProto(p) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.DescriptorProto)
                    return d;
                var m = new $root.google.protobuf.DescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.field) {
                    if (!Array.isArray(d.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    m.field = [];
                    for (var i = 0; i < d.field.length; ++i) {
                        if (typeof d.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        m.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(d.field[i]);
                    }
                }
                if (d.extension) {
                    if (!Array.isArray(d.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    m.extension = [];
                    for (var i = 0; i < d.extension.length; ++i) {
                        if (typeof d.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        m.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(d.extension[i]);
                    }
                }
                if (d.nestedType) {
                    if (!Array.isArray(d.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    m.nestedType = [];
                    for (var i = 0; i < d.nestedType.length; ++i) {
                        if (typeof d.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        m.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(d.nestedType[i]);
                    }
                }
                if (d.enumType) {
                    if (!Array.isArray(d.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    m.enumType = [];
                    for (var i = 0; i < d.enumType.length; ++i) {
                        if (typeof d.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        m.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(d.enumType[i]);
                    }
                }
                if (d.extensionRange) {
                    if (!Array.isArray(d.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    m.extensionRange = [];
                    for (var i = 0; i < d.extensionRange.length; ++i) {
                        if (typeof d.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        m.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(d.extensionRange[i]);
                    }
                }
                if (d.oneofDecl) {
                    if (!Array.isArray(d.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    m.oneofDecl = [];
                    for (var i = 0; i < d.oneofDecl.length; ++i) {
                        if (typeof d.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        m.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(d.oneofDecl[i]);
                    }
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.MessageOptions.fromObject(d.options);
                }
                if (d.reservedRange) {
                    if (!Array.isArray(d.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    m.reservedRange = [];
                    for (var i = 0; i < d.reservedRange.length; ++i) {
                        if (typeof d.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        m.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(d.reservedRange[i]);
                    }
                }
                if (d.reservedName) {
                    if (!Array.isArray(d.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    m.reservedName = [];
                    for (var i = 0; i < d.reservedName.length; ++i) {
                        m.reservedName[i] = String(d.reservedName[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} m DescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.field = [];
                    d.nestedType = [];
                    d.enumType = [];
                    d.extensionRange = [];
                    d.extension = [];
                    d.oneofDecl = [];
                    d.reservedRange = [];
                    d.reservedName = [];
                }
                if (o.defaults) {
                    d.name = "";
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.field && m.field.length) {
                    d.field = [];
                    for (var j = 0; j < m.field.length; ++j) {
                        d.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(m.field[j], o);
                    }
                }
                if (m.nestedType && m.nestedType.length) {
                    d.nestedType = [];
                    for (var j = 0; j < m.nestedType.length; ++j) {
                        d.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(m.nestedType[j], o);
                    }
                }
                if (m.enumType && m.enumType.length) {
                    d.enumType = [];
                    for (var j = 0; j < m.enumType.length; ++j) {
                        d.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(m.enumType[j], o);
                    }
                }
                if (m.extensionRange && m.extensionRange.length) {
                    d.extensionRange = [];
                    for (var j = 0; j < m.extensionRange.length; ++j) {
                        d.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(m.extensionRange[j], o);
                    }
                }
                if (m.extension && m.extension.length) {
                    d.extension = [];
                    for (var j = 0; j < m.extension.length; ++j) {
                        d.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(m.extension[j], o);
                    }
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.MessageOptions.toObject(m.options, o);
                }
                if (m.oneofDecl && m.oneofDecl.length) {
                    d.oneofDecl = [];
                    for (var j = 0; j < m.oneofDecl.length; ++j) {
                        d.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(m.oneofDecl[j], o);
                    }
                }
                if (m.reservedRange && m.reservedRange.length) {
                    d.reservedRange = [];
                    for (var j = 0; j < m.reservedRange.length; ++j) {
                        d.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(m.reservedRange[j], o);
                    }
                }
                if (m.reservedName && m.reservedName.length) {
                    d.reservedName = [];
                    for (var j = 0; j < m.reservedName.length; ++j) {
                        d.reservedName[j] = m.reservedName[j];
                    }
                }
                return d;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [p] Properties to set
                 */
                function ExtensionRange(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return d;
                    var m = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (d.start != null) {
                        m.start = d.start | 0;
                    }
                    if (d.end != null) {
                        m.end = d.end | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} m ExtensionRange
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.start = 0;
                        d.end = 0;
                    }
                    if (m.start != null && m.hasOwnProperty("start")) {
                        d.start = m.start;
                    }
                    if (m.end != null && m.hasOwnProperty("end")) {
                        d.end = m.end;
                    }
                    return d;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [p] Properties to set
                 */
                function ReservedRange(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return d;
                    var m = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (d.start != null) {
                        m.start = d.start | 0;
                    }
                    if (d.end != null) {
                        m.end = d.end | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} m ReservedRange
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.start = 0;
                        d.end = 0;
                    }
                    if (m.start != null && m.hasOwnProperty("start")) {
                        d.start = m.start;
                    }
                    if (m.end != null && m.hasOwnProperty("end")) {
                        d.end = m.end;
                    }
                    return d;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [p] Properties to set
             */
            function FieldDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FieldDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.FieldDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.number != null) {
                    m.number = d.number | 0;
                }
                switch (d.label) {
                case "LABEL_OPTIONAL":
                case 1:
                    m.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    m.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    m.label = 3;
                    break;
                }
                switch (d.type) {
                case "TYPE_DOUBLE":
                case 1:
                    m.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    m.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    m.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    m.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    m.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    m.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    m.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    m.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    m.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    m.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    m.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    m.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    m.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    m.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    m.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    m.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    m.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    m.type = 18;
                    break;
                }
                if (d.typeName != null) {
                    m.typeName = String(d.typeName);
                }
                if (d.extendee != null) {
                    m.extendee = String(d.extendee);
                }
                if (d.defaultValue != null) {
                    m.defaultValue = String(d.defaultValue);
                }
                if (d.oneofIndex != null) {
                    m.oneofIndex = d.oneofIndex | 0;
                }
                if (d.jsonName != null) {
                    m.jsonName = String(d.jsonName);
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.FieldOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} m FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.extendee = "";
                    d.number = 0;
                    d.label = o.enums === String ? "LABEL_OPTIONAL" : 1;
                    d.type = o.enums === String ? "TYPE_DOUBLE" : 1;
                    d.typeName = "";
                    d.defaultValue = "";
                    d.options = null;
                    d.oneofIndex = 0;
                    d.jsonName = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.extendee != null && m.hasOwnProperty("extendee")) {
                    d.extendee = m.extendee;
                }
                if (m.number != null && m.hasOwnProperty("number")) {
                    d.number = m.number;
                }
                if (m.label != null && m.hasOwnProperty("label")) {
                    d.label = o.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[m.label] : m.label;
                }
                if (m.type != null && m.hasOwnProperty("type")) {
                    d.type = o.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[m.type] : m.type;
                }
                if (m.typeName != null && m.hasOwnProperty("typeName")) {
                    d.typeName = m.typeName;
                }
                if (m.defaultValue != null && m.hasOwnProperty("defaultValue")) {
                    d.defaultValue = m.defaultValue;
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.FieldOptions.toObject(m.options, o);
                }
                if (m.oneofIndex != null && m.hasOwnProperty("oneofIndex")) {
                    d.oneofIndex = m.oneofIndex;
                }
                if (m.jsonName != null && m.hasOwnProperty("jsonName")) {
                    d.jsonName = m.jsonName;
                }
                return d;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {string}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {string}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [p] Properties to set
             */
            function OneofDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.OneofDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.OneofDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.OneofOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} m OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.OneofOptions.toObject(m.options, o);
                }
                return d;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [p] Properties to set
             */
            function EnumDescriptorProto(p) {
                this.value = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.EnumDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.EnumDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.value) {
                    if (!Array.isArray(d.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    m.value = [];
                    for (var i = 0; i < d.value.length; ++i) {
                        if (typeof d.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        m.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(d.value[i]);
                    }
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.EnumOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} m EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.value = [];
                }
                if (o.defaults) {
                    d.name = "";
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.value && m.value.length) {
                    d.value = [];
                    for (var j = 0; j < m.value.length; ++j) {
                        d.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(m.value[j], o);
                    }
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.EnumOptions.toObject(m.options, o);
                }
                return d;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [p] Properties to set
             */
            function EnumValueDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.EnumValueDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.number != null) {
                    m.number = d.number | 0;
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.EnumValueOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} m EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.number = 0;
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.number != null && m.hasOwnProperty("number")) {
                    d.number = m.number;
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.EnumValueOptions.toObject(m.options, o);
                }
                return d;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [p] Properties to set
             */
            function ServiceDescriptorProto(p) {
                this.method = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.ServiceDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.method) {
                    if (!Array.isArray(d.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    m.method = [];
                    for (var i = 0; i < d.method.length; ++i) {
                        if (typeof d.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        m.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(d.method[i]);
                    }
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.ServiceOptions.fromObject(d.options);
                }
                return m;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} m ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.method = [];
                }
                if (o.defaults) {
                    d.name = "";
                    d.options = null;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.method && m.method.length) {
                    d.method = [];
                    for (var j = 0; j < m.method.length; ++j) {
                        d.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(m.method[j], o);
                    }
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.ServiceOptions.toObject(m.options, o);
                }
                return d;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [p] Properties to set
             */
            function MethodDescriptorProto(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.MethodDescriptorProto)
                    return d;
                var m = new $root.google.protobuf.MethodDescriptorProto();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.inputType != null) {
                    m.inputType = String(d.inputType);
                }
                if (d.outputType != null) {
                    m.outputType = String(d.outputType);
                }
                if (d.options != null) {
                    if (typeof d.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    m.options = $root.google.protobuf.MethodOptions.fromObject(d.options);
                }
                if (d.clientStreaming != null) {
                    m.clientStreaming = Boolean(d.clientStreaming);
                }
                if (d.serverStreaming != null) {
                    m.serverStreaming = Boolean(d.serverStreaming);
                }
                return m;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} m MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                    d.inputType = "";
                    d.outputType = "";
                    d.options = null;
                    d.clientStreaming = false;
                    d.serverStreaming = false;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.inputType != null && m.hasOwnProperty("inputType")) {
                    d.inputType = m.inputType;
                }
                if (m.outputType != null && m.hasOwnProperty("outputType")) {
                    d.outputType = m.outputType;
                }
                if (m.options != null && m.hasOwnProperty("options")) {
                    d.options = $root.google.protobuf.MethodOptions.toObject(m.options, o);
                }
                if (m.clientStreaming != null && m.hasOwnProperty("clientStreaming")) {
                    d.clientStreaming = m.clientStreaming;
                }
                if (m.serverStreaming != null && m.hasOwnProperty("serverStreaming")) {
                    d.serverStreaming = m.serverStreaming;
                }
                return d;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [p] Properties to set
             */
            function FileOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FileOptions)
                    return d;
                var m = new $root.google.protobuf.FileOptions();
                if (d.javaPackage != null) {
                    m.javaPackage = String(d.javaPackage);
                }
                if (d.javaOuterClassname != null) {
                    m.javaOuterClassname = String(d.javaOuterClassname);
                }
                if (d.javaMultipleFiles != null) {
                    m.javaMultipleFiles = Boolean(d.javaMultipleFiles);
                }
                if (d.javaGenerateEqualsAndHash != null) {
                    m.javaGenerateEqualsAndHash = Boolean(d.javaGenerateEqualsAndHash);
                }
                if (d.javaStringCheckUtf8 != null) {
                    m.javaStringCheckUtf8 = Boolean(d.javaStringCheckUtf8);
                }
                switch (d.optimizeFor) {
                case "SPEED":
                case 1:
                    m.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    m.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    m.optimizeFor = 3;
                    break;
                }
                if (d.goPackage != null) {
                    m.goPackage = String(d.goPackage);
                }
                if (d.ccGenericServices != null) {
                    m.ccGenericServices = Boolean(d.ccGenericServices);
                }
                if (d.javaGenericServices != null) {
                    m.javaGenericServices = Boolean(d.javaGenericServices);
                }
                if (d.pyGenericServices != null) {
                    m.pyGenericServices = Boolean(d.pyGenericServices);
                }
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.ccEnableArenas != null) {
                    m.ccEnableArenas = Boolean(d.ccEnableArenas);
                }
                if (d.objcClassPrefix != null) {
                    m.objcClassPrefix = String(d.objcClassPrefix);
                }
                if (d.csharpNamespace != null) {
                    m.csharpNamespace = String(d.csharpNamespace);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} m FileOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.javaPackage = "";
                    d.javaOuterClassname = "";
                    d.optimizeFor = o.enums === String ? "SPEED" : 1;
                    d.javaMultipleFiles = false;
                    d.goPackage = "";
                    d.ccGenericServices = false;
                    d.javaGenericServices = false;
                    d.pyGenericServices = false;
                    d.javaGenerateEqualsAndHash = false;
                    d.deprecated = false;
                    d.javaStringCheckUtf8 = false;
                    d.ccEnableArenas = false;
                    d.objcClassPrefix = "";
                    d.csharpNamespace = "";
                }
                if (m.javaPackage != null && m.hasOwnProperty("javaPackage")) {
                    d.javaPackage = m.javaPackage;
                }
                if (m.javaOuterClassname != null && m.hasOwnProperty("javaOuterClassname")) {
                    d.javaOuterClassname = m.javaOuterClassname;
                }
                if (m.optimizeFor != null && m.hasOwnProperty("optimizeFor")) {
                    d.optimizeFor = o.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[m.optimizeFor] : m.optimizeFor;
                }
                if (m.javaMultipleFiles != null && m.hasOwnProperty("javaMultipleFiles")) {
                    d.javaMultipleFiles = m.javaMultipleFiles;
                }
                if (m.goPackage != null && m.hasOwnProperty("goPackage")) {
                    d.goPackage = m.goPackage;
                }
                if (m.ccGenericServices != null && m.hasOwnProperty("ccGenericServices")) {
                    d.ccGenericServices = m.ccGenericServices;
                }
                if (m.javaGenericServices != null && m.hasOwnProperty("javaGenericServices")) {
                    d.javaGenericServices = m.javaGenericServices;
                }
                if (m.pyGenericServices != null && m.hasOwnProperty("pyGenericServices")) {
                    d.pyGenericServices = m.pyGenericServices;
                }
                if (m.javaGenerateEqualsAndHash != null && m.hasOwnProperty("javaGenerateEqualsAndHash")) {
                    d.javaGenerateEqualsAndHash = m.javaGenerateEqualsAndHash;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.javaStringCheckUtf8 != null && m.hasOwnProperty("javaStringCheckUtf8")) {
                    d.javaStringCheckUtf8 = m.javaStringCheckUtf8;
                }
                if (m.ccEnableArenas != null && m.hasOwnProperty("ccEnableArenas")) {
                    d.ccEnableArenas = m.ccEnableArenas;
                }
                if (m.objcClassPrefix != null && m.hasOwnProperty("objcClassPrefix")) {
                    d.objcClassPrefix = m.objcClassPrefix;
                }
                if (m.csharpNamespace != null && m.hasOwnProperty("csharpNamespace")) {
                    d.csharpNamespace = m.csharpNamespace;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {string}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             * @property {boolean|null} [".taxi.clientDeprecatedMessage"] MessageOptions .taxi.clientDeprecatedMessage
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [p] Properties to set
             */
            function MessageOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MessageOptions .taxi.clientDeprecatedMessage.
             * @member {boolean} .taxi.clientDeprecatedMessage
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype[".taxi.clientDeprecatedMessage"] = false;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.MessageOptions)
                    return d;
                var m = new $root.google.protobuf.MessageOptions();
                if (d.messageSetWireFormat != null) {
                    m.messageSetWireFormat = Boolean(d.messageSetWireFormat);
                }
                if (d.noStandardDescriptorAccessor != null) {
                    m.noStandardDescriptorAccessor = Boolean(d.noStandardDescriptorAccessor);
                }
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.mapEntry != null) {
                    m.mapEntry = Boolean(d.mapEntry);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                if (d[".taxi.clientDeprecatedMessage"] != null) {
                    m[".taxi.clientDeprecatedMessage"] = Boolean(d[".taxi.clientDeprecatedMessage"]);
                }
                return m;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} m MessageOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.messageSetWireFormat = false;
                    d.noStandardDescriptorAccessor = false;
                    d.deprecated = false;
                    d.mapEntry = false;
                    d[".taxi.clientDeprecatedMessage"] = false;
                }
                if (m.messageSetWireFormat != null && m.hasOwnProperty("messageSetWireFormat")) {
                    d.messageSetWireFormat = m.messageSetWireFormat;
                }
                if (m.noStandardDescriptorAccessor != null && m.hasOwnProperty("noStandardDescriptorAccessor")) {
                    d.noStandardDescriptorAccessor = m.noStandardDescriptorAccessor;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.mapEntry != null && m.hasOwnProperty("mapEntry")) {
                    d.mapEntry = m.mapEntry;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                if (m[".taxi.clientDeprecatedMessage"] != null && m.hasOwnProperty(".taxi.clientDeprecatedMessage")) {
                    d[".taxi.clientDeprecatedMessage"] = m[".taxi.clientDeprecatedMessage"];
                }
                return d;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {boolean|null} [".taxi.clientDeprecatedField"] FieldOptions .taxi.clientDeprecatedField
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [p] Properties to set
             */
            function FieldOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .taxi.clientDeprecatedField.
             * @member {boolean} .taxi.clientDeprecatedField
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".taxi.clientDeprecatedField"] = false;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.FieldOptions)
                    return d;
                var m = new $root.google.protobuf.FieldOptions();
                switch (d.ctype) {
                case "STRING":
                case 0:
                    m.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    m.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    m.ctype = 2;
                    break;
                }
                if (d.packed != null) {
                    m.packed = Boolean(d.packed);
                }
                switch (d.jstype) {
                case "JS_NORMAL":
                case 0:
                    m.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    m.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    m.jstype = 2;
                    break;
                }
                if (d.lazy != null) {
                    m.lazy = Boolean(d.lazy);
                }
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.weak != null) {
                    m.weak = Boolean(d.weak);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                if (d[".taxi.clientDeprecatedField"] != null) {
                    m[".taxi.clientDeprecatedField"] = Boolean(d[".taxi.clientDeprecatedField"]);
                }
                return m;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} m FieldOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.ctype = o.enums === String ? "STRING" : 0;
                    d.packed = false;
                    d.deprecated = false;
                    d.lazy = false;
                    d.jstype = o.enums === String ? "JS_NORMAL" : 0;
                    d.weak = false;
                    d[".taxi.clientDeprecatedField"] = false;
                }
                if (m.ctype != null && m.hasOwnProperty("ctype")) {
                    d.ctype = o.enums === String ? $root.google.protobuf.FieldOptions.CType[m.ctype] : m.ctype;
                }
                if (m.packed != null && m.hasOwnProperty("packed")) {
                    d.packed = m.packed;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.lazy != null && m.hasOwnProperty("lazy")) {
                    d.lazy = m.lazy;
                }
                if (m.jstype != null && m.hasOwnProperty("jstype")) {
                    d.jstype = o.enums === String ? $root.google.protobuf.FieldOptions.JSType[m.jstype] : m.jstype;
                }
                if (m.weak != null && m.hasOwnProperty("weak")) {
                    d.weak = m.weak;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                if (m[".taxi.clientDeprecatedField"] != null && m.hasOwnProperty(".taxi.clientDeprecatedField")) {
                    d[".taxi.clientDeprecatedField"] = m[".taxi.clientDeprecatedField"];
                }
                return d;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {string}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {string}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [p] Properties to set
             */
            function OneofOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.OneofOptions)
                    return d;
                var m = new $root.google.protobuf.OneofOptions();
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} m OneofOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             * @property {boolean|null} [".taxi.clientDeprecatedEnum"] EnumOptions .taxi.clientDeprecatedEnum
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [p] Properties to set
             */
            function EnumOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * EnumOptions .taxi.clientDeprecatedEnum.
             * @member {boolean} .taxi.clientDeprecatedEnum
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype[".taxi.clientDeprecatedEnum"] = false;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.EnumOptions)
                    return d;
                var m = new $root.google.protobuf.EnumOptions();
                if (d.allowAlias != null) {
                    m.allowAlias = Boolean(d.allowAlias);
                }
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                if (d[".taxi.clientDeprecatedEnum"] != null) {
                    m[".taxi.clientDeprecatedEnum"] = Boolean(d[".taxi.clientDeprecatedEnum"]);
                }
                return m;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} m EnumOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.allowAlias = false;
                    d.deprecated = false;
                    d[".taxi.clientDeprecatedEnum"] = false;
                }
                if (m.allowAlias != null && m.hasOwnProperty("allowAlias")) {
                    d.allowAlias = m.allowAlias;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                if (m[".taxi.clientDeprecatedEnum"] != null && m.hasOwnProperty(".taxi.clientDeprecatedEnum")) {
                    d[".taxi.clientDeprecatedEnum"] = m[".taxi.clientDeprecatedEnum"];
                }
                return d;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             * @property {boolean|null} [".taxi.clientDeprecatedEnumValue"] EnumValueOptions .taxi.clientDeprecatedEnumValue
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [p] Properties to set
             */
            function EnumValueOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * EnumValueOptions .taxi.clientDeprecatedEnumValue.
             * @member {boolean} .taxi.clientDeprecatedEnumValue
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype[".taxi.clientDeprecatedEnumValue"] = false;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.EnumValueOptions)
                    return d;
                var m = new $root.google.protobuf.EnumValueOptions();
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                if (d[".taxi.clientDeprecatedEnumValue"] != null) {
                    m[".taxi.clientDeprecatedEnumValue"] = Boolean(d[".taxi.clientDeprecatedEnumValue"]);
                }
                return m;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} m EnumValueOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.deprecated = false;
                    d[".taxi.clientDeprecatedEnumValue"] = false;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                if (m[".taxi.clientDeprecatedEnumValue"] != null && m.hasOwnProperty(".taxi.clientDeprecatedEnumValue")) {
                    d[".taxi.clientDeprecatedEnumValue"] = m[".taxi.clientDeprecatedEnumValue"];
                }
                return d;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [p] Properties to set
             */
            function ServiceOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.ServiceOptions)
                    return d;
                var m = new $root.google.protobuf.ServiceOptions();
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} m ServiceOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.deprecated = false;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [p] Properties to set
             */
            function MethodOptions(p) {
                this.uninterpretedOption = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.MethodOptions)
                    return d;
                var m = new $root.google.protobuf.MethodOptions();
                if (d.deprecated != null) {
                    m.deprecated = Boolean(d.deprecated);
                }
                if (d.uninterpretedOption) {
                    if (!Array.isArray(d.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    m.uninterpretedOption = [];
                    for (var i = 0; i < d.uninterpretedOption.length; ++i) {
                        if (typeof d.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        m.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(d.uninterpretedOption[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} m MethodOptions
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.uninterpretedOption = [];
                }
                if (o.defaults) {
                    d.deprecated = false;
                }
                if (m.deprecated != null && m.hasOwnProperty("deprecated")) {
                    d.deprecated = m.deprecated;
                }
                if (m.uninterpretedOption && m.uninterpretedOption.length) {
                    d.uninterpretedOption = [];
                    for (var j = 0; j < m.uninterpretedOption.length; ++j) {
                        d.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(m.uninterpretedOption[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {number|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {number|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [p] Properties to set
             */
            function UninterpretedOption(p) {
                this.name = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {number} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {number} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.UninterpretedOption)
                    return d;
                var m = new $root.google.protobuf.UninterpretedOption();
                if (d.name) {
                    if (!Array.isArray(d.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    m.name = [];
                    for (var i = 0; i < d.name.length; ++i) {
                        if (typeof d.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        m.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(d.name[i]);
                    }
                }
                if (d.identifierValue != null) {
                    m.identifierValue = String(d.identifierValue);
                }
                if (d.positiveIntValue != null) {
                    if ($util.Long)
                        (m.positiveIntValue = $util.Long.fromValue(d.positiveIntValue)).unsigned = true;
                    else if (typeof d.positiveIntValue === "string")
                        m.positiveIntValue = parseInt(d.positiveIntValue, 10);
                    else if (typeof d.positiveIntValue === "number")
                        m.positiveIntValue = d.positiveIntValue;
                    else if (typeof d.positiveIntValue === "object")
                        m.positiveIntValue = new $util.LongBits(d.positiveIntValue.low >>> 0, d.positiveIntValue.high >>> 0).toNumber(true);
                }
                if (d.negativeIntValue != null) {
                    if ($util.Long)
                        (m.negativeIntValue = $util.Long.fromValue(d.negativeIntValue)).unsigned = false;
                    else if (typeof d.negativeIntValue === "string")
                        m.negativeIntValue = parseInt(d.negativeIntValue, 10);
                    else if (typeof d.negativeIntValue === "number")
                        m.negativeIntValue = d.negativeIntValue;
                    else if (typeof d.negativeIntValue === "object")
                        m.negativeIntValue = new $util.LongBits(d.negativeIntValue.low >>> 0, d.negativeIntValue.high >>> 0).toNumber();
                }
                if (d.doubleValue != null) {
                    m.doubleValue = Number(d.doubleValue);
                }
                if (d.stringValue != null) {
                    if (typeof d.stringValue === "string")
                        $util.base64.decode(d.stringValue, m.stringValue = $util.newBuffer($util.base64.length(d.stringValue)), 0);
                    else if (d.stringValue.length)
                        m.stringValue = d.stringValue;
                }
                if (d.aggregateValue != null) {
                    m.aggregateValue = String(d.aggregateValue);
                }
                return m;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} m UninterpretedOption
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.name = [];
                }
                if (o.defaults) {
                    d.identifierValue = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, true);
                        d.positiveIntValue = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.positiveIntValue = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.negativeIntValue = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.negativeIntValue = o.longs === String ? "0" : 0;
                    d.doubleValue = 0;
                    if (o.bytes === String)
                        d.stringValue = "";
                    else {
                        d.stringValue = [];
                        if (o.bytes !== Array)
                            d.stringValue = $util.newBuffer(d.stringValue);
                    }
                    d.aggregateValue = "";
                }
                if (m.name && m.name.length) {
                    d.name = [];
                    for (var j = 0; j < m.name.length; ++j) {
                        d.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(m.name[j], o);
                    }
                }
                if (m.identifierValue != null && m.hasOwnProperty("identifierValue")) {
                    d.identifierValue = m.identifierValue;
                }
                if (m.positiveIntValue != null && m.hasOwnProperty("positiveIntValue")) {
                    if (typeof m.positiveIntValue === "number")
                        d.positiveIntValue = o.longs === String ? String(m.positiveIntValue) : m.positiveIntValue;
                    else
                        d.positiveIntValue = o.longs === String ? $util.Long.prototype.toString.call(m.positiveIntValue) : o.longs === Number ? new $util.LongBits(m.positiveIntValue.low >>> 0, m.positiveIntValue.high >>> 0).toNumber(true) : m.positiveIntValue;
                }
                if (m.negativeIntValue != null && m.hasOwnProperty("negativeIntValue")) {
                    if (typeof m.negativeIntValue === "number")
                        d.negativeIntValue = o.longs === String ? String(m.negativeIntValue) : m.negativeIntValue;
                    else
                        d.negativeIntValue = o.longs === String ? $util.Long.prototype.toString.call(m.negativeIntValue) : o.longs === Number ? new $util.LongBits(m.negativeIntValue.low >>> 0, m.negativeIntValue.high >>> 0).toNumber() : m.negativeIntValue;
                }
                if (m.doubleValue != null && m.hasOwnProperty("doubleValue")) {
                    d.doubleValue = o.json && !isFinite(m.doubleValue) ? String(m.doubleValue) : m.doubleValue;
                }
                if (m.stringValue != null && m.hasOwnProperty("stringValue")) {
                    d.stringValue = o.bytes === String ? $util.base64.encode(m.stringValue, 0, m.stringValue.length) : o.bytes === Array ? Array.prototype.slice.call(m.stringValue) : m.stringValue;
                }
                if (m.aggregateValue != null && m.hasOwnProperty("aggregateValue")) {
                    d.aggregateValue = m.aggregateValue;
                }
                return d;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [p] Properties to set
                 */
                function NamePart(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return d;
                    var m = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (d.namePart != null) {
                        m.namePart = String(d.namePart);
                    }
                    if (d.isExtension != null) {
                        m.isExtension = Boolean(d.isExtension);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} m NamePart
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.namePart = "";
                        d.isExtension = false;
                    }
                    if (m.namePart != null && m.hasOwnProperty("namePart")) {
                        d.namePart = m.namePart;
                    }
                    if (m.isExtension != null && m.hasOwnProperty("isExtension")) {
                        d.isExtension = m.isExtension;
                    }
                    return d;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [p] Properties to set
             */
            function SourceCodeInfo(p) {
                this.location = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.SourceCodeInfo)
                    return d;
                var m = new $root.google.protobuf.SourceCodeInfo();
                if (d.location) {
                    if (!Array.isArray(d.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    m.location = [];
                    for (var i = 0; i < d.location.length; ++i) {
                        if (typeof d.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        m.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(d.location[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} m SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.location = [];
                }
                if (m.location && m.location.length) {
                    d.location = [];
                    for (var j = 0; j < m.location.length; ++j) {
                        d.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(m.location[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [p] Properties to set
                 */
                function Location(p) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return d;
                    var m = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (d.path) {
                        if (!Array.isArray(d.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        m.path = [];
                        for (var i = 0; i < d.path.length; ++i) {
                            m.path[i] = d.path[i] | 0;
                        }
                    }
                    if (d.span) {
                        if (!Array.isArray(d.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        m.span = [];
                        for (var i = 0; i < d.span.length; ++i) {
                            m.span[i] = d.span[i] | 0;
                        }
                    }
                    if (d.leadingComments != null) {
                        m.leadingComments = String(d.leadingComments);
                    }
                    if (d.trailingComments != null) {
                        m.trailingComments = String(d.trailingComments);
                    }
                    if (d.leadingDetachedComments) {
                        if (!Array.isArray(d.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        m.leadingDetachedComments = [];
                        for (var i = 0; i < d.leadingDetachedComments.length; ++i) {
                            m.leadingDetachedComments[i] = String(d.leadingDetachedComments[i]);
                        }
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} m Location
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.path = [];
                        d.span = [];
                        d.leadingDetachedComments = [];
                    }
                    if (o.defaults) {
                        d.leadingComments = "";
                        d.trailingComments = "";
                    }
                    if (m.path && m.path.length) {
                        d.path = [];
                        for (var j = 0; j < m.path.length; ++j) {
                            d.path[j] = m.path[j];
                        }
                    }
                    if (m.span && m.span.length) {
                        d.span = [];
                        for (var j = 0; j < m.span.length; ++j) {
                            d.span[j] = m.span[j];
                        }
                    }
                    if (m.leadingComments != null && m.hasOwnProperty("leadingComments")) {
                        d.leadingComments = m.leadingComments;
                    }
                    if (m.trailingComments != null && m.hasOwnProperty("trailingComments")) {
                        d.trailingComments = m.trailingComments;
                    }
                    if (m.leadingDetachedComments && m.leadingDetachedComments.length) {
                        d.leadingDetachedComments = [];
                        for (var j = 0; j < m.leadingDetachedComments.length; ++j) {
                            d.leadingDetachedComments[j] = m.leadingDetachedComments[j];
                        }
                    }
                    return d;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [p] Properties to set
             */
            function GeneratedCodeInfo(p) {
                this.annotation = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return d;
                var m = new $root.google.protobuf.GeneratedCodeInfo();
                if (d.annotation) {
                    if (!Array.isArray(d.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    m.annotation = [];
                    for (var i = 0; i < d.annotation.length; ++i) {
                        if (typeof d.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        m.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(d.annotation[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} m GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.annotation = [];
                }
                if (m.annotation && m.annotation.length) {
                    d.annotation = [];
                    for (var j = 0; j < m.annotation.length; ++j) {
                        d.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(m.annotation[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [p] Properties to set
                 */
                function Annotation(p) {
                    this.path = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(d) {
                    if (d instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return d;
                    var m = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (d.path) {
                        if (!Array.isArray(d.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        m.path = [];
                        for (var i = 0; i < d.path.length; ++i) {
                            m.path[i] = d.path[i] | 0;
                        }
                    }
                    if (d.sourceFile != null) {
                        m.sourceFile = String(d.sourceFile);
                    }
                    if (d.begin != null) {
                        m.begin = d.begin | 0;
                    }
                    if (d.end != null) {
                        m.end = d.end | 0;
                    }
                    return m;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} m Annotation
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.path = [];
                    }
                    if (o.defaults) {
                        d.sourceFile = "";
                        d.begin = 0;
                        d.end = 0;
                    }
                    if (m.path && m.path.length) {
                        d.path = [];
                        for (var j = 0; j < m.path.length; ++j) {
                            d.path[j] = m.path[j];
                        }
                    }
                    if (m.sourceFile != null && m.hasOwnProperty("sourceFile")) {
                        d.sourceFile = m.sourceFile;
                    }
                    if (m.begin != null && m.hasOwnProperty("begin")) {
                        d.begin = m.begin;
                    }
                    if (m.end != null && m.hasOwnProperty("end")) {
                        d.end = m.end;
                    }
                    return d;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
