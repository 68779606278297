import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import { taxi } from 'core'
import { formatDate } from '@angular/common'

@Pipe({
  name: 'kst',
})
export class KstPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
  ) {
  }

  transform(timestamp: taxi.ITimestamp | null | undefined, format: string): string {
    if (timestamp == null) {
      return ''
    }
    const epochTime = Number(timestamp.value)
    let kstHours = this.getKstHours(epochTime)
    if (kstHours == 0) {
      // 12시간제는 1 ~ 12시까지 표현하기 때문에 `오전 0시` 같은 표기가 불가능하다.
      // 따라서 KST기준 0시인 경우 항상 24시간제로 변경하여 표기한다.
      format = format.replace(RegExp('\\bh\\b'), 'H').replace(RegExp('\\bhh\\b'), 'HH')
    }
    return formatDate(Number(epochTime), format, this.locale, '+0900')
  }

  private getKstHours(timestamp: number): number {
    let date = new Date(timestamp)
    return (date.getUTCHours() + 9) % 24
  }
}
